import React from 'react';
import PolicyPage from './PolicyPage';

const TermsOfUsePage = () => {
  return (
    <PolicyPage title="Terms of Use">
      <p>
        PLEASE READ! https://www.scp911.com REQUIRES CONSIDERATION FOR AND AS A CONDITION OF ALLOWING YOU ACCESS.
      </p>

      <p>
        READING AND ACCEPTING THE TERMS OF USE AND READING AND ACCEPTING THE PROVISIONS OF THE PRIVACY 
        POLICY OF https://www.scp911.com ARE REQUIRED CONSIDERATIONS FOR https://www.scp911.com GRANTING 
        YOU THE RIGHT TO VISIT, READ OR INTERACT WITH IT.
      </p>

      <p>
        ALL PERSONS ARE DENIED ACCESS TO THIS SITE UNLESS THEY READ AND ACCEPT THE TERMS OF USE AND THE 
        PRIVACY POLICY.
      </p>

      <p>
        ALL PERSONS UNDER THE AGE OF 18 ARE DENIED ACCESS TO https://www.scp911.com. IF YOU ARE UNDER 18 
        YEARS OF AGE, IT IS UNLAWFUL FOR YOU TO VISIT, READ, OR INTERACT WITH https://www.scp911.com OR 
        ITS CONTENTS IN ANY MANNER. https://www.scp911.com SPECIFICALLY DENIES ACCESS TO ANY INDIVIDUAL 
        THAT IS COVERED BY THE CHILD ONLINE PRIVACY ACT (COPA) OF 1998.
      </p>

      <h2>Parties to the Terms of Use Agreement</h2>
      <p>
        Visitors, viewers, users, subscribers, members, affiliates, or customers, collectively referred 
        to herein as "Visitors," are parties to this agreement. The website and its owners and/or 
        operators are parties to this agreement, herein referred to as "Website."
      </p>

      <h2>Use of Information from this Website</h2>
      <p>
        Unless you have entered into an express written contract with this website to the contrary, 
        visitors, viewers, subscribers, members, affiliates, or customers have no right to use this 
        information in a commercial or public setting; they have no right to broadcast it, copy it, 
        save it, print it, sell it, or publish any portions of the content of this website.
      </p>

      <h2>Ownership of Website</h2>
      <p>
        The website and its contents are owned or licensed by the website. Material contained on the 
        website must be presumed to be proprietary and copyrighted. Visitors have no rights whatsoever 
        in the site content. Use of website content for any reason is unlawful unless it is done with 
        express contract or permission of the website.
      </p>

      <h2>Hyperlinking to Site, Co-Branding, "Framing" and Referencing Site Prohibited</h2>
      <p>
        Unless expressly authorized by website, no one may hyperlink this site, or portions thereof, 
        (including, but not limited to, logotypes, trademarks, branding or copyrighted material) to 
        theirs for any reason. Further, you are not allowed to reference the url (website address) of 
        this website in any commercial or non-commercial media without express permission, nor are you 
        allowed to 'frame' the site.
      </p>

      <h2>Disclaimer for Contents of Site</h2>
      <p>
        The website disclaims any responsibility for the accuracy of the content of this website. 
        Visitors assume the all risk of viewing, reading, using, or relying upon this information.
      </p>

      <h2>Disclaimer for Harm Caused to Your Computer or Software</h2>
      <p>
        The website assumes no responsibility for damage to computers or software of the visitor or any 
        person the visitor subsequently communicates with from corrupting code or data that is 
        inadvertently passed to the visitor's computer. Again, visitor views and interacts with this 
        site, or banners or pop-ups or advertising displayed thereon, at his own risk.
      </p>

      <h2>Jurisdiction and Venue</h2>
      <p>
        If any matter concerning this purchase shall be brought before a court of law, pre- or 
        post-arbitration, Viewer, visitor, member, subscriber or customer agrees to that the sole and 
        proper jurisdiction to be the state and city declared in the contact information of the web owner.
      </p>

      <h2>Applicable Law</h2>
      <p>
        Viewer, visitor, member, subscriber or customer agrees that the applicable law to be applied shall, 
        in all cases, be that of the state of the Seller.
      </p>
    </PolicyPage>
  );
};

export default TermsOfUsePage; 