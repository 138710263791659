import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/theme';
import { useSplash } from '../context/SplashContext';
import logo from '../assets/logo.png';

const SplashScreen = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const { isEnabled } = useSplash();

  useEffect(() => {
    // Function to check if page has finished loading
    const checkPageLoad = () => {
      if (document.readyState === 'complete') {
        setIsLoading(false);
        // Wait for curtain animation to complete before hiding splash screen
        setTimeout(() => {
          setIsVisible(false);
        }, 1000); // Match this with curtain animation duration
      }
    };

    // Add event listener for page load
    window.addEventListener('load', checkPageLoad);
    
    // Check immediately in case page is already loaded
    checkPageLoad();

    // Initial check for document ready state
    if (document.readyState === 'complete') {
      setIsLoading(false);
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    return () => {
      window.removeEventListener('load', checkPageLoad);
    };
  }, []);

  return (
    <>
      {children}
      {(isEnabled && isVisible) && (
        <SplashWrapper>
          <SplashCurtain isLoading={isLoading}>
            <LogoContainer>
              <Logo src={logo} alt="Security Central Protection" isLoading={isLoading} />
            </LogoContainer>
          </SplashCurtain>
        </SplashWrapper>
      )}
    </>
  );
};

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const curtainUp = keyframes`
  from { transform: translateY(0); }
  to { transform: translateY(-100%); }
`;

const SplashWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  pointer-events: none;
`;

const SplashCurtain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, ${colors.primary.start}, ${colors.primary.end});
  animation: ${curtainUp} 1s ease-in-out forwards;
  animation-delay: ${props => props.isLoading ? '9999s' : '0s'};
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  animation: ${spin} 2s infinite linear;
  animation-play-state: ${props => props.isLoading ? 'running' : 'paused'};
  will-change: transform;
`;

export default SplashScreen; 