import React from "react";
import styled from "styled-components";

const FeatureCard = ({ icon, altText, primaryText, secondaryText }) => {
  return (
    <CardWrapper>
      <IconWrapper>
        <FeatureIcon src={icon} alt={altText} />
      </IconWrapper>
      <FeatureText>
        <span>{primaryText}</span>
        <br />
        <span>{secondaryText}</span>
      </FeatureText>
    </CardWrapper>
  );
};

const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
`;

const IconWrapper = styled.div`
  animation: iconPulse 2s ease-in-out infinite;
  
  @keyframes iconPulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const FeatureIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const FeatureText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  animation: textFadeIn 0.5s ease-out forwards;
  
  @keyframes textFadeIn {
    from {
      opacity: 0.7;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default FeatureCard; 