import { functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';

export const emailService = {
  sendContactForm: async (formData) => {
    try {
      const sendEmail = httpsCallable(functions, 'sendContactForm');
      const result = await sendEmail(formData);
      return result.data;
    } catch (error) { 
      console.error('Error sending contact form:', error);
      throw error;
    }
  },

  sendQuoteRequest: async (quoteData) => {
    try {
      const sendQuote = httpsCallable(functions, 'sendQuoteRequest');
      const result = await sendQuote(quoteData);
      return result.data;
    } catch (error) {
      console.error('Error sending quote request:', error);
      throw error;
    }
  },

  subscribeToNewsletter: async (email) => {
    try {
      const subscribe = httpsCallable(functions, 'subscribeToNewsletter');
      const result = await subscribe({ email });
      return result.data;
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      throw error;
    }
  }
}; 