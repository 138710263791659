import React from 'react';
import styled from 'styled-components';

export const AnimatedButtonWrapper = styled.div`
  transition: all 0.3s ease;
  transform-origin: center;
  width: 100%;

  &:hover {
    transform: translateY(-2px) scale(1.02);
    filter: brightness(1.1);
    box-shadow: 0 4px 12px rgba(231, 28, 36, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

export default AnimatedButtonWrapper; 