import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { colors } from '../styles/SharedStyles';
import { DisclaimerMessage } from './DisclaimerMessage';
import { ProductRecommendation } from './ProductRecommendation';

const CartItem = ({ item, onUpdateQuantity, onRemove }) => (
  <CartItemContainer>
    <ItemImageContainer>
      {item.imageUrl ? (
        <ItemImage src={item.imageUrl} alt={item.name} />
      ) : (
        <PlaceholderImage>No Image</PlaceholderImage>
      )}
    </ItemImageContainer>
    <ItemDetails>
      <ItemName>{item.name}</ItemName>
      {item.type === 'package' && (
        <>
          <PackageLabel>Package</PackageLabel>
          <SystemComponents>
            {item.products?.map((product, index) => (
              <ComponentItem key={`product-${index}`}>
                • {product.name} × {product.packageQuantity || product.quantity || 1} (${((product.price || 0) * (product.packageQuantity || product.quantity || 1)).toFixed(2)})
              </ComponentItem>
            ))}
          </SystemComponents>
        </>
      )}
      {item.type === 'system' && (
        <SystemComponents>
          {item.components?.model && (
            <ComponentItem>
              • Virtual Guard: {item.components.model.name} (${item.components.model.price.toFixed(2)})
            </ComponentItem>
          )}
          {item.components?.nvr && (
            <ComponentItem>
              • NVR: {item.components.nvr.name} (${item.components.nvr.price.toFixed(2)})
            </ComponentItem>
          )}
          {item.components?.power && (
            <ComponentItem>
              • POE: {item.components.power.name} (${item.components.power.price.toFixed(2)})
            </ComponentItem>
          )}
          {item.components?.alarms?.map((alarm, index) => (
            <ComponentItem key={`alarm-${index}`}>
              • {alarm.name} × {alarm.quantity} (${(alarm.price * alarm.quantity).toFixed(2)})
            </ComponentItem>
          ))}
          {item.components?.cameras?.map((camera, index) => (
            <ComponentItem key={`camera-${index}`}>
              • {camera.name} × {camera.quantity} (${(camera.price * camera.quantity).toFixed(2)})
            </ComponentItem>
          ))}
          {item.components?.deterrence?.map((device, index) => (
            <ComponentItem key={`deterrence-${index}`}>
              • {device.name} × {device.quantity} (${(device.price * device.quantity).toFixed(2)})
            </ComponentItem>
          ))}
        </SystemComponents>
      )}
      <ItemPrice>${typeof item.price === 'number' ? item.price.toFixed(2) : parseFloat(item.price).toFixed(2)}</ItemPrice>
      <QuantityControls>
        <QuantityButton onClick={() => onUpdateQuantity(item.id, Math.max(1, item.quantity - 1))}>-</QuantityButton>
        <Quantity>{item.quantity}</Quantity>
        <QuantityButton onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}>+</QuantityButton>
      </QuantityControls>
    </ItemDetails>
    <RemoveButton onClick={() => onRemove(item.id)}>×</RemoveButton>
  </CartItemContainer>
);

export const CartWidget = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { cartItems, updateQuantity, removeFromCart, getTotalPrice } = useCart();

  const totalQuantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  useEffect(() => {
    // Re-render component when cartItems change
  }, [cartItems]);

  const handleCheckout = () => {
    setIsOpen(false);
    navigate('/checkout');
  };

  const handleBuyNow = () => {
    // Implementation of handleBuyNow function
  };

  return (
    <>
      <CartTab onClick={() => setIsOpen(!isOpen)}>
        Cart <CartCount>{totalQuantity}</CartCount>
      </CartTab>

      <CartSidebar data-isopen={isOpen}>
        <CartHeader>
          <CartTitle>Shopping Cart</CartTitle>
          <CloseButton onClick={() => setIsOpen(false)}>×</CloseButton>
        </CartHeader>

        <CartItemsContainer>
          {cartItems.length === 0 ? (
            <EmptyCart>
              <EmptyCartIcon>🛒</EmptyCartIcon>
              <EmptyCartText>Your cart is empty</EmptyCartText>
              <EmptyCartSubtext>Add some products to get started!</EmptyCartSubtext>
            </EmptyCart>
          ) : (
            <>
            <ProductRecommendation location="cart" />
              {cartItems.map(item => (
                <CartItem
                  key={item.id}
                  item={item}
                  onUpdateQuantity={updateQuantity}
                  onRemove={removeFromCart}
                />
              ))}
              
            </>
          )}
        </CartItemsContainer>

        {cartItems.length > 0 && (
          <CartFooter>
            <CartSummary>
              <SummaryRow>
                <SummaryLabel>Subtotal:</SummaryLabel>
                <SummaryValue>${getTotalPrice().toFixed(2)}</SummaryValue>
              </SummaryRow>
              <SummaryRow>
                <SummaryLabel>Shipping:</SummaryLabel>
                <SummaryValue>Free</SummaryValue>
              </SummaryRow>
              <TotalRow>
                <TotalLabel>Total:</TotalLabel>
                <TotalValue>${getTotalPrice().toFixed(2)}</TotalValue>
              </TotalRow>
            </CartSummary>
            
            <DisclaimerMessage location="cart" />
            
            <CheckoutButton onClick={handleCheckout}>
              Proceed to Checkout
            </CheckoutButton>
          </CartFooter>
        )}
      </CartSidebar>
      {isOpen && <Overlay onClick={() => setIsOpen(false)} />}
    </>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CartTab = styled.button`
  font-family: Tahoma, sans-serif;
  position: fixed;
  right: -30px;
  top: 50%;
  transform: rotate(-90deg) translateX(50%);
  background: linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%);
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(-90deg) translateX(45%);
  }
`;

const CartCount = styled.div`
  font-family: Tahoma, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: ${colors.primary.solid};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
`;

const CartSidebar = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props['data-isopen'] ? '0' : '-400px'};
  width: 400px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (max-width: 480px) {
    width: 100%;
    right: ${props => props['data-isopen'] ? '0' : '-100%'};
  }
`;

const CartHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%);
  color: white;
`;

const CartTitle = styled.h2`
  font-family: Tahoma, sans-serif;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 28px;
  color: white;
  cursor: pointer;
  padding: 0 10px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const CartItemsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const CartItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ItemImageContainer = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 8px;
  overflow: hidden;
  background: #f5f5f5;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px;
`;

const PlaceholderImage = styled.div`
  font-family: Tahoma, sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 12px;
`;

const ItemDetails = styled.div`
  flex: 1;
  min-width: 0;
`;

const ItemName = styled.div`
  font-family: Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const PackageLabel = styled.div`
  font-family: Tahoma, sans-serif;
  display: inline-block;
  padding: 2px 8px;
  background-color: ${colors.primary.solid};
  color: white;
  border-radius: 12px;
  font-size: 12px;
  margin-bottom: 5px;
`;

const ItemPrice = styled.div`
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: ${colors.primary.solid};
  font-weight: 600;
  margin-bottom: 8px;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const QuantityButton = styled.button`
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${colors.primary.solid};
    color: ${colors.primary.solid};
  }
`;

const Quantity = styled.span`
  font-family: Tahoma, sans-serif;
  min-width: 30px;
  text-align: center;
  font-weight: 500;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #999;
  font-size: 24px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${colors.primary.solid};
  }
`;

const EmptyCart = styled.div`
  text-align: center;
  padding: 40px 20px;
  color: #666;
`;

const EmptyCartIcon = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
  opacity: 0.5;
`;

const EmptyCartText = styled.div`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  color: #666;
  margin: 10px 0;
`;

const EmptyCartSubtext = styled.div`
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #999;
`;

const CartFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #eee;
  background: #f9f9f9;
`;

const CartSummary = styled.div`
  margin-bottom: 20px;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #666;
`;

const TotalRow = styled(SummaryRow)`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #eee;
  margin-bottom: 0;
`;

const SummaryLabel = styled.span`
  font-family: Tahoma, sans-serif;
  color: #666;
`;

const SummaryValue = styled.span`
  font-family: Tahoma, sans-serif;
  font-weight: 600;
`;

const TotalLabel = styled.span`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 600;
`;

const TotalValue = styled.span`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: ${colors.primary.solid};
`;

const CheckoutButton = styled.button`
  font-family: Tahoma, sans-serif;
  width: 100%;
  padding: 15px;
  background: linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(231, 28, 36, 0.2);
  }
`;

const SystemComponents = styled.div`
  margin: 10px 0;
  font-size: 12px;
  color: #666;
  background: #f9f9f9;
  padding: 8px;
  border-radius: 6px;
`;

const ComponentItem = styled.div`
  margin: 4px 0;
  padding-left: 10px;
  font-family: 'Tahoma', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:not(:last-child) {
    border-bottom: 1px dashed #eee;
    padding-bottom: 4px;
  }
`; 