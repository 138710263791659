import React from 'react';
import PolicyPage from './PolicyPage';

const PrivacyPolicyPage = () => {
  return (
    <PolicyPage title="Privacy Policy">
      <p>
        Welcome to https://www.scp911.com (the "Site"). We understand that privacy online is important 
        to users of our Site, especially when conducting business. This statement governs our privacy 
        policies with respect to those users of the Site ("Visitors") who visit without transacting 
        business and Visitors who register to transact business on the Site and make use of the various 
        services offered by Security Central Protection (collectively, "Services") ("Authorized Customers").
      </p>

      <p>
        "Personally Identifiable Information" refers to any information that identifies or can be used 
        to identify, contact, or locate the person to whom such information pertains, including, but not 
        limited to, name, address, phone number, fax number, email address, financial profiles, social 
        security number, and credit card information.
      </p>

      <h2>What Personally Identifiable Information is collected?</h2>
      <p>
        We may collect basic user profile information from all of our Visitors. We collect the following 
        additional information from our Authorized Customers: the names, addresses, phone numbers and 
        email addresses of Authorized Customers, the nature and size of the business, and the nature and 
        size of the advertising inventory that the Authorized Customer intends to purchase or sell.
      </p>

      <h2>What organizations are collecting the information?</h2>
      <p>
        In addition to our direct collection of information, our third party service vendors (such as 
        credit card companies, clearinghouses and banks) who may provide such services as credit, 
        insurance, and escrow services may collect this information from our Visitors and Authorized 
        Customers.
      </p>

      <h2>How does the Site use Personally Identifiable Information?</h2>
      <p>
        We use Personally Identifiable Information to customize the Site, to make appropriate service 
        offerings, and to fulfill buying and selling requests on the Site. We may email Visitors and 
        Authorized Customers about research or purchase and selling opportunities on the Site or 
        information related to the subject matter of the Site.
      </p>

      <h2>How is Personally Identifiable Information stored?</h2>
      <p>
        Personally Identifiable Information collected by Security Central Protection is securely stored 
        and is not accessible to third parties or employees of Security Central Protection except for 
        use as indicated above.
      </p>

      <h2>What choices are available to Visitors regarding collection, use and distribution of the information?</h2>
      <p>
        Visitors and Authorized Customers may opt out of receiving unsolicited information from or being 
        contacted by us and/or our vendors and affiliated agencies by responding to emails as instructed, 
        or by finding our contact information via our Contact Page, https://www.scp911.com/contact.
      </p>

      <h2>Are Cookies Used on the Site?</h2>
      <p>
        Cookies are used for a variety of reasons. We use Cookies to obtain information about the 
        preferences of our Visitors and the services they select. We also use Cookies for security 
        purposes to protect our Authorized Customers.
      </p>

      <h2>How does Security Central Protection use login information?</h2>
      <p>
        Security Central Protection uses login information, including, but not limited to, IP addresses, 
        ISPs, and browser types, to analyze trends, administer the Site, track a user's movement and use, 
        and gather broad demographic information.
      </p>

      <h2>How does the Site keep Personally Identifiable Information secure?</h2>
      <p>
        All of our employees are familiar with our security policy and practices. The Personally 
        Identifiable Information of our Visitors and Authorized Customers is only accessible to a limited 
        number of qualified employees who are given a password in order to gain access to the information.
      </p>

      <h2>How can Visitors correct any inaccuracies in Personally Identifiable Information?</h2>
      <p>
        Visitors and Authorized Customers may contact us to update Personally Identifiable Information 
        about them or to correct any inaccuracies by contacting us via our information available on our 
        Contact Page, https://www.scp911.com/contact.
      </p>

      <h2>Can a Visitor delete or deactivate Personally Identifiable Information collected by the Site?</h2>
      <p>
        We provide Visitors and Authorized Customers with a mechanism to delete/deactivate Personally 
        Identifiable Information from the Site's database by contacting us. However, because of backups 
        and records of deletions, it may be impossible to delete a Visitor's entry without retaining some 
        residual information.
      </p>

      <h2>Changes to Privacy Policy</h2>
      <p>
        We will let our Visitors and Authorized Customers know about changes to our privacy policy by 
        posting such changes on the Site.
      </p>
    </PolicyPage>
  );
};

export default PrivacyPolicyPage; 