import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { auth, db } from '../config/firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { colors } from '../styles/SharedStyles';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { isDevelopment } from '../utils/environment';

const ProfileContainer = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 1002;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  background: white;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const DefaultIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
`;

const DropdownPortal = ({ children, isOpen }) => {
  return isOpen ? createPortal(
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      pointerEvents: 'none',
    }}>
      {children}
    </div>,
    document.body
  ) : null;
};

const DropdownMenu = styled.div`
  position: fixed;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  pointer-events: auto;
  
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 12px;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
  }
`;

const Button = styled.button`
  padding: 10px;
  background: ${colors.primary.solid};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease;
  
  &:hover {
    background: ${colors.primary.dark};
  }
`;

const ToggleText = styled.p`
  font-size: 14px;
  color: ${colors.text};
  text-align: center;
  margin: 0;
  cursor: pointer;
  
  &:hover {
    color: ${colors.primary.solid};
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin: 0;
`;

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${colors.primary.solid};
  }
`;

const UserProfileButton = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // Set up auth state listener
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setUser({
              ...user,
              ...userDoc.data()
            });
          } else {
            setUser(user);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in:', result.user);
      setEmail('');
      setPassword('');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document in Firestore
      await setDoc(doc(db, 'users', result.user.uid), {
        email: result.user.email,
        name: name,
        createdAt: new Date(),
        userType: 'customer'
      });
      
      setEmail('');
      setPassword('');
      setName('');
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      setError(error.message);
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setError(null);
  };

  const getDropdownPosition = () => {
    const profileButton = document.querySelector('[data-user-profile]');
    if (!profileButton) return {};
    
    const rect = profileButton.getBoundingClientRect();
    return {
      top: rect.bottom + 8,
      right: window.innerWidth - rect.right,
    };
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 150);
  };

  // Only show in development/staging
  if (!isDevelopment()) {
    return null;
  }

  if (loading) {
    return <DefaultIcon />;
  }

  return (
    <ProfileContainer 
      data-user-profile
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {user ? (
        <ProfileImage 
          src={user.profileImage || `https://ui-avatars.com/api/?name=${user.email}&background=e71c24&color=fff`}
          alt={user.email}
        />
      ) : (
        <DefaultIcon>
          <svg viewBox="0 0 24 24">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
          </svg>
        </DefaultIcon>
      )}
      
      <DropdownPortal isOpen={showDropdown}>
        <DropdownMenu style={getDropdownPosition()}>
          {user ? (
            <>
              <div>
                <p>Logged in as: {user.email}</p>
                {user.name && <p>Name: {user.name}</p>}
              </div>
              <Button onClick={() => navigate('/profile')}>My Profile</Button>
              {user.userType === 'admin' && (
                <Button onClick={() => navigate('/admin')}>Admin Dashboard</Button>
              )}
              <Button onClick={handleLogout}>Logout</Button>
            </>
          ) : (
            <>
              <Form onSubmit={isLogin ? handleLogin : handleSignup}>
                {!isLogin && (
                  <Input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                )}
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <PasswordContainer>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <PasswordToggle 
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </PasswordToggle>
                </PasswordContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <Button type="submit">
                  {isLogin ? 'Login' : 'Sign Up'}
                </Button>
              </Form>
              <ToggleText onClick={toggleForm}>
                {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Login'}
              </ToggleText>
            </>
          )}
        </DropdownMenu>
      </DropdownPortal>
    </ProfileContainer>
  );
};

export default UserProfileButton; 