import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/SharedStyles';

export const SearchBar = ({ value, onChange, placeholder = "Search..." }) => {
  return (
    <SearchContainer>
      <SearchInput
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <SearchIcon>🔍</SearchIcon>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0;
  
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 40px 12px 15px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${colors.primary};
  }

  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.1);
  }
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
`; 