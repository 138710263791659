import { db } from '../config/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

// Function to test if disclaimers are properly saved to Firestore
export const testDisclaimers = async () => {
  try {
    // Get the disclaimers document
    const disclaimerDoc = await getDoc(doc(db, 'siteSettings', 'disclaimers'));
    
    if (disclaimerDoc.exists()) {
      const data = disclaimerDoc.data();
      console.log('Disclaimers document exists:', data);
      
      // Test updating the disclaimers
      await setDoc(doc(db, 'siteSettings', 'disclaimers'), {
        ...data,
        cart: {
          ...data.cart,
          enabled: true
        },
        checkout: {
          ...data.checkout,
          enabled: true
        },
        updatedAt: new Date()
      });
      
      console.log('Disclaimers updated successfully!');
      
      // Get the updated disclaimers
      const updatedDoc = await getDoc(doc(db, 'siteSettings', 'disclaimers'));
      console.log('Updated disclaimers:', updatedDoc.data());
      
      return true;
    } else {
      console.log('Disclaimers document does not exist.');
      return false;
    }
  } catch (err) {
    console.error('Error testing disclaimers:', err);
    return false;
  }
};

// Export a function to enable disclaimers
export const enableDisclaimers = async () => {
  try {
    // Get the disclaimers document
    const disclaimerDoc = await getDoc(doc(db, 'siteSettings', 'disclaimers'));
    
    if (disclaimerDoc.exists()) {
      const data = disclaimerDoc.data();
      
      // Enable both disclaimers
      await setDoc(doc(db, 'siteSettings', 'disclaimers'), {
        ...data,
        cart: {
          ...data.cart,
          enabled: true
        },
        checkout: {
          ...data.checkout,
          enabled: true
        },
        updatedAt: new Date()
      });
      
      console.log('Disclaimers enabled successfully!');
      return true;
    } else {
      console.log('Disclaimers document does not exist.');
      return false;
    }
  } catch (err) {
    console.error('Error enabling disclaimers:', err);
    return false;
  }
}; 