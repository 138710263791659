import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled, { keyframes } from 'styled-components';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { colors, breakpoints } from '../styles/theme';
import { usePageTitle } from '../hooks/usePageTitle';
import { db } from '../config/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { FaPhone, FaDownload, FaPlay, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Add VideoModal component at the top
const VideoModal = ({ video, onClose }) => {
  if (!video) return null;
  
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        <VideoContainer>
          {video.videoUrl && (
            <iframe
              src={video.videoUrl}
              title={video.title}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              poster={video.thumbnailUrl}
            />
          )}
        </VideoContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const SupportPage = () => {
  usePageTitle('Support');
  const [supportContent, setSupportContent] = useState(null);
  const [tutorials, setTutorials] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [canMakePhoneCalls, setCanMakePhoneCalls] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    // Check if device supports phone calls
    const checkPhoneCapability = () => {
      // Check if running in a mobile context
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      setCanMakePhoneCalls(isMobile);
    };

    checkPhoneCapability();
    
    const fetchContent = async () => {
      try {
        const supportDoc = await getDoc(doc(db, 'siteSettings', 'support'));
        if (supportDoc.exists()) {
          setSupportContent(supportDoc.data());
        }

        const tutorialsSnapshot = await getDocs(collection(db, 'tutorials'));
        const tutorialsData = tutorialsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTutorials(tutorialsData);

        const documentsSnapshot = await getDocs(collection(db, 'supportDocuments'));
        const documentsData = documentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setDocuments(documentsData);
      } catch (error) {
        console.error('Error fetching support content:', error);
      }
    };

    fetchContent();
  }, []);

  const handlePhoneCall = () => {
    const phoneNumber = supportContent?.phoneNumber || '1-800-123-4567';
    const formattedNumber = phoneNumber.replace(/[^\d+]/g, ''); // Remove non-numeric characters except +
    
    if (canMakePhoneCalls) {
      window.location.href = `tel:${formattedNumber}`;
    } else {
      // If device can't make calls, copy number to clipboard
      navigator.clipboard.writeText(phoneNumber)
        .then(() => {
          alert(`Phone number ${phoneNumber} has been copied to your clipboard`);
        })
        .catch(() => {
          alert(`Please call us at ${phoneNumber}`);
        });
    }
  };

  return (
    <PageContainer>
      <HeaderBar />
      <MainContent>
        <SupportWrapper>
          <HeaderSection>
            <SupportHeader>
              <HeaderContent>
                <HeaderText>{supportContent?.headerText || 'All support services are local to USA 24/7 | Never need to go through a prompt to reach an operator…'}</HeaderText>
                {supportContent?.subheaderText && (
                  <SubheaderText dangerouslySetInnerHTML={{ __html: supportContent.subheaderText }} />
                )}
                <ButtonGroup>
                  <PhoneButton 
                    onClick={handlePhoneCall} 
                    title={canMakePhoneCalls ? "Call Support" : "Copy Support Number"}
                  >
                    <FaPhone style={{ marginRight: '10px' }} />
                    <span>
                      {canMakePhoneCalls ? 'Call Support: ' : 'Support: '}
                      {supportContent?.phoneNumber || '1-800-123-4567'}
                    </span>
                  </PhoneButton>
                  <ContactButton to="/contact">
                    Message a Representative
                  </ContactButton>
                </ButtonGroup>
              </HeaderContent>
            </SupportHeader>
          </HeaderSection>

          {/* Tutorials Section */}
          <SectionTitle>How-to Videos & Tutorials</SectionTitle>
          <TutorialsGrid>
            {tutorials.map((tutorial) => (
              <TutorialCard key={tutorial.id}>
                <VideoThumbnail>
                  <PlayButton onClick={() => setSelectedVideo(tutorial)}>
                    <FaPlay />
                  </PlayButton>
                  {tutorial.thumbnailUrl ? (
                    <ThumbnailImage src={tutorial.thumbnailUrl} alt={tutorial.title} />
                  ) : (
                    <video
                      src={tutorial.videoUrl}
                      style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, objectFit: 'cover' }}
                      muted
                      playsInline
                    />
                  )}
                </VideoThumbnail>
                <TutorialContent>
                  <TutorialTitle>{tutorial.title}</TutorialTitle>
                  {tutorial.steps && (
                    <StepsList>
                      {tutorial.steps.map((step, index) => (
                        <StepItem key={index}>{step}</StepItem>
                      ))}
                    </StepsList>
                  )}
                </TutorialContent>
              </TutorialCard>
            ))}
          </TutorialsGrid>

          {/* Video Modal */}
          <VideoModal video={selectedVideo} onClose={() => setSelectedVideo(null)} />

          {/* Documents Section */}
          <SectionTitle>Product Documentation</SectionTitle>
          <DocumentsGrid>
            {documents.map((doc) => (
              <DocumentCard key={doc.id}>
                <DocumentInfo>
                  <DocumentTitle>{doc.title}</DocumentTitle>
                  <DocumentType>{doc.type}</DocumentType>
                </DocumentInfo>
                <DownloadButton href={doc.fileUrl} target="_blank" download>
                  <FaDownload style={{ marginRight: '5px' }} />
                  Download
                </DownloadButton>
              </DocumentCard>
            ))}
          </DocumentsGrid>
        </SupportWrapper>
      </MainContent>
      <Footer />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #f5f5f5 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 400px;
    background: linear-gradient(135deg, ${colors.primary.start}15 0%, ${colors.primary.end}15 100%);
    z-index: 0;
  }
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 80px;
  position: relative;
  z-index: 1;
  animation: ${fadeIn} 0.5s ease-out;
`;

const SupportWrapper = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
  min-height: 100vh;

  @media (max-width: ${breakpoints.md}px) {
    margin: 20px auto;
  }
`;

const HeaderSection = styled.div`
  margin-bottom: 60px;
  animation: ${slideUp} 0.6s ease-out;
`;

const SupportHeader = styled.div`
  background: linear-gradient(135deg, #ffffff 0%, #f8f8f8 100%);
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  animation: ${slideUp} 0.6s ease-out;
  border: 1px solid rgba(231, 28, 36, 0.1);

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    text-align: center;
    padding: 30px 20px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const HeaderText = styled.h1`
  font-size: 28px;
  color: ${colors.primary.solid};
  margin: 0;
  flex: 1;
  font-family: 'Tahoma', sans-serif;
  line-height: 1.4;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);

  @media (max-width: ${breakpoints.md}px) {
    font-size: 22px;
  }
`;

const PhoneButton = styled.button`
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-family: 'Tahoma', sans-serif;
  box-shadow: 0 4px 15px rgba(231, 28, 36, 0.2);
  min-width: 200px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(231, 28, 36, 0.3);
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    min-width: unset;
    padding: 12px 20px;
    font-size: 16px;
    margin: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 32px;
  color: ${colors.primary.solid};
  margin: 60px 0 30px;
  text-align: center;
  font-family: 'Tahoma', sans-serif;
  position: relative;
  animation: ${slideUp} 0.7s ease-out;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, ${colors.primary.start}, ${colors.primary.end});
    border-radius: 2px;
  }
`;

const TutorialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 20px;
  animation: ${slideUp} 0.8s ease-out;
`;

const TutorialCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
  }
`;

const VideoThumbnail = styled.div`
  position: relative;
  padding-top: 56.25%;
  background: #f5f5f5;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(231, 28, 36, 0.9);
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 1;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.primary.solid};
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

const ThumbnailImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TutorialContent = styled.div`
  padding: 25px;
`;

const TutorialTitle = styled.h3`
  font-size: 20px;
  color: ${colors.primary.solid};
  margin: 0 0 15px;
  font-family: 'Tahoma', sans-serif;
  line-height: 1.4;
`;

const StepsList = styled.ol`
  margin: 0;
  padding-left: 20px;
`;

const StepItem = styled.li`
  color: #666;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Tahoma', sans-serif;
`;

const DocumentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 30px;
  animation: ${slideUp} 0.9s ease-out;
`;

const DocumentCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  border: 1px solid rgba(231, 28, 36, 0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
  }
`;

const DocumentInfo = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const DocumentTitle = styled.h4`
  font-size: 18px;
  color: #333;
  margin: 0 0 8px;
  font-family: 'Tahoma', sans-serif;
`;

const DocumentType = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  font-family: 'Tahoma', sans-serif;
  opacity: 0.8;
`;

const DownloadButton = styled.a`
  background: ${colors.primary.solid}10;
  color: ${colors.primary.solid};
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
  font-family: 'Tahoma', sans-serif;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
    transform: translateY(-2px);
  }
`;

const SubheaderText = styled.div`
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  font-family: 'Tahoma', sans-serif;
  margin-top: 20px;
  max-width: 800px;
  
  p {
    margin: 0 0 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${colors.primary.solid};
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${colors.primary.end};
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const ModalContent = styled.div`
  position: relative;
  width: 90%;
  max-width: 1000px;
  background: white;
  border-radius: 15px;
  padding: 20px;
  animation: ${slideUp} 0.3s ease-out;
`;

const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -40px;
  right: -40px;
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  transition: all 0.2s ease;
  
  &:hover {
    transform: scale(1.1);
    background: ${colors.primary.solid};
    color: white;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    width: 100%;
    gap: 15px;
    padding: 0 25px;
    margin-top: 25px;
  }
`;

const ContactButton = styled(Link)`
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-family: 'Tahoma', sans-serif;
  text-decoration: none;
  min-width: 200px;
  box-shadow: 0 4px 15px rgba(231, 28, 36, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(231, 28, 36, 0.3);
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    min-width: unset;
    padding: 12px 0px;
    font-size: 16px;
    margin: 0;
    
  }
`;

export default SupportPage; 