import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../styles/SharedStyles';

const StepComponent = ({ 
  step, 
  isOpen, 
  selected, 
  onSelection, 
  onStepChange,
  onQuantityChange,
  allowMultiSelect = false,
  index,
  currentStep,
  options
}) => {
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // Determine if this is a product-based step
  const isProductStep = ['alarms', 'cameras', 'model', 'power', 'nvr', 'deterrence'].includes(step.id);
  const isBasicStep = ['space', 'system-type'].includes(step.id);

  // Ensure scrolling is always enabled
  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  console.log(`Rendering StepComponent for ${step.id}:`, {
    step,
    isOpen,
    selected,
    options,
    allowMultiSelect
  });

  // Find the selected option to get its image
  const getSelectedImage = () => {
    // For alarms, cameras, deterrence steps, show the selected component's image or first selected
    if ((step.id === 'alarms' || step.id === 'cameras' || step.id === 'model' || step.id === 'power' || step.id === 'deterrence') && options && options.length > 0) {
      if (Array.isArray(selected) && selected.length > 0) {
        // For multi-select, show the first selected item's image
        const firstSelected = selected[0];
        const selectedOption = options.find(opt => opt.id === firstSelected.id);
        return selectedOption?.imageUrl || selectedOption?.image || step.image;
      } else if (selected) {
        // For single select, show the selected item's image
        const selectedOption = options.find(opt => opt.id === selected.id);
        return selectedOption?.imageUrl || selectedOption?.image || step.image;
      }
      // If nothing selected, show the first option's image
      return options[0].imageUrl || options[0].image || step.image;
    }

    // For other steps, use the selected image or step default
    if (!selected) return step.image;
    if (Array.isArray(selected)) {
      return selected[0]?.imageUrl || selected[0]?.image || step.image;
    }
    const selectedOption = options?.find(opt => opt.id === selected.id);
    return selectedOption?.imageUrl || selectedOption?.image || step.image;
  };

  const isOptionSelected = (option) => {
    if (!selected) return false;
    if (Array.isArray(selected)) {
      return selected.some(s => s.id === option.id);
    }
    return selected.id === option.id;
  };

  const handleQuantityChange = (option, newQuantity, event) => {
    // If event is provided, stop propagation
    if (event) {
      event.stopPropagation();
    }
    
    // Parse the new quantity, defaulting to 0 if invalid
    let quantity = parseInt(newQuantity) || 0;
    
    // Ensure quantity is within bounds
    // Use minQuantity if defined, otherwise default to 0
    const minQuantity = option.minQuantity !== undefined ? option.minQuantity : 0;
    quantity = Math.max(minQuantity, quantity);
    quantity = Math.min(quantity, option.maxQuantity || 99);
    
    // Only update if quantity changed
    if (quantity !== getOptionQuantity(option)) {
      const updatedOption = { ...option, quantity };
      onSelection(step.id, updatedOption);
    }
    
    // Ensure scrolling is maintained
    document.body.style.overflow = 'auto';
  };

  const handleOptionClick = (option) => {
    if (option.disabled) return;
    onSelection(step.id, option);
    
    // Ensure scrolling is maintained
    document.body.style.overflow = 'auto';
  };

  const getOptionQuantity = (option) => {
    if (Array.isArray(selected)) {
      const selectedOption = selected.find(s => s.id === option.id);
      return selectedOption?.quantity || 0;
    }
    return option.quantity || 0;
  };

  const toggleDescription = (optionId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [optionId]: !prev[optionId]
    }));
    
    // Ensure scrolling is maintained
    document.body.style.overflow = 'auto';
  };

  return (
    <StepSection $isOpen={isOpen}>
      <StepHeader $completed={!!selected}>
        <StepTitle>
          <span>{step.title}</span> {step.question}
        </StepTitle>
      </StepHeader>
      {isOpen && (
        <StepContent $active={isOpen}>
          {step.note && (
            <RequirementNote>
              <span>⚠️</span> {step.note}
            </RequirementNote>
          )}
          <ContentLayout $isProductStep={isProductStep}>
            {!isProductStep && (
              <ProductImage>
                <img src={getSelectedImage()} alt={step.title} />
              </ProductImage>
            )}
            <OptionsContainer $isProductStep={isProductStep}>
              {options && options.map(option => (
                <OptionCard
                  key={option.id}
                  $selected={isOptionSelected(option)}
                  disabled={option.disabled}
                  onClick={() => handleOptionClick(option)}
                  $isProductStep={isProductStep}
                >
                  <OptionHeader>
                    <OptionContent>
                      {isProductStep && option.imageUrl && (
                        <ProductThumbnail src={option.imageUrl} alt={option.title} />
                      )}
                      <ProductInfo>
                        <OptionTitle>{option.title}</OptionTitle>
                        <OptionDescription>
                          {option.shortDescription || option.description.slice(0, 100)}
                          {!isBasicStep && option.description && !expandedDescriptions[option.id] && (
                            <ShowMoreButton
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDescription(option.id);
                              }}
                            >
                              Show More
                            </ShowMoreButton>
                          )}
                        </OptionDescription>
                        {option.note && (
                          <RequirementNote>
                            <span>⚠️</span> {option.note}
                          </RequirementNote>
                        )}
                        {!option.disabled && (step.id === 'alarms' || step.id === 'cameras' || step.id === 'deterrence') && !expandedDescriptions[option.id] && (
                          <QuantityControls onClick={(e) => e.stopPropagation()}>
                            <QuantityButton 
                              onClick={(e) => {
                                const currentQuantity = getOptionQuantity(option);
                                handleQuantityChange(option, currentQuantity - 1, e);
                              }}
                              disabled={getOptionQuantity(option) <= (option.minQuantity !== undefined ? option.minQuantity : 0)}
                            >
                              -
                            </QuantityButton>
                            <QuantityInput
                              type="number"
                              min={option.minQuantity !== undefined ? option.minQuantity : 0}
                              max={option.maxQuantity || 99}
                              value={getOptionQuantity(option)}
                              onChange={(e) => handleQuantityChange(option, e.target.value, e)}
                              onClick={(e) => e.stopPropagation()}
                            />
                            <QuantityButton 
                              onClick={(e) => {
                                const currentQuantity = getOptionQuantity(option);
                                handleQuantityChange(option, currentQuantity + 1, e);
                              }}
                              disabled={getOptionQuantity(option) >= (option.maxQuantity || 99)}
                            >
                              +
                            </QuantityButton>
                          </QuantityControls>
                        )}
                        {!isBasicStep && expandedDescriptions[option.id] && (
                          <>
                            <FullDescription>
                              {option.description}
                            </FullDescription>
                            <ShowMoreButton
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDescription(option.id);
                              }}
                            >
                              Show Less
                            </ShowMoreButton>
                            {!option.disabled && (step.id === 'alarms' || step.id === 'cameras' || step.id === 'deterrence') && (
                              <QuantityControls onClick={(e) => e.stopPropagation()}>
                                <QuantityButton 
                                  onClick={(e) => {
                                    const currentQuantity = getOptionQuantity(option);
                                    handleQuantityChange(option, currentQuantity - 1, e);
                                  }}
                                  disabled={getOptionQuantity(option) <= (option.minQuantity !== undefined ? option.minQuantity : 0)}
                                >
                                  -
                                </QuantityButton>
                                <QuantityInput
                                  type="number"
                                  min={option.minQuantity !== undefined ? option.minQuantity : 0}
                                  max={option.maxQuantity || 99}
                                  value={getOptionQuantity(option)}
                                  onChange={(e) => handleQuantityChange(option, e.target.value, e)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <QuantityButton 
                                  onClick={(e) => {
                                    const currentQuantity = getOptionQuantity(option);
                                    handleQuantityChange(option, currentQuantity + 1, e);
                                  }}
                                  disabled={getOptionQuantity(option) >= (option.maxQuantity || 99)}
                                >
                                  +
                                </QuantityButton>
                              </QuantityControls>
                            )}
                          </>
                        )}
                        {option.subtext && (
                          <OptionSubtext>{option.subtext}</OptionSubtext>
                        )}
                        {option.disabledReason && (
                          <DisabledReason>{option.disabledReason}</DisabledReason>
                        )}
                        {option.disclaimer && (
                          <div style={{ marginTop: '15px' }}>
                            {option.disclaimer}
                          </div>
                        )}
                      </ProductInfo>
                    </OptionContent>
                    <OptionPrice>
                      {option.free ? (
                        'Free'
                      ) : option.price === 0 ? null : (
                        <>
                          ${option.price}
                          {option.monthly && '/mo'}
                        </>
                      )}
                    </OptionPrice>
                  </OptionHeader>
                </OptionCard>
              ))}
            </OptionsContainer>
          </ContentLayout>
        </StepContent>
      )}
    </StepSection>
  );
};

const StepSection = styled.section`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-bottom: 20px;
  max-height: ${props => props.$isOpen ? '2000px' : '80px'};
`;

const StepHeader = styled.div`
  background: ${props => props.$completed ? '#f8f8f8' : colors.primary.solid};
  padding: 20px;
  color: ${props => props.$completed ? '#333' : 'white'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
`;

const StepTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 500;

  span {
    font-weight: 700;
  }
`;

const StepContent = styled.div`
  padding: ${props => props.$active ? '30px' : '0'};
  height: ${props => props.$active ? 'auto' : '0'};
  opacity: ${props => props.$active ? 1 : 0};
  overflow: hidden;
  visibility: ${props => props.$active ? 'visible' : 'hidden'};
  transition: all 0.3s ease;
  will-change: height, opacity, visibility;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 576px) {
    padding: ${props => props.$active ? '20px 15px' : '0'};
  }
`;

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.$isProductStep ? '1fr' : '300px 1fr'};
  gap: 30px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProductImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%; // This creates a square aspect ratio
  border-radius: 8px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  box-sizing: border-box;
`;

const OptionCard = styled.div`
  border: 2px solid ${props => props.disabled ? '#ddd' : props.$selected ? colors.primary.solid : '#eee'};
  border-radius: 8px;
  padding: 20px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  background: ${props => props.disabled ? '#f5f5f5' : props.$selected ? `${colors.primary.solid}10` : 'white'};
  opacity: ${props => props.disabled ? 0.7 : 1};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;

  &:hover {
    transform: ${props => !props.disabled && 'translateY(-2px)'};
    box-shadow: ${props => !props.disabled && '0 4px 8px rgba(0, 0, 0, 0.1)'};
  }
  
  @media (max-width: 576px) {
    padding: 15px;
    width: 100%;
    overflow-wrap: break-word;
  }
`;

const OptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const OptionContent = styled.div`
  display: flex;
  gap: 15px;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  max-width: 100%;
  
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const ProductThumbnail = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 5px;
  background: white;
  padding: 5px;
  flex-shrink: 0;
  
  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
`;

const ProductInfo = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  padding-right: 15px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding-right: 0;
    width: 100%;
  }
`;

const OptionTitle = styled.h3`
  margin: 0 0 5px;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 576px) {
    white-space: normal;
    word-break: break-word;
  }
`;

const OptionDescription = styled.div`
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: ${props => !props.expanded ? '10px' : '0'};
  
  @media (max-width: 576px) {
    word-break: break-word;
  }
`;

const OptionSubtext = styled.p`
  margin: 5px 0 0;
  font-size: 12px;
  opacity: 0.7;
`;

const OptionPrice = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.primary.solid};
  margin-left: auto;
  padding-left: 15px;
  display: flex;
  align-items: center;
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  
  @media (max-width: 768px) {
    margin-left: 0;
    padding-left: 0;
    margin-top: 10px;
  }
`;

const DisabledReason = styled.div`
  color: #999;
  font-size: 12px;
  font-style: italic;
  margin-top: 8px;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 0;
  width: fit-content;
  margin-bottom: 10px;
  
  @media (max-width: 576px) {
    max-width: 100%;
    overflow: visible;
  }
`;

const QuantityButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: white;
      color: ${colors.primary.solid};
    }
  }
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  font-size: 16px;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
  
  @media (max-width: 576px) {
    width: 50px;
  }
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary.solid};
  font-size: 12px;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  font-weight: 600;
  
  &:hover {
    text-decoration: underline;
  }
`;

const FullDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #666;
  
  @media (max-width: 576px) {
    word-break: break-word;
    overflow-wrap: break-word;
  }
`;

const RequirementNote = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  span {
    margin-right: 8px;
    font-size: 16px;
  }
  
  @media (max-width: 576px) {
    font-size: 13px;
    padding: 8px 12px;
    word-break: break-word;
  }
`;

export default StepComponent; 