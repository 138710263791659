import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { colors } from '../../styles/SharedStyles';
import { db } from '../../config/firebase';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { GradientButton } from '../GradientButton';

export const MessageManager = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const messagesRef = collection(db, 'contactSubmissions');
      const q = query(messagesRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const messagesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.() || new Date(doc.data().createdAt)
      }));
      
      setMessages(messagesData);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError('Failed to load messages. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <View style={styles.container}>
        <Text style={styles.loadingText}>Loading messages...</Text>
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.container}>
        <Text style={styles.errorText}>{error}</Text>
        <GradientButton
          onPress={fetchMessages}
          text="Retry"
          variant="secondary"
          style={styles.retryButton}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Contact Form Messages</Text>
      
      <View style={styles.contentContainer}>
        <View style={styles.messagesList}>
          <ScrollView style={styles.scrollView}>
            {messages.length === 0 ? (
              <Text style={styles.emptyText}>No messages yet</Text>
            ) : (
              messages.map((message) => (
                <View 
                  key={message.id} 
                  style={[
                    styles.messageCard,
                    selectedMessage?.id === message.id && styles.selectedCard
                  ]}
                  onClick={() => setSelectedMessage(message)}
                >
                  <View style={styles.messageHeader}>
                    <Text style={styles.messageName}>{message.name}</Text>
                    <Text style={styles.messageDate}>
                      {formatDate(message.createdAt)}
                    </Text>
                  </View>
                  <Text style={styles.messageEmail}>{message.email}</Text>
                  {message.phone && (
                    <Text style={styles.messagePhone}>{message.phone}</Text>
                  )}
                  <Text 
                    style={styles.messagePreview}
                    numberOfLines={2}
                  >
                    {message.message}
                  </Text>
                </View>
              ))
            )}
          </ScrollView>
        </View>

        {selectedMessage && (
          <View style={styles.messageDetail}>
            <View style={styles.detailHeader}>
              <Text style={styles.detailTitle}>Message Details</Text>
              <GradientButton
                onPress={() => setSelectedMessage(null)}
                text="Close"
                variant="secondary"
                style={styles.closeButton}
              />
            </View>
            <View style={styles.detailContent}>
              <View style={styles.detailField}>
                <Text style={styles.fieldLabel}>From:</Text>
                <Text style={styles.fieldValue}>{selectedMessage.name}</Text>
              </View>
              <View style={styles.detailField}>
                <Text style={styles.fieldLabel}>Email:</Text>
                <Text style={styles.fieldValue}>{selectedMessage.email}</Text>
              </View>
              {selectedMessage.phone && (
                <View style={styles.detailField}>
                  <Text style={styles.fieldLabel}>Phone:</Text>
                  <Text style={styles.fieldValue}>{selectedMessage.phone}</Text>
                </View>
              )}
              <View style={styles.detailField}>
                <Text style={styles.fieldLabel}>Date:</Text>
                <Text style={styles.fieldValue}>
                  {formatDate(selectedMessage.createdAt)}
                </Text>
              </View>
              <View style={[styles.detailField, styles.messageField]}>
                <Text style={styles.fieldLabel}>Message:</Text>
                <Text style={styles.messageText}>{selectedMessage.message}</Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary.solid,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 20,
  },
  messagesList: {
    flex: 1,
    maxWidth: 400,
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  scrollView: {
    flex: 1,
  },
  messageCard: {
    padding: 15,
    backgroundColor: colors.white,
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#eee',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  selectedCard: {
    borderColor: colors.primary.solid,
    boxShadow: '0 2px 4px rgba(231, 28, 36, 0.1)',
  },
  messageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  messageName: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary.solid,
  },
  messageDate: {
    fontSize: 12,
    color: '#666',
  },
  messageEmail: {
    fontSize: 14,
    color: '#666',
    marginBottom: 5,
  },
  messagePhone: {
    fontSize: 14,
    color: '#666',
    marginBottom: 5,
  },
  messagePreview: {
    fontSize: 14,
    color: '#333',
    lineHeight: 1.4,
  },
  messageDetail: {
    flex: 2,
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  detailHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  detailTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: colors.primary.solid,
  },
  closeButton: {
    minWidth: 100,
    height: 36,
  },
  detailContent: {
    flex: 1,
  },
  detailField: {
    marginBottom: 15,
  },
  fieldLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: '#666',
    marginBottom: 5,
  },
  fieldValue: {
    fontSize: 16,
    color: '#333',
  },
  messageField: {
    flex: 1,
  },
  messageText: {
    fontSize: 16,
    color: '#333',
    lineHeight: 1.6,
  },
  loadingText: {
    fontSize: 16,
    color: '#666',
    textAlign: 'center',
  },
  errorText: {
    fontSize: 16,
    color: colors.error,
    textAlign: 'center',
    marginBottom: 20,
  },
  retryButton: {
    alignSelf: 'center',
    minWidth: 120,
  },
  emptyText: {
    fontSize: 16,
    color: '#666',
    textAlign: 'center',
    fontStyle: 'italic',
  },
}); 