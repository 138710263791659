import React from 'react';
import { TouchableOpacity, Text, Platform, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { colors, gradients, shadows, spacing, typography } from '../styles/theme';

export const GradientButton = ({ 
  onPress, 
  text, 
  style, 
  textStyle,
  variant = 'primary',
  disabled = false 
}) => {
  // On web, we use CSS gradients
  if (Platform.OS === 'web') {
    const webStyle = {
      background: variant === 'primary' 
        ? `linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end})`
        : colors.white,
      border: variant === 'primary' 
        ? 'none' 
        : `2px solid ${colors.primary.solid}`,
    };

    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={[
          styles.button,
          { background: webStyle.background, border: webStyle.border },
          disabled && styles.disabled,
          style
        ]}
      >
        <Text style={[
          styles.text,
          variant === 'primary' ? styles.primaryText : styles.secondaryText,
          disabled && styles.disabledText,
          textStyle
        ]}>
          {text}
        </Text>
      </TouchableOpacity>
    );
  }

  // On native, we use LinearGradient
  if (variant === 'primary') {
    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={[styles.buttonWrapper, disabled && styles.disabled, style]}
      >
        <LinearGradient
          colors={gradients.primary.colors}
          start={gradients.primary.start}
          end={gradients.primary.end}
          style={styles.gradient}
        >
          <Text style={[styles.text, styles.primaryText, disabled && styles.disabledText, textStyle]}>
            {text}
          </Text>
        </LinearGradient>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[styles.button, styles.secondary, disabled && styles.disabled, style]}
    >
      <Text style={[styles.text, styles.secondaryText, disabled && styles.disabledText, textStyle]}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonWrapper: {
    borderRadius: 50,
    ...shadows.medium,
    minWidth: 320,
    maxWidth: '100%',
  },
  button: {
    borderRadius: 50,
    padding: spacing.md,
    minWidth: 320,
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    ...shadows.medium,
 
  },
  gradient: {
    borderRadius: 50,
    padding: spacing.md,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  secondary: {
    backgroundColor: colors.white,
    borderWidth: 2,
    borderColor: colors.primary.solid,
  },
  text: {
    ...typography.button,
  },
  primaryText: {
    color: colors.white,
    fontSize: 22,
  },
  secondaryText: {
    color: colors.primary.solid,
  },
  disabled: {
    opacity: 0.5,
  },
  disabledText: {
    opacity: 0.8,
  },
}); 