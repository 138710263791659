import { 
  getAuth, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { db } from '../config/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

const auth = getAuth();

// Helper function to check if string is an email
const isEmail = (str) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(str);
};

// Helper function to get user document by username
const getUserByUsername = async (username) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);
  
  if (querySnapshot.empty) {
    throw new Error('User not found');
  }
  
  return querySnapshot.docs[0].data();
};

export const signIn = async (identifier, password) => {
  try {
    let email = identifier;
    
    // If identifier is not an email, look up the user by username
    if (!isEmail(identifier)) {
      const userData = await getUserByUsername(identifier);
      email = userData.email;
    }
    
    // Sign in with Firebase Auth
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    
    // Get the user's document from Firestore
    const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
    
    if (!userDoc.exists()) {
      await signOut();
      throw new Error('User document not found');
    }
    
    const userData = userDoc.data();
    
    // Check if user has admin role
    if (userData.userType !== 'admin') {
      await signOut();
      throw new Error('Unauthorized access');
    }
    
    return userCredential.user;
  } catch (error) {
    console.error('Sign in error:', error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
};

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const onAuthChange = (callback) => {
  return onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Get the user's document from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists() && userDoc.data().userType === 'admin') {
        callback(user);
      } else {
        // If user is not an admin, sign them out
        await signOut();
        callback(null);
      }
    } else {
      callback(null);
    }
  });
}; 