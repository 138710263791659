import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, ScrollView } from 'react-native';
import { colors, typography } from '../../styles/SharedStyles';
import { db } from '../../config/firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { GradientButton } from '../../components/GradientButton';

let isFirebaseInitialized = false;

export const AnnouncementManager = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  const [currentAnnouncement, setCurrentAnnouncement] = useState({
    title: '',
    content: '',
    type: '',
    active: true,
    displayType: 'banner' // 'banner' or 'sidebar'
  });

  useEffect(() => {
    const initializeAnnouncements = async () => {
      if (!db) {
        setError('Firebase is not initialized properly');
        setInitialLoad(false);
        return;
      }

      try {
        if (!isFirebaseInitialized) {
          const announcementsRef = collection(db, 'announcements');
          isFirebaseInitialized = true;
        }

        const querySnapshot = await getDocs(collection(db, 'announcements'));
        const announcementsData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            startDate: data.startDate ? data.startDate.toDate() : null,
            endDate: data.endDate ? data.endDate.toDate() : null
          };
        });
        setAnnouncements(announcementsData);
      } catch (err) {
        console.log('Error initializing announcements:', err);
        if (err.code === 'permission-denied') {
          setError('You do not have permission to access this data');
        } else if (err.code === 'unavailable') {
          setError('Unable to connect to the database. Please check your internet connection.');
        } else {
          setError('Error loading announcements: ' + err.message);
        }
        setAnnouncements([]);
      } finally {
        setInitialLoad(false);
      }
    };

    initializeAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    if (!loading && db) {
      setLoading(true);
      setError(null);
      try {
        const querySnapshot = await getDocs(collection(db, 'announcements'));
        const announcementsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAnnouncements(announcementsData);
      } catch (err) {
        console.error('Error fetching announcements:', err);
        if (err.code === 'unavailable') {
          setError('Unable to connect to the database. Please check your internet connection.');
        } else {
          setError('Failed to fetch announcements: ' + err.message);
        }
      }
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    console.log('Submitting announcement:', currentAnnouncement);
    if (!db) {
      setError('Database connection is not available');
      return;
    }

    if (!currentAnnouncement.title || !currentAnnouncement.content) {
      setError('Title and content are required');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      if (currentAnnouncement.id) {
        const announcementRef = doc(db, 'announcements', currentAnnouncement.id);
        const { id, ...announcementData } = currentAnnouncement;
        await updateDoc(announcementRef, announcementData);
      } else {
        await addDoc(collection(db, 'announcements'), currentAnnouncement);
      }
      await fetchAnnouncements();
      setCurrentAnnouncement({
        title: '',
        content: '',
        type: '',
        active: true,
        displayType: 'banner'
      });
    } catch (err) {
      console.error('Error saving announcement:', err);
      if (err.code === 'unavailable') {
        setError('Unable to save. Please check your internet connection.');
      } else {
        setError('Failed to save announcement: ' + err.message);
      }
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this announcement?')) {
      setLoading(true);
      setError(null);
      try {
        await deleteDoc(doc(db, 'announcements', id));
        await fetchAnnouncements();
      } catch (err) {
        setError('Failed to delete announcement: ' + err.message);
        console.error('Error deleting announcement:', err);
      }
      setLoading(false);
    }
  };

  const handleEdit = (announcement) => {
    console.log('Editing announcement:', announcement);
    setCurrentAnnouncement({
      ...announcement,
      startDate: isNaN(new Date(announcement.startDate)) ? new Date() : new Date(announcement.startDate),
      endDate: isNaN(new Date(announcement.endDate)) ? new Date() : new Date(announcement.endDate)
    });
  };

  const toggleActive = async (announcement) => {
    if (!loading && db) {
      setLoading(true);
      setError(null);
      try {
        const announcementRef = doc(db, 'announcements', announcement.id);
        await updateDoc(announcementRef, {
          active: !announcement.active
        });
        await fetchAnnouncements();
      } catch (err) {
        console.error('Error toggling announcement:', err);
        if (err.code === 'unavailable') {
          setError('Unable to update. Please check your internet connection.');
        } else {
          setError('Failed to toggle announcement status: ' + err.message);
        }
      }
      setLoading(false);
    }
  };

  if (initialLoad) {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Announcement Manager</Text>
        <View style={styles.loadingContainer}>
          <Text style={styles.loadingText}>Initializing Announcements...</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Announcement Manager</Text>
      
      {error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          <GradientButton
            onPress={() => setError(null)}
            text="Dismiss"
            variant="secondary"
            style={styles.dismissButton}
            textStyle={styles.buttonText}
          />
        </View>
      )}
      
      {/* Announcement Form */}
      <View style={styles.form}>
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Display Type</Text>
            <select
              style={styles.input}
              value={currentAnnouncement.displayType}
              onChange={(e) => setCurrentAnnouncement({...currentAnnouncement, displayType: e.target.value})}
            >
              <option value="banner">Banner</option>
              <option value="sidebar">Sidebar</option>
            </select>
          </View>
        </View>

        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Title</Text>
            <TextInput
              style={styles.input}
              placeholder="Title"
              value={currentAnnouncement.title}
              onChangeText={(text) => setCurrentAnnouncement({...currentAnnouncement, title: text})}
            />
          </View>
        </View>

        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Content</Text>
            <TextInput
              style={[styles.input, styles.textArea]}
              placeholder="Content"
              value={currentAnnouncement.content}
              onChangeText={(text) => setCurrentAnnouncement({...currentAnnouncement, content: text})}
              multiline
            />
          </View>
        </View>
      
        <View style={styles.buttonContainer}>
          <GradientButton
            onPress={handleSubmit}
            text={currentAnnouncement.id ? 'Update Announcement' : 'Add Announcement'}
            disabled={loading}
            style={styles.submitButton}
            textStyle={styles.buttonText}
          />
        </View>
      </View>

      {/* Announcements List */}
      <View style={styles.listContainer}>
        <View style={styles.listHeader}>
          <Text style={styles.listTitle}>Announcements List</Text>
          {announcements.length === 0 && !loading && (
            <Text style={styles.emptyText}>No announcements added yet</Text>
          )}
        </View>
        <ScrollView style={styles.announcementsList}>
          {announcements.map((announcement) => {
            console.log('Announcement Dates:', announcement.startDate, announcement.endDate);
            console.log('Type of startDate:', typeof announcement.startDate);
            console.log('Type of endDate:', typeof announcement.endDate);
            return (
              <View key={announcement.id} style={styles.announcementItem}>
                <View style={styles.announcementInfo}>
                  <Text style={styles.announcementTitle}>{announcement.title}</Text>
                  <Text style={styles.announcementType}>{announcement.type}</Text>
                </View>
                <View style={styles.announcementActions}>
                  <GradientButton
                    onPress={() => toggleActive(announcement)}
                    text={announcement.active ? 'Active' : 'Inactive'}
                    disabled={loading}
                    variant={announcement.active ? 'primary' : 'secondary'}
                    style={styles.actionButton}
                    textStyle={styles.buttonText}
                  />
                  <GradientButton
                    onPress={() => handleEdit(announcement)}
                    text="Edit"
                    disabled={loading}
                    variant="secondary"
                    style={styles.actionButton}
                    textStyle={styles.buttonText}
                  />
                  <GradientButton
                    onPress={() => handleDelete(announcement.id)}
                    text="Delete"
                    disabled={loading}
                    variant="secondary"
                    style={[styles.actionButton, styles.deleteButton]}
                    textStyle={styles.buttonText}
                  />
                </View>
              </View>
            );
          })}
        </ScrollView>
      </View>
      
      {loading && (
        <View style={styles.loadingOverlay}>
          <Text style={styles.loadingText}>Processing...</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    position: 'relative',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  form: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  formRow: {
    flexDirection: 'row',
    gap: 15,
    marginBottom: 10,
  },
  formColumn: {
    flex: 1,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    fontSize: 14,
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: 20,
  },
  submitButton: {
    minWidth: 180,
    height: 40,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '600',
  },
  listContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  listHeader: {
    marginBottom: 20,
  },
  listTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: colors.primary,
    marginBottom: 5,
  },
  emptyText: {
    color: colors.gray,
    fontSize: 14,
    fontStyle: 'italic',
  },
  announcementsList: {
    flex: 1,
  },
  announcementItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    backgroundColor: colors.white,
    borderRadius: 5,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  announcementInfo: {
    flex: 1,
  },
  announcementTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  announcementType: {
    color: colors.gray,
    fontSize: 14,
  },
  announcementDates: {
    color: colors.gray,
    fontSize: 12,
    marginTop: 4,
  },
  announcementActions: {
    flexDirection: 'row',
    gap: 10,
  },
  actionButton: {
    minWidth: 80,
    height: 36,
  },
  deleteButton: {
    backgroundColor: colors.error,
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
  },
  errorText: {
    color: colors.error,
    fontSize: 14,
    flex: 1,
  },
  dismissButton: {
    minWidth: 80,
    height: 30,
    marginLeft: 10,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: colors.primary,
    fontSize: 16,
    fontWeight: '600',
  },
}); 