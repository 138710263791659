import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const SplashContext = createContext();

export const useSplash = () => {
  const context = useContext(SplashContext);
  if (!context) {
    throw new Error('useSplash must be used within a SplashProvider');
  }
  return context;
};

export const SplashProvider = ({ children }) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSplashSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'siteSettings', 'general'));
        if (settingsDoc.exists()) {
          setIsEnabled(settingsDoc.data().splashScreenEnabled ?? true);
        }
      } catch (error) {
        console.error('Error fetching splash screen settings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSplashSettings();
  }, []);

  const value = {
    isEnabled,
    isLoading
  };

  return (
    <SplashContext.Provider value={value}>
      {children}
    </SplashContext.Provider>
  );
}; 