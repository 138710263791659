import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Button } from 'react-native';
import { db } from '../../config/firebase';
import { collection, getDocs, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { colors, typography } from '../../styles/SharedStyles';
import { GradientButton } from '../../components/GradientButton';
import styled from 'styled-components';

const formatDate = (date) => {
  if (!date) return 'N/A';
  const d = date.toDate ? date.toDate() : new Date(date);
  return d.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const OrderCard = ({ order, onStatusChange }) => (
  <Card>
    <CardHeader>
      <OrderId>Order #{order.orderId || order.id}</OrderId>
      <OrderDate>{formatDate(order.createdAt)}</OrderDate>
    </CardHeader>

    <CustomerInfo>
      <InfoRow>
        <InfoLabel>Status</InfoLabel>
        <InfoValue style={{ 
          color: order.status === 'pending' ? '#e71c24' : 
                 order.status === 'fulfilled' ? '#28a745' : '#666' 
        }}>
          {order.status === 'pending' ? 'Pending' : 
           order.status === 'fulfilled' ? 'Fulfilled' : 'Archived'}
        </InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Payment Status</InfoLabel>
        <InfoValue style={{ color: '#28a745' }}>
          {order['Payment Status'] || 'Paid'}
        </InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Delivery Method</InfoLabel>
        <InfoValue>{order['Delivery Method'] || 'N/A'}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Installation Service</InfoLabel>
        <InfoValue>{order['Installation Service'] ? 'Yes' : 'No'}</InfoValue>
      </InfoRow>
      {(order['Delivery Method'] === 'installation' || order['Delivery Method'] === 'shipping') && (
        <InfoRow>
          <InfoLabel>{order['Delivery Method'] === 'installation' ? 'Installation Fee' : 'Shipping Cost'}</InfoLabel>
          <InfoValue>
            ${order[order['Delivery Method'] === 'installation' ? 'installationFee' : 'shippingCost']?.toFixed(2) || 'N/A'}
          </InfoValue>
        </InfoRow>
      )}
    </CustomerInfo>

    <OrderItems>
      <ItemsTitle>Order Items</ItemsTitle>
      {order.items ? order.items.map((item, index) => (
        <ItemRow key={index}>
          <ItemName>{item.name}</ItemName>
          <ItemQuantity>x{item.quantity}</ItemQuantity>
          <ItemPrice>${(item.price * item.quantity).toFixed(2)}</ItemPrice>
        </ItemRow>
      )) : <ItemRow><ItemName>No items available</ItemName></ItemRow>}
      <TotalRow>
        <TotalLabel>Total Amount</TotalLabel>
        <TotalAmount>${order.totalAmount?.toFixed(2) || 'N/A'}</TotalAmount>
      </TotalRow>
    </OrderItems>

    <StatusActions>
      {order.status === 'pending' && (
        <StatusButton
          onClick={() => onStatusChange(order.id, 'fulfilled')}
          style={{ background: '#28a745' }}
        >
          Mark as Fulfilled
        </StatusButton>
      )}
      {order.status === 'fulfilled' && (
        <>
          <StatusButton
            onClick={() => onStatusChange(order.id, 'pending')}
            style={{ background: '#e71c24' }}
          >
            Move to Pending
          </StatusButton>
          <StatusButton
            onClick={() => onStatusChange(order.id, 'archived')}
            style={{ background: '#6c757d' }}
          >
            Archive
          </StatusButton>
        </>
      )}
      {order.status === 'archived' && (
        <StatusButton
          onClick={() => onStatusChange(order.id, 'fulfilled')}
          style={{ background: '#28a745' }}
        >
          Move to Fulfilled
        </StatusButton>
      )}
    </StatusActions>
  </Card>
);

export const FulfillmentManager = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('pending'); // 'pending', 'fulfilled', 'archived'

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'orders'));
        const ordersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).filter(order => {
          // Show orders that are either pending or have been paid
          return order.status === 'pending' || order.paymentStatus === 'paid';
        });
        console.log('Fetched orders:', ordersData);
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      // Update Firestore
      const orderRef = doc(db, 'orders', orderId);
      await updateDoc(orderRef, {
        status: newStatus,
        updatedAt: serverTimestamp()
      });

      // Update local state
      setOrders(prevOrders => 
        prevOrders.map(order => 
          order.id === orderId 
            ? { ...order, status: newStatus }
            : order
        )
      );
    } catch (error) {
      console.error('Error updating order status:', error);
      // You might want to show an error message to the user here
    }
  };

  const filteredOrders = orders.filter(order => {
    if (filter === 'pending') return order.status === 'pending';
    if (filter === 'fulfilled') return order.status === 'fulfilled';
    if (filter === 'archived') return order.status === 'archived';
    return true;
  });

  const renderOrderItem = ({ item }) => (
    <View style={styles.orderCard}>
      <Text style={styles.orderTitle}>Order ID: {item.orderId || item.id}</Text>
      <View style={styles.orderContent}>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Status:</Text>
          <Text style={[
            styles.orderValue,
            { color: item.status === 'pending' ? '#e71c24' : item.status === 'fulfilled' ? '#28a745' : '#666' }
          ]}>
            {item.status === 'pending' ? 'Pending' : item.status === 'fulfilled' ? 'Fulfilled' : 'Archived'}
          </Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Payment Status:</Text>
          <Text style={[styles.orderValue, { color: '#28a745' }]}>
            {item['Payment Status'] || 'Paid'}
          </Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Total:</Text>
          <Text style={styles.orderValue}>${item.totalAmount ? item.totalAmount.toFixed(2) : 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Delivery Method:</Text>
          <Text style={styles.orderValue}>{item['Delivery Method'] || 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Installation Service:</Text>
          <Text style={styles.orderValue}>{item['Installation Service'] ? 'Yes' : 'No'}</Text>
        </View>
        {item['Delivery Method'] === 'installation' && (
          <View style={styles.orderDetails}>
            <Text style={styles.orderLabel}>Installation Fee:</Text>
            <Text style={styles.orderValue}>${item.installationFee ? item.installationFee.toFixed(2) : 'N/A'}</Text>
          </View>
        )}
        {item['Delivery Method'] === 'shipping' && (
          <View style={styles.orderDetails}>
            <Text style={styles.orderLabel}>Shipping Cost:</Text>
            <Text style={styles.orderValue}>${item.shippingCost ? item.shippingCost.toFixed(2) : 'N/A'}</Text>
          </View>
        )}
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Created At:</Text>
          <Text style={styles.orderValue}>
            {item.createdAt ? new Date(item.createdAt.toDate ? item.createdAt.toDate() : item.createdAt).toLocaleString() : 'N/A'}
          </Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Customer Info:</Text>
          <Text style={styles.orderValue}>
            {item.customerInfo ? 
              `${item.customerInfo.firstName} ${item.customerInfo.lastName}, ${item.customerInfo.email}` : 
              'N/A'}
          </Text>
        </View>
        {(item['Delivery Method'] === 'shipping' || item['Delivery Method'] === 'installation') && (
          <View style={styles.orderDetails}>
            <Text style={styles.orderLabel}>Delivery Address:</Text>
            <Text style={styles.orderValue}>
              {item.customerInfo && item.customerInfo.address ? 
                `${item.customerInfo.address}, ${item.customerInfo.city}, ${item.customerInfo.state} ${item.customerInfo.zipCode}` : 
                'N/A'}
            </Text>
          </View>
        )}
        <Text style={styles.orderItemsTitle}>Items:</Text>
        <View style={styles.itemsContainer}>
          {item.items ? item.items.map((orderItem, index) => (
            <Text key={index} style={styles.orderItemText}>
              - {orderItem.name} x{orderItem.quantity} (${(orderItem.price * orderItem.quantity).toFixed(2)})
            </Text>
          )) : <Text style={styles.orderItemText}>No items available</Text>}
        </View>
      </View>
      <View style={styles.buttonGroup}>
        {item.status === 'pending' && (
          <GradientButton 
            onPress={() => handleStatusChange(item.id, 'fulfilled')} 
            text="Mark as Fulfilled" 
            variant="primary" 
            style={styles.actionButton} 
          />
        )}
        {item.status === 'fulfilled' && (
          <GradientButton 
            onPress={() => handleStatusChange(item.id, 'archived')} 
            text="Archive" 
            variant="secondary" 
            style={styles.actionButton} 
          />
        )}
      </View>
    </View>
  );

  return (
    <Container>
      <Title>Order Fulfillment</Title>
      
      <FilterContainer>
        <FilterButton 
          active={filter === 'pending'} 
          onClick={() => setFilter('pending')}
        >
          Pending Orders
        </FilterButton>
        <FilterButton 
          active={filter === 'fulfilled'} 
          onClick={() => setFilter('fulfilled')}
        >
          Fulfilled Orders
        </FilterButton>
        <FilterButton 
          active={filter === 'archived'} 
          onClick={() => setFilter('archived')}
        >
          Archived Orders
        </FilterButton>
      </FilterContainer>

      {loading ? (
        <LoadingMessage>Loading orders...</LoadingMessage>
      ) : error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : filteredOrders.length === 0 ? (
        <EmptyMessage>No {filter} orders found</EmptyMessage>
      ) : (
        <OrdersGrid>
          {filteredOrders.map(order => (
            <OrderCard 
              key={order.id} 
              order={order} 
              onStatusChange={handleStatusChange}
            />
          ))}
        </OrdersGrid>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: colors.background || '#f5f5f5',
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary || '#e71c24',
    textAlign: 'center',
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
    height: 'calc(100vh - 150px)',
  },
  column: {
    flex: 1,
    width: 'calc(33.333% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  columnTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    color: colors.primary || '#e71c24',
    textAlign: 'center',
    borderBottom: '2px solid #f0f0f0',
    paddingBottom: 10,
  },
  listContent: {
    paddingBottom: 20,
  },
  orderCard: {
    padding: 15,
    marginBottom: 15,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'column',
  },
  orderTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.primary || '#e71c24',
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 8,
  },
  orderContent: {
    flex: 1,
  },
  orderDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  orderLabel: {
    fontWeight: '600',
    color: '#666',
    fontSize: 14,
  },
  orderValue: {
    color: '#333',
    fontSize: 14,
    maxWidth: '60%',
    textAlign: 'right',
  },
  orderItemsTitle: {
    fontWeight: '600',
    marginTop: 10,
    marginBottom: 5,
    color: '#333',
    fontSize: 14,
  },
  itemsContainer: {
    maxHeight: 100,
    overflowY: 'auto',
  },
  orderItemText: {
    marginLeft: 10,
    color: '#666',
    fontSize: 13,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 15,
    borderTop: '1px solid #f0f0f0',
    paddingTop: 10,
  },
  actionButton: {
    minWidth: 150,
    height: 36,
  },
});

const Container = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: ${typography.fontFamily};
`;

const Title = styled.h1`
  font-family: ${typography.fontFamily};
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  color: ${colors.primary.solid};
  text-align: center;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  justify-content: center;
`;

const FilterButton = styled.button`
  padding: 10px 20px;
  border: 2px solid ${colors.primary.solid};
  border-radius: 8px;
  background: ${props => props.active ? colors.primary.solid : 'white'};
  color: ${props => props.active ? 'white' : colors.primary.solid};
  font-family: ${typography.fontFamily};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: ${props => props.active ? colors.primary.dark : colors.primary.light};
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(231, 28, 36, 0.2);
  }
`;

const OrdersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const Card = styled.div`
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f5f5f5;
  gap: 10px;
`;

const OrderId = styled.h2`
  font-family: ${typography.fontFamily};
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primary.solid};
  flex: 1;
  word-break: break-word;
  margin-right: 10px;
`;

const OrderDate = styled.span`
  font-family: ${typography.fontFamily};
  color: ${colors.gray};
  font-size: 14px;
  white-space: nowrap;
  flex-shrink: 0;
`;

const CustomerInfo = styled.div`
  margin-bottom: 20px;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px dashed #eee;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const InfoLabel = styled.span`
  font-family: ${typography.fontFamily};
  font-weight: 600;
  color: ${colors.gray};
  font-size: 14px;
`;

const InfoValue = styled.span`
  font-family: ${typography.fontFamily};
  color: #333;
  font-size: 14px;
  max-width: 60%;
  text-align: right;
`;

const OrderItems = styled.div`
  margin-bottom: 20px;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
`;

const ItemsTitle = styled.h3`
  font-family: ${typography.fontFamily};
  font-weight: 600;
  margin-bottom: 10px;
  color: ${colors.primary.solid};
  font-size: 16px;
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px dashed #eee;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const ItemName = styled.span`
  font-family: ${typography.fontFamily};
  color: #333;
  font-size: 14px;
  flex: 2;
`;

const ItemQuantity = styled.span`
  font-family: ${typography.fontFamily};
  color: ${colors.gray};
  font-size: 14px;
  margin: 0 10px;
`;

const ItemPrice = styled.span`
  font-family: ${typography.fontFamily};
  color: ${colors.primary.solid};
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  text-align: right;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #eee;
`;

const TotalLabel = styled.span`
  font-family: ${typography.fontFamily};
  font-weight: 600;
  color: #333;
  font-size: 16px;
`;

const TotalAmount = styled.span`
  font-family: ${typography.fontFamily};
  color: ${colors.primary.solid};
  font-size: 18px;
  font-weight: bold;
`;

const StatusActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #f5f5f5;
  flex-wrap: wrap;
`;

const StatusButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  color: white;
  font-family: ${typography.fontFamily};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const LoadingMessage = styled.p`
  font-family: ${typography.fontFamily};
  text-align: center;
  margin-top: 40px;
  color: ${colors.gray};
  font-size: 16px;
`;

const ErrorMessage = styled.p`
  font-family: ${typography.fontFamily};
  text-align: center;
  margin-top: 40px;
  color: ${colors.primary.solid};
  font-size: 16px;
`;

const EmptyMessage = styled.p`
  font-family: ${typography.fontFamily};
  text-align: center;
  margin-top: 40px;
  color: ${colors.gray};
  font-size: 16px;
`; 