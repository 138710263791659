import React from 'react';
import PolicyPage from './PolicyPage';

const TestimonialsDisclosurePage = () => {
  return (
    <PolicyPage title="Testimonials Disclosure">
      <p>
        Unique experiences and past performances do not guarantee future results! Testimonials herein are 
        unsolicited and are non-representative of all clients; certain accounts may have worse performance 
        than that indicated.
      </p>
    </PolicyPage>
  );
};

export default TestimonialsDisclosurePage; 