import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useCart } from '../context/CartContext';
import Logo from '../assets/logo.png';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/theme';
import UserProfileButton from './UserProfileButton';

const navigationItems = [
  {text: "Home", path: "/"},
  { text: "Our Services", path: "/services" },
  { text: "Build a System", path: "/build" },
  { 
    text: "Shopping",
    path: "/products",
    items: [
      { text: "All Products", path: "/products" },
      { text: "Shop Packages", path: "/packages" }
    ]
  },
  { 
    text: "Support", 
    path: "/support",
    items: [
      { text: "Technical Support", path: "/support" },
      { text: "Blog", path: "/blog" }
    ] 
  },
  { text: "Contact Us", path: "/contact" }
];

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, ${colors.primary.start} 0%, ${colors.primary.end} 100%);
  padding: 11px 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  transform: translateZ(0);
  
  @media (max-width: 768px) {
    padding: 11px 20px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 60px;
  cursor: pointer;
  margin-bottom: 5px;
  
  @media (max-width: 768px) {
    height: 50px;
  }
`;

const Navigation = styled.nav`
  display: flex;
  gap: 25px;
  align-items: center;
  position: relative;
  z-index: 1002;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1002;
  
  &:hover {
    & > div {
      display: block;
      opacity: 1;
      z-index: 1003;
    }
  }
`;

const NavLink = styled.a`
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1.2px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Tahoma', sans-serif;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  padding: 8px 0;
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease;
  }
  
  &:hover {
    opacity: 1;
    
    &:before {
      width: 100%;
    }
  }

  &:after {
    content: ${props => props.$hasDropdown ? '"▼"' : 'none'};
    font-size: 12px;
    margin-top: 2px;
    transition: transform 0.3s ease;
  }
  
  &:hover:after {
    transform: ${props => props.$hasDropdown ? 'rotate(180deg)' : 'none'};
  }
`;

const DropdownPortal = ({ children, isOpen }) => {
  return isOpen ? createPortal(
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      pointerEvents: 'none',
    }}>
      {children}
    </div>,
    document.body
  ) : null;
};

const DropdownContent = styled.div`
  position: fixed;
  background: white;
  min-width: 200px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  border-radius: 8px;
  padding: 8px 0;
  pointer-events: auto;
  
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
`;

const DropdownItem = styled.a`
  color: #333;
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  font-family: 'Tahoma', sans-serif;
  font-size: 15px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${colors.primary.solid}10;
    color: ${colors.primary.solid};
    padding-left: 25px;
  }
`;

// Hamburger menu components
const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1003;
  position: ${({ $isOpen }) => $isOpen ? 'fixed' : 'relative'};
  right: ${({ $isOpen }) => $isOpen ? '20px' : '0'};
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50px;
    height: 40px;
  }
`;

const HamburgerLine = styled.span`
  display: block;
  height: 4px;
  width: 100%;
  background-color: white;
  border-radius: 3px;
  transition: all 0.3s ease;
  
  &:nth-child(1) {
    transform: ${({ $isOpen }) => $isOpen ? 'rotate(-45deg) translate(10px, 15px)' : 'none'};
  }
  
  &:nth-child(2) {
    opacity: ${({ $isOpen }) => $isOpen ? '0' : '1'};
  }
  
  &:nth-child(3) {
    transform: ${({ $isOpen }) => $isOpen ? 'rotate(45deg) translate(-10px, -7px)' : 'none'};
  }
`;

const MobileMenu = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, ${colors.primary.start} 0%, ${colors.primary.end} 100%);
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 20px 40px;
  transform: translateX(${({ $isOpen }) => $isOpen ? '0' : '100%'});
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  
  @media (max-width: 768px) {
    display: flex;
  }
`;

const CloseOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1002;
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const MobileNavContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 140px);
  padding: 20px 0 40px;
`;

const MobileNavSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const MobileNavLink = styled.a`
  color: white;
  font-size: 28px;
  font-weight: 600;
  text-decoration: none;
  font-family: 'Tahoma', sans-serif;
  text-align: center;
  width: 100%;
  padding: 15px;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 0.8;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  &:after {
    content: ${props => props.$hasDropdown ? '"▼"' : 'none'};
    font-size: 18px;
    margin-left: 8px;
    transform: ${props => props.$isOpen ? 'rotate(180deg)' : 'none'};
    transition: transform 0.3s ease;
    display: inline-block;
  }
`;

const MobileDropdownContent = styled.div`
  margin: 5px 0 15px;
  display: ${props => props.$isOpen ? 'block' : 'none'};
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 15px 0;
  width: 100%;
`;

const MobileDropdownItem = styled.a`
  color: white;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
  font-family: 'Tahoma', sans-serif;
  display: block;
  text-align: center;
  opacity: 0.9;
  padding: 12px;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
  }
`;

const ProfileImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.hasInitials ? colors.primary.solid : '#f5f5f5'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.hasInitials ? 'white' : '#666'};
  font-size: ${props => props.hasInitials ? '16px' : '18px'};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;

  &:hover {
    border-color: ${colors.primary.solid}20;
    transform: translateY(-1px);
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const HeaderBar = () => {
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setMobileDropdownOpen(null);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMenuOpen) {
      toggleMenu();
    }
    setOpenDropdown(null);
    setMobileDropdownOpen(null);
  };

  const handleMouseEnter = (text) => {
    clearTimeout(window.dropdownTimeout);
    setOpenDropdown(text);
  };

  const handleMouseLeave = () => {
    window.dropdownTimeout = setTimeout(() => {
      setOpenDropdown(null);
    }, 150);
  };

  const handleMobileDropdown = (text) => {
    setMobileDropdownOpen(mobileDropdownOpen === text ? null : text);
  };

  const getDropdownPosition = (text) => {
    const navItem = document.querySelector(`[data-nav-item="${text}"]`);
    if (!navItem) return {};
    
    const rect = navItem.getBoundingClientRect();
    return {
      top: rect.bottom + 5,
      left: rect.left + (rect.width / 2),
      transform: 'translateX(-50%)',
    };
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      toggleMenu();
    }
  };

  return (
    <HeaderWrapper>
      <LogoContainer>
        <LogoImage
          src={Logo}
          alt="Logo"
          onClick={() => handleNavigation('/')}
        />
      </LogoContainer>
      <Navigation>
        {navigationItems.map((item, index) => (
          <NavItem 
            key={index}
            data-nav-item={item.text}
            onMouseEnter={() => item.items && handleMouseEnter(item.text)}
            onMouseLeave={handleMouseLeave}
          >
            {item.items ? (
              <>
                <NavLink 
                  href={item.path}
                  $hasDropdown
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(item.path);
                  }}
                >
                  {item.text}
                </NavLink>
                <DropdownPortal isOpen={openDropdown === item.text}>
                  <DropdownContent 
                    style={getDropdownPosition(item.text)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.items.map((subItem, subIndex) => (
                      <DropdownItem
                        key={subIndex}
                        href={subItem.path}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigation(subItem.path);
                        }}
                      >
                        {subItem.text}
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </DropdownPortal>
              </>
            ) : (
              <NavLink 
                href={item.path}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation(item.path);
                }}
              >
                {item.text}
              </NavLink>
            )}
          </NavItem>
        ))}
        <UserProfileButton />
      </Navigation>
      
      {/* Hamburger Menu Button */}
      <HamburgerButton onClick={toggleMenu} $isOpen={isMenuOpen}>
        <HamburgerLine $isOpen={isMenuOpen} />
        <HamburgerLine $isOpen={isMenuOpen} />
        <HamburgerLine $isOpen={isMenuOpen} />
      </HamburgerButton>
      
      {/* Mobile Menu */}
      <CloseOverlay $isOpen={isMenuOpen} onClick={handleOverlayClick}>
        <MobileMenu $isOpen={isMenuOpen} onClick={(e) => e.stopPropagation()}>
          <MobileNavContainer>
            <MobileNavSection>
              {navigationItems.map((item, index) => (
                <div key={index} style={{ width: '100%' }}>
                  {item.items ? (
                    <>
                      <MobileNavLink 
                        href="#"
                        $hasDropdown
                        $isOpen={mobileDropdownOpen === item.text}
                        onClick={(e) => {
                          e.preventDefault();
                          handleMobileDropdown(item.text);
                        }}
                      >
                        {item.text}
                      </MobileNavLink>
                      <MobileDropdownContent $isOpen={mobileDropdownOpen === item.text}>
                        {item.items.map((subItem, subIndex) => (
                          <MobileDropdownItem
                            key={subIndex}
                            href={subItem.path}
                            onClick={(e) => {
                              e.preventDefault();
                              handleNavigation(subItem.path);
                            }}
                          >
                            {subItem.text}
                          </MobileDropdownItem>
                        ))}
                      </MobileDropdownContent>
                    </>
                  ) : (
                    <MobileNavLink 
                      href={item.path}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigation(item.path);
                      }}
                    >
                      {item.text}
                    </MobileNavLink>
                  )}
                </div>
              ))}
            </MobileNavSection>
          </MobileNavContainer>
        </MobileMenu>
      </CloseOverlay>
    </HeaderWrapper>
  );
}; 