import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled from 'styled-components';
import { colors } from '../../styles/SharedStyles';
import { db, storage, auth } from '../../config/firebase';
import { collection, getDocs, doc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';
import { GradientButton } from '../GradientButton';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { isDevelopment } from '../../utils/environment';
import { US_STATES } from '../../utils/constants';

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${colors.primary.solid}20;
`;

const HeaderTitle = styled.h1`
  color: ${colors.primary.solid};
  font-size: 28px;
  margin: 0;
`;

const Container = styled.div`
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const UserGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const UserCard = styled.div`
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const AddUserForm = styled.form`
  display: grid;
  gap: 15px;
  margin-bottom: 30px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const CustomerLinksSection = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-bottom: 10px;
`;

const ImageUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
`;

const DefaultUserImage = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${props => props.hasInitials ? colors.primary.solid : '#f5f5f5'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.hasInitials ? 'white' : '#666'};
  font-size: ${props => props.hasInitials ? '36px' : '32px'};
  font-weight: bold;
  border: 2px solid #ddd;
`;

const ImageActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadButton = styled.button`
  padding: 8px 16px;
  background: ${colors.primary.solid};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(231, 28, 36, 0.2);
  }
`;

const EditButton = styled.button`
  padding: 8px 16px;
  background: ${colors.primary.solid};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;

  &:hover {
    background: ${colors.primary.dark};
  }
`;

const UserCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const UserCardContent = styled.div`
  margin-top: 15px;
`;

const UserCardActions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  background: ${props => props.variant === 'secondary' ? '#666' : colors.primary.solid};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  opacity: ${props => props.disabled ? 0.7 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: ${props => props.variant === 'secondary' ? '#555' : colors.primary.dark};
  }
`;

const ShippingSection = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.primary.solid};
  margin-bottom: 15px;
`;

const ComingSoonContainer = styled.div`
  padding: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ComingSoonTitle = styled.h2`
  color: ${colors.primary.solid};
  font-size: 28px;
  margin-bottom: 20px;
`;

const ComingSoonText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
`;

const generateTempPassword = () => {
  const length = 12;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
  let password = '';
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

export const UsersManager = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    userType: 'customer',
    firstName: '',
    lastName: '',
    phone: '',
    profileImage: '',
    customerLinks: Array(5).fill({ title: '', url: '' }),
    shipping: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    }
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const scrollToTop = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setNewUser(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleLinkChange = (index, field, value) => {
    setNewUser(prev => {
      const newLinks = [...prev.customerLinks];
      newLinks[index] = {
        ...newLinks[index],
        [field]: value
      };
      return {
        ...prev,
        customerLinks: newLinks
      };
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      handleInputChange('profileImage', '');
    }
  };

  const handleShippingChange = (field, value) => {
    setNewUser(prev => ({
      ...prev,
      shipping: {
        ...prev.shipping,
        [field]: value
      }
    }));
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Validate required fields
      if (!newUser.email || !newUser.firstName || !newUser.lastName) {
        throw new Error('Email, first name, and last name are required');
      }

      // Generate a secure temporary password
      const tempPassword = generateTempPassword();

      // Create user using Cloud Function
      const createUserFunction = httpsCallable(functions, 'createUser');
      const result = await createUserFunction({
        email: newUser.email,
        password: tempPassword,
        displayName: `${newUser.firstName} ${newUser.lastName}`,
      });

      const userId = result.data.uid;
      
      let profileImageUrl = newUser.profileImage;
      if (imageFile) {
        const storageRef = ref(storage, `profile_images/${Date.now()}_${imageFile.name}`);
        const snapshot = await uploadBytes(storageRef, imageFile);
        profileImageUrl = await getDownloadURL(snapshot.ref);
      }

      // Create user document in Firestore
      const userData = {
        id: userId,
        username: newUser.username || newUser.email.split('@')[0],
        email: newUser.email,
        userType: newUser.userType,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        phone: newUser.phone || '',
        profileImage: profileImageUrl || '',
        createdAt: serverTimestamp(),
        lastLogin: null,
        orders: [],
        customerLinks: newUser.customerLinks || [],
        shipping: newUser.shipping || {}
      };

      await setDoc(doc(db, 'users', userId), userData);

      // Send password reset email using Cloud Function
      const sendPasswordResetFunction = httpsCallable(functions, 'sendPasswordResetEmail');
      await sendPasswordResetFunction({ email: newUser.email });

      // Reset form
      setNewUser({
        username: '',
        email: '',
        userType: 'customer',
        firstName: '',
        lastName: '',
        phone: '',
        profileImage: '',
        customerLinks: Array(5).fill({ title: '', url: '' }),
        shipping: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          country: ''
        }
      });
      setImageFile(null);
      setImagePreview(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      
      // Refresh users list
      await fetchUsers();
      setError('User created successfully! A password reset email has been sent to ' + newUser.email);
    } catch (err) {
      console.error('Error creating user:', err);
      setError('Failed to create user: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setNewUser({
      ...user,
      customerLinks: user.customerLinks || Array(5).fill({ title: '', url: '' }),
      shipping: user.shipping || {}
    });
    if (user.profileImage) {
      setImagePreview(user.profileImage);
    }
    scrollToTop();
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Handle profile image
      let profileImageUrl = newUser.profileImage;
      if (imageFile) {
        const storageRef = ref(storage, `profile_images/${Date.now()}_${imageFile.name}`);
        const snapshot = await uploadBytes(storageRef, imageFile);
        profileImageUrl = await getDownloadURL(snapshot.ref);
      }

      // Create userData object, only including profileImage if it has a value
      const userData = {
        username: newUser.username || newUser.email.split('@')[0],
        userType: newUser.userType,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        phone: newUser.phone || '',
        customerLinks: newUser.customerLinks || [],
        shipping: newUser.shipping || {}
      };

      // Only add profileImage to userData if it exists
      if (profileImageUrl) {
        userData.profileImage = profileImageUrl;
      }

      await updateDoc(doc(db, 'users', editingUser.id), userData);
      
      setEditingUser(null);
      setNewUser({
        username: '',
        email: '',
        userType: 'customer',
        firstName: '',
        lastName: '',
        phone: '',
        profileImage: '',
        customerLinks: Array(5).fill({ title: '', url: '' }),
        shipping: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          country: ''
        }
      });
      setImageFile(null);
      setImagePreview(null);
      
      await fetchUsers();
      setError('User updated successfully!');
    } catch (err) {
      console.error('Error updating user:', err);
      setError('Failed to update user: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent successfully!');
    } catch (err) {
      console.error('Error sending password reset:', err);
      setError('Failed to send password reset: ' + err.message);
    }
  };

  const handleUpdateUserType = async (userId, newType) => {
    try {
      await updateDoc(doc(db, 'users', userId), {
        userType: newType
      });
      await fetchUsers();
    } catch (err) {
      console.error('Error updating user type:', err);
      setError('Failed to update user type: ' + err.message);
    }
  };

  const getDefaultUserImage = (firstName, lastName) => {
    if (!firstName || !lastName) return null;
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  };

  const handleDeleteImage = () => {
    setImageFile(null);
    setImagePreview(null);
    setNewUser(prev => ({ ...prev, profileImage: '' }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  if (!isDevelopment()) {
    return (
      <ComingSoonContainer>
        <ComingSoonTitle>Coming Soon</ComingSoonTitle>
        <ComingSoonText>
          The user management system is currently under development and will be available soon. 
          We're working on making it secure and user-friendly for managing customer accounts.
        </ComingSoonText>
      </ComingSoonContainer>
    );
  }

  return (
    <Container>
      <PageHeader>
        <HeaderTitle>User Management</HeaderTitle>
      </PageHeader>

      <AddUserForm 
        ref={formRef}
        onSubmit={editingUser ? handleUpdateUser : handleCreateUser}
      >
        <h3>{editingUser ? 'Edit User' : 'Add New User'}</h3>
        
        <ImageUploadContainer>
          {imagePreview || newUser.profileImage ? (
            <ImagePreview 
              src={imagePreview || newUser.profileImage} 
              alt="Profile preview" 
            />
          ) : newUser.firstName && newUser.lastName ? (
            <DefaultUserImage hasInitials={true}>
              {getDefaultUserImage(newUser.firstName, newUser.lastName)}
            </DefaultUserImage>
          ) : (
            <DefaultUserImage hasInitials={false}>
              <i className="fas fa-user"></i>
            </DefaultUserImage>
          )}
          <ImageActions>
            <UploadButton type="button" onClick={() => fileInputRef.current?.click()}>
              Upload Image
            </UploadButton>
            {(imagePreview || newUser.profileImage) && (
              <ActionButton
                type="button"
                variant="secondary"
                onClick={handleDeleteImage}
              >
                Delete Image
              </ActionButton>
            )}
          </ImageActions>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </ImageUploadContainer>

        <Input
          type="text"
          placeholder="Username"
          value={newUser.username}
          onChange={(e) => handleInputChange('username', e.target.value)}
        />
        <Input
          type="text"
          placeholder="First Name"
          value={newUser.firstName}
          onChange={(e) => handleInputChange('firstName', e.target.value)}
        />
        <Input
          type="text"
          placeholder="Last Name"
          value={newUser.lastName}
          onChange={(e) => handleInputChange('lastName', e.target.value)}
        />
        <Input
          type="email"
          placeholder="Email"
          value={newUser.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
          disabled={editingUser}
        />
        <Input
          type="text"
          placeholder="Phone"
          value={newUser.phone}
          onChange={(e) => handleInputChange('phone', e.target.value)}
        />
        <Select
          value={newUser.userType}
          onChange={(e) => handleInputChange('userType', e.target.value)}
        >
          <option value="customer">Customer</option>
          <option value="admin">Admin</option>
        </Select>
        
        <ShippingSection>
          <SectionTitle>Shipping Information</SectionTitle>
          <Input
            type="text"
            placeholder="Address Line 1"
            value={newUser.shipping.address1}
            onChange={(e) => handleShippingChange('address1', e.target.value)}
          />
          <Input
            type="text"
            placeholder="Address Line 2 (Optional)"
            value={newUser.shipping.address2}
            onChange={(e) => handleShippingChange('address2', e.target.value)}
          />
          <FormRow>
            <Input
              type="text"
              placeholder="City"
              value={newUser.shipping.city}
              onChange={(e) => handleShippingChange('city', e.target.value)}
            />
            <Select
              value={newUser.shipping.state}
              onChange={(e) => handleShippingChange('state', e.target.value)}
            >
              <option value="">Select State</option>
              {US_STATES.map(state => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </Select>
          </FormRow>
          <FormRow>
            <Input
              type="text"
              placeholder="ZIP/Postal Code"
              value={newUser.shipping.zipCode}
              onChange={(e) => handleShippingChange('zipCode', e.target.value)}
            />
            <Input
              type="text"
              placeholder="Country"
              value={newUser.shipping.country}
              onChange={(e) => handleShippingChange('country', e.target.value)}
            />
          </FormRow>
        </ShippingSection>
        
        <CustomerLinksSection>
          <SectionTitle>Customer Links</SectionTitle>
          {newUser.customerLinks.map((link, index) => (
            <LinkRow key={index}>
              <Input
                type="text"
                placeholder={`Link ${index + 1} Title`}
                value={link.title}
                onChange={(e) => handleLinkChange(index, 'title', e.target.value)}
              />
              <Input
                type="text"
                placeholder={`Link ${index + 1} URL`}
                value={link.url}
                onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
              />
            </LinkRow>
          ))}
        </CustomerLinksSection>

        <ActionButton
          type="submit"
          disabled={loading}
        >
          {editingUser ? 'Update User' : 'Create User'}
        </ActionButton>
        
        {editingUser && (
          <ActionButton
            type="button"
            variant="secondary"
            onClick={() => {
              setEditingUser(null);
              setNewUser({
                username: '',
                email: '',
                userType: 'customer',
                firstName: '',
                lastName: '',
                phone: '',
                profileImage: '',
                customerLinks: Array(5).fill({ title: '', url: '' }),
                shipping: {
                  address1: '',
                  address2: '',
                  city: '',
                  state: '',
                  zipCode: '',
                  country: ''
                }
              });
              setImageFile(null);
              setImagePreview(null);
            }}
          >
            Cancel Edit
          </ActionButton>
        )}
      </AddUserForm>

      {error && <Text style={{ color: error.includes('success') ? 'green' : 'red', marginBottom: 20 }}>{error}</Text>}

      <UserGrid>
        {users.map(user => (
          <UserCard key={user.id}>
            <UserCardHeader>
              <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                {user.profileImage ? (
                  <ImagePreview 
                    src={user.profileImage} 
                    alt={`${user.firstName} ${user.lastName}`}
                    style={{ width: '50px', height: '50px' }}
                  />
                ) : (
                  <DefaultUserImage 
                    hasInitials={true} 
                    style={{ width: '50px', height: '50px', fontSize: '20px' }}
                  >
                    {getDefaultUserImage(user.firstName, user.lastName)}
                  </DefaultUserImage>
                )}
                <h3>{user.firstName} {user.lastName}</h3>
              </div>
              <UserCardActions>
                <EditButton onClick={() => handleEditUser(user)}>
                  Edit
                </EditButton>
                <EditButton onClick={() => handleSendPasswordReset(user.email)}>
                  Reset Password
                </EditButton>
              </UserCardActions>
            </UserCardHeader>
            
            <UserCardContent>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Type:</strong> {user.userType}</p>
              <p><strong>Username:</strong> {user.username}</p>
              <p><strong>Phone:</strong> {user.phone}</p>

              {user.shipping && (user.shipping.address1 || user.shipping.city || user.shipping.state || user.shipping.country) && (
                <div style={{ marginTop: '10px' }}>
                  <strong>Shipping Address:</strong>
                  <p style={{ margin: '5px 0' }}>
                    {user.shipping.address1}
                    {user.shipping.address2 && <>, {user.shipping.address2}</>}
                  </p>
                  <p style={{ margin: '5px 0' }}>
                    {user.shipping.city}{user.shipping.city && user.shipping.state && ', '}{user.shipping.state} {user.shipping.zipCode}
                  </p>
                  {user.shipping.country && <p style={{ margin: '5px 0' }}>{user.shipping.country}</p>}
                </div>
              )}
              
              {user.customerLinks?.length > 0 && (
                <div>
                  <strong>Customer Links:</strong>
                  <ul>
                    {user.customerLinks.map((link, index) => (
                      link.title && link.url && (
                        <li key={index}>
                          {link.title}: {link.url}
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              )}
            </UserCardContent>
          </UserCard>
        ))}
      </UserGrid>
    </Container>
  );
}; 