import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/SharedStyles';

const words = ['Business', 'Home', 'Warehouse', 'Storefront', 'Shop', 'Garage', 'Lot'];

const rotateAnimation = keyframes`
  0%, 5% {
    transform: translateY(0);
    opacity: 1;
  }
  10% {
    transform: translateY(-100%);
    opacity: 0;
  }
  15% {
    transform: translateY(100%);
    opacity: 0;
  }
  20%, 100% {
    transform: translateY(0);
    opacity: 0;
  }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
  height: 1.2em;
  vertical-align: top;
  min-width: 200px;

`;

const Word = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  color: ${props => props.textColor || colors.white};
  animation: ${rotateAnimation} ${props => props.totalWords * 2.5}s linear infinite;
  animation-delay: ${props => props.index * 2.5}s;
  opacity: 0;
  white-space: nowrap;
  transform-origin: center;
  will-change: transform, opacity;

  &:first-child {
    opacity: 1;
  }
`;

export const RotatingWords = ({ textColor }) => {
  return (
    <Container>
      {words.map((word, index) => (
        <Word 
          key={word} 
          index={index} 
          totalWords={words.length}
          textColor={textColor}
        >
          {word}
        </Word>
      ))}
    </Container>
  );
}; 