import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { colors, breakpoints, createContainerStyle, getResponsiveSpacing } from '../styles/theme';
import { db } from '../config/firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { GradientButton } from '../components/GradientButton';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaFacebook, FaLinkedin, FaCopy } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { usePageTitle } from '../hooks/usePageTitle';

// Styled components
const BlogWrapper = styled.div`
  ${createContainerStyle()}
  display: flex;
  flex-direction: column;
  font-family: Tahoma, sans-serif;
  align-items: center;
  margin: 0 auto;
  padding-top: calc(var(--announcement-offset, 0px) + 80px);
  padding-bottom: 80px;

  @media (max-width: ${breakpoints.md}px) {
    padding-top: calc(var(--announcement-offset-mobile, 0px) + 60px);
    padding-bottom: 60px;
  }
`;

const BlogPostContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 250px);
`;

const BlogContent = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 0 20px;
  margin: 0 auto;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  
  @media (min-width: 768px) {
    padding: 50px;
  }
  
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;

const BackButton = styled.button`
  background: transparent;
  color: ${colors.primary.solid};
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 30px;
  transition: all 0.2s ease;
  
  &:hover {
    color: ${colors.primary.dark};
    transform: translateX(-3px);
  }
  
  &::before {
    content: '←';
    font-size: 18px;
    margin-right: 8px;
  }
`;

const PostHeader = styled.div`
  margin-bottom: 40px;
`;

const PostTitle = styled.h1`
  font-size: 38px;
  font-weight: 800;
  margin-bottom: 15px;
  color: ${colors.primary.solid};
  line-height: 1.3;
  
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const PostMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  font-size: 15px;
  color: #666;
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  span {
    font-weight: 500;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 30px;
`;

const Tag = styled.span`
  display: inline-block;
  padding: 6px 14px;
  background: #f7f7f7;
  border-radius: 50px;
  font-size: 14px;
  color: #666;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background: #f0f0f0;
    color: ${colors.primary.solid};
  }
`;

const FeaturedImageContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
`;

const FeaturedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  transition: transform 0.5s ease;
`;

const PostContent = styled.div`
  line-height: 1.8;
  font-size: 17px;
  color: #333;
  margin-bottom: 50px;
  
  h1, h2, h3, h4, h5, h6 {
    color: ${colors.primary.solid};
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.4;
  }
  
  h1 {
    font-size: 32px;
  }
  
  h2 {
    font-size: 28px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  p {
    margin-bottom: 25px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    margin: 30px 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  }
  
  ul, ol {
    margin-bottom: 25px;
    padding-left: 25px;
    
    li {
      margin-bottom: 10px;
    }
  }
  
  blockquote {
    border-left: 4px solid ${colors.primary.solid};
    padding: 20px 30px;
    font-style: italic;
    margin: 30px 0;
    background: #f9f9f9;
    border-radius: 0 8px 8px 0;
    color: #555;
    
    p {
      margin-bottom: 0;
    }
  }
  
  a {
    color: ${colors.primary.solid};
    text-decoration: none;
    border-bottom: 1px solid ${colors.primary.solid};
    transition: all 0.2s ease;
    
    &:hover {
      color: ${colors.primary.dark};
      border-bottom-color: ${colors.primary.dark};
    }
  }
`;

const ShareSection = styled.div`
  padding: 30px;
  background: #f9f9f9;
  border-radius: 12px;
  margin: 40px 0;
  text-align: center;
  
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  
  p {
    margin-bottom: 20px;
  }
`;

const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`;

const ShareButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || colors.primary.solid};
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

const ShareButtonWrapper = styled.div`
  position: relative;
`;

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || "#555555"};
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

const ShareTooltip = styled.div`
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  opacity: ${props => props.visible ? '1' : '0'};
  transition: opacity 0.2s ease;
  pointer-events: none;
  white-space: nowrap;
`;

const RelatedPosts = styled.div`
  margin-top: 60px;
  
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: ${colors.primary.solid};
  }
`;

const NotFound = styled.div`
  text-align: center;
  padding: 60px 30px;
  background: white;
  border-radius: 16px;
  margin: 40px auto;
  max-width: 700px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
`;

const NotFoundTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.primary.solid};
  margin-bottom: 15px;
`;

const NotFoundText = styled.p`
  font-size: 17px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.7;
  max-width: 500px;
`;

export const BlogPostPage = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [copyTooltip, setCopyTooltip] = useState(null);
  
  // Set page title based on blog post title
  usePageTitle(blogPost ? blogPost.title : 'Blog');
  
  useEffect(() => {
    if (slug) {
      fetchBlogPost();
    }
  }, [slug]);
  
  const fetchBlogPost = async () => {
    setLoading(true);
    try {
      // Simplified query - get all blogs and filter by slug client-side
      const blogQuery = query(collection(db, 'blogs'));
      const querySnapshot = await getDocs(blogQuery);
      
      // Find the published post with matching slug
      const posts = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate() || new Date(),
        }))
        .filter(post => post.slug === slug && post.published === true);
      
      if (posts.length === 0) {
        setError('Blog post not found.');
        setBlogPost(null);
      } else {
        setBlogPost(posts[0]);
      }
    } catch (err) {
      console.error('Error fetching blog post:', err);
      setError('Failed to load blog post. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  const goBack = () => {
    navigate('/blog');
  };
  
  // Function to copy URL to clipboard and then navigate
  const copyAndNavigate = (platform) => {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const title = encodeURIComponent(blogPost?.title || 'SCP911 Blog');
    let textToCopy = url;
    
    // For Twitter/X, include the title
    if (platform === 'twitter') {
      textToCopy = `${blogPost?.title || 'SCP911 Blog'} ${url}`;
    }
    
    // Copy to clipboard
    navigator.clipboard.writeText(textToCopy).then(() => {
      // Show tooltip
      setCopyTooltip(platform);
      setTimeout(() => setCopyTooltip(null), 2000);
      
      // Navigate after a brief delay
      setTimeout(() => {
        let shareUrl = '';
        switch (platform) {
          case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
            break;
          case 'twitter':
            shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${title}`;
            break;
          case 'linkedin':
            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
            break;
          default:
            return; // Just copy for default case
        }
        
        if (shareUrl) {
          window.open(shareUrl, '_blank');
        }
      }, 300);
    });
  };
  
  // Function to just copy URL to clipboard (for the copy button)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopyTooltip('copy');
      setTimeout(() => setCopyTooltip(null), 2000);
    });
  };
  
  return (
    <View style={styles.container}>
      <HeaderBar />
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <BlogWrapper>
            <BlogPostContainer>
              <BlogContent>
                <BackButton onClick={goBack}>
                  Back to Blog
                </BackButton>
                
                {loading ? (
                  <Text style={styles.loadingText}>Loading blog post...</Text>
                ) : error ? (
                  <NotFound>
                    <NotFoundTitle>Blog Post Not Found</NotFoundTitle>
                    <NotFoundText>{error}</NotFoundText>
                    <GradientButton
                      onPress={goBack}
                      text="Back to Blog"
                      style={styles.backButton}
                    />
                  </NotFound>
                ) : blogPost ? (
                  <>
                    <PostHeader>
                      <PostTitle>{blogPost.title}</PostTitle>
                      
                      <PostMeta>
                        <AuthorInfo>
                          By <span>{blogPost.author || 'Admin'}</span>
                        </AuthorInfo>
                        <span>Published on {formatDate(blogPost.createdAt)}</span>
                      </PostMeta>
                    </PostHeader>
                    
                    {blogPost.featuredImageUrl && (
                      <FeaturedImageContainer>
                        <FeaturedImage 
                          src={blogPost.featuredImageUrl} 
                          alt={blogPost.title}
                        />
                      </FeaturedImageContainer>
                    )}
                    
                    <PostContent dangerouslySetInnerHTML={{ __html: blogPost.content }} />
                    
                    {blogPost.tags && blogPost.tags.length > 0 && (
                      <TagsWrapper>
                        {blogPost.tags.map((tag, index) => (
                          <Tag key={index}>{tag}</Tag>
                        ))}
                      </TagsWrapper>
                    )}
                    
                    <ShareSection>
                      <h3>Found this article helpful?</h3>
                      <p>Share it with others who might benefit from this information.</p>
                      <ShareButtons>
                        <ShareButtonWrapper>
                          <ShareButton 
                            as="button"
                            bgColor="#1877F2" 
                            onClick={() => copyAndNavigate('facebook')} 
                            aria-label="Share on Facebook"
                          >
                            <FaFacebook size={20} />
                          </ShareButton>
                          <ShareTooltip visible={copyTooltip === 'facebook'}>Copied to clipboard!</ShareTooltip>
                        </ShareButtonWrapper>
                        
                        <ShareButtonWrapper>
                          <ShareButton 
                            as="button"
                            bgColor="#000000" 
                            onClick={() => copyAndNavigate('twitter')} 
                            aria-label="Share on X"
                          >
                            <FaXTwitter size={20} />
                          </ShareButton>
                          <ShareTooltip visible={copyTooltip === 'twitter'}>Copied to clipboard!</ShareTooltip>
                        </ShareButtonWrapper>
                        
                        <ShareButtonWrapper>
                          <ShareButton 
                            as="button"
                            bgColor="#0A66C2" 
                            onClick={() => copyAndNavigate('linkedin')} 
                            aria-label="Share on LinkedIn"
                          >
                            <FaLinkedin size={20} />
                          </ShareButton>
                          <ShareTooltip visible={copyTooltip === 'linkedin'}>Copied to clipboard!</ShareTooltip>
                        </ShareButtonWrapper>
                        
                        <ShareButtonWrapper>
                          <CopyButton 
                            onClick={copyToClipboard}
                            aria-label="Copy URL"
                          >
                            <FaCopy size={20} />
                          </CopyButton>
                          <ShareTooltip visible={copyTooltip === 'copy'}>URL copied!</ShareTooltip>
                        </ShareButtonWrapper>
                      </ShareButtons>
                    </ShareSection>
                  </>
                ) : (
                  <NotFound>
                    <NotFoundTitle>Blog Post Not Found</NotFoundTitle>
                    <NotFoundText>Sorry, we couldn't find the blog post you're looking for.</NotFoundText>
                    <GradientButton
                      onPress={goBack}
                      text="Back to Blog"
                      style={styles.backButton}
                    />
                  </NotFound>
                )}
              </BlogContent>
            </BlogPostContainer>
          </BlogWrapper>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingTop: getResponsiveSpacing('xl', 3).xs,
    paddingBottom: getResponsiveSpacing('xl').xs,
  },
  loadingText: {
    textAlign: 'center',
    fontSize: 16,
    color: colors.primary.solid,
    padding: 20,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#d32f2f',
    marginBottom: 25,
  },
  backButton: {
    minWidth: 160,
  }
}); 