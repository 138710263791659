import React from 'react';
import PolicyPage from './PolicyPage';

const LinkingPolicyPage = () => {
  return (
    <PolicyPage title="Linking Policy">
      <p>
        Status of linking policy Security Central Protection's links to this website [made in accordance 
        with the terms of this linking policy]. [This linking policy is intended to assist you when 
        linking to this website.] OR [By using this website you agree to be bound by the terms and 
        conditions of this linking policy.]
      </p>

      <h2>Links to https://www.scp911.com</h2>
      <p>
        Links pointing to https://www.scp911.com should not be misleading. Appropriate link text should 
        be always be used.
      </p>

      <p>
        From time to time we may update the URL structure of our website, and unless we agree in writing 
        otherwise, you are responsible for updating said links. You must not use our logo to link to this 
        website (or otherwise) without our express written permission.
      </p>

      <p>
        You must not frame the content of this website or use any similar technology in relation to the 
        content of this website.
      </p>

      <h2>Links from this website</h2>
      <p>
        This website includes links to other websites owned and operated by third parties. These links 
        are not endorsements or recommendations. https://www.scp911.com has no control over the contents 
        of third party websites, and https://www.scp911.com accepts no responsibility for them or for any 
        loss or damage that may arise from your use of them.
      </p>

      <h2>Removal of links</h2>
      <p>
        You agree that, should we request the deletion of a link to https://www.scp911.com that is within 
        your control, you will delete the link promptly. If you would like us to remove a link to your 
        website that is included on https://www.scp911.com, please contact us using the contact details below.
      </p>

      <h2>Changes to this linking policy</h2>
      <p>
        We may amend this linking policy at any time by publishing a new version on this website.
      </p>

      <h2>Contact us</h2>
      <p>
        Should you have any questions about this linking policy, please contact us via our contact page 
        located at https://www.scp911.com/contact, All Rights Reserved.
      </p>
    </PolicyPage>
  );
};

export default LinkingPolicyPage; 