import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../styles/theme';
import { signIn } from '../services/auth';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const AdminLoginPage = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/admin';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await signIn(identifier, password);
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.code === 'auth/invalid-credential'
          ? 'Invalid username/email or password'
          : error.message === 'User not found'
          ? 'Username not found'
          : error.message === 'Unauthorized access'
          ? 'This account does not have admin access'
          : 'An error occurred during login'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.pageContainer}>
      <HeaderBar />
      <LoginContainer>
        <LoginCard>
          <LoginTitle>Admin Login</LoginTitle>
          <LoginDescription>
            Sign in with your admin credentials to access the dashboard.
          </LoginDescription>
          
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="identifier">Email or Username</Label>
              <Input
                id="identifier"
                type="text"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                placeholder="Enter your email or username"
                disabled={loading}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <PasswordContainer>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  disabled={loading}
                  required
                />
                <PasswordToggle 
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </PasswordToggle>
              </PasswordContainer>
            </FormGroup>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <LoginButton type="submit" disabled={loading}>
              {loading ? 'Signing in...' : 'Sign In'}
            </LoginButton>
          </form>
        </LoginCard>
      </LoginContainer>
      <Footer />
    </View>
  );
};

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  padding: 20px;
`;

const LoginCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LoginTitle = styled.h1`
  color: ${colors.primary.solid};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`;

const LoginDescription = styled.p`
  color: ${colors.gray.dark};
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  color: ${colors.gray.dark};
  font-size: 14px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${props => props.error ? colors.error : colors.gray.light};
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.1);
  }
  
  &:disabled {
    background-color: ${colors.gray.lighter};
    cursor: not-allowed;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 12px;
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(231, 28, 36, 0.2);
  }
  
  &:active {
    transform: translateY(1px);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

const ErrorMessage = styled.div`
  color: ${colors.error};
  background-color: ${colors.error}10;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
`;

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${colors.primary.solid};
  }
`;

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    minHeight: '100vh',
  },
}); 