import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FeatureCard from "./FeatureCard";
import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const SecurityFeatures = () => {
  const [features, setFeatures] = useState([
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/99c9e509f48986770b9a575244ed94b419308fce",
      altText: "Custom security",
      primaryText: "Custom security solutions to",
      secondaryText: "meet your needs",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e21b42cc5941749421ba4f994421aac8aea469ff",
      altText: "Burglary prevention",
      primaryText: "We specialize in burglary prevention",
      secondaryText: "not just notification",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c6ebf0c08c0778df66068dd8275202a87c44761f",
      altText: "Live monitoring",
      primaryText: "Live operator monitoring 24/7",
      secondaryText: "no pre-recordings",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/35098c908c3a100f74025cf44aaa530cd4798919",
      altText: "Real-time alerts",
      primaryText: "Live real-time alerts to your phone with",
      secondaryText: "absolutely no false alerts",
    },
  ]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const docRef = doc(db, "siteContent", "landingPage");
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists() && docSnap.data().securityFeatures) {
          setFeatures(docSnap.data().securityFeatures);
        }
      } catch (error) {
        console.error("Error fetching security features:", error);
      }
    };

    fetchFeatures();
  }, []);

  return (
    <FeaturesSection>
      {features.map((feature, index) => (
        <AnimatedFeatureWrapper 
          key={index}
          delay={index * 0.2}
        >
          <FeatureCard
            icon={feature.icon}
            altText={feature.altText}
            primaryText={feature.primaryText}
            secondaryText={feature.secondaryText}
          />
        </AnimatedFeatureWrapper>
      ))}
    </FeaturesSection>
  );
};

const FeaturesSection = styled.section`
  display: flex;
  justify-content: center;
  gap: 75px;
  padding: 60px 83px;
  margin-top: -150px;
  background-color: #ECECEC;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    gap: 40px;
    padding: 40px 20px;
    margin-top: 0px;
  }
`;

const AnimatedFeatureWrapper = styled.div`
  animation: featureAppear 0.8s ease-out forwards;
  animation-delay: ${props => props.delay}s;
  opacity: 0;
  transform: translateY(30px);
  
  @keyframes featureAppear {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default SecurityFeatures; 