import React from 'react';
import PolicyPage from './PolicyPage';

const DisclaimerPage = () => {
  return (
    <PolicyPage title="Disclaimer">
      <p>
        The information contained in https://www.scp911.com is for general information purposes only. 
        The information is provided by https://www.scp911.com and while we endeavor to keep the information 
        up to date and correct, we make no representations or warranties of any kind, express or implied, 
        about the completeness, accuracy, reliability, suitability or availability with respect to 
        https://www.scp911.com or the information, products, services, or related graphics contained 
        on https://www.scp911.com for any purpose.
      </p>
      
      <p>
        Any reliance you place on such information is therefore strictly at your own risk.
      </p>
      
      <p>
        In no event will we be liable for any loss or damage including without limitation, indirect or 
        consequential loss or damage, or any loss or damage whatsoever arising from loss of data or 
        profits arising out of, or in connection with, the use of https://www.scp911.com.
      </p>
      
      <p>
        Through https://www.scp911.com you are able to link to other websites which are not under the 
        control of https://www.scp911.com. We have no control over the nature, content and availability 
        of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse 
        the views expressed within them.
      </p>
      
      <p>
        Every effort is made to keep https://www.scp911.com up and running smoothly. However, 
        https://www.scp911.com takes no responsibility for, and will not be liable for, https://www.scp911.com 
        being temporarily unavailable due to technical issues beyond our control.
      </p>
    </PolicyPage>
  );
};

export default DisclaimerPage; 