import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { colors, breakpoints, createContainerStyle, getResponsiveSpacing } from '../styles/theme';
import { db } from '../config/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { GradientButton } from '../components/GradientButton';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePageTitle } from '../hooks/usePageTitle';

// Styled components
const BlogWrapper = styled.div`
  ${createContainerStyle()}
  display: flex;
  flex-direction: column;
  font-family: Tahoma, sans-serif;
  align-items: center;
  margin: 0 auto;
  padding-top: calc(var(--announcement-offset, 0px) + 80px);
  padding-bottom: 80px;

  @media (max-width: ${breakpoints.md}px) {
    padding-top: calc(var(--announcement-offset-mobile, 0px) + 60px);
    padding-bottom: 60px;
  }
`;

const BlogContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 250px);
`;

const BlogHeadingCard = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 40px;
  margin-bottom: 50px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  
  @media (max-width: ${breakpoints.md}px) {
    padding: 30px 20px;
    margin-bottom: 30px;
  }
`;

const BlogHeading = styled.h1`
  font-size: 36px;
  font-weight: 800;
  color: ${colors.primary.solid};
  margin-bottom: 15px;
  
  @media (max-width: ${breakpoints.md}px) {
    font-size: 28px;
  }
`;

const BlogSubheading = styled.p`
  font-size: 18px;
  color: #666;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
  
  @media (max-width: ${breakpoints.md}px) {
    font-size: 16px;
  }
`;

const BlogContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  width: 100%;
  margin: 30px 0;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BlogCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
  }
`;

const BlogImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  overflow: hidden;
`;

const BlogImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  
  ${BlogCard}:hover & {
    transform: scale(1.05);
  }
`;

const BlogCardContent = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BlogTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: ${colors.primary.solid};
  margin-bottom: 12px;
  line-height: 1.3;
  transition: color 0.2s ease;
  
  ${BlogCard}:hover & {
    color: ${colors.primary.dark};
  }
`;

const BlogSummary = styled.p`
  font-size: 15px;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-grow: 1;
`;

const BlogMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  color: #777;
  padding-top: 15px;
  border-top: 1px solid #f0f0f0;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
`;

const Tag = styled.span`
  display: inline-block;
  padding: 5px 12px;
  background: #f7f7f7;
  border-radius: 50px;
  font-size: 12px;
  color: #666;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background: #f0f0f0;
    color: ${colors.primary.solid};
  }
`;

const ReadMoreButton = styled.button`
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  border: none;
  padding: 12px 22px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  align-self: flex-start;
  margin-top: 20px;
  box-shadow: 0 4px 15px rgba(231, 28, 36, 0.15);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(231, 28, 36, 0.2);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(231, 28, 36, 0.15);
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 60px 30px;
  background: white;
  border-radius: 16px;
  margin: 40px auto;
  max-width: 700px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
`;

const EmptyStateTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.primary.solid};
  margin-bottom: 15px;
`;

const EmptyStateText = styled.p`
  font-size: 17px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.7;
  max-width: 500px;
`;

export const BlogPage = () => {
  usePageTitle('Blog');
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchBlogPosts();
  }, []);
  
  const fetchBlogPosts = async () => {
    setLoading(true);
    try {
      // Simplified query - get all blogs first, then filter published ones in JS
      const blogQuery = query(
        collection(db, 'blogs')
      );
      
      const querySnapshot = await getDocs(blogQuery);
      const allPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));
      
      // Filter published posts client-side and sort by date
      const publishedPosts = allPosts
        .filter(post => post.published === true)
        .sort((a, b) => b.createdAt - a.createdAt);
      
      setBlogPosts(publishedPosts);
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error('Error fetching blog posts:', err);
      setError('Failed to load blog posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  const navigateToBlogPost = (slug) => {
    navigate(`/blog/${slug}`);
  };
  
  return (
    <View style={styles.container}>
      <HeaderBar />
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <BlogWrapper>
            <BlogContainer>
              <BlogHeadingCard>
                <BlogHeading>Blog</BlogHeading>
                <BlogSubheading>
                  Insights, tips, and updates to help you protect what matters most. 
                  Explore our articles for expert security advice and industry trends.
                </BlogSubheading>
              </BlogHeadingCard>
              
              <BlogContent>
                {loading ? (
                  <Text style={styles.loadingText}>Loading blog posts...</Text>
                ) : error ? (
                  <Text style={styles.errorText}>{error}</Text>
                ) : blogPosts.length === 0 ? (
                  <EmptyState>
                    <EmptyStateTitle>Our Blog is Coming Soon</EmptyStateTitle>
                    <EmptyStateText>
                      We're working on valuable content to help you enhance your security knowledge.
                      Check back soon for security tips, product updates, and industry insights.
                    </EmptyStateText>
                    <GradientButton
                      onPress={() => navigate('/')}
                      text="Back to Home"
                      style={styles.homeButton}
                    />
                  </EmptyState>
                ) : (
                  <BlogGrid>
                    {blogPosts.map(post => (
                      <BlogCard key={post.id} onClick={() => navigateToBlogPost(post.slug)}>
                        {post.featuredImageUrl ? (
                          <BlogImageContainer>
                            <BlogImage 
                              src={post.featuredImageUrl} 
                              alt={post.title}
                            />
                          </BlogImageContainer>
                        ) : (
                          <BlogImageContainer>
                            <BlogImage 
                              src="https://via.placeholder.com/600x400?text=SCP911+Blog" 
                              alt="Blog placeholder"
                            />
                          </BlogImageContainer>
                        )}
                        <BlogCardContent>
                          <BlogTitle>{post.title}</BlogTitle>
                          
                          {post.tags && post.tags.length > 0 && (
                            <TagsWrapper>
                              {post.tags.slice(0, 3).map((tag, index) => (
                                <Tag key={index}>{tag}</Tag>
                              ))}
                            </TagsWrapper>
                          )}
                          
                          <BlogSummary>{post.summary}</BlogSummary>
                          
                          <BlogMeta>
                            <span>By {post.author || 'Admin'}</span>
                            <span>{formatDate(post.createdAt)}</span>
                          </BlogMeta>
                          
                          <ReadMoreButton onClick={(e) => {
                            e.stopPropagation();
                            navigateToBlogPost(post.slug);
                          }}>
                            Read More
                          </ReadMoreButton>
                        </BlogCardContent>
                      </BlogCard>
                    ))}
                  </BlogGrid>
                )}
              </BlogContent>
            </BlogContainer>
          </BlogWrapper>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingTop: getResponsiveSpacing('xl', 3).xs,
    paddingBottom: getResponsiveSpacing('xl').xs,
  },
  loadingText: {
    textAlign: 'center',
    fontSize: 16,
    color: colors.primary.solid,
    padding: 20,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#d32f2f',
    padding: 20,
    backgroundColor: '#ffebee',
    borderRadius: 8,
    margin: 20,
  },
  homeButton: {
    minWidth: 160,
  }
}); 