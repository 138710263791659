import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled from 'styled-components';
import { colors } from '../../styles/theme';
import { db, storage } from '../../config/firebase';
import { collection, doc, getDoc, setDoc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage';
import { RichTextEditor } from '../RichTextEditor';
import { FaUpload, FaTrash, FaEdit, FaSave, FaPlay } from 'react-icons/fa';

export const SupportManager = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [supportContent, setSupportContent] = useState({
    headerText: '',
    subheaderText: '',
    phoneNumber: '',
  });
  const [tutorials, setTutorials] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedTutorialVideo, setSelectedTutorialVideo] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [newTutorial, setNewTutorial] = useState({
    title: '',
    steps: [''],
    videoUrl: '',
    thumbnailUrl: '',
  });
  const [newDocument, setNewDocument] = useState({
    title: '',
    type: '',
    fileUrl: '',
  });
  const [uploadProgress, setUploadProgress] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const fileInputRef = useRef();
  const videoInputRef = useRef();
  const thumbnailInputRef = useRef();

  useEffect(() => {
    fetchSupportContent();
  }, []);

  const fetchSupportContent = async () => {
    try {
      const supportDoc = await getDoc(doc(db, 'siteSettings', 'support'));
      if (supportDoc.exists()) {
        setSupportContent(supportDoc.data());
      }

      const tutorialsSnapshot = await getDocs(collection(db, 'tutorials'));
      const tutorialsData = tutorialsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTutorials(tutorialsData);

      const documentsSnapshot = await getDocs(collection(db, 'supportDocuments'));
      const documentsData = documentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDocuments(documentsData);
    } catch (error) {
      console.error('Error fetching support content:', error);
      setError('Failed to load support content');
    }
  };

  const handleSaveContent = async () => {
    setLoading(true);
    setError(null);
    try {
      await setDoc(doc(db, 'siteSettings', 'support'), supportContent);
      setIsEditing(false);
      alert('Support content saved successfully!');
    } catch (error) {
      console.error('Error saving support content:', error);
      setError('Failed to save support content');
    } finally {
      setLoading(false);
    }
  };

  const handleAddTutorialStep = () => {
    setNewTutorial(prev => ({
      ...prev,
      steps: [...prev.steps, '']
    }));
  };

  const handleRemoveTutorialStep = (index) => {
    setNewTutorial(prev => ({
      ...prev,
      steps: prev.steps.filter((_, i) => i !== index)
    }));
  };

  const handleTutorialStepChange = (index, value) => {
    setNewTutorial(prev => ({
      ...prev,
      steps: prev.steps.map((step, i) => i === index ? value : step)
    }));
  };

  const handleSaveTutorial = async () => {
    if (!newTutorial.title) {
      alert('Please enter a tutorial title');
      return;
    }

    setLoading(true);
    try {
      const tutorialRef = doc(collection(db, 'tutorials'));
      await setDoc(tutorialRef, {
        ...newTutorial,
        createdAt: new Date()
      });

      // Reset form
      setNewTutorial({
        title: '',
        steps: [''],
        videoUrl: '',
        thumbnailUrl: '',
      });

      // Refresh tutorials
      fetchSupportContent();
      alert('Tutorial saved successfully!');
    } catch (error) {
      console.error('Error saving tutorial:', error);
      setError('Failed to save tutorial');
    } finally {
      setLoading(false);
    }
  };

  const handleVideoUpload = async (file, tutorialId) => {
    if (!file) return;

    const storageRef = ref(storage, `tutorials/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Create a new progress tracker
    setUploadProgress(prev => ({
      ...prev,
      [tutorialId]: 0
    }));

    uploadTask.on('state_changed',
      (snapshot) => {
        // Update progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(prev => ({
          ...prev,
          [tutorialId]: progress
        }));
      },
      (error) => {
        console.error('Upload error:', error);
        setUploadProgress(prev => ({
          ...prev,
          [tutorialId]: 0
        }));
      },
      async () => {
        // Upload completed
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        
        // Update tutorial with video URL
        await updateDoc(doc(db, 'tutorials', tutorialId), {
          videoUrl: downloadURL
        });

        // Clear progress
        setUploadProgress(prev => {
          const newProgress = { ...prev };
          delete newProgress[tutorialId];
          return newProgress;
        });

        // Refresh tutorials
        fetchSupportContent();
      }
    );
  };

  const handleThumbnailUpload = async (file, tutorialId) => {
    if (!file) return;

    const storageRef = ref(storage, `tutorials/thumbnails/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Thumbnail upload progress:', progress);
      },
      (error) => {
        console.error('Thumbnail upload error:', error);
        alert('Failed to upload thumbnail');
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        
        // Update tutorial with thumbnail URL
        await updateDoc(doc(db, 'tutorials', tutorialId), {
          thumbnailUrl: downloadURL
        });

        // Refresh tutorials
        fetchSupportContent();
        alert('Thumbnail uploaded successfully!');
      }
    );
  };

  const handleUploadDocument = async () => {
    if (!selectedDocument) return;
    setLoading(true);
    try {
      const storageRef = ref(storage, `support/documents/${selectedDocument.name}-${Date.now()}`);
      const snapshot = await uploadBytes(storageRef, selectedDocument);
      const fileUrl = await getDownloadURL(snapshot.ref);
      setNewDocument(prev => ({ ...prev, fileUrl }));
      alert('Document uploaded successfully!');
    } catch (error) {
      console.error('Error uploading document:', error);
      setError('Failed to upload document');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveDocument = async () => {
    setLoading(true);
    try {
      const docRef = doc(collection(db, 'supportDocuments'));
      await setDoc(docRef, {
        ...newDocument,
        createdAt: new Date()
      });
      setNewDocument({
        title: '',
        type: '',
        fileUrl: '',
      });
      setSelectedDocument(null);
      fetchSupportContent();
      alert('Document saved successfully!');
    } catch (error) {
      console.error('Error saving document:', error);
      setError('Failed to save document');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTutorial = async (tutorialId) => {
    if (!window.confirm('Are you sure you want to delete this tutorial?')) return;
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'tutorials', tutorialId));
      fetchSupportContent();
      alert('Tutorial deleted successfully!');
    } catch (error) {
      console.error('Error deleting tutorial:', error);
      setError('Failed to delete tutorial');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    if (!window.confirm('Are you sure you want to delete this document?')) return;
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'supportDocuments', documentId));
      fetchSupportContent();
      alert('Document deleted successfully!');
    } catch (error) {
      console.error('Error deleting document:', error);
      setError('Failed to delete document');
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <TabContent>
        <TabHeader>
          <h2>Support Content Management</h2>
        </TabHeader>

        {error && <ErrorText>{error}</ErrorText>}

        {/* Header Content Section */}
        <Section>
          <SectionTitle>Support Page Content</SectionTitle>
          <ContentForm>
            <FormGroup>
              <Label>Header Text</Label>
              <Input
                value={supportContent.headerText}
                onChange={(e) => setSupportContent(prev => ({ ...prev, headerText: e.target.value }))}
                disabled={!isEditing}
              />
            </FormGroup>

            <FormGroup>
              <Label>Subheader Text</Label>
              <RichTextEditor
                value={supportContent.subheaderText}
                onChange={(value) => setSupportContent(prev => ({ ...prev, subheaderText: value }))}
                readOnly={!isEditing}
              />
            </FormGroup>

            <FormGroup>
              <Label>Support Phone Number</Label>
              <Input
                value={supportContent.phoneNumber}
                onChange={(e) => setSupportContent(prev => ({ ...prev, phoneNumber: e.target.value }))}
                disabled={!isEditing}
              />
            </FormGroup>

            <ButtonGroup>
              {!isEditing ? (
                <ActionButton onClick={() => setIsEditing(true)}>
                  <FaEdit /> Edit Content
                </ActionButton>
              ) : (
                <>
                  <ActionButton onClick={handleSaveContent}>
                    <FaSave /> Save Changes
                  </ActionButton>
                  <CancelButton onClick={() => setIsEditing(false)}>
                    Cancel
                  </CancelButton>
                </>
              )}
            </ButtonGroup>
          </ContentForm>
        </Section>

        {/* Tutorials Section */}
        <Section>
          <SectionTitle>Tutorial Videos</SectionTitle>
          
          {/* Add New Tutorial */}
          <Card>
            <CardTitle>Add New Tutorial</CardTitle>
            <FormGroup>
              <Label>Title</Label>
              <Input
                type="text"
                value={newTutorial.title}
                onChange={(e) => setNewTutorial(prev => ({ ...prev, title: e.target.value }))}
                placeholder="Enter tutorial title..."
              />
            </FormGroup>

            <FormGroup>
              <Label>Steps</Label>
              {newTutorial.steps.map((step, index) => (
                <StepContainer key={index}>
                  <Input
                    type="text"
                    value={step}
                    onChange={(e) => handleTutorialStepChange(index, e.target.value)}
                    placeholder={`Step ${index + 1}`}
                  />
                  <RemoveButton onClick={() => handleRemoveTutorialStep(index)}>×</RemoveButton>
                </StepContainer>
              ))}
              <AddButton onClick={handleAddTutorialStep}>+ Add Step</AddButton>
            </FormGroup>

            <ButtonGroup>
              <SaveButton onClick={handleSaveTutorial} disabled={loading}>
                {loading ? 'Saving...' : 'Save Tutorial'}
              </SaveButton>
            </ButtonGroup>
          </Card>

          <TutorialsGrid>
            {tutorials.map((tutorial) => (
              <TutorialCard key={tutorial.id}>
                <ThumbnailContainer>
                  {tutorial.thumbnailUrl ? (
                    <ThumbnailPreview src={tutorial.thumbnailUrl} alt={tutorial.title} />
                  ) : tutorial.videoUrl ? (
                    <ThumbnailPreview src={tutorial.videoUrl} alt={tutorial.title} />
                  ) : (
                    <ThumbnailPlaceholder>
                      <FaPlay size={30} />
                    </ThumbnailPlaceholder>
                  )}
                </ThumbnailContainer>
                <TutorialInfo>
                  <TutorialTitle>{tutorial.title}</TutorialTitle>
                  
                  {/* Display Steps */}
                  {tutorial.steps && tutorial.steps.length > 0 && (
                    <StepsList>
                      {tutorial.steps.map((step, index) => (
                        <StepItem key={index}>{step}</StepItem>
                      ))}
                    </StepsList>
                  )}
                  
                  {uploadProgress[tutorial.id] !== undefined && (
                    <ProgressBar>
                      <Progress width={uploadProgress[tutorial.id]} />
                    </ProgressBar>
                  )}
                  <ButtonGroup>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={(e) => handleVideoUpload(e.target.files[0], tutorial.id)}
                      style={{ display: 'none' }}
                      ref={videoInputRef}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleThumbnailUpload(e.target.files[0], tutorial.id)}
                      style={{ display: 'none' }}
                      ref={thumbnailInputRef}
                    />
                    <ActionButton onClick={() => videoInputRef.current.click()}>
                      <FaUpload /> Upload Video
                    </ActionButton>
                    <ActionButton onClick={() => thumbnailInputRef.current.click()}>
                      <FaUpload /> Upload Thumbnail
                    </ActionButton>
                    <DeleteButton onClick={() => handleDeleteTutorial(tutorial.id)}>
                      <FaTrash /> Delete
                    </DeleteButton>
                  </ButtonGroup>
                </TutorialInfo>
              </TutorialCard>
            ))}
          </TutorialsGrid>
        </Section>

        {/* Documents Section */}
        <Section>
          <SectionTitle>Product Documentation</SectionTitle>
          
          {/* Add New Document */}
          <Card>
            <CardTitle>Add New Document</CardTitle>
            <FormGroup>
              <Label>Title</Label>
              <Input
                type="text"
                value={newDocument.title}
                onChange={(e) => setNewDocument(prev => ({ ...prev, title: e.target.value }))}
                placeholder="Enter document title..."
              />
            </FormGroup>
            
            <FormGroup>
              <Label>Document Type</Label>
              <Input
                type="text"
                value={newDocument.type}
                onChange={(e) => setNewDocument(prev => ({ ...prev, type: e.target.value }))}
                placeholder="Enter document type (e.g., Manual, Guide)..."
              />
            </FormGroup>

            <FormGroup>
              <Label>Document File (PDF)</Label>
              <FileInputWrapper>
                <FileInputLabel>
                  Choose File
                  <HiddenFileInput
                    type="file"
                    accept=".pdf"
                    onChange={(e) => setSelectedDocument(e.target.files[0])}
                  />
                </FileInputLabel>
                {selectedDocument && (
                  <UploadButton onClick={handleUploadDocument} disabled={loading}>
                    Upload Document
                  </UploadButton>
                )}
              </FileInputWrapper>
            </FormGroup>

            <SaveButton onClick={handleSaveDocument} disabled={loading}>
              {loading ? 'Saving...' : 'Save Document'}
            </SaveButton>
          </Card>

          {/* Existing Documents */}
          <DocumentsGrid>
            {documents.map(doc => (
              <DocumentCard key={doc.id}>
                <DocumentInfo>
                  <DocumentTitle>{doc.title}</DocumentTitle>
                  <DocumentType>{doc.type}</DocumentType>
                </DocumentInfo>
                <DeleteButton onClick={() => handleDeleteDocument(doc.id)}>
                  Delete Document
                </DeleteButton>
              </DocumentCard>
            ))}
          </DocumentsGrid>
        </Section>
      </TabContent>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
});

const TabContent = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;

  h2 {
    margin: 0;
    color: ${colors.primary.solid};
    font-size: 24px;
  }
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  color: ${colors.primary.solid};
  margin: 0 0 20px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h4`
  font-size: 18px;
  color: #333;
  margin: 0 0 15px;
`;

const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-weight: 600;
  color: #666;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;

  &:disabled {
    background: #f5f5f5;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const CancelButton = styled(ActionButton)`
  background: #666;
`;

const DeleteButton = styled(ActionButton)`
  background: #dc3545;
`;

const TutorialsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

const TutorialCard = styled.div`
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: calc(33.333% - 14px);
  min-width: 300px;
  
  @media (max-width: 1024px) {
    width: calc(50% - 10px);
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9 aspect ratio
  background: #f5f5f5;
  overflow: hidden;
`;

const ThumbnailPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primary.solid};
  background: #f5f5f5;
`;

const ThumbnailPreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TutorialInfo = styled.div`
  padding: 15px;
`;

const TutorialTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 18px;
  color: #333;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  margin: 10px 0;
`;

const Progress = styled.div`
  width: ${props => props.width}%;
  height: 100%;
  background: ${colors.primary.solid};
  border-radius: 2px;
  transition: width 0.3s ease;
`;

const DocumentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const DocumentCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DocumentInfo = styled.div`
  margin-bottom: 15px;
`;

const DocumentTitle = styled.h4`
  font-size: 16px;
  color: #333;
  margin: 0 0 5px;
`;

const DocumentType = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const ErrorText = styled.div`
  color: #ff4444;
  background: #ffe5e5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 14px;
`;

const FileInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    transform: translateY(-2px);
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const UploadButton = styled(ActionButton)`
  background: ${colors.primary.solid}20;
  color: ${colors.primary.solid};

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SaveButton = styled(ActionButton)`
  width: 100%;
  justify-content: center;
  margin-top: 20px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StepContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary.solid};
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
  
  &:hover {
    opacity: 0.8;
  }
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary.solid};
  font-weight: 600;
  cursor: pointer;
  padding: 5px 0;
  
  &:hover {
    opacity: 0.8;
  }
`;

const StepsList = styled.ol`
  margin: 10px 0;
  padding-left: 20px;
`;

const StepItem = styled.li`
  color: #666;
  margin-bottom: 5px;
  font-size: 14px;
`; 