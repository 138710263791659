import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import styled from 'styled-components';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { 
  colors, 
  breakpoints, 
  createContainerStyle, 
  getResponsiveSpacing, 
  getResponsiveTypography 
} from '../styles/theme';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { PageNavigation, PageHeader } from '../components/PageNavigation';
import { SearchBar } from '../components/SearchBar';
import { usePageTitle } from '../hooks/usePageTitle';

function ProductCard({ 
  id, 
  name, 
  price, 
  image, 
  imageUrl, 
  description, 
  shortDescription,
  sku, 
  installationFee, 
  columns 
}) {
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [addedToCart, setAddedToCart] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleBuyNow = () => {
    addToCart({
      id,
      type: 'product',
      name,
      price,
      quantity,
      imageUrl,
      sku,
      installationFee: installationFee || 0
    });
    navigate('/checkout');
  };

  const handleAddToCart = () => {
    addToCart({ 
      id, 
      name, 
      price, 
      quantity,
      imageUrl,
      sku,
      installationFee: installationFee || 0
    });
    setAddedToCart(true);
    setTimeout(() => setAddedToCart(false), 2000);
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    if (value > 0) setQuantity(value);
  };

  return (
    <CardContainer>
      <ProductInfo columns={columns}>
        <ProductImageWrapper columns={columns}>
          <ProductImage 
            src={imageUrl || 'https://via.placeholder.com/300x300?text=No+Image'} 
            alt={name} 
            loading="lazy" 
          />
        </ProductImageWrapper>
        <ProductDetails columns={columns}>
          <ProductTitle columns={columns}>{name}</ProductTitle>
          <ProductPrice columns={columns}>Base Price: ${typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2)}</ProductPrice>
          <ProductDescription columns={columns}>
            {showFullDescription ? description : (shortDescription || description.slice(0, 100))}
            {description && (
              <ShowMoreButton
                onClick={() => setShowFullDescription(!showFullDescription)}
              >
                {showFullDescription ? 'Show Less' : 'Show More'}
              </ShowMoreButton>
            )}
          </ProductDescription>
          <QuantityContainer>
            <QuantityLabel>Quantity:</QuantityLabel>
            <QuantityControls>
              <QuantityButton onClick={() => quantity > 1 && setQuantity(q => q - 1)}>-</QuantityButton>
              <QuantityInput
                type="number"
                min="1"
                value={quantity}
                onChange={handleQuantityChange}
              />
              <QuantityButton onClick={() => setQuantity(q => q + 1)}>+</QuantityButton>
            </QuantityControls>
          </QuantityContainer>
        </ProductDetails>
      </ProductInfo>
      <ButtonGroup>
        <ActionButton style={{


          background: 'linear-gradient(0deg, #e71c24 -14.96%, #912528 100%)',
          '@media (max-width: 480px)': {
            fontSize: 12,
          }
        }} onClick={handleBuyNow}>Buy Now</ActionButton>
        <ActionButton 
          onClick={handleAddToCart}
          disabled={addedToCart}
          style={{
           border: '2px solid #e71c24',
           background: 'white',
           color: '#e71c24',
           '@media (max-width: 480px)': {
            fontSize: 12,
           }
          }}
        >
          {addedToCart ? 'Added to Cart!' : 'Add to Cart'}
        </ActionButton>
      </ButtonGroup>
    </CardContainer>
  );
}

function ProductsPage() {
  usePageTitle('Products');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [columnCount, setColumnCount] = useState(3);
  const [searchTerm, setSearchTerm] = useState('');

  const PRODUCT_CATEGORIES = [
    'Alarms',
    'Control Panels',
    'Motion Detectors',
    'Environmental Sensors',
    'Door/Window Sensors',
    'Life Safety',
    'Accessories',
    'Cameras',
    'Deterrence Devices',
    'Power Over Ethernet (POE)',
    'Virtual Guard',
    'NVR'
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          displayOrder: doc.data().displayOrder || 0
        }));
        
        // Sort products by displayOrder
        const sortedProducts = productsData.sort((a, b) => 
          a.displayOrder - b.displayOrder
        );
        
        setProducts(sortedProducts);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Helper function to score search matches
  const getSearchScore = (product, term) => {
    const lowerTerm = term.toLowerCase();
    const name = product.name.toLowerCase();
    const sku = product.sku.toLowerCase();
    const description = product.description.toLowerCase();
    
    // Exact title match (highest priority)
    if (name === lowerTerm) return 100;
    
    // Title starts with search term (high priority)
    if (name.startsWith(lowerTerm)) return 80;
    
    // SKU match (high priority)
    if (sku === lowerTerm) return 70;
    
    // Title contains search term (medium priority)
    if (name.includes(lowerTerm)) return 60;
    
    // SKU contains search term (medium priority)
    if (sku.includes(lowerTerm)) return 50;
    
    // Description contains exact search term (lower priority)
    if (description.includes(lowerTerm)) return 30;
    
    // No match
    return 0;
  };

  // Filter and sort products based on search term and category
  const getFilteredProducts = () => {
    // First filter by category
    let filtered = products;
    if (selectedCategory) {
      filtered = filtered.filter(product => product.category === selectedCategory);
    }
    
    // If no search term, return category-filtered products in their display order
    if (!searchTerm) return filtered;
    
    // Filter and score products based on search term
    const scored = filtered
      .map(product => ({
        ...product,
        score: getSearchScore(product, searchTerm)
      }))
      .filter(product => product.score > 0);
    
    // Sort by score (highest first)
    return scored.sort((a, b) => b.score - a.score);
  };

  const filteredProducts = getFilteredProducts();

  if (loading) {
    return (
      <View style={styles.container}>
        <HeaderBar />
        <LoadingText>Loading products...</LoadingText>
        <Footer />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.container}>
        <HeaderBar />
        <ErrorText>{error}</ErrorText>
        <Footer />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <HeaderBar />
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <CatalogWrapper>
            <PageNavigation />
            <CatalogContainer>
              <PageHeader title="Shop by Product" />
              <FilterContainer>
                <SearchBar
                  value={searchTerm}
                  onChange={setSearchTerm}
                  placeholder="Search products by name, SKU, or description..."
                />
              
                <CategoryFilter>
                  <CategorySelect
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">All Categories</option>
                    {PRODUCT_CATEGORIES.map((category) => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </CategorySelect>
                </CategoryFilter>
                <LayoutControl>
                  <LayoutLabel>Display Columns:</LayoutLabel>
                  <LayoutSelect
                    value={columnCount}
                    onChange={(e) => setColumnCount(Number(e.target.value))}
                  >
                    <option value={1}>1 Column</option>
                    <option value={2}>2 Columns</option>
                    <option value={3}>3 Columns</option>
                  </LayoutSelect>
                </LayoutControl>
              </FilterContainer>
              <ProductGrid columns={columnCount}>
                {filteredProducts.map((product) => (
                  <ProductCard key={product.id} {...product} columns={columnCount} />
                ))}
              </ProductGrid>
              <PageNavigation isFooter />
            </CatalogContainer>
          </CatalogWrapper>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
}

const Container = styled.div`
  padding-top: 80px;
  min-height: 100vh;
  background-color: #f5f5f5;
  
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

const CatalogWrapper = styled.div`
  ${createContainerStyle()}
  display: flex;
  flex-direction: column;
  font-family: Tahoma, sans-serif;
  align-items: center;
  margin: 0 auto;
  padding-top: var(--announcement-offset, 0px);

  @media (max-width: ${breakpoints.md}px) {
    padding-top: var(--announcement-offset-mobile, 0px);
  }
`;

const CatalogContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${getResponsiveSpacing('xl').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('xl').md}px;
  }
`;

const CatalogHeader = styled.h1`
  width: 100%;
  background: linear-gradient(0deg, #e71c24 -14.96%, #912528 100%);
  border-radius: 15px;
  padding: 30px;
  font-size: 36px;
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ButtonContainer = styled.nav`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 0;
  flex-wrap: wrap;

  ${props => props.isFooter && `
    margin-top: 60px;
    justify-content: space-between;
    
    @media (max-width: 768px) {
      justify-content: center;
    }
  `}
`;

const NavButton = styled.button`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 30px;
  min-width: 200px;
  height: 60px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    min-width: 180px;
    height: 50px;
    font-size: 16px;
  }
`;

const NavButtonActive = styled(NavButton)`
  background: linear-gradient(0deg, #e71c24 -14.96%, #912528 100%);
  color: white;
`;

const GradientText = styled.span`
  background: linear-gradient(0deg, #e71c24 -14.96%, #912528 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const CardContainer = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: 480px) {
    border-radius: 8px;
  }
`;

const ProductInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${props => props.columns === 1 ? 'row' : 'column'};
  gap: 20px;
  padding: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  
  @media (max-width: 480px) {
    padding: 12px;
    gap: 10px;
  }
`;

const ProductImageWrapper = styled.div`
  width: ${props => props.columns === 1 ? '300px' : '100%'};
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  
  @media (max-width: 768px) {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
  
  @media (max-width: 480px) {
    max-width: 200px;
    border-radius: 6px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
  padding: 10px;
  box-sizing: border-box;
  
  @media (max-width: 480px) {
    padding: 5px;
  }
`;

const ProductDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const ProductTitle = styled.h3`
  ${getResponsiveTypography('h4')}
  color: ${colors.primary.solid};
  margin: 0;
  
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const ProductSku = styled.p`
  ${getResponsiveTypography('body')}
  color: ${colors.gray.dark};
  margin: 0;
  opacity: 0.8;
  
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ProductPrice = styled.p`
  ${getResponsiveTypography('h5')}
  color: #000000;
  margin: 0;
  font-weight: 600;
  
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const ProductDescription = styled.div`
  ${getResponsiveTypography('body')}
  color: ${colors.gray.dark};
  margin: ${props => props.columns === 1 ? '15px 0' : '10px 0'};
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: pre-line;
  
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 1.4;
    margin: 5px 0;
    gap: 5px;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
  
  @media (max-width: 480px) {
    gap: 10px;
    margin-top: 5px;
    flex-wrap: wrap;
  }
`;

const QuantityLabel = styled.label`
  font-size: 14px;
  color: ${colors.gray.dark};
  
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const QuantityButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: white;
      color: ${colors.primary.solid};
    }
  }
  
  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  font-size: 16px;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
  
  @media (max-width: 480px) {
    width: 50px;
    padding: 3px;
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;
  border-top: 1px solid ${colors.gray.light};
  background: white;
  
  @media (max-width: 768px) {
    padding: 15px;
  }
  
  @media (max-width: 480px) {
    padding: 12px;
    gap: 8px;
  }
`;

const ActionButton = styled.button`
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  
  color: white;
  font-weight: 600;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;

  &:hover {
    transform: ${props => !props.disabled && 'translateY(-2px)'};
    box-shadow: ${props => !props.disabled && '0 4px 8px rgba(231, 28, 36, 0.2)'};
  }
  
  @media (max-width: 480px) {
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
  }
`;

const LoadingText = styled.p`
  ${getResponsiveTypography('h3')}
  text-align: center;
  color: ${colors.primary.solid};
  margin-top: ${getResponsiveSpacing('xl').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    margin-top: ${getResponsiveSpacing('xl').md}px;
  }
`;

const ErrorText = styled.p`
  ${getResponsiveTypography('body')}
  text-align: center;
  color: ${colors.primary.solid};
  margin-top: ${getResponsiveSpacing('xl').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    margin-top: ${getResponsiveSpacing('xl').md}px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${getResponsiveSpacing('md').xs}px;
  margin-bottom: ${getResponsiveSpacing('lg').xs}px;

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${getResponsiveSpacing('md').md}px;
    margin-bottom: ${getResponsiveSpacing('lg').md}px;
  }
  
  @media (max-width: 480px) {
    gap: 10px;
    margin-bottom: 15px;
  }
`;

const CategoryFilter = styled.div`
  flex: 0 0 auto;
  min-width: 100px;
  max-width: 90%;
  
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    max-width: 100%;
  }
`;

const CategorySelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid ${colors.gray.medium};
  border-radius: 8px;
  background-color: white;
  font-size: ${getResponsiveTypography('body').fontSize.xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    font-size: ${getResponsiveTypography('body').fontSize.md}px;
  }
  
  @media (max-width: 480px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const LayoutControl = styled.div`
  display: flex;
  align-items: center;
  gap: ${getResponsiveSpacing('sm').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('sm').md}px;
  }
`;

const LayoutLabel = styled.span`
  font-size: ${getResponsiveTypography('body').fontSize.xs}px;
  white-space: nowrap;
  
  @media (min-width: ${breakpoints.md}px) {
    font-size: ${getResponsiveTypography('body').fontSize.md}px;
  }
`;

const LayoutSelect = styled.select`
  padding: 8px;
  border: 1px solid ${colors.gray.medium};
  border-radius: 8px;
  background-color: white;
  font-size: ${getResponsiveTypography('body').fontSize.xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    font-size: ${getResponsiveTypography('body').fontSize.md}px;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  width: 100%;
  gap: ${getResponsiveSpacing('lg').xs}px;
  grid-template-columns: 1fr;
  
  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: ${props => props.columns >= 2 ? 'repeat(2, 1fr)' : '1fr'};
    gap: ${getResponsiveSpacing('lg').sm}px;
  }
  
  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: ${props => props.columns === 3 ? 'repeat(3, 1fr)' : props.columns === 2 ? 'repeat(2, 1fr)' : '1fr'};
    gap: ${getResponsiveSpacing('lg').lg}px;
  }
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary.solid};
  font-size: 12px;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  font-weight: 600;
  
  &:hover {
    text-decoration: underline;
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingTop: getResponsiveSpacing('xl', 3).xs,
    paddingBottom: getResponsiveSpacing('xl').xs,
    
    '@media (min-width: ${breakpoints.md}px)': {
      paddingTop: getResponsiveSpacing('xl', 3).md,
      paddingBottom: getResponsiveSpacing('xl').md,
    }
  },
});

export default ProductsPage; 