import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { colors } from '../styles/SharedStyles';
import { FaFacebook, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { FaXTwitter, FaInstagram, FaTiktok } from 'react-icons/fa6';

export const SocialMediaTab = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollDirection, setScrollDirection] = useState('up');
  const location = useLocation();

  useEffect(() => {
    // console.log('Component mounted, isLandingPage:', isLandingPage);
    
    // Log initial scroll position
    // console.log('Initial scroll position:', window.pageYOffset);
    
    let lastScroll = window.pageYOffset || document.documentElement.scrollTop;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      //  console.log('Raw scroll event detected:', currentScroll);

      if (currentScroll > lastScroll) {
        // console.log('Scrolling DOWN');
        setScrollDirection('down');
        setIsVisible(false);
      } else if (currentScroll < lastScroll) {
        // console.log('Scrolling UP');
        setScrollDirection('up');
        setIsVisible(true);
      }

      lastScroll = currentScroll;
    };

    // Add both scroll and wheel events to ensure detection
    document.addEventListener('scroll', handleScroll, { passive: true });
    document.addEventListener('wheel', handleScroll, { passive: true });

    // Test log to verify event listeners are attached
    // console.log('Scroll and wheel event listeners attached');

    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('wheel', handleScroll);
      // console.log('Event listeners cleaned up');
    };
  }, []);

  // Log state changes
  useEffect(() => {
    // console.log('Visibility changed:', isVisible);
  }, [isVisible]);

  useEffect(() => {
    // console.log('Direction changed:', scrollDirection);
  }, [scrollDirection]);

  return (
    <TabContainer>
      <SocialTab $isVisible={isVisible}>
        <SocialLink href="https://www.facebook.com/people/Security-Central-Protection/100087748939726/" target="_blank" rel="noopener noreferrer" className="facebook">
          <FaFacebook size={16} />
        </SocialLink>
        <SocialLink href="https://twitter.com/scp_911" target="_blank" rel="noopener noreferrer" className="twitter">
          <FaXTwitter size={16} />
        </SocialLink>
        <SocialLink href="https://www.linkedin.com/company/security-central-protection-services/" target="_blank" rel="noopener noreferrer" className="linkedin">
          <FaLinkedin size={16} />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/channel/UC9WujQWYB_24w7X4MZxLnRw/videos" target="_blank" rel="noopener noreferrer" className="youtube">
          <FaYoutube size={16} />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/scp911/" target="_blank" rel="noopener noreferrer" className="instagram">
          <FaInstagram size={16} />
        </SocialLink>
        <SocialLink href="https://www.tiktok.com/@scp911security" target="_blank" rel="noopener noreferrer" className="tiktok">
          <FaTiktok size={16} />
        </SocialLink>
      </SocialTab>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  position: fixed;
  right: -85px;
  top: 55%;
  z-index: 999;
  
  &:hover {
    & > div {
      right: 0;
    }
  }
`;


// background: #333333;
const SocialTab = styled.div`
  position: relative;
  right: ${props => props.$isVisible ? '0' : '-35px'};
  transform: rotate(-90deg) translateX(-50%); 
  background: linear-gradient(to right,rgb(0, 102, 204),rgb(0, 51, 153));
  padding: 8px 15px;
  border: none;
  border-radius: 8px 8px 0 0;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 15px;
  transition: right 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 100%;
    background: transparent;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SocialLink = styled.a`
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-2px);
  }
  
  /* Add company-specific colors for each social media icon on hover using class names */
  &.facebook:hover {
    color: #1877F2; /* Facebook blue */
  }
  
  &.twitter:hover {
    color: #000000; /* Twitter/X black */
  }
  
  &.linkedin:hover {
    color: #0A66C2; /* LinkedIn blue */
  }
  
  &.youtube:hover {
    color: #FF0000; /* YouTube red */
  }
  
  &.instagram:hover {
    color: #E4405F; /* Instagram pink */
  }
  
  &.tiktok:hover {
    color: #000000; /* TikTok black */
  }
`; 