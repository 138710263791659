import React from 'react';
import styled from 'styled-components';
import { HeaderBar } from '../../components/HeaderBar';
import { Footer } from '../../components/Footer';
import { View } from 'react-native';
import { usePageTitle } from '../../hooks/usePageTitle';

const PageWrapper = styled(View)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PolicyContainer = styled.div`
  max-width: 1200px;
  margin: 80px auto;
  padding: 0 20px;
  font-family: 'Tahoma', sans-serif;
  flex: 1;
`;

const PolicyContent = styled.div`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PolicyTitle = styled.h1`
  color: #333;
  margin-bottom: 30px;
  font-size: 2.5em;
  text-align: center;
`;

const PolicyText = styled.div`
  color: #444;
  line-height: 1.8;
  font-size: 16px;
  
  p {
    margin-bottom: 20px;
  }
  
  ul {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }

  h2 {
    color: #333;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 1.8em;
  }
`;

const PolicyPage = ({ title, children }) => {
  usePageTitle(title);
  
  return (
    <PageWrapper>
      <HeaderBar />
      <PolicyContainer>
        <PolicyContent>
          <PolicyTitle>{title}</PolicyTitle>
          <PolicyText>
            {children}
          </PolicyText>
        </PolicyContent>
      </PolicyContainer>
      <Footer />
    </PageWrapper>
  );
};

export default PolicyPage; 