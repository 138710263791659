import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

export function PageNavigation({ isFooter }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  
  return (
    <ButtonContainer isFooter={isFooter}>
      {!isFooter ? (
        <>
          <NavButton 
            onClick={() => navigate('/build')}
            isActive={currentPath === '/build'}
          >
            {currentPath === '/build' ? (
              'Build a System'
            ) : (
              <GradientText>Build a System</GradientText>
            )}
          </NavButton>
          <NavButton 
            onClick={() => navigate('/products')}
            isActive={currentPath === '/products'}
          >
            {currentPath === '/products' ? (
              'Shop by Product'
            ) : (
              <GradientText>Shop by Product</GradientText>
            )}
          </NavButton>
          <NavButton 
            onClick={() => navigate('/packages')}
            isActive={currentPath === '/packages'}
          >
            {currentPath === '/packages' ? (
              'Shop Packages'
            ) : (
              <GradientText>Shop Packages</GradientText>
            )}
          </NavButton>
        </>
      ) : (
        <>
          <NavButton onClick={() => navigate(-1)}>
            <GradientText>Back</GradientText>
          </NavButton>
          <NavButton 
            onClick={() => navigate('/checkout')}
            isActive={true}
          >
            Checkout
          </NavButton>
        </>
      )}
    </ButtonContainer>
  );
}

export function PageHeader({ title }) {
  return (
    <CatalogHeader>{title}</CatalogHeader>
  );
}

const ButtonContainer = styled.nav`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 0;
  flex-wrap: wrap;


  ${props => props.isFooter && `
    margin-top: 60px;
    justify-content: space-between;
    
    @media (max-width: 768px) {
      justify-content: center;
    }
  `}
`;

const NavButton = styled.button`
  background-color: ${props => props.isActive ? 'transparent' : 'rgba(255, 255, 255, 0.95)'};
  background-image: ${props => props.isActive ? 'linear-gradient(0deg, #e71c24 -14.96%, #912528 100%)' : 'none'};
  border-radius: 30px;
  min-width: 200px;
  height: 60px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: ${props => props.isActive ? 'white' : 'inherit'};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    min-width: 180px;
    height: 50px;
    font-size: 16px;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(0deg, #e71c24 -14.96%, #912528 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const CatalogHeader = styled.h1`
  width: 100%;
  background: linear-gradient(0deg, #e71c24 -14.96%, #912528 100%);
  border-radius: 15px;
  padding: 30px;
  font-size: 36px;
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`; 