import { db } from '../config/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

// Default disclaimer messages
const DEFAULT_DISCLAIMERS = {
  cart: {
    enabled: true,
    message: '<p><strong>IMPORTANT:</strong> Please carefully review your selected products to ensure compatibility with your existing security system and that they meet your specific security needs. Our team is available to assist with any questions regarding product selection or system compatibility.</p>'
  },
  checkout: {
    enabled: true,
    message: '<p><strong>COMPATIBILITY NOTICE:</strong> By proceeding with this purchase, you acknowledge that you have verified the compatibility of all selected products with your existing security infrastructure. Security Central Protection cannot guarantee functionality for incompatible systems.</p><p>For installation assistance or compatibility questions, please contact our technical support team at <strong>248.543.0000</strong> before completing your purchase.</p>'
  },
  poe: {
    enabled: true,
    message: '<p><strong>COMPATIBILITY NOTICE:</strong> Please ensure the selected POE switch has sufficient ports and power capacity for your camera system. For assistance with POE selection, contact our support team.</p>'
  },
  updatedAt: new Date()
};

// Function to initialize disclaimers in Firestore
export const initializeDisclaimers = async () => {
  try {
    // Check if disclaimers document already exists
    const disclaimerDoc = await getDoc(doc(db, 'siteSettings', 'disclaimers'));
    
    // If it doesn't exist, create it with default values
    if (!disclaimerDoc.exists()) {
      await setDoc(doc(db, 'siteSettings', 'disclaimers'), DEFAULT_DISCLAIMERS);
      console.log('Disclaimers initialized successfully!');
    } else {
      console.log('Disclaimers document already exists.');
    }
  } catch (err) {
    console.error('Error initializing disclaimers:', err);
  }
};

// Export default disclaimers for reference
export default DEFAULT_DISCLAIMERS; 