import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { colors } from '../../styles/SharedStyles';
import { db } from '../../config/firebase';
import { collection, query, orderBy, getDocs, where, Timestamp } from 'firebase/firestore';
import { GradientButton } from '../GradientButton';

export const SalesAnalytics = () => {
  const [salesData, setSalesData] = useState({
    totalSales: 0,
    totalOrders: 0,
    averageOrderValue: 0,
    recentOrders: [],
    dailyStats: [],
    topProducts: []
  });
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState('week'); // week, month, year

  useEffect(() => {
    fetchSalesData();
  }, [timeframe]);

  const fetchSalesData = async () => {
    try {
      setLoading(true);
      const ordersRef = collection(db, 'orders');
      
      // Calculate date range
      const now = new Date();
      const startDate = new Date();
      switch (timeframe) {
        case 'week':
          startDate.setDate(now.getDate() - 7);
          break;
        case 'month':
          startDate.setMonth(now.getMonth() - 1);
          break;
        case 'year':
          startDate.setFullYear(now.getFullYear() - 1);
          break;
      }

      // Query orders within date range
      const q = query(
        ordersRef,
        where('createdAt', '>=', Timestamp.fromDate(startDate)),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.() || new Date(doc.data().createdAt)
      }));

      console.log('Fetched orders:', orders); // Debug log

      // Calculate statistics
      const totalSales = orders.reduce((sum, order) => sum + (order.total || 0), 0);
      const totalOrders = orders.length;
      const averageOrderValue = totalOrders > 0 ? totalSales / totalOrders : 0;

      // Calculate daily stats
      const dailyStats = calculateDailyStats(orders);

      // Calculate top products
      const topProducts = calculateTopProducts(orders);

      setSalesData({
        totalSales,
        totalOrders,
        averageOrderValue,
        recentOrders: orders.slice(0, 5),
        dailyStats,
        topProducts
      });
    } catch (err) {
      console.error('Error fetching sales data:', err);
      // Reset to default empty state instead of showing error
      setSalesData({
        totalSales: 0,
        totalOrders: 0,
        averageOrderValue: 0,
        recentOrders: [],
        dailyStats: [],
        topProducts: []
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateDailyStats = (orders) => {
    const stats = {};
    orders.forEach(order => {
      const date = order.createdAt.toISOString().split('T')[0];
      if (!stats[date]) {
        stats[date] = {
          total: 0,
          count: 0
        };
      }
      stats[date].total += order.total || 0;
      stats[date].count += 1;
    });
    return Object.entries(stats)
      .map(([date, data]) => ({
        date,
        ...data,
        average: data.total / data.count
      }))
      .sort((a, b) => b.date.localeCompare(a.date));
  };

  const calculateTopProducts = (orders) => {
    const products = {};
    orders.forEach(order => {
      if (!order.items) return;
      order.items.forEach(item => {
        if (!products[item.id]) {
          products[item.id] = {
            id: item.id,
            name: item.name,
            quantity: 0,
            revenue: 0
          };
        }
        products[item.id].quantity += item.quantity || 0;
        products[item.id].revenue += (item.price * item.quantity) || 0;
      });
    });
    return Object.values(products)
      .sort((a, b) => b.revenue - a.revenue)
      .slice(0, 5);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <View style={styles.container}>
        <Text style={styles.loadingText}>Loading sales data...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Sales Analytics</Text>
        <View style={styles.timeframeButtons}>
          <GradientButton
            onPress={() => setTimeframe('week')}
            text="Last Week"
            variant={timeframe === 'week' ? 'primary' : 'secondary'}
            style={styles.timeframeButton}
          />
          <GradientButton
            onPress={() => setTimeframe('month')}
            text="Last Month"
            variant={timeframe === 'month' ? 'primary' : 'secondary'}
            style={styles.timeframeButton}
          />
          <GradientButton
            onPress={() => setTimeframe('year')}
            text="Last Year"
            variant={timeframe === 'year' ? 'primary' : 'secondary'}
            style={styles.timeframeButton}
          />
        </View>
      </View>

      <View style={styles.statsGrid}>
        <View style={styles.statCard}>
          <Text style={styles.statLabel}>Total Sales</Text>
          <Text style={styles.statValue}>{formatCurrency(salesData.totalSales)}</Text>
        </View>
        <View style={styles.statCard}>
          <Text style={styles.statLabel}>Total Orders</Text>
          <Text style={styles.statValue}>{salesData.totalOrders}</Text>
        </View>
        <View style={styles.statCard}>
          <Text style={styles.statLabel}>Average Order Value</Text>
          <Text style={styles.statValue}>{formatCurrency(salesData.averageOrderValue)}</Text>
        </View>
      </View>

      <View style={styles.contentGrid}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Recent Orders</Text>
          {salesData.recentOrders.length > 0 ? (
            <View style={styles.ordersTable}>
              {salesData.recentOrders.map(order => (
                <View key={order.id} style={styles.orderRow}>
                  <Text style={styles.orderDate}>{formatDate(order.createdAt)}</Text>
                  <Text style={styles.orderAmount}>{formatCurrency(order.total || 0)}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text style={styles.emptyText}>No orders yet</Text>
          )}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Top Products</Text>
          {salesData.topProducts.length > 0 ? (
            <View style={styles.productsTable}>
              {salesData.topProducts.map(product => (
                <View key={product.id} style={styles.productRow}>
                  <Text style={styles.productName}>{product.name}</Text>
                  <Text style={styles.productQuantity}>Qty: {product.quantity}</Text>
                  <Text style={styles.productRevenue}>{formatCurrency(product.revenue)}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text style={styles.emptyText}>No product sales data yet</Text>
          )}
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Daily Sales</Text>
        {salesData.dailyStats.length > 0 ? (
          <View style={styles.dailyStatsTable}>
            {salesData.dailyStats.map(stat => (
              <View key={stat.date} style={styles.dailyRow}>
                <Text style={styles.dailyDate}>{formatDate(stat.date)}</Text>
                <Text style={styles.dailyOrders}>Orders: {stat.count}</Text>
                <Text style={styles.dailyTotal}>{formatCurrency(stat.total)}</Text>
                <Text style={styles.dailyAverage}>Avg: {formatCurrency(stat.average)}</Text>
              </View>
            ))}
          </View>
        ) : (
          <Text style={styles.emptyText}>No daily sales data yet</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary.solid,
  },
  timeframeButtons: {
    flexDirection: 'row',
    gap: 10,
  },
  timeframeButton: {
    minWidth: 100,
    height: 36,
  },
  statsGrid: {
    flexDirection: 'row',
    gap: 20,
    marginBottom: 30,
  },
  statCard: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  statLabel: {
    fontSize: 14,
    color: '#666',
    marginBottom: 5,
  },
  statValue: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary.solid,
  },
  contentGrid: {
    flexDirection: 'row',
    gap: 20,
    marginBottom: 30,
  },
  section: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 15,
    color: colors.primary.solid,
  },
  ordersTable: {
    gap: 10,
  },
  orderRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  orderDate: {
    color: '#666',
  },
  orderAmount: {
    fontWeight: '600',
  },
  productsTable: {
    gap: 10,
  },
  productRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  productName: {
    flex: 2,
  },
  productQuantity: {
    flex: 1,
    color: '#666',
  },
  productRevenue: {
    flex: 1,
    textAlign: 'right',
    fontWeight: '600',
  },
  dailyStatsTable: {
    gap: 10,
  },
  dailyRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  dailyDate: {
    flex: 1,
  },
  dailyOrders: {
    flex: 1,
    color: '#666',
  },
  dailyTotal: {
    flex: 1,
    textAlign: 'right',
    fontWeight: '600',
  },
  dailyAverage: {
    flex: 1,
    textAlign: 'right',
    color: '#666',
  },
  loadingText: {
    fontSize: 16,
    color: '#666',
    textAlign: 'center',
  },
  emptyText: {
    fontSize: 14,
    color: '#666',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: 20,
  },
}); 