import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { colors } from '../styles/theme';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link'],
    ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link'
];

const StyledQuillWrapper = styled.div`
  .ql-toolbar.ql-snow {
    border: 1px solid #ddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #f9f9f9;
  }

  .ql-container.ql-snow {
    border: 1px solid #ddd;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: 'Tahoma', sans-serif;
    font-size: 16px;
    min-height: 200px;
  }

  .ql-editor {
    min-height: 200px;
    line-height: 1.6;
    
    p {
      margin-bottom: 1em;
    }
    
    h1, h2, h3 {
      margin: 1em 0 0.5em;
      color: ${colors.primary.solid};
    }
  }

  &.disabled {
    .ql-toolbar.ql-snow {
      display: none;
    }
    
    .ql-container.ql-snow {
      border: 1px solid #ddd;
      border-radius: 5px;
    }
    
    .ql-editor {
      background: #f5f5f5;
      cursor: not-allowed;
    }
  }
`;

export const RichTextEditor = ({ value, onChange, readOnly }) => {
  useEffect(() => {
    // Force Quill to refresh its toolbar after mounting
    const timeoutId = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <StyledQuillWrapper className={readOnly ? 'disabled' : ''}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        readOnly={readOnly}
        preserveWhitespace={true}
      />
    </StyledQuillWrapper>
  );
}; 