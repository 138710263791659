import { Platform } from 'react-native';

// Constants
export const breakpoints = {
  xs: 320,    // Small phones
  sm: 480,    // Large phones
  md: 768,    // Tablets
  lg: 1024,   // Small laptops/tablets landscape
  xl: 1280,   // Standard laptops
  xxl: 1536   // Large laptops/desktops
};

export const containerWidths = {
  xs: '100%',
  sm: '100%',
  md: '750px',
  lg: '970px',
  xl: '1170px',
  xxl: '1400px'
};

export const gridConfig = {
  columns: 12,
  gutterWidth: {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 30,
    xl: 30,
    xxl: 30
  },
  containerPadding: {
    xs: 16,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40,
    xxl: 40
  }
};

export const colors = {
  primary: {
    start: '#E71C24',
    end: '#912528',
    solid: '#E71C24',
  },
  dark: '#282828',
  white: '#fff',
  black: '#000',
  gray: {
    light: '#f5f5f5',
    medium: '#d9d9d9',
    dark: '#666666',
  }
};

export const gradients = {
  primary: {
    colors: [colors.primary.start, colors.primary.end],
    start: { x: 0, y: 0 },
    end: { x: 0, y: 1 },
  }
};

export const spacing = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
};

// Enhanced typography with responsive sizes
export const typography = {
  h1: {
    fontSize: {
      xs: 32,
      sm: 40,
      md: 48,
      lg: 56,
      xl: 64,
      xxl: 64
    },
    fontWeight: '800',
    letterSpacing: {
      xs: 3.2,
      sm: 4,
      md: 4.8,
      lg: 5.6,
      xl: 6.4,
      xxl: 6.4
    },
    fontFamily: 'Tahoma, sans-serif',
  },
  h2: {
    fontSize: {
      xs: 28,
      sm: 34,
      md: 40,
      lg: 44,
      xl: 50,
      xxl: 50
    },
    fontWeight: '700',
    letterSpacing: {
      xs: 2.8,
      sm: 3.4,
      md: 4,
      lg: 4.4,
      xl: 5,
      xxl: 5
    },
    fontFamily: 'Tahoma, sans-serif',
  },
  h3: {
    fontSize: {
      xs: 24,
      sm: 26,
      md: 28,
      lg: 30,
      xl: 32,
      xxl: 32
    },
    fontWeight: '700',
    letterSpacing: {
      xs: 2.4,
      sm: 2.6,
      md: 2.8,
      lg: 3,
      xl: 3.2,
      xxl: 3.2
    },
    fontFamily: 'Tahoma, sans-serif',
  },
  h4: {
    fontSize: {
      xs: 20,
      sm: 21,
      md: 22,
      lg: 23,
      xl: 24,
      xxl: 24
    },
    letterSpacing: {
      xs: 2,
      sm: 2.1,
      md: 2.2,
      lg: 2.3,
      xl: 2.4,
      xxl: 2.4
    },
    fontFamily: 'Tahoma, sans-serif',
  },
  body: {
    fontSize: {
      xs: 14,
      sm: 14,
      md: 15,
      lg: 16,
      xl: 16,
      xxl: 16
    },
    letterSpacing: {
      xs: 1.4,
      sm: 1.4,
      md: 1.5,
      lg: 1.6,
      xl: 1.6,
      xxl: 1.6
    },
    fontFamily: 'Tahoma, sans-serif',
  },
  button: {
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
      lg: 22,
      xl: 24,
      xxl: 24
    },
    fontWeight: '700',
    letterSpacing: {
      xs: 1.6,
      sm: 1.8,
      md: 2,
      lg: 2.2,
      xl: 2.4,
      xxl: 2.4
    },
    fontFamily: 'Tahoma, sans-serif',
  }
};

export const shadows = {
  small: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  medium: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 4,
  },
  large: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 6,
  }
};

// Utility functions
export const createResponsiveStyle = (property, values) => {
  if (Platform.OS !== 'web') return values.xs || values;

  const mediaQueries = {};
  Object.entries(breakpoints).forEach(([size, width]) => {
    if (values[size]) {
      if (size === 'xs') {
        mediaQueries[property] = values[size];
      } else {
        mediaQueries[`@media (min-width: ${width}px)`] = {
          [property]: values[size]
        };
      }
    }
  });

  return mediaQueries;
};

export const createGridStyles = (columns = 12, gapSize) => {
  const styles = {};
  for (let i = 1; i <= columns; i++) {
    styles[`col-${i}`] = {
      flex: `0 0 ${(i / columns) * 100}%`,
      maxWidth: `${(i / columns) * 100}%`,
    };
  }
  return styles;
};

export const createContainerStyle = () => {
  if (Platform.OS !== 'web') {
    return { 
      width: '100%', 
      paddingHorizontal: gridConfig.containerPadding.xs 
    };
  }

  return {
    width: '100%',
    paddingHorizontal: gridConfig.containerPadding.xs,
    marginHorizontal: 'auto',
    ...Object.entries(breakpoints).reduce((acc, [size, width]) => ({
      ...acc,
      [`@media (min-width: ${width}px)`]: {
        maxWidth: containerWidths[size],
        paddingHorizontal: gridConfig.containerPadding[size]
      }
    }), {})
  };
};

export const createGradientBackground = (gradient) => {
  if (Platform.OS === 'web') {
    return {
      backgroundImage: `linear-gradient(to bottom, ${gradient.colors.join(', ')})`,
    };
  }
  return {};
};

export const getResponsiveSpacing = (size, multiplier = 1) => {
  const baseSpacing = spacing[size] || spacing.md;
  return {
    xs: baseSpacing * multiplier,
    sm: baseSpacing * multiplier * 1.2,
    md: baseSpacing * multiplier * 1.4,
    lg: baseSpacing * multiplier * 1.6,
    xl: baseSpacing * multiplier * 1.8,
    xxl: baseSpacing * multiplier * 2,
    xxxl: baseSpacing * multiplier * 4
  };
};

export const getResponsiveTypography = (type) => {
  const baseStyle = typography[type];
  if (!baseStyle) return typography.body;
  
  return {
    fontSize: createResponsiveStyle('fontSize', baseStyle.fontSize),
    fontWeight: baseStyle.fontWeight,
    letterSpacing: createResponsiveStyle('letterSpacing', baseStyle.letterSpacing)
  };
}; 