import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updateEmail, updatePassword, sendPasswordResetEmail } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FaEye, FaEyeSlash, FaCamera, FaTrash } from 'react-icons/fa';
import { colors } from '../styles/SharedStyles';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { US_STATES } from '../utils/constants';

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 20px;
  transition: margin-top 0.3s ease;
`;

const ProfileContainer = styled.div`
  max-width: 1200px;
  margin: 100px auto 40px;
  padding: 0 20px;
`;

const ProfileCard = styled.div`
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  background: white;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ProfileImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid ${colors.primary.solid};
  background: white;
`;

const ImageEditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const EditIcon = styled.div`
  color: white;
  font-size: 20px;
`;

const ProfileInfo = styled.div`
  flex: 1;
`;

const Name = styled.h1`
  font-size: 32px;
  color: ${colors.primary.solid};
  margin: 0 0 10px;
  font-family: 'Tahoma', sans-serif;
  font-weight: 600;
`;

const Username = styled.p`
  font-size: 18px;
  color: #666;
  margin: 0;
  font-family: 'Tahoma', sans-serif;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: ${colors.primary.solid};
  margin: 0 0 20px;
`;

const CustomerLinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  background: linear-gradient(135deg, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(231, 28, 36, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(231, 28, 36, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(231, 28, 36, 0.2);
  }
`;

const UserType = styled.span`
  display: inline-block;
  padding: 4px 12px;
  background: ${colors.primary.solid}20;
  color: ${colors.primary.solid};
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
`;

const OrdersSection = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #eee;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${colors.primary.solid};
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.primary.dark};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 5px 0;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 14px;
  margin: 5px 0;
`;

const Title = styled.h2`
  color: ${colors.primary.solid};
  margin-bottom: 20px;
  font-family: 'Tahoma', sans-serif;
  font-weight: 600;
  font-size: 24px;
`;

const FileInput = styled.input`
  display: none;
`;

const ShippingForm = styled.div`
  margin-top: 20px;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const RemoveImageButton = styled.button`
  background: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin-top: 8px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    background: #d32f2f;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  background-color: white;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.1);
  }
`;

const UserProfilePage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    shipping: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'USA'
    },
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser({
            uid: auth.currentUser.uid,
            email: auth.currentUser.email,
            ...userData
          });
          
          // Update to include phone in formData
          setFormData({
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: auth.currentUser.email || '',
            phone: userData.phone || '',
            shipping: {
              address1: userData.shipping?.address1 || '',
              address2: userData.shipping?.address2 || '',
              city: userData.shipping?.city || '',
              state: userData.shipping?.state || '',
              zipCode: userData.shipping?.zipCode || '',
              country: userData.shipping?.country || 'USA'
            },
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
          });
        }
        setLoading(false);
      }
    };
    fetchUserData();

    // Listen for announcement banner resize
    const checkBanner = () => {
      const announcementBanner = document.querySelector('.announcement-banner');
      if (announcementBanner) {
        setBannerHeight(announcementBanner.offsetHeight);
      } else {
        setBannerHeight(0);
      }
    };

    // Check on mount and when window resizes
    checkBanner();
    window.addEventListener('resize', checkBanner);
    
    // Create a mutation observer to detect DOM changes
    const observer = new MutationObserver(checkBanner);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener('resize', checkBanner);
      observer.disconnect();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      shipping: {
        ...prev.shipping,
        [name]: value
      }
    }));
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      
      // Create a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const userRef = doc(db, 'users', user.uid);
      
      // Handle profile image upload if there's a new file
      let profileImageUrl = user.profileImage;
      if (imageFile) {
        const storageRef = ref(storage, `profile_images/${user.uid}/${Date.now()}_${imageFile.name}`);
        const snapshot = await uploadBytes(storageRef, imageFile);
        profileImageUrl = await getDownloadURL(snapshot.ref);
      }
      
      // Prepare update data
      const updateData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        shipping: formData.shipping
      };
      
      // Only include profileImage if it's defined
      if (profileImageUrl) {
        updateData.profileImage = profileImageUrl;
      }
      
      await updateDoc(userRef, updateData);

      if (formData.email !== user.email) {
        await updateEmail(auth.currentUser, formData.email);
      }

      // Update local user state with new data
      setUser(prev => ({
        ...prev,
        ...updateData
      }));
      
      // Reset image file state
      setImageFile(null);
      
      setSuccess('Profile updated successfully');
    } catch (err) {
      console.error('Error updating profile:', err);
      setError(err.message);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (formData.newPassword !== formData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    try {
      await updatePassword(auth.currentUser, formData.newPassword);
      setSuccess('Password updated successfully');
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
    } catch (err) {
      console.error('Error updating password:', err);
      setError(err.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      setSuccess('Password reset email sent');
    } catch (err) {
      console.error('Error sending password reset:', err);
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <PageWrapper>
        <HeaderBar />
        <Container>Loading...</Container>
        <Footer />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <HeaderBar />
      <Container style={{ marginTop: `calc(120px + ${bannerHeight}px)` }}>
        <Section>
          <ProfileHeader>
            <ProfileImageContainer>
              {imagePreview ? (
                <ProfileImage 
                  src={imagePreview} 
                  alt={`${formData.firstName} ${formData.lastName}`}
                />
              ) : user.profileImage ? (
                <ProfileImage 
                  src={user.profileImage} 
                  alt={`${user.firstName} ${user.lastName}`}
                />
              ) : (
                <ProfileImage 
                  src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=e71c24&color=fff`}
                  alt={`${user.firstName} ${user.lastName}`}
                />
              )}
              <ImageEditOverlay onClick={() => fileInputRef.current?.click()}>
                <EditIcon><FaCamera /></EditIcon>
              </ImageEditOverlay>
              <FileInput
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageSelect}
              />
            </ProfileImageContainer>
            <ProfileInfo>
              <Name>{user.firstName} {user.lastName}</Name>
              <Username>{user.email}</Username>
              {user.userType && <UserType>{user.userType}</UserType>}
              {imagePreview && (
                <RemoveImageButton onClick={handleRemoveImage}>
                  <FaTrash /> Remove image
                </RemoveImageButton>
              )}
            </ProfileInfo>
          </ProfileHeader>
        </Section>
        
        {user && user.customerLinks && user.customerLinks.length > 0 && user.customerLinks.some(link => link.title && link.url) && (
          <Section>
            <Title>Your Links</Title>
            <CustomerLinksGrid>
              {user.customerLinks.map((link, index) => {
                if (link.title && link.url) {
                  const url = link.url.startsWith('http') ? link.url : `https://${link.url}`;
                  return (
                    <LinkButton 
                      key={index}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title}
                    </LinkButton>
                  );
                }
                return null;
              })}
            </CustomerLinksGrid>
          </Section>
        )}

        <Section>
          <Title>Profile Information</Title>
          <Form onSubmit={handleUpdateProfile}>
            <FormRow>
              <Input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </FormRow>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <Input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
            />
            
            <ShippingForm>
              <Title>Shipping Information</Title>
              <Input
                type="text"
                name="address1"
                placeholder="Address Line 1"
                value={formData.shipping.address1}
                onChange={handleShippingChange}
              />
              <Input
                type="text"
                name="address2"
                placeholder="Address Line 2 (Optional)"
                value={formData.shipping.address2}
                onChange={handleShippingChange}
              />
              <FormRow>
                <Input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={formData.shipping.city}
                  onChange={handleShippingChange}
                />
                <Select
                  name="state"
                  value={formData.shipping.state}
                  onChange={handleShippingChange}
                >
                  <option value="">Select State</option>
                  {US_STATES.map(state => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Select>
              </FormRow>
              <FormRow>
                <Input
                  type="text"
                  name="zipCode"
                  placeholder="ZIP/Postal Code"
                  value={formData.shipping.zipCode}
                  onChange={handleShippingChange}
                />
                <Input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={formData.shipping.country}
                  onChange={handleShippingChange}
                />
              </FormRow>
            </ShippingForm>
            
            <Button type="submit">Update Profile</Button>
          </Form>
        </Section>

        <Section>
          <Title>Change Password</Title>
          <Form onSubmit={handleUpdatePassword}>
            <PasswordContainer>
              <Input
                type={showPassword ? "text" : "password"}
                name="currentPassword"
                placeholder="Current Password"
                value={formData.currentPassword}
                onChange={handleInputChange}
              />
              <PasswordToggle 
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </PasswordToggle>
            </PasswordContainer>
            <PasswordContainer>
              <Input
                type={showPassword ? "text" : "password"}
                name="newPassword"
                placeholder="New Password"
                value={formData.newPassword}
                onChange={handleInputChange}
              />
            </PasswordContainer>
            <PasswordContainer>
              <Input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm New Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </PasswordContainer>
            <Button type="submit">Update Password</Button>
          </Form>
          <Button 
            onClick={handlePasswordReset}
            style={{ marginTop: '10px', background: '#666' }}
          >
            Send Password Reset Email
          </Button>
        </Section>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
      </Container>
      <Footer />
    </PageWrapper>
  );
};

export default UserProfilePage; 