import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/SharedStyles';

const BuildProgress = ({ steps, currentStep, selections }) => {
  const calculateProgress = (stepIndex) => {
    const step = steps[stepIndex];
    if (!step) return 0;

    // Calculate total cameras if any are selected
    const totalCameras = selections.cameras ? 
      selections.cameras.reduce((sum, camera) => sum + (camera.quantity || 0), 0) : 0;

    // For multi-select steps, check if there are any selections
    if (step.id === 'alarms' || step.id === 'cameras' || step.id === 'deterrence') {
      return selections[step.id] && selections[step.id].length > 0 ? 100 : 0;
    }

    // Special handling for HALO and NVR steps - either one or both must be selected
    if (step.id === 'model' || step.id === 'nvr') {
      // If this is the HALO step and HALO is selected, or this is the NVR step and NVR is selected
      if (selections[step.id]) {
        const maxCameras = selections[step.id].maxCameras || 0;
        // Return 0 progress if the selected component can't support current camera count
        return maxCameras >= totalCameras ? 100 : 0;
      }
      
      // If this step isn't selected, check if the other one is
      const otherStepId = step.id === 'model' ? 'nvr' : 'model';
      const otherStepAvailable = steps.some(s => s.id === otherStepId);
      
      // If the other step is available and selected, this step is optional
      if (otherStepAvailable && selections[otherStepId]) {
        return 100; // Mark as complete since the other option is selected
      }
      
      return 0; // Neither this nor the other option is selected
    }

    // For POE step, check if selection is valid for current camera count
    if (step.id === 'power') {
      if (!selections[step.id]) return 0;
      const maxCameras = selections[step.id].maxCameras || 0;
      // Return 0 progress if the selected component can't support current camera count
      return maxCameras >= totalCameras ? 100 : 0;
    }

    // For single-select steps, check if there is a selection
    return selections[step.id] ? 100 : 0;
  };

  return (
    <ProgressContainer>
      <ProgressTitle>Build Progress</ProgressTitle>
      {steps.map((step, index) => (
        <ProgressStep key={step.id}>
          <StepIndicator $active={index <= currentStep} $complete={calculateProgress(index) === 100}>
            {index + 1}
          </StepIndicator>
          <StepContent>
            <StepTitle $active={index <= currentStep}>
              {step.id === 'space' ? 'Premises' : step.title}
            </StepTitle>
            <ProgressBar>
              <ProgressFill $progress={calculateProgress(index)} />
            </ProgressBar>
          </StepContent>
        </ProgressStep>
      ))}
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  position: fixed;
  left: 0px;
  top: 45%;
  transform: translateY(-50%);
  background: white;
  padding: 12px;
  border-radius: 0px 8px 8px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100px;
  z-index: 90;
  opacity: 0.9;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
    transform: translateX(5px);
  }
`;

const ProgressTitle = styled.h3`
  font-size: 14px;
  color: ${colors.primary.solid};
  margin: 0 0 12px;
  text-align: center;
  font-weight: 600;
`;

const ProgressStep = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StepIndicator = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${props => props.$complete ? colors.primary.solid : props.$active ? colors.primary.solid + '50' : '#eee'};
  color: ${props => props.$complete || props.$active ? 'white' : '#666'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  transition: all 0.3s ease;
`;

const StepContent = styled.div`
  flex: 1;
  min-width: 0; // Prevent text overflow
`;

const StepTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  color: ${props => props.$active ? colors.primary.solid : '#666'};
  font-weight: ${props => props.$active ? '600' : '400'};
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 3px;
  background: #eee;
  border-radius: 1.5px;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  width: ${props => props.$progress}%;
  height: 100%;
  background: ${colors.primary.solid};
  transition: width 0.3s ease;
`;

export default BuildProgress; 