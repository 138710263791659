import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, ScrollView } from 'react-native';
import { colors, typography } from '../../styles/SharedStyles';
import { db, storage } from '../../config/firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, writeBatch } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { GradientButton } from '../../components/GradientButton';
import styled from 'styled-components';
import OpenAI from 'openai';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Initialize connection check
let isFirebaseInitialized = false;

// Initialize OpenAI
const openai = new OpenAI({
  apiKey: 'sk-svcacct-nc-aWq3_-REX-3dNZMF7JIFtoTzoDG2a89TAVMj-9QB3xecnl-ytMgPKakTtm6T3BlbkFJYpM3-tvwhZ37Q7QXhqkiiTWBczlgUsyPa6nQVLU0plmiNvuOSZqJ9_ud8lZxoA',
  dangerouslyAllowBrowser: true // Note: In production, handle API calls through a backend
});

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;

  &:hover {
    background: ${colors.primary.dark};
  }
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SelectedFileName = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: ${colors.gray.dark};
`;

const MAX_SHORT_DESC_WORDS = 30;

const countWords = (text) => {
  if (!text) return 0;
  return text.trim().split(/\s+/).filter(word => word.length > 0).length;
};

const GenerateOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 5px;
`;

// Create arrow button components instead of drag handles
const ArrowButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
  align-items: center;
`;

const ArrowButton = styled.button`
  width: 28px;
  height: 28px;
  background: ${props => props.disabled ? '#f0f0f0' : '#fff'};
  border: 1px solid ${props => props.disabled ? '#ddd' : colors.primary.solid};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? '#aaa' : colors.primary.solid};
  font-size: 16px;
  padding: 0;
  
  &:hover {
    background: ${props => props.disabled ? '#f0f0f0' : '#f8f8f8'};
  }
  
  @media (max-width: 640px) {
    width: 26px;
    height: 26px;
    font-size: 14px;
  }
`;

const OrderLabel = styled.span`
  flex: 1;
  font-size: 12px;
  text-align: right;
  color: #999;
  white-space: nowrap;
  
  @media (max-width: 640px) {
    font-size: 10px;
  }
`;

// Update the ProductGrid to ensure consistent column sizing
const ProductGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  
  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

// Make the product card more compact and consistent in width
const ProductCardWrapper = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

// Update the ProductName, ProductPrice, and other text elements to be smaller
const ProductName = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.primary.solid};
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductPrice = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin: 0 0 4px 0;
`;

const ProductSku = styled.p`
  font-size: 12px;
  color: ${colors.gray.dark};
  margin: 0 0 4px 0;
`;

const ProductCategory = styled.p`
  font-size: 12px;
  color: ${colors.gray.dark};
  margin: 0;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: auto;
`;

// Add back the missing styled components
const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
`;

const ProductInfo = styled.div`
  margin-bottom: 10px;
  flex: 1;
`;

// Add a note component
const DragNoteContainer = styled.div`
  background-color: #e8f4fd;
  border-left: 4px solid #2196f3;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 13px;
  color: #0d47a1;
`;

// Add back the missing styled components
const SaveOrderButton = styled.button`
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  margin: 20px 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const OrderChangedNotice = styled.div`
  background-color: #fff8e1;
  border-left: 4px solid #ffc107;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchBarContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.2);
  }
`;

const ClearSearchButton = styled.button`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  font-size: 16px;
  line-height: 1;
  display: ${props => props.visible ? 'block' : 'none'};
  
  &:hover {
    color: ${colors.primary.solid};
  }
`;

const SearchInfo = styled.div`
  font-size: 13px;
  color: ${colors.gray.dark};
  margin-bottom: 15px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 140px;
  object-fit: contain;
  background: white;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;
`;

// Add a note about searching and reordering
const SearchNote = styled.div`
  background-color: #e8f4fd;
  border-left: 4px solid #2196f3;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 13px;
  color: #0d47a1;
`;

export const ProductManager = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  const [currentProduct, setCurrentProduct] = useState({
    name: '',
    description: '',
    shortDescription: '',
    price: '',
    sku: '',
    category: '',
    image: null,
    imageUrl: '',
    installationFee: 0,
    maxCameras: 0,
    specifications: [],
    features: [],
    displayOrder: 0
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = React.useRef(null);
  const [isGeneratingDesc, setIsGeneratingDesc] = useState(false);
  const [orderChanged, setOrderChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const categories = [
    'Alarms',
    'Control Panels',
    'Motion Detectors',
    'Environmental Sensors',
    'Door/Window Sensors',
    'Life Safety',
    'Accessories',
    'Cameras',
    'Deterrence Devices',
    'Power Over Ethernet (POE)',
    'Virtual Guard',
    'NVR'
  ];

  useEffect(() => {
    const initializeProducts = async () => {
      if (!db) {
        setError('Firebase is not initialized properly');
        setInitialLoad(false);
        return;
      }

      try {
        if (!isFirebaseInitialized) {
          // Create products collection if it doesn't exist
          const productsRef = collection(db, 'products');
          isFirebaseInitialized = true;
        }

        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          displayOrder: doc.data().displayOrder || 0
        }));
        
        // Sort products by displayOrder
        const sortedProducts = productsData.sort((a, b) => 
          a.displayOrder - b.displayOrder
        );
        
        setProducts(sortedProducts);
      } catch (err) {
        console.log('Error initializing products:', err);
        if (err.code === 'permission-denied') {
          setError('You do not have permission to access this data');
        } else if (err.code === 'unavailable') {
          setError('Unable to connect to the database. Please check your internet connection.');
        } else {
          setError('Error loading products: ' + err.message);
        }
        setProducts([]);
      } finally {
        setInitialLoad(false);
      }
    };

    initializeProducts();
  }, []);

  const fetchProducts = async () => {
    if (!loading && db) {
      setLoading(true);
      setError(null);
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          displayOrder: doc.data().displayOrder || 0
        }));
        
        // Sort products by displayOrder
        const sortedProducts = productsData.sort((a, b) => 
          a.displayOrder - b.displayOrder
        );
        
        setProducts(sortedProducts);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Error loading products: ' + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Revoke the previous preview URL to avoid memory leaks
      if (imagePreview && !imagePreview.startsWith('http')) {
        URL.revokeObjectURL(imagePreview);
      }
      setImageFile(file);
      const newPreviewUrl = URL.createObjectURL(file);
      setImagePreview(newPreviewUrl);
      setCurrentProduct(prev => ({
        ...prev,
        image: file.name
      }));
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    
    try {
      const storageRef = ref(storage, `productImages/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      return downloadUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Failed to upload image: ' + error.message);
    }
  };

  const updateProductReferences = async (productId, updatedProductData) => {
    try {
      const batch = writeBatch(db);

      // Update packages that contain this product
      const packagesSnapshot = await getDocs(collection(db, 'packages'));
      packagesSnapshot.docs.forEach(packageDoc => {
        const packageData = packageDoc.data();
        if (packageData.products?.some(p => p.id === productId)) {
          const updatedProducts = packageData.products.map(p => 
            p.id === productId ? {
              ...p,
              name: updatedProductData.name,
              imageUrl: updatedProductData.imageUrl,
              price: updatedProductData.price,
              installationFee: updatedProductData.installationFee || 0
            } : p
          );
          batch.update(doc(db, 'packages', packageDoc.id), { products: updatedProducts });
        }
      });

      // Update system builder components
      const systemTypes = ['alarms', 'cameras', 'halo', 'poe', 'nvr', 'deterrence'];
      for (const type of systemTypes) {
        const componentsSnapshot = await getDocs(collection(db, 'systemBuilder', type, 'components'));
        componentsSnapshot.docs.forEach(componentDoc => {
          const componentData = componentDoc.data();
          if (componentData.productId === productId) {
            batch.update(doc(db, 'systemBuilder', type, 'components', componentDoc.id), {
              name: updatedProductData.name,
              description: updatedProductData.description,
              price: updatedProductData.price,
              imageUrl: updatedProductData.imageUrl,
              maxCameras: updatedProductData.maxCameras || 0,
              installationFee: updatedProductData.installationFee || 0
            });
          }
        });
      }

      await batch.commit();
      console.log('Updated all product references successfully');
    } catch (error) {
      console.error('Error updating product references:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!currentProduct.name || !currentProduct.price) {
      setError('Product name and price are required');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      let imageUrl = currentProduct.imageUrl;

      if (imageFile) {
        const storageRef = ref(storage, `products/${Date.now()}_${imageFile.name}`);
        const snapshot = await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const productData = {
        name: currentProduct.name,
        description: currentProduct.description || '',
        shortDescription: currentProduct.shortDescription || '',
        price: parseFloat(currentProduct.price) || 0,
        sku: currentProduct.sku || '',
        category: currentProduct.category || '',
        imageUrl: imageUrl || '',
        installationFee: parseFloat(currentProduct.installationFee) || 0,
        maxCameras: parseInt(currentProduct.maxCameras) || 0,
        specifications: currentProduct.specifications || [],
        features: currentProduct.features || [],
        createdAt: new Date().toISOString(),
      };

      if (currentProduct.id) {
        // Updating existing product
        const productRef = doc(db, 'products', currentProduct.id);
        await updateDoc(productRef, productData);
        // Update all references to this product
        await updateProductReferences(currentProduct.id, productData);
      } else {
        // Creating new product
        const highestOrder = products.length > 0 
          ? Math.max(...products.map(p => p.displayOrder || 0)) 
          : -1;
        
        productData.displayOrder = highestOrder + 1;
        await addDoc(collection(db, 'products'), productData);
      }

      resetForm();
      fetchProducts();
      setError('Product saved successfully!');
    } catch (err) {
      console.error('Error saving product:', err);
      setError('Error saving product: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, imageUrl) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      setLoading(true);
      setError(null);
      try {
        await deleteDoc(doc(db, 'products', id));
        if (imageUrl) {
          const imageRef = ref(storage, imageUrl);
          await deleteObject(imageRef);
        }
        await fetchProducts();
      } catch (err) {
        setError('Failed to delete product: ' + err.message);
      }
      setLoading(false);
    }
  };

  const handleEdit = (product) => {
    // Scroll to top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    // Clear previous image preview if it exists
    if (imagePreview && !imagePreview.startsWith('http')) {
      URL.revokeObjectURL(imagePreview);
    }
    
    // Ensure shortDescription is initialized when editing
    setCurrentProduct({
      ...product,
      shortDescription: product.shortDescription || ''
    });
    setImagePreview(product.imageUrl);
    setImageFile(null);
    resetFileInput();
  };

  // Clean up preview URLs when component unmounts
  useEffect(() => {
    return () => {
      if (imagePreview && !imagePreview.startsWith('http')) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, []);

  // Add this function to handle short description changes
  const handleShortDescriptionChange = (text) => {
    if (countWords(text) <= MAX_SHORT_DESC_WORDS) {
      setCurrentProduct({ ...currentProduct, shortDescription: text });
    }
  };

  // Update the generateShortDescription function to use ChatGPT
  const generateShortDescription = async () => {
    if (!currentProduct.name || !currentProduct.description) {
      setError('Product name and description are required to generate a short description');
      return;
    }

    setIsGeneratingDesc(true);
    try {
      const prompt = `Create a concise and engaging product description (maximum ${MAX_SHORT_DESC_WORDS} words) for the following product:
      
Product Name: ${currentProduct.name}
Full Description: ${currentProduct.description}

Focus on the key benefits and features. Make it compelling but factual.`;

      const completion = await openai.chat.completions.create({
        messages: [
          { 
            role: "system", 
            content: "You are a professional product copywriter. Create concise, engaging product descriptions that highlight key benefits and features."
          },
          {
            role: "user",
            content: prompt
          }
        ],
        model: "gpt-3.5-turbo",
      });

      const shortDesc = completion.choices[0].message.content.trim();
      
      // Ensure the generated description doesn't exceed word limit
      const words = shortDesc.split(/\s+/);
      const truncatedDesc = words.slice(0, MAX_SHORT_DESC_WORDS).join(' ');
      
      setCurrentProduct({ ...currentProduct, shortDescription: truncatedDesc });
    } catch (err) {
      console.error('Error generating description:', err);
      setError('Failed to generate short description: ' + (err.message || 'Unknown error'));
    } finally {
      setIsGeneratingDesc(false);
    }
  };

  // Update maxCameras logic
  const showMaxCameras = currentProduct.category === 'Power Over Ethernet (POE)' || 
                        currentProduct.category === 'Virtual Guard' || 
                        currentProduct.category === 'NVR';

  // Update maxCameras label
  const maxCamerasLabel = currentProduct.category === 'Virtual Guard'
    ? 'Maximum number of cameras this Virtual Guard can support'
    : currentProduct.category === 'NVR'
    ? 'Maximum number of cameras this NVR can support'
    : 'Maximum number of cameras this POE can support';

  // Function to move a product left in order
  const moveProductLeft = (index) => {
    if (index <= 0) return; // Can't move left if at the leftmost position
    
    const newProducts = [...products];
    // Swap current product with the one to the left
    [newProducts[index], newProducts[index - 1]] = [newProducts[index - 1], newProducts[index]];
    
    // Update display order for all products
    const updatedProducts = newProducts.map((product, idx) => ({
      ...product,
      displayOrder: idx
    }));
    
    setProducts(updatedProducts);
    setOrderChanged(true);
  };

  // Function to move a product right in order
  const moveProductRight = (index) => {
    if (index >= products.length - 1) return; // Can't move right if at the rightmost position
    
    const newProducts = [...products];
    // Swap current product with the one to the right
    [newProducts[index], newProducts[index + 1]] = [newProducts[index + 1], newProducts[index]];
    
    // Update display order for all products
    const updatedProducts = newProducts.map((product, idx) => ({
      ...product,
      displayOrder: idx
    }));
    
    setProducts(updatedProducts);
    setOrderChanged(true);
  };

  // Update the filtered products logic to handle move operations
  const getActualIndex = (filteredIndex) => {
    const filtered = filteredProducts();
    if (filteredIndex < 0 || filteredIndex >= filtered.length) return -1;
    
    const productId = filtered[filteredIndex].id;
    return products.findIndex(p => p.id === productId);
  };

  // Update the saveDisplayOrder function to properly handle loading
  const saveDisplayOrder = async () => {
    if (loading) return;
    
    setLoading(true);
    try {
      const batch = writeBatch(db);
      
      products.forEach((product) => {
        const productRef = doc(db, 'products', product.id);
        batch.update(productRef, { displayOrder: product.displayOrder });
      });
      
      await batch.commit();
      setOrderChanged(false);
      console.log('Display order saved successfully');
    } catch (error) {
      console.error('Error saving display order:', error);
      setError('Failed to save product order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Add the resetForm function
  const resetForm = () => {
    setCurrentProduct({
      name: '',
      description: '',
      shortDescription: '',
      price: '',
      sku: '',
      category: '',
      image: null,
      imageUrl: '',
      installationFee: 0,
      maxCameras: 0,
      specifications: [],
      features: [],
      displayOrder: 0
    });
    setImageFile(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Add this function to filter products based on search term
  const filteredProducts = () => {
    if (!searchTerm.trim()) return products;
    
    return products.filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  if (initialLoad) {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Product Manager</Text>
        <View style={styles.loadingContainer}>
          <Text style={styles.loadingText}>Initializing Products...</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Product Manager</Text>
      
      {error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          <GradientButton
            onPress={() => setError(null)}
            text="Dismiss"
            variant="secondary"
            style={styles.dismissButton}
            textStyle={styles.buttonText}
          />
        </View>
      )}
      
      <View style={styles.form}>
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <TextInput
              style={styles.input}
              placeholder="Product Name"
              value={currentProduct.name}
              onChangeText={(text) => setCurrentProduct({...currentProduct, name: text})}
            />
          </View>
          <View style={styles.formColumn}>
            <TextInput
              style={styles.input}
              placeholder="SKU"
              value={currentProduct.sku}
              onChangeText={(text) => setCurrentProduct({...currentProduct, sku: text})}
            />
          </View>
        </View>

        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <TextInput
              style={styles.input}
              placeholder="Price"
              value={currentProduct.price}
              onChangeText={(text) => setCurrentProduct({...currentProduct, price: text})}
              keyboardType="numeric"
            />
          </View>
          <View style={styles.formColumn}>
            <select
              style={styles.select}
              value={currentProduct.category}
              onChange={(e) => setCurrentProduct({...currentProduct, category: e.target.value})}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </View>
        </View>

        {showMaxCameras && (
          <View style={styles.formRow}>
            <View style={styles.formColumn}>
              <TextInput
                style={styles.input}
                placeholder="Maximum Supported Cameras"
                value={currentProduct.maxCameras !== undefined ? currentProduct.maxCameras.toString() : '0'}
                onChangeText={(text) => {
                  const value = text === '' ? 0 : parseInt(text);
                  if (!isNaN(value) && value >= 0) {
                    setCurrentProduct({...currentProduct, maxCameras: value});
                  }
                }}
                keyboardType="numeric"
              />
              <Text style={styles.helperText}>{maxCamerasLabel}</Text>
            </View>
          </View>
        )}

        <TextInput
          style={[styles.input, styles.textArea]}
          placeholder="Description"
          value={currentProduct.description}
          onChangeText={(text) => setCurrentProduct({...currentProduct, description: text})}
          multiline
        />

        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <TextInput
              style={styles.input}
              placeholder="Installation Fee ($)"
              value={currentProduct.installationFee > 0 ? currentProduct.installationFee.toString() : ''}
              onChangeText={(text) => setCurrentProduct({...currentProduct, installationFee: parseFloat(text) || 0})}
              keyboardType="numeric"
            />
          </View>
        </View>

        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.inputLabel}>Short Description ({MAX_SHORT_DESC_WORDS} words max)</Text>
            <View style={styles.shortDescriptionContainer}>
              {isGeneratingDesc && (
                <GenerateOverlay>
                  <Text style={styles.loadingText}>Generating description...</Text>
                </GenerateOverlay>
              )}
              <TextInput
                style={[styles.input, styles.shortDescInput]}
                placeholder="Short product description"
                value={currentProduct.shortDescription}
                onChangeText={handleShortDescriptionChange}
                multiline
              />
              <Text style={styles.wordCount}>
                {countWords(currentProduct.shortDescription)}/{MAX_SHORT_DESC_WORDS} words
              </Text>
              <GradientButton
                onPress={generateShortDescription}
                text={isGeneratingDesc ? "Generating..." : "Auto-Generate"}
                disabled={isGeneratingDesc}
                style={styles.generateButton}
                variant="secondary"
              />
            </View>
          </View>
        </View>

        <View style={styles.imageUploadContainer}>
          <ButtonGroup>
            <FileInputLabel>
              Choose Image
              <HiddenFileInput
                ref={fileInputRef}
                accept="image/*"
                onChange={handleImageChange}
                disabled={loading}
              />
            </FileInputLabel>
          </ButtonGroup>
          {imageFile && (
            <SelectedFileName>Selected file: {imageFile.name}</SelectedFileName>
          )}
          {imagePreview && (
            <img 
              src={imagePreview} 
              alt="Preview" 
              style={styles.imagePreview}
            />
          )}
        </View>

        <View style={styles.buttonContainer}>
          <GradientButton
            onPress={handleSubmit}
            text={currentProduct.id ? 'Update Product' : 'Add Product'}
            disabled={loading}
            style={styles.submitButton}
            textStyle={styles.buttonText}
          />
        </View>
      </View>

      <View style={styles.listContainer}>
        <View style={styles.listHeader}>
          <Text style={styles.listTitle}>Products</Text>
        </View>
        
        <SearchBarContainer>
          <SearchInput
            type="text"
            placeholder="Search by name, SKU, or category..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <ClearSearchButton 
            visible={searchTerm.length > 0}
            onClick={() => setSearchTerm('')}
            title="Clear search"
          >
            ×
          </ClearSearchButton>
        </SearchBarContainer>
        
        {searchTerm.length > 0 && (
          <>
            <SearchInfo>
              Showing {filteredProducts().length} of {products.length} products. 
              <span style={{ color: colors.primary.solid, cursor: 'pointer', marginLeft: 5 }} onClick={() => setSearchTerm('')}>
                Clear search
              </span>
            </SearchInfo>
            <SearchNote>
              Note: Left/right ordering is disabled while searching. Clear the search to change product order.
            </SearchNote>
          </>
        )}
        
        {orderChanged && (
          <>
            <OrderChangedNotice>
              <span>You've changed the product order. Save your changes to update how products appear on the website.</span>
            </OrderChangedNotice>
            <SaveOrderButton onClick={saveDisplayOrder}>
              {loading ? 'Saving...' : 'Save Product Order'}
            </SaveOrderButton>
          </>
        )}
        
        {products.length === 0 ? (
          <Text style={styles.emptyText}>No products found</Text>
        ) : filteredProducts().length === 0 ? (
          <Text style={styles.emptyText}>No products match your search</Text>
        ) : (
          <ProductGrid>
            {filteredProducts().map((product, index) => {
              const actualIndex = getActualIndex(index);
              const isFirstItem = actualIndex === 0;
              const isLastItem = actualIndex === products.length - 1;
              
              return (
                <ProductCardWrapper key={product.id}>
                  <ArrowButtonContainer>
                    <ArrowButton 
                      onClick={() => moveProductLeft(actualIndex)} 
                      disabled={isFirstItem || searchTerm.length > 0}
                      title={searchTerm.length > 0 ? "Clear search to reorder" : isFirstItem ? "Already first" : "Move left"}
                    >
                      ◀
                    </ArrowButton>
                    <ArrowButton 
                      onClick={() => moveProductRight(actualIndex)} 
                      disabled={isLastItem || searchTerm.length > 0}
                      title={searchTerm.length > 0 ? "Clear search to reorder" : isLastItem ? "Already last" : "Move right"}
                    >
                      ▶
                    </ArrowButton>
                    <OrderLabel>
                      Order: {actualIndex + 1}
                    </OrderLabel>
                  </ArrowButtonContainer>
                  <ProductContent>
                    {product.imageUrl && (
                      <ProductImage 
                        src={product.imageUrl} 
                        alt={product.name}
                      />
                    )}
                    <ProductInfo>
                      <ProductName title={product.name}>{product.name}</ProductName>
                      <ProductPrice>${product.price.toFixed(2)}</ProductPrice>
                      <ProductSku>SKU: {product.sku}</ProductSku>
                      <ProductCategory>{product.category}</ProductCategory>
                    </ProductInfo>
                    <ActionButtons>
                      <GradientButton
                        text="Edit"
                        onPress={() => handleEdit(product)}
                        style={{ flex: 1, minWidth: 0, height: 30 }}
                        textStyle={{ fontSize: 12 }}
                        variant="secondary"
                      />
                      <GradientButton
                        text="Delete"
                        onPress={() => handleDelete(product.id, product.imageUrl)}
                        style={{ flex: 1, minWidth: 0, height: 30, backgroundColor: colors.error }}
                        textStyle={{ fontSize: 12 }}
                        variant="secondary"
                      />
                    </ActionButtons>
                  </ProductContent>
                </ProductCardWrapper>
              );
            })}
          </ProductGrid>
        )}
      </View>
      
      {loading && (
        <View style={styles.loadingOverlay}>
          <Text style={styles.loadingText}>Processing...</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    position: 'relative',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  form: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  formRow: {
    flexDirection: 'row',
    gap: 15,
    marginBottom: 10,
  },
  formColumn: {
    flex: 1,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    fontSize: 14,
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: 20,
  },
  submitButton: {
    minWidth: 180,
    height: 40,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '600',
  },
  listContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    overflow: 'hidden',
  },
  listHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    paddingBottom: 10,
  },
  listTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: colors.primary.solid,
  },
  emptyText: {
    color: colors.gray,
    fontSize: 14,
    fontStyle: 'italic',
  },
  productsList: {
    width: '100%',
    marginTop: 10,
  },
  productItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderRadius: 8,
    marginBottom: 10,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#eee',
    transition: 'all 0.2s ease',
  },
  productInfo: {
    flex: 1,
  },
  productName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  productPrice: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 'bold',
  },
  productActions: {
    flexDirection: 'row',
    gap: 10,
  },
  actionButton: {
    minWidth: 80,
    height: 36,
  },
  deleteButton: {
    backgroundColor: colors.error,
  },
  productSku: {
    fontSize: 14,
    color: colors.gray,
    marginTop: 4,
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
  },
  errorText: {
    color: colors.error,
    fontSize: 14,
    flex: 1,
  },
  dismissButton: {
    minWidth: 80,
    height: 30,
    marginLeft: 10,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: colors.primary,
    fontSize: 16,
    fontWeight: '600',
  },
  imageUploadContainer: {
    marginBottom: 20,
    alignItems: 'center',
  },
  imagePreview: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    borderRadius: 10,
    marginTop: 10,
  },
  productImage: {
    width: 80,
    height: 80,
    objectFit: 'cover',
    borderRadius: 5,
    marginRight: 15,
  },
  select: {
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    fontSize: 14,
    height: 42,
    backgroundColor: 'white',
  },
  helperText: {
    fontSize: 12,
    color: colors.gray,
    marginTop: 5,
  },
  inputLabel: {
    fontSize: 14,
    color: colors.gray.dark,
    marginBottom: 5,
  },
  shortDescriptionContainer: {
    position: 'relative',
    marginBottom: 15,
  },
  shortDescInput: {
    marginBottom: 5,
  },
  wordCount: {
    fontSize: 12,
    color: colors.gray,
    textAlign: 'right',
    marginBottom: 5,
  },
  generateButton: {
    alignSelf: 'flex-end',
    minWidth: 120,
    height: 32,
  },
  productCard: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 15,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease',
  },
  dragHandleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  productCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  productCardImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 5,
    marginBottom: 10,
  },
  productInfo: {
    flex: 1,
  },
  productName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  productPrice: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 'bold',
  },
  productSku: {
    fontSize: 14,
    color: colors.gray,
    marginTop: 4,
  },
  productCategory: {
    fontSize: 14,
    color: colors.gray,
    marginTop: 4,
  },
  productActions: {
    flexDirection: 'row',
    gap: 10,
  },
}); 