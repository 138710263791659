import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { colors } from '../styles/SharedStyles';

export const DisclaimerMessage = ({ location }) => {
  const [disclaimer, setDisclaimer] = useState({
    enabled: false,
    message: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDisclaimer = async () => {
      try {
        const disclaimerDoc = await getDoc(doc(db, 'siteSettings', 'disclaimers'));
        if (disclaimerDoc.exists()) {
          const data = disclaimerDoc.data();
          // Get the specific disclaimer for the location (cart or checkout)
          const locationData = data[location] || { enabled: false, message: '' };
          setDisclaimer(locationData);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching disclaimer:', err);
        setLoading(false);
      }
    };

    fetchDisclaimer();
  }, [location]);

  if (loading || !disclaimer.enabled || !disclaimer.message) {
    return null;
  }

  return (
    <DisclaimerContainer location={location}>
      <DisclaimerIcon>⚠️</DisclaimerIcon>
      <DisclaimerText location={location} dangerouslySetInnerHTML={{ __html: disclaimer.message }} />
    </DisclaimerContainer>
  );
};

const DisclaimerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
  margin: ${props => props.location === 'cart' ? '10px 0' : '15px 0'};
  background-color: #fff8e1;
  border: 1px solid #ffecb3;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  ${props => props.location === 'cart' && `
    font-size: 12px;
    padding: 10px;
  `}
`;

const DisclaimerIcon = styled.div`
  font-size: 20px;
  line-height: 24px;
  flex-shrink: 0;
`;

const DisclaimerText = styled.div`
  font-family: Tahoma, sans-serif;
  font-size: ${props => props.location === 'cart' ? '12px' : '14px'};
  line-height: 1.5;
  color: #5d4037;
  flex: 1;

  a {
    color: ${colors.primary.solid};
    text-decoration: underline;
  }

  p {
    margin: 0 0 8px 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
  
  strong {
    font-weight: 700;
  }
`; 