import React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { colors, gradients } from '../styles/theme';

export const GradientBackground = ({ 
  children, 
  style, 
  variant = 'primary',
  direction = 'vertical' // 'vertical' or 'horizontal'
}) => {
  // On web, we use CSS gradients
  if (Platform.OS === 'web') {
    const webStyle = {
      background: variant === 'primary' 
        ? `linear-gradient(${direction === 'vertical' ? 'to bottom' : 'to right'}, ${colors.primary.start}, ${colors.primary.end})`
        : colors.white,
    };

    return (
      <View style={[styles.container, { background: webStyle.background }, style]}>
        {children}
      </View>
    );
  }

  // On native, we use LinearGradient
  return (
    <LinearGradient
      colors={gradients[variant].colors}
      start={direction === 'vertical' ? { x: 0, y: 0 } : { x: 0, y: 0 }}
      end={direction === 'vertical' ? { x: 0, y: 1 } : { x: 1, y: 0 }}
      style={[styles.container, style]}
    >
      {children}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
}); 