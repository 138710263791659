import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import styled from 'styled-components';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { 
  colors, 
  breakpoints, 
  createContainerStyle, 
  getResponsiveSpacing, 
  getResponsiveTypography 
} from '../styles/theme';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { PageNavigation, PageHeader } from '../components/PageNavigation';
import { usePageTitle } from '../hooks/usePageTitle';

const ProductCard = styled.div`
  display: flex;
  align-items: center;
  gap: ${getResponsiveSpacing('sm').xs}px;
  background: white;
  padding: ${getResponsiveSpacing('md').xs}px;
  border-radius: 8px;
  border: 1px solid ${colors.gray.light};
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('sm').md}px;
    padding: ${getResponsiveSpacing('md').md}px;
  }
  
  @media (max-width: 480px) {
    padding: 12px;
    gap: 10px;
  }
`;

const ProductInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${getResponsiveSpacing('xs').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('xs').md}px;
  }
  
  @media (max-width: 480px) {
    gap: 5px;
  }
`;

const ProductName = styled.h3`
  ${getResponsiveTypography('body')}
  font-weight: 600;
  margin: 0;
  color: ${colors.primary.solid};
  
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const ProductQuantity = styled.p`
  ${getResponsiveTypography('body')}
  margin: 0;
  color: ${colors.gray.dark};
  font-size: 14px;
  
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  background: white;
  border-radius: 5px;
  padding: ${getResponsiveSpacing('xs').xs}px;
  border: 1px solid ${colors.gray.light};
  
  @media (min-width: ${breakpoints.md}px) {
    width: 100px;
    height: 100px;
    padding: ${getResponsiveSpacing('xs').md}px;
  }
  
  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
    padding: 5px;
  }
`;

function PackageCard({ id, title, price, description, products = [], features = [], imageUrl }) {
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [addedToCart, setAddedToCart] = useState(false);

  const handleBuyNow = () => {
    addToCart({
      id,
      type: 'package',
      name: title,
      price,
      quantity,
      products: products.map(p => ({ 
        ...p, 
        packageQuantity: p.quantity * quantity,
        installationFee: p.installationFee || 0
      }))
    });
    navigate('/checkout');
  };

  const handleAddToCart = () => {
    addToCart({
      id: id + '-' + Date.now(),
      type: 'package',
      name: title,
      price,
      imageUrl: imageUrl,
      quantity,
      products: products.map(p => ({ 
        ...p, 
        packageQuantity: p.quantity * quantity,
        installationFee: p.installationFee || 0
      }))
    });
    setAddedToCart(true);
    setTimeout(() => setAddedToCart(false), 2000);
  };

  return (
    <PackageContainer>
      <PackageInfo>
        <PackageHeader>
          <div>
            <PackageTitle>{title}</PackageTitle>
            <PackagePrice>${price}</PackagePrice>
          </div>
          <QuantityContainer>
            <QuantityLabel>Package Quantity:</QuantityLabel>
            <QuantityControls>
              <QuantityButton onClick={() => quantity > 1 && setQuantity(q => q - 1)}>-</QuantityButton>
              <QuantityInput
                type="number"
                min="1"
                value={quantity}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (value > 0) setQuantity(value);
                }}
              />
              <QuantityButton onClick={() => setQuantity(q => q + 1)}>+</QuantityButton>
            </QuantityControls>
          </QuantityContainer>
        </PackageHeader>
        
        <PackageDescription>{description}</PackageDescription>
        
        <ProductsGrid>
          {products.map((product) => (
            <ProductCard key={product.id}>
              <ProductImage 
                src={product.imageUrl || 'https://via.placeholder.com/100x100?text=No+Image'} 
                alt={product.name} 
              />
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductQuantity>Quantity: {product.quantity}</ProductQuantity>
              </ProductInfo>
            </ProductCard>
          ))}
        </ProductsGrid>

        <FeaturesList>
          {features.map((feature, index) => (
            <FeatureItem key={index}>• {feature}</FeatureItem>
          ))}
        </FeaturesList>
      </PackageInfo>
      
      <ButtonGroup>
        <ActionButton primary onClick={handleBuyNow}>Buy Now</ActionButton>
        <ActionButton 
          onClick={handleAddToCart}
          disabled={addedToCart}
        >
          {addedToCart ? 'Added to Cart!' : 'Add to Cart'}
        </ActionButton>
      </ButtonGroup>
    </PackageContainer>
  );
}

function PackagesPage() {
  usePageTitle('Shop Packages');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'packages'));
        const packagesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPackages(packagesData);
      } catch (err) {
        console.error('Error fetching packages:', err);
        setError('Failed to load packages. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  if (loading) {
    return (
      <View style={styles.container}>
        <HeaderBar />
        <LoadingText>Loading packages...</LoadingText>
        <Footer />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.container}>
        <HeaderBar />
        <ErrorText>{error}</ErrorText>
        <Footer />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <HeaderBar />
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <PageWrapper>
            <PageNavigation />
            <PageContent>
              <PageHeader title="Shop Packages" />
              {packages.map((pkg) => (
                <PackageCard key={pkg.id} {...pkg} />
              ))}
              <PageNavigation isFooter />
            </PageContent>
          </PageWrapper>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
}

const PageWrapper = styled.div`
  ${createContainerStyle()}
  display: flex;
  flex-direction: column;
  font-family: Tahoma, sans-serif;
  align-items: center;
  margin: 0 auto;
  padding-top: var(--announcement-offset, 0px);

  @media (max-width: ${breakpoints.md}px) {
    padding-top: var(--announcement-offset-mobile, 0px);
  }
`;

const PageContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${getResponsiveSpacing('xl').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('xl').md}px;
  }
`;

const PackageContainer = styled.article`
  width: 100%;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const PackageInfo = styled.div`
  padding: ${getResponsiveSpacing('lg').xs}px;
  background: white;
  color: ${colors.gray.dark};
  
  @media (min-width: ${breakpoints.md}px) {
    padding: ${getResponsiveSpacing('lg').md}px;
  }
`;

const PackageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getResponsiveSpacing('md').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${getResponsiveSpacing('md').md}px;
  }
`;

const PackageTitle = styled.h2`
  ${getResponsiveTypography('h3')}
  margin: 0;
  color: ${colors.primary.solid};
`;

const PackagePrice = styled.p`
  ${getResponsiveTypography('h4')}
  margin: ${getResponsiveSpacing('xs').xs}px 0 0;
  color: #000000;
  font-weight: bold;
  
  @media (min-width: ${breakpoints.md}px) {
    margin: ${getResponsiveSpacing('xs').md}px 0 0;
  }
`;

const PackageDescription = styled.pre`
  font-family: inherit;
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin: 20px 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: none;
  border: none;
  padding: 0;
  overflow: visible;
`;

const ProductsGrid = styled.div`
  display: grid;
  gap: ${getResponsiveSpacing('md').xs}px;
  grid-template-columns: 1fr;
  margin: ${getResponsiveSpacing('lg').xs}px 0;
  
  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${getResponsiveSpacing('md').sm}px;
    margin: ${getResponsiveSpacing('lg').sm}px 0;
  }
  
  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
    gap: ${getResponsiveSpacing('md').lg}px;
    margin: ${getResponsiveSpacing('lg').lg}px 0;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${getResponsiveSpacing('lg').xs}px 0 0;
  display: grid;
  gap: ${getResponsiveSpacing('sm').xs}px;
  grid-template-columns: 1fr;
  
  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${getResponsiveSpacing('sm').sm}px;
    margin: ${getResponsiveSpacing('lg').sm}px 0 0;
  }
  
  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
    gap: ${getResponsiveSpacing('sm').lg}px;
    margin: ${getResponsiveSpacing('lg').lg}px 0 0;
  }
`;

const FeatureItem = styled.li`
  ${getResponsiveTypography('body')}
  color: ${colors.gray.dark};
  display: flex;
  align-items: center;
  gap: ${getResponsiveSpacing('xs').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('xs').md}px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${getResponsiveSpacing('sm').xs}px;
  padding: ${getResponsiveSpacing('md').xs}px;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('sm').md}px;
    padding: ${getResponsiveSpacing('md').md}px;
  }
`;

const ActionButton = styled.button`
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  background: ${props => props.primary ? 'linear-gradient(0deg, #e71c24 -14.96%, #912528 100%)' : 'white'};
  color: ${props => props.primary ? 'white' : '#e71c24'};
  border: ${props => props.primary ? 'none' : '2px solid #e71c24'};

  &:hover {
    transform: ${props => !props.disabled && 'translateY(-2px)'};
    box-shadow: ${props => !props.disabled && '0 4px 8px rgba(231, 28, 36, 0.2)'};
  }
  
  @media (max-width: 480px) {
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  
  @media (max-width: 480px) {
    gap: 10px;
    margin-top: 5px;
    flex-wrap: wrap;
  }
`;

const QuantityLabel = styled.label`
  font-size: 14px;
  color: ${colors.gray.dark};
  
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const QuantityButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: white;
      color: ${colors.primary.solid};
    }
  }
  
  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  font-size: 16px;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
  
  @media (max-width: 480px) {
    width: 50px;
    padding: 3px;
    font-size: 14px;
  }
`;

const LoadingText = styled.p`
  ${getResponsiveTypography('h3')}
  text-align: center;
  color: ${colors.primary.solid};
  margin-top: ${getResponsiveSpacing('xl').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    margin-top: ${getResponsiveSpacing('xl').md}px;
  }
`;

const ErrorText = styled.p`
  ${getResponsiveTypography('body')}
  text-align: center;
  color: ${colors.primary.solid};
  margin-top: ${getResponsiveSpacing('xl').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    margin-top: ${getResponsiveSpacing('xl').md}px;
  }
`;

const Container = styled.div`
  padding-top: 120px;
  min-height: 100vh;
  background-color: #f5f5f5;
  
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingTop: getResponsiveSpacing('xl', 3).xs,
    paddingBottom: getResponsiveSpacing('xl').xs,
    
    '@media (min-width: ${breakpoints.md}px)': {
      paddingTop: getResponsiveSpacing('xl', 3).md,
      paddingBottom: getResponsiveSpacing('xl').md,
    }
  },
});

export default PackagesPage; 