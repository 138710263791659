import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { colors } from '../../styles/SharedStyles';
import { db, storage } from '../../config/firebase';
import { collection, doc, getDoc, setDoc, getDocs, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { GradientButton } from '../GradientButton';
import styled from 'styled-components';

// Import default video
import landerVideo from '../../assets/videos/lander-video.mp4';

// Import SupportManager
import { SupportManager } from './SupportManager';

// Styled components
const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;

  &:hover {
    background: ${colors.primary.dark};
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const VideoControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SelectedFileName = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: ${colors.gray.dark};
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  background: ${props => props.active ? colors.primary.solid : 'transparent'};
  color: ${props => props.active ? 'white' : colors.gray.dark};
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: ${props => props.active ? '600' : '400'};
  transition: all 0.2s ease;
  
  &:hover {
    background: ${props => props.active ? colors.primary.solid : '#f5f5f5'};
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const ImageCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
  position: relative;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageCardContent = styled.div`
  padding: 15px;
`;

const ImageCardTitle = styled.h3`
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #333;
`;

// Add a new styled component for the inverted upload button
const InvertedButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  background: white;
  color: ${colors.primary.solid};
  border: 2px solid ${colors.primary.solid};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;

  &:hover {
    background: #f8f8f8;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

// Add a new styled component for text inputs
const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: inherit;
  font-size: 14px;
  resize: vertical;
  
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #333;
`;

const RichTextEditor = ({ value, onChange, disabled }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    // Only update the content if the value has changed and doesn't match current content
    if (editorRef.current && editorRef.current.innerHTML !== value) {
      editorRef.current.innerHTML = value;
    }
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.metaKey || e.ctrlKey) {
      switch (e.key.toLowerCase()) {
        case 'b':
          e.preventDefault();
          document.execCommand('bold', false);
          break;
        case 'i':
          e.preventDefault();
          document.execCommand('italic', false);
          break;
        default:
          break;
      }
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      const content = editorRef.current.innerHTML;
      onChange(content);
    }
  };

  return (
    <EditorContainer>
      <EditorToolbar>
        <ToolbarText>Use Cmd/Ctrl + B for bold, Cmd/Ctrl + I for italic</ToolbarText>
      </EditorToolbar>
      <EditableDiv
        ref={editorRef}
        contentEditable={!disabled}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        suppressContentEditableWarning={true}
        $disabled={disabled}
      />
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
`;

const EditorToolbar = styled.div`
  background: #f5f5f5;
  padding: 8px;
  border-bottom: 1px solid #ddd;
`;

const ToolbarText = styled.span`
  font-size: 12px;
  color: #666;
`;

const EditableDiv = styled.div`
  min-height: 150px;
  padding: 12px;
  font-family: Tahoma, sans-serif;
  background: ${props => props.$disabled ? '#f5f5f5' : 'white'};
  cursor: ${props => props.$disabled ? 'not-allowed' : 'text'};
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
  }
`;

// Add default bullet points
const DEFAULT_SERVICE_BULLET_POINTS = {
  1: [
    'Professional installation by certified technicians',
    'Advanced motion detection and analytics',
    'Remote system access and control',
    'Customizable alert notifications',
    '24/7 monitoring capabilities',
    'Regular system maintenance and updates'
  ],
  2: [
    'Real-time video monitoring',
    'Instant threat detection',
    'Professional response coordination',
    'Cloud storage and backup',
    'Mobile app access',
    'Custom security protocols'
  ],
  3: [
    'Keycard and biometric options',
    'Time-based access restrictions',
    'Detailed access logs and reporting',
    'Integration with existing systems',
    'Emergency lockdown capabilities',
    'Remote access management'
  ],
  4: [
    'Live video guard monitoring',
    'Two-way audio communication',
    'Immediate incident response',
    'Customized patrol schedules',
    'Detailed activity reports',
    'Holiday and after-hours coverage'
  ]
};

const TabContent = styled.div`
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;

  h2 {
    margin: 0;
    color: ${colors.primary.solid};
    font-size: 24px;
  }
`;

const SaveButton = styled.button`
  background: ${colors.primary.solid};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const RecommendationCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

const SettingsGroup = styled.div`
  margin-bottom: 30px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SettingsTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin: 0 0 15px;
`;

const ConditionInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  label {
    font-size: 14px;
    color: #666;
  }
  
  input {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
`;

const ProductSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background: white;
  cursor: pointer;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
  }
`;

const MessageInput = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
  }
`;

export const SiteManagement = () => {
  const [activeTab, setActiveTab] = useState('landing');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Landing page states
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedBackgroundVideo, setSelectedBackgroundVideo] = useState(null);
  const [currentBackgroundVideo, setCurrentBackgroundVideo] = useState(null);
  const [selectedHowItWorksVideo, setSelectedHowItWorksVideo] = useState(null);
  const [currentHowItWorksVideo, setCurrentHowItWorksVideo] = useState(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const [isBackgroundVideoDefault, setIsBackgroundVideoDefault] = useState(true);
  const [isHowItWorksVideoDefault, setIsHowItWorksVideoDefault] = useState(true);
  const [backgroundFileName, setBackgroundFileName] = useState('');
  const [howItWorksFileName, setHowItWorksFileName] = useState('');
  const [uploadingBackground, setUploadingBackground] = useState(false);
  const [uploadingHowItWorks, setUploadingHowItWorks] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  
  // New announcement states
  const [announcementTopText, setAnnouncementTopText] = useState('');
  const [announcementBottomText, setAnnouncementBottomText] = useState('');
  const [announcementRightText, setAnnouncementRightText] = useState('');
  const [selectedAnnouncementImage, setSelectedAnnouncementImage] = useState(null);
  const [currentAnnouncementImage, setCurrentAnnouncementImage] = useState(null);
  const [announcementImagePreview, setAnnouncementImagePreview] = useState(null);
  const [uploadingAnnouncementImage, setUploadingAnnouncementImage] = useState(false);
  
  // Security features states
  const [securityFeatures, setSecurityFeatures] = useState([
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/99c9e509f48986770b9a575244ed94b419308fce",
      altText: "Custom security",
      primaryText: "Custom security solutions to",
      secondaryText: "meet your needs",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e21b42cc5941749421ba4f994421aac8aea469ff",
      altText: "Burglary prevention",
      primaryText: "We specialize in burglary prevention",
      secondaryText: "not just notification",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c6ebf0c08c0778df66068dd8275202a87c44761f",
      altText: "Live monitoring",
      primaryText: "Live operator monitoring 24/7",
      secondaryText: "no pre-recordings",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/35098c908c3a100f74025cf44aaa530cd4798919",
      altText: "Real-time alerts",
      primaryText: "Live real-time alerts to your phone with",
      secondaryText: "absolutely no false alerts",
    },
  ]);
  const [selectedFeatureIcons, setSelectedFeatureIcons] = useState({});
  const [featureIconPreviews, setFeatureIconPreviews] = useState({});
  const [uploadingFeatureIcon, setUploadingFeatureIcon] = useState(null);
  
  // Services page states
  const [servicesImages, setServicesImages] = useState({
    heroImage: { url: null, file: null, preview: null, uploading: false },
    service1Image: { url: null, file: null, preview: null, uploading: false },
    service2Image: { url: null, file: null, preview: null, uploading: false },
    service3Image: { url: null, file: null, preview: null, uploading: false },
    service4Image: { url: null, file: null, preview: null, uploading: false },
    service5Image: { url: null, file: null, preview: null, uploading: false },
    service6Image: { url: null, file: null, preview: null, uploading: false }
  });

  // Update the initial services content state
  const [servicesContent, setServicesContent] = useState({
    heroTitle: '',
    heroDescription: '',
    services: [
      { id: 1, title: '', shortDescription: '', fullDescription: '', bulletPoints: [...DEFAULT_SERVICE_BULLET_POINTS[1]] },
      { id: 2, title: '', shortDescription: '', fullDescription: '', bulletPoints: [...DEFAULT_SERVICE_BULLET_POINTS[2]] },
      { id: 3, title: '', shortDescription: '', fullDescription: '', bulletPoints: [...DEFAULT_SERVICE_BULLET_POINTS[3]] },
      { id: 4, title: '', shortDescription: '', fullDescription: '', bulletPoints: [...DEFAULT_SERVICE_BULLET_POINTS[4]] }
    ]
  });
  
  // Disclaimer states
  const [disclaimers, setDisclaimers] = useState({
    cart: {
      enabled: false,
      message: '<p><strong>IMPORTANT:</strong> Please carefully review your selected products to ensure compatibility with your existing security system and that they meet your specific security needs. Our team is available to assist with any questions regarding product selection or system compatibility.</p>'
    },
    checkout: {
      enabled: false,
      message: '<p><strong>COMPATIBILITY NOTICE:</strong> By proceeding with this purchase, you acknowledge that you have verified the compatibility of all selected products with your existing security infrastructure. Security Central Protection cannot guarantee functionality for incompatible systems.</p><p>For installation assistance or compatibility questions, please contact our technical support team at <strong>248.543.0000</strong> before completing your purchase.</p>'
    },
    poe: {
      enabled: false,
      message: '<p><strong>COMPATIBILITY NOTICE:</strong> Please ensure the selected POE switch has sufficient ports and power capacity for your camera system. For assistance with POE selection, contact our support team.</p>'
    }
  });

  // Move recommendation state to top level
  const [recommendation, setRecommendation] = useState({
    conditions: {
      minTotalComponents: 0
    },
    products: ['', '', ''],
    message: '<p><strong>COMPATIBILITY NOTICE:</strong> Your current system configuration may not be fully supported by the selected POE. To ensure proper functionality, please consider adding one of the recommended products below to your order:</p>',
    showInCart: true,
    showInCheckout: true,
    excludedProducts: []
  });

  // Add recommendation effect at top level
  useEffect(() => {
    if (activeTab === 'recommendations') {
      const fetchCurrentSettings = async () => {
        try {
          const doc = await getDoc(doc(db, 'siteSettings', 'recommendations'));
          if (doc.exists()) {
            const data = doc.data();
            if (data.recommendations?.[0]) {
              setRecommendation(data.recommendations[0]);
            }
          }
        } catch (error) {
          console.error('Error fetching recommendations:', error);
        }
      };

      fetchCurrentSettings();
    }
  }, [activeTab]);

  // Add state for available products
  const [availableProducts, setAvailableProducts] = useState([]);

  useEffect(() => {
    fetchCurrentSettings();
  }, [activeTab]);

  const fetchCurrentSettings = async () => {
    try {
      if (activeTab === 'landing') {
        const settingsDoc = await getDoc(doc(db, 'siteSettings', 'landingPage'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setCurrentImage(data.productImage);
          setCurrentBackgroundVideo(data.backgroundVideo || landerVideo);
          setCurrentHowItWorksVideo(data.howItWorksVideo || landerVideo);
          setIsBackgroundVideoDefault(!data.backgroundVideo);
          setIsHowItWorksVideoDefault(!data.howItWorksVideo);
          
          // Set announcement data
          setAnnouncementTopText(data.announcementTopText || '');
          setAnnouncementBottomText(data.announcementBottomText || '');
          setAnnouncementRightText(data.announcementRightText || '');
          setCurrentAnnouncementImage(data.announcementImage || null);
          
          // Set security features data
          if (data.securityFeatures && data.securityFeatures.length > 0) {
            setSecurityFeatures(data.securityFeatures);
          }
        }
      } else if (activeTab === 'services') {
        const servicesDoc = await getDoc(doc(db, 'siteSettings', 'servicesPage'));
        if (servicesDoc.exists()) {
          const data = servicesDoc.data();
          
          // Set images
          const imageState = {
            heroImage: { 
              url: data.heroImage || null, 
              file: null, 
              preview: null, 
              uploading: false 
            },
            service1Image: { 
              url: data.burglaryIntrusionImage || null, 
              file: null, 
              preview: null, 
              uploading: false 
            },
            service2Image: { 
              url: data.visualVerificationImage || null, 
              file: null, 
              preview: null, 
              uploading: false 
            },
            service3Image: { 
              url: data.accessControlImage || null, 
              file: null, 
              preview: null, 
              uploading: false 
            },
            service4Image: { 
              url: data.vgmImage || null, 
              file: null, 
              preview: null, 
              uploading: false 
            },
            service5Image: {
              url: data.service5Image || null,
              file: null,
              preview: null,
              uploading: false
            },
            service6Image: {
              url: data.service6Image || null,
              file: null,
              preview: null,
              uploading: false
            }
          };
          
          setServicesImages(imageState);
          
          // Set content and ensure all services have bullet points
          if (data.content) {
            const services = data.content.services.map(service => ({
              ...service,
              bulletPoints: service.bulletPoints || DEFAULT_SERVICE_BULLET_POINTS[service.id] || ['', '', '', '', '', '']
            }));
            
            setServicesContent({
              ...data.content,
              services
            });
          }
        }
      } else if (activeTab === 'disclaimers') {
        const disclaimersDoc = await getDoc(doc(db, 'siteSettings', 'disclaimers'));
        if (disclaimersDoc.exists()) {
          const data = disclaimersDoc.data();
          setDisclaimers({
            cart: data.cart || { enabled: false, message: '' },
            checkout: data.checkout || { enabled: false, message: '' },
            poe: data.poe || { enabled: false, message: '' }
          });
        }
      } else if (activeTab === 'recommendations') {
        const recommendationsDoc = await getDoc(doc(db, 'siteSettings', 'recommendations'));
        if (recommendationsDoc.exists()) {
          const data = recommendationsDoc.data();
          if (data.recommendations?.[0]) {
            setRecommendation(data.recommendations[0]);
          }
        }
      }
    } catch (err) {
      console.error('Error fetching settings:', err);
      setError('Failed to fetch settings: ' + err.message);
    }
  };

  // Add function to fetch products
  const fetchAvailableProducts = async () => {
    try {
      const productsSnapshot = await getDocs(collection(db, 'products'));
      const products = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Sort products to show POEs first
      const sortedProducts = products.sort((a, b) => {
        if (a.category === 'Power Over Ethernet (POE)' && b.category !== 'Power Over Ethernet (POE)') return -1;
        if (b.category === 'Power Over Ethernet (POE)' && a.category !== 'Power Over Ethernet (POE)') return 1;
        return a.name.localeCompare(b.name);
      });
      
      setAvailableProducts(sortedProducts);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products: ' + err.message);
    }
  };

  // Add to useEffect for recommendations tab
  useEffect(() => {
    if (activeTab === 'recommendations') {
      fetchAvailableProducts();
    }
  }, [activeTab]);

  // Landing page handlers
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleBackgroundVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedBackgroundVideo(file);
      setBackgroundFileName(file.name);
      setIsBackgroundVideoDefault(false);
    }
  };

  const handleHowItWorksVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedHowItWorksVideo(file);
      setHowItWorksFileName(file.name);
      setIsHowItWorksVideoDefault(false);
    }
  };

  // Services page handlers
  const handleServicesImageChange = (imageKey, e) => {
    const file = e.target.files[0];
    if (file) {
      setServicesImages(prev => ({
        ...prev,
        [imageKey]: {
          ...prev[imageKey],
          file: file,
          preview: URL.createObjectURL(file)
        }
      }));
    }
  };

  const handleUploadServicesImage = async (imageKey) => {
    setServicesImages(prev => ({
      ...prev,
      [imageKey]: {
        ...prev[imageKey],
        uploading: true
      }
    }));
    
    setError(null);

    try {
      const file = servicesImages[imageKey].file;
      if (file) {
        const storageRef = ref(storage, `site/servicesPage/${imageKey}-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, file);
        const imageUrl = await getDownloadURL(snapshot.ref);
        
        // Get existing data to merge with
        const servicesDoc = await getDoc(doc(db, 'siteSettings', 'servicesPage'));
        const existingData = servicesDoc.exists() ? servicesDoc.data() : {};
        
        // Map the imageKey to the correct field name in Firestore
        let firestoreField = imageKey;
        if (imageKey === 'service1Image') firestoreField = 'burglaryIntrusionImage';
        if (imageKey === 'service2Image') firestoreField = 'visualVerificationImage';
        if (imageKey === 'service3Image') firestoreField = 'accessControlImage';
        if (imageKey === 'service4Image') firestoreField = 'vgmImage';
        if (imageKey === 'service5Image') firestoreField = 'service5Image';
        if (imageKey === 'service6Image') firestoreField = 'service6Image';
        
        // Update Firestore with new image URL
        await setDoc(doc(db, 'siteSettings', 'servicesPage'), {
          ...existingData,
          [firestoreField]: imageUrl,
          updatedAt: new Date()
        }, { merge: true });

        // Update local state
        setServicesImages(prev => ({
          ...prev,
          [imageKey]: {
            ...prev[imageKey],
            url: imageUrl,
            file: null,
            preview: null,
            uploading: false
          }
        }));
        
        alert(`Image uploaded successfully!`);
      }
    } catch (err) {
      console.error(`Error uploading ${imageKey}:`, err);
      setError(`Failed to upload image: ${err.message}`);
      
      setServicesImages(prev => ({
        ...prev,
        [imageKey]: {
          ...prev[imageKey],
          uploading: false
        }
      }));
    }
  };

  // Existing handlers
  const handleDeleteVideo = async (type) => {
    if (!window.confirm('Are you sure you want to delete this video and revert to the default?')) {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const settingsDoc = await getDoc(doc(db, 'siteSettings', 'landingPage'));
      const data = settingsDoc.exists() ? settingsDoc.data() : {};
      
      if (type === 'background') {
        if (data.backgroundVideo) {
          // Extract the path from the URL
          const videoPath = data.backgroundVideo.split('site/landingPage/')[1];
          if (videoPath) {
            const videoRef = ref(storage, `site/landingPage/${videoPath}`);
            await deleteObject(videoRef);
          }
        }
        setCurrentBackgroundVideo(landerVideo);
        setIsBackgroundVideoDefault(true);
        setSelectedBackgroundVideo(null);
        setBackgroundFileName('');
        
        await setDoc(doc(db, 'siteSettings', 'landingPage'), {
          ...data,
          backgroundVideo: null,
          updatedAt: new Date()
        }, { merge: true });
      } else if (type === 'howItWorks') {
        if (data.howItWorksVideo) {
          const videoPath = data.howItWorksVideo.split('site/landingPage/')[1];
          if (videoPath) {
            const videoRef = ref(storage, `site/landingPage/${videoPath}`);
            await deleteObject(videoRef);
          }
        }
        setCurrentHowItWorksVideo(landerVideo);
        setIsHowItWorksVideoDefault(true);
        setSelectedHowItWorksVideo(null);
        setHowItWorksFileName('');
        
        await setDoc(doc(db, 'siteSettings', 'landingPage'), {
          ...data,
          howItWorksVideo: null,
          updatedAt: new Date()
        }, { merge: true });
      }

      alert('Successfully reverted to default video!');
    } catch (err) {
      console.error('Error deleting video:', err);
      setError('Failed to delete video: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadVideo = async (type) => {
    if (type === 'background') {
      setUploadingBackground(true);
    } else {
      setUploadingHowItWorks(true);
    }
    setError(null);

    try {
      let videoUrl;
      if (type === 'background' && selectedBackgroundVideo) {
        const storageRef = ref(storage, `site/landingPage/backgroundVideo-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedBackgroundVideo);
        videoUrl = await getDownloadURL(snapshot.ref);
        
        await setDoc(doc(db, 'siteSettings', 'landingPage'), {
          backgroundVideo: videoUrl,
          updatedAt: new Date()
        }, { merge: true });

        setCurrentBackgroundVideo(videoUrl);
        setIsBackgroundVideoDefault(false);
        setSelectedBackgroundVideo(null);
        setBackgroundFileName('');
      } else if (type === 'howItWorks' && selectedHowItWorksVideo) {
        const storageRef = ref(storage, `site/landingPage/howItWorksVideo-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedHowItWorksVideo);
        videoUrl = await getDownloadURL(snapshot.ref);
        
        await setDoc(doc(db, 'siteSettings', 'landingPage'), {
          howItWorksVideo: videoUrl,
          updatedAt: new Date()
        }, { merge: true });

        setCurrentHowItWorksVideo(videoUrl);
        setIsHowItWorksVideoDefault(false);
        setSelectedHowItWorksVideo(null);
        setHowItWorksFileName('');
      }

      alert('Video uploaded successfully!');
    } catch (err) {
      console.error('Error uploading video:', err);
      setError('Failed to upload video: ' + err.message);
    } finally {
      setUploadingBackground(false);
      setUploadingHowItWorks(false);
    }
  };

  const handleUploadImage = async () => {
    setUploadingImage(true);
    setError(null);

    try {
      if (selectedImage) {
        const storageRef = ref(storage, `site/landingPage/productImage-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedImage);
        const imageUrl = await getDownloadURL(snapshot.ref);
        
        await setDoc(doc(db, 'siteSettings', 'landingPage'), {
          productImage: imageUrl,
          updatedAt: new Date()
        }, { merge: true });

        setCurrentImage(imageUrl);
        setSelectedImage(null);
        setImagePreview(null);
        alert('Image uploaded successfully!');
      }
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Failed to upload image: ' + err.message);
    } finally {
      setUploadingImage(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      let imageUrl = currentImage;
      let backgroundVideoUrl = isBackgroundVideoDefault ? null : currentBackgroundVideo;
      let howItWorksVideoUrl = isHowItWorksVideoDefault ? null : currentHowItWorksVideo;

      if (selectedImage) {
        const storageRef = ref(storage, `site/landingPage/productImage-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      if (selectedBackgroundVideo) {
        const storageRef = ref(storage, `site/landingPage/backgroundVideo-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedBackgroundVideo);
        backgroundVideoUrl = await getDownloadURL(snapshot.ref);
      }

      if (selectedHowItWorksVideo) {
        const storageRef = ref(storage, `site/landingPage/howItWorksVideo-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedHowItWorksVideo);
        howItWorksVideoUrl = await getDownloadURL(snapshot.ref);
      }

      await setDoc(doc(db, 'siteSettings', 'landingPage'), {
        productImage: imageUrl,
        backgroundVideo: backgroundVideoUrl,
        howItWorksVideo: howItWorksVideoUrl,
        securityFeatures: securityFeatures,
        updatedAt: new Date()
      }, { merge: true });

      setCurrentImage(imageUrl);
      if (backgroundVideoUrl) {
        setCurrentBackgroundVideo(backgroundVideoUrl);
        setIsBackgroundVideoDefault(false);
      }
      if (howItWorksVideoUrl) {
        setCurrentHowItWorksVideo(howItWorksVideoUrl);
        setIsHowItWorksVideoDefault(false);
      }
      setSelectedImage(null);
      setSelectedBackgroundVideo(null);
      setSelectedHowItWorksVideo(null);
      alert('Settings saved successfully!');
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Failed to save settings: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Add handler for services content changes
  const handleServicesContentChange = (field, value) => {
    setServicesContent(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleServiceItemChange = (index, field, value) => {
    setServicesContent(prev => {
      const updatedServices = [...prev.services];
      updatedServices[index] = {
        ...updatedServices[index],
        [field]: value
      };
      return {
        ...prev,
        services: updatedServices
      };
    });
  };

  // Update the handleBulletPointChange function to handle defaults
  const handleBulletPointChange = (serviceIndex, bulletIndex, value) => {
    setServicesContent(prev => {
      const updatedServices = [...prev.services];
      
      // Ensure the service exists
      if (!updatedServices[serviceIndex]) {
        return prev;
      }
      
      // Create a copy of the bullet points array
      const updatedBulletPoints = [...(updatedServices[serviceIndex].bulletPoints || [])];
      
      // If this is the first edit and we're using defaults, create a fresh copy
      if (value !== DEFAULT_SERVICE_BULLET_POINTS[serviceIndex + 1]?.[bulletIndex]) {
        // Only create a fresh copy if we haven't already modified the bullet points
        if (JSON.stringify(updatedBulletPoints) === JSON.stringify(DEFAULT_SERVICE_BULLET_POINTS[serviceIndex + 1])) {
          updatedBulletPoints.length = 6; // Ensure we have 6 slots
          updatedBulletPoints.fill(''); // Reset all to empty
        }
      }
      
      // Update the specific bullet point
      updatedBulletPoints[bulletIndex] = value;
      
      // Update the service with the new bullet points
      updatedServices[serviceIndex] = {
        ...updatedServices[serviceIndex],
        bulletPoints: updatedBulletPoints
      };
      
      return {
        ...prev,
        services: updatedServices
      };
    });
  };

  // Add function to save services content
  const handleSaveServicesContent = async () => {
    setLoading(true);
    setError(null);

    try {
      // Get existing data to merge with
      const servicesDoc = await getDoc(doc(db, 'siteSettings', 'servicesPage'));
      const existingData = servicesDoc.exists() ? servicesDoc.data() : {};
      
      // Update Firestore with new content
      await setDoc(doc(db, 'siteSettings', 'servicesPage'), {
        ...existingData,
        content: servicesContent,
        heroTitle: servicesContent.heroTitle,
        heroDescription: servicesContent.heroDescription,
        updatedAt: new Date()
      }, { merge: true });
      
      alert('Services content updated successfully!');
    } catch (err) {
      console.error('Error saving services content:', err);
      setError('Failed to save services content: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Add function to add a new service
  const handleAddService = () => {
    setServicesContent(prev => {
      const newId = prev.services.length > 0 ? Math.max(...prev.services.map(s => s.id)) + 1 : 1;
      
      // Create a new service with empty bullet points
      const newService = { 
        id: newId, 
        title: '', 
        shortDescription: '', 
        fullDescription: '', 
        bulletPoints: ['', '', '', '', '', ''] // Initialize with 6 empty bullet points
      };
      
      return {
        ...prev,
        services: [
          ...prev.services,
          newService
        ]
      };
    });
  };

  // Add function to remove a service
  const handleRemoveService = (index) => {
    if (window.confirm('Are you sure you want to remove this service?')) {
      setServicesContent(prev => {
        const updatedServices = [...prev.services];
        updatedServices.splice(index, 1);
        return {
          ...prev,
          services: updatedServices
        };
      });
    }
  };

  const handleAnnouncementImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedAnnouncementImage(file);
      setAnnouncementImagePreview(URL.createObjectURL(file));
    }
  };

  const handleUploadAnnouncementImage = async () => {
    setUploadingAnnouncementImage(true);
    setError(null);

    try {
      if (selectedAnnouncementImage) {
        const storageRef = ref(storage, `site/landingPage/announcementImage-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, selectedAnnouncementImage);
        const imageUrl = await getDownloadURL(snapshot.ref);
        
        const settingsRef = doc(db, 'siteSettings', 'landingPage');
        const settingsDoc = await getDoc(settingsRef);
        const existingData = settingsDoc.exists() ? settingsDoc.data() : {};
        
        await setDoc(settingsRef, {
          ...existingData,
          announcementImage: imageUrl
        }, { merge: true });

        setCurrentAnnouncementImage(imageUrl);
        setSelectedAnnouncementImage(null);
        setAnnouncementImagePreview(null);
        alert('Announcement image uploaded successfully!');
      }
    } catch (err) {
      console.error('Error uploading announcement image:', err);
      setError('Failed to upload announcement image: ' + err.message);
    } finally {
      setUploadingAnnouncementImage(false);
    }
  };

  const handleSaveAnnouncement = async () => {
    setLoading(true);
    setError(null);

    try {
      const settingsRef = doc(db, 'siteSettings', 'landingPage');
      const settingsDoc = await getDoc(settingsRef);
      const existingData = settingsDoc.exists() ? settingsDoc.data() : {};
      
      await setDoc(settingsRef, {
        ...existingData,
        announcementTopText: announcementTopText,
        announcementBottomText: announcementBottomText,
        announcementRightText: announcementRightText
      }, { merge: true });
      
      setLoading(false);
      alert('Announcement saved successfully!');
    } catch (err) {
      console.error('Error saving announcement:', err);
      setError('Failed to save announcement');
      setLoading(false);
    }
  };

  const handleFeatureIconChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFeatureIcons(prev => ({
        ...prev,
        [index]: file
      }));
      
      // Create and store preview URL
      const previewUrl = URL.createObjectURL(file);
      setFeatureIconPreviews(prev => ({
        ...prev,
        [index]: previewUrl
      }));
    }
  };

  const handleFeatureTextChange = (index, field, value) => {
    const updatedFeatures = [...securityFeatures];
    updatedFeatures[index] = {
      ...updatedFeatures[index],
      [field]: value
    };
    setSecurityFeatures(updatedFeatures);
  };

  const handleUploadFeatureIcon = async (index) => {
    setUploadingFeatureIcon(index);
    setError(null);

    try {
      const file = selectedFeatureIcons[index];
      if (file) {
        const storageRef = ref(storage, `site/landingPage/featureIcon-${index}-${Date.now()}`);
        const snapshot = await uploadBytes(storageRef, file);
        const iconUrl = await getDownloadURL(snapshot.ref);
        
        // Update the feature with the new icon URL
        const updatedFeatures = [...securityFeatures];
        updatedFeatures[index] = {
          ...updatedFeatures[index],
          icon: iconUrl
        };
        setSecurityFeatures(updatedFeatures);
        
        // Clear the selected file and preview
        setSelectedFeatureIcons(prev => {
          const updated = {...prev};
          delete updated[index];
          return updated;
        });
        
        setFeatureIconPreviews(prev => {
          const updated = {...prev};
          delete updated[index];
          return updated;
        });
        
        // Save to Firebase
        const settingsRef = doc(db, 'siteSettings', 'landingPage');
        const settingsDoc = await getDoc(settingsRef);
        const existingData = settingsDoc.exists() ? settingsDoc.data() : {};
        
        await setDoc(settingsRef, {
          ...existingData,
          securityFeatures: updatedFeatures
        }, { merge: true });
        
        alert('Feature icon uploaded successfully!');
      }
    } catch (err) {
      console.error('Error uploading feature icon:', err);
      setError('Failed to upload feature icon: ' + err.message);
    } finally {
      setUploadingFeatureIcon(null);
    }
  };

  const handleSaveSecurityFeatures = async () => {
    setLoading(true);
    setError(null);

    try {
      const settingsRef = doc(db, 'siteSettings', 'landingPage');
      const settingsDoc = await getDoc(settingsRef);
      const existingData = settingsDoc.exists() ? settingsDoc.data() : {};
      
      await setDoc(settingsRef, {
        ...existingData,
        securityFeatures: securityFeatures
      }, { merge: true });
      
      setLoading(false);
      alert('Security features saved successfully!');
    } catch (err) {
      console.error('Error saving security features:', err);
      setError('Failed to save security features');
      setLoading(false);
    }
  };

  const handleDisclaimerChange = (location, field, value) => {
    setDisclaimers(prev => ({
      ...prev,
      [location]: {
        ...prev[location],
        [field]: value
      }
    }));
  };

  const handleSaveDisclaimers = async () => {
    setLoading(true);
    setError(null);

    try {
      await setDoc(doc(db, 'siteSettings', 'disclaimers'), {
        ...disclaimers,
        updatedAt: new Date()
      });
      
      setLoading(false);
      alert('Disclaimers saved successfully!');
    } catch (err) {
      console.error('Error saving disclaimers:', err);
      setError('Failed to save disclaimers: ' + err.message);
      setLoading(false);
    }
  };

  const handleRecommendationChange = (index, field, value) => {
    setRecommendation(prev => {
      const updated = [...prev];
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        updated[index] = {
          ...updated[index],
          [parent]: {
            ...updated[index][parent],
            [child]: value
          }
        };
      } else if (field === 'products') {
        // Ensure we always have exactly 3 product slots
        const newProducts = [...value];
        while (newProducts.length < 3) newProducts.push('');
        updated[index] = {
          ...updated[index],
          [field]: newProducts.slice(0, 3)
        };
      } else {
        updated[index] = {
          ...updated[index],
          [field]: value
        };
      }
      return updated;
    });
  };

  const handleAddRecommendation = () => {
    setRecommendation(prev => [
      ...prev,
      {
        id: Date.now(),
        conditions: {
          minTotalComponents: 0
        },
        products: ['', '', ''],
        message: '<p><strong>COMPATIBILITY NOTICE:</strong> Your current system configuration may not be fully supported by the selected POE. To ensure proper functionality, please consider adding one of the recommended products below to your order:</p>'
      }
    ]);
  };

  const handleRemoveRecommendation = (index) => {
    if (window.confirm('Are you sure you want to remove this recommendation?')) {
      setRecommendation(prev => prev.filter((_, i) => i !== index));
    }
  };

  const handleSaveRecommendation = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate the recommendation
      if (!recommendation.conditions?.minTotalComponents) {
        setError('Please set a minimum number of total components');
        return;
      }

      if (!recommendation.products?.some(p => p)) {
        setError('Please select at least one recommended product');
        return;
      }

      // Prepare the data
      const recommendationData = {
        recommendations: [{
          conditions: {
            minTotalComponents: parseInt(recommendation.conditions.minTotalComponents) || 0
          },
          products: recommendation.products || ['', '', ''],
          message: recommendation.message || '',
          showInCart: recommendation.showInCart ?? true,
          showInCheckout: recommendation.showInCheckout ?? true,
          excludedProducts: recommendation.excludedProducts || []
        }],
        updatedAt: new Date()
      };

      console.log('Saving recommendation data:', recommendationData);

      // Save to Firestore
      await setDoc(doc(db, 'siteSettings', 'recommendations'), recommendationData);
      
      alert('Recommendation settings saved successfully!');
    } catch (error) {
      console.error('Error saving recommendation:', error);
      setError('Error saving recommendation settings: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Render functions for different tabs
  const renderLandingPageTab = () => (
    <>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Hero Announcement</Text>
        <Text style={styles.sectionDescription}>
          This announcement will appear at the bottom of the hero video on the landing page.
        </Text>
        
        <View style={styles.announcementContainer}>
          <View style={styles.announcementTextSection}>
            <FormGroup>
              <FormLabel>Top Text</FormLabel>
              <StyledInput
                type="text"
                value={announcementTopText}
                onChange={(e) => setAnnouncementTopText(e.target.value)}
                placeholder="Enter top announcement text"
              />
            </FormGroup>
            
            <FormGroup>
              <FormLabel>Bottom Text</FormLabel>
              <StyledInput
                type="text"
                value={announcementBottomText}
                onChange={(e) => setAnnouncementBottomText(e.target.value)}
                placeholder="Enter bottom announcement text"
              />
            </FormGroup>
            
            <FormGroup>
              <FormLabel>Right Text (e.g. percentage off)</FormLabel>
              <StyledInput
                type="text"
                value={announcementRightText}
                onChange={(e) => setAnnouncementRightText(e.target.value)}
                placeholder="Enter right side text (optional)"
              />
            </FormGroup>
        </View>
          
          <View style={styles.announcementImageSection}>
            <FormLabel>Announcement Image (optional)</FormLabel>
            <View style={styles.imagePreviewContainer}>
              {(currentAnnouncementImage || announcementImagePreview) && (
                <img
                  src={announcementImagePreview || currentAnnouncementImage}
                  alt="Announcement"
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    marginBottom: '10px'
                  }}
                />
              )}
            </View>
            <ButtonGroup>
              <FileInputLabel>
                Choose Image
                <HiddenFileInput
                  type="file"
                  accept="image/*"
                  onChange={handleAnnouncementImageChange}
                  disabled={uploadingAnnouncementImage}
                />
              </FileInputLabel>
              {selectedAnnouncementImage && (
                <InvertedButton
                  onClick={handleUploadAnnouncementImage}
                  disabled={uploadingAnnouncementImage}
                >
                  {uploadingAnnouncementImage ? "Uploading..." : "Upload Image"}
                </InvertedButton>
              )}
            </ButtonGroup>
            {uploadingAnnouncementImage && (
              <SelectedFileName>Upload in progress... Please wait.</SelectedFileName>
            )}
          </View>
        </View>
        
        <InvertedButton 
          onClick={handleSaveAnnouncement}
          disabled={loading}
          style={{ marginTop: '20px' }}
        >
          {loading ? 'Saving...' : 'Save Announcement'}
        </InvertedButton>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Security Features</Text>
        <Text style={styles.sectionDescription}>
          Edit the security features section that appears below the hero section.
        </Text>
        
        {securityFeatures.map((feature, index) => (
          <View key={index} style={styles.featureCard}>
            <Text style={styles.featureIndex}>Feature {index + 1}</Text>
            
            <View style={styles.featureContent}>
              <View style={styles.featureIconSection}>
                <FormLabel>Feature Icon</FormLabel>
                <View style={styles.imagePreviewContainer}>
                  <img
                    src={featureIconPreviews[index] || feature.icon}
                    alt={feature.altText}
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'contain',
                      marginBottom: '10px'
                    }}
                  />
                </View>
                <ButtonGroup>
                  <FileInputLabel>
                    Choose Icon
                    <HiddenFileInput
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFeatureIconChange(index, e)}
                      disabled={uploadingFeatureIcon === index}
                    />
                  </FileInputLabel>
                  {selectedFeatureIcons[index] && (
                    <InvertedButton
                      onClick={() => handleUploadFeatureIcon(index)}
                      disabled={uploadingFeatureIcon === index}
                    >
                      {uploadingFeatureIcon === index ? "Uploading..." : "Upload Icon"}
                    </InvertedButton>
                  )}
                </ButtonGroup>
                {uploadingFeatureIcon === index && (
                  <SelectedFileName>Upload in progress... Please wait.</SelectedFileName>
                )}
              </View>
              
              <View style={styles.featureTextSection}>
                <FormGroup>
                  <FormLabel>Alt Text</FormLabel>
                  <StyledInput
                    type="text"
                    value={feature.altText}
                    onChange={(e) => handleFeatureTextChange(index, 'altText', e.target.value)}
                    placeholder="Enter alt text for the icon"
                  />
                </FormGroup>
                
                <FormGroup>
                  <FormLabel>Primary Text</FormLabel>
                  <StyledInput
                    type="text"
                    value={feature.primaryText}
                    onChange={(e) => handleFeatureTextChange(index, 'primaryText', e.target.value)}
                    placeholder="Enter primary text"
                  />
                </FormGroup>
                
                <FormGroup>
                  <FormLabel>Secondary Text</FormLabel>
                  <StyledInput
                    type="text"
                    value={feature.secondaryText}
                    onChange={(e) => handleFeatureTextChange(index, 'secondaryText', e.target.value)}
                    placeholder="Enter secondary text"
                  />
                </FormGroup>
              </View>
            </View>
          </View>
        ))}
        
        <InvertedButton 
          onClick={handleSaveSecurityFeatures}
          disabled={loading || uploadingFeatureIcon !== null}
          style={{ marginTop: '20px' }}
        >
          Save Security Features
        </InvertedButton>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Landing Page Background Video</Text>
        <Text style={styles.sectionDescription}>
          This video will play in the background of the landing page hero section.
          {isBackgroundVideoDefault && ' (Currently using default video)'}
        </Text>
        <View style={styles.videoSection}>
          {currentBackgroundVideo && (
            <video
              src={currentBackgroundVideo}
              style={styles.videoPreview}
              controls
              muted
            />
          )}
          <VideoControlsContainer>
            <ButtonGroup>
              <FileInputLabel>
                Choose Video
                <HiddenFileInput
                  type="file"
                  accept="video/*"
                  onChange={handleBackgroundVideoChange}
                  disabled={uploadingBackground}
                />
              </FileInputLabel>
              {selectedBackgroundVideo && (
                <GradientButton
                  onPress={() => handleUploadVideo('background')}
                  text={uploadingBackground ? "Uploading..." : "Upload Video"}
                  style={styles.uploadButton}
                  textStyle={{ fontSize: 14 }}
                  disabled={uploadingBackground}
                />
              )}
              {!isBackgroundVideoDefault && (
                <GradientButton
                  onPress={() => handleDeleteVideo('background')}
                  text="Delete & Use Default"
                  style={styles.deleteButton}
                  variant="secondary"
                  textStyle={{ fontSize: 14 }}
                  disabled={uploadingBackground}
                />
              )}
            </ButtonGroup>
            {backgroundFileName && (
              <SelectedFileName>Selected file: {backgroundFileName}</SelectedFileName>
            )}
            {uploadingBackground && (
              <SelectedFileName>Upload in progress... Please wait.</SelectedFileName>
            )}
          </VideoControlsContainer>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>"How It Works" Section Video</Text>
        <Text style={styles.sectionDescription}>
          This video will be displayed in the "How It Works" section of the landing page.
          {isHowItWorksVideoDefault && ' (Currently using default video)'}
        </Text>
        <View style={styles.videoSection}>
          {currentHowItWorksVideo && (
            <video
              src={currentHowItWorksVideo}
              style={styles.videoPreview}
              controls
              muted
            />
          )}
          <VideoControlsContainer>
            <ButtonGroup>
              <FileInputLabel>
                Choose Video
                <HiddenFileInput
                  type="file"
                  accept="video/*"
                  onChange={handleHowItWorksVideoChange}
                  disabled={uploadingHowItWorks}
                />
              </FileInputLabel>
              {selectedHowItWorksVideo && (
                <GradientButton
                  onPress={() => handleUploadVideo('howItWorks')}
                  text={uploadingHowItWorks ? "Uploading..." : "Upload Video"}
                  style={styles.uploadButton}
                  textStyle={{ fontSize: 14 }}
                  disabled={uploadingHowItWorks}
                />
              )}
              {!isHowItWorksVideoDefault && (
                <GradientButton
                  onPress={() => handleDeleteVideo('howItWorks')}
                  text="Delete & Use Default"
                  style={styles.deleteButton}
                  variant="secondary"
                  textStyle={{ fontSize: 14 }}
                  disabled={uploadingHowItWorks}
                />
              )}
            </ButtonGroup>
            {howItWorksFileName && (
              <SelectedFileName>Selected file: {howItWorksFileName}</SelectedFileName>
            )}
            {uploadingHowItWorks && (
              <SelectedFileName>Upload in progress... Please wait.</SelectedFileName>
            )}
          </VideoControlsContainer>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Product Image</Text>
        <Text style={styles.sectionDescription}>
          This image will appear next to "Get Better Protected with Virtual Guard Server" on the landing page.
        </Text>
        <View style={styles.imageSection}>
          {(currentImage || imagePreview) && (
            <Image
              source={{ uri: imagePreview || currentImage }}
              style={styles.preview}
              resizeMode="cover"
            />
          )}
          <ButtonGroup>
            <FileInputLabel>
              Choose Image
              <HiddenFileInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                disabled={uploadingImage}
              />
            </FileInputLabel>
            {selectedImage && (
              <GradientButton
                onPress={handleUploadImage}
                text={uploadingImage ? "Uploading..." : "Upload Image"}
                style={styles.uploadButton}
                textStyle={{ fontSize: 14 }}
                disabled={uploadingImage}
              />
            )}
          </ButtonGroup>
          {uploadingImage && (
            <SelectedFileName>Upload in progress... Please wait.</SelectedFileName>
          )}
        </View>
      </View>
    </>
  );

  const renderServicesPageTab = () => (
    <>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Services Page Content</Text>
        <Text style={styles.sectionDescription}>
          Edit the content displayed on the Services page, including the hero section and service cards.
        </Text>
        
        {/* Hero Section Content */}
        <View style={styles.contentSection}>
          <Text style={styles.subsectionTitle}>Hero Section</Text>
          
          <View style={styles.heroContentContainer}>
            <View style={styles.heroImageSection}>
              <ImagePreview style={styles.serviceImagePreview}>
                {(servicesImages.heroImage.url || servicesImages.heroImage.preview) && (
                  <img 
                    src={servicesImages.heroImage.preview || servicesImages.heroImage.url} 
                    alt="Hero Section" 
                  />
                )}
              </ImagePreview>
              <ButtonGroup>
                <FileInputLabel>
                  Choose Image
                  <HiddenFileInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleServicesImageChange('heroImage', e)}
                    disabled={servicesImages.heroImage.uploading}
                  />
                </FileInputLabel>
                {servicesImages.heroImage.file && (
                  <InvertedButton
                    onClick={() => handleUploadServicesImage('heroImage')}
                    disabled={servicesImages.heroImage.uploading}
                  >
                    {servicesImages.heroImage.uploading ? "Uploading..." : "Upload"}
                  </InvertedButton>
                )}
              </ButtonGroup>
            </View>
            
            <View style={styles.heroTextSection}>
              <FormGroup>
                <FormLabel>Title</FormLabel>
                <StyledInput 
                  type="text" 
                  value={servicesContent.heroTitle} 
                  onChange={(e) => handleServicesContentChange('heroTitle', e.target.value)}
                  placeholder="Enter hero section title"
                />
              </FormGroup>
              
              <FormGroup>
                <FormLabel>Description</FormLabel>
                <StyledTextarea 
                  value={servicesContent.heroDescription} 
                  onChange={(e) => handleServicesContentChange('heroDescription', e.target.value)}
                  placeholder="Enter hero section description"
                  rows={5}
                />
              </FormGroup>
            </View>
          </View>
        </View>
        
        {/* Service Cards Content */}
        <View style={styles.contentSection}>
          <Text style={styles.subsectionTitle}>Service Cards</Text>
          <Text style={styles.sectionDescription}>
            Manage your service cards below. Each card can have a title, descriptions, bullet points, and an image.
          </Text>
          
          {servicesContent.services.map((service, index) => {
            // Determine which image key to use based on index
            const imageKey = `service${index + 1}Image`;
            
            return (
              <View key={service.id} style={styles.serviceCard}>
                <View style={styles.serviceCardHeader}>
                  <Text style={styles.serviceCardTitle}>Service {index + 1}</Text>
                  <InvertedButton 
                    onClick={() => handleRemoveService(index)}
                    style={{ 
                      padding: '5px 10px', 
                      fontSize: '12px',
                      marginLeft: 'auto'
                    }}
                  >
                    Remove
                  </InvertedButton>
                </View>
                
                <View style={styles.serviceContentContainer}>
                  <View style={styles.serviceImageSection}>
                    <ImagePreview style={styles.serviceImagePreview}>
                      {(servicesImages[imageKey]?.url || servicesImages[imageKey]?.preview) && (
                        <img 
                          src={servicesImages[imageKey]?.preview || servicesImages[imageKey]?.url} 
                          alt={`Service ${index + 1}`} 
                        />
                      )}
                    </ImagePreview>
                    <ButtonGroup>
                      <FileInputLabel>
                        Choose Image
                        <HiddenFileInput
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleServicesImageChange(imageKey, e)}
                          disabled={servicesImages[imageKey]?.uploading}
                        />
                      </FileInputLabel>
                      {servicesImages[imageKey]?.file && (
                        <InvertedButton
                          onClick={() => handleUploadServicesImage(imageKey)}
                          disabled={servicesImages[imageKey]?.uploading}
                        >
                          {servicesImages[imageKey]?.uploading ? "Uploading..." : "Upload"}
                        </InvertedButton>
                      )}
                    </ButtonGroup>
                  </View>
                  
                  <View style={styles.serviceTextSection}>
                    <FormGroup>
                      <FormLabel>Title</FormLabel>
                      <StyledInput 
                        type="text" 
                        value={service.title} 
                        onChange={(e) => handleServiceItemChange(index, 'title', e.target.value)}
                        placeholder="Enter service title"
                      />
                    </FormGroup>
                    
                    <FormGroup>
                      <FormLabel>Short Description (displayed on collapsed card)</FormLabel>
                      <StyledTextarea 
                        value={service.shortDescription} 
                        onChange={(e) => handleServiceItemChange(index, 'shortDescription', e.target.value)}
                        placeholder="Enter short description"
                        rows={3}
                      />
                    </FormGroup>
                    
                    <FormGroup>
                      <FormLabel>Full Description (displayed on expanded card)</FormLabel>
                      <StyledTextarea 
                        value={service.fullDescription} 
                        onChange={(e) => handleServiceItemChange(index, 'fullDescription', e.target.value)}
                        rows={5}
                        placeholder="Enter full description"
                      />
                    </FormGroup>
                  </View>
                </View>
                
                <FormGroup>
                  <FormLabel>Bullet Points (displayed on both collapsed and expanded card)</FormLabel>
                  <View style={styles.bulletPointsGrid}>
                    {service.bulletPoints && service.bulletPoints.map((bullet, bulletIndex) => (
                      <StyledInput 
                        key={bulletIndex}
                        type="text" 
                        value={bullet} 
                        onChange={(e) => handleBulletPointChange(index, bulletIndex, e.target.value)}
                        placeholder={`Bullet point ${bulletIndex + 1}`}
                        style={styles.bulletPointInput}
                      />
                    ))}
                  </View>
                </FormGroup>
              </View>
            );
          })}
          
          <View style={styles.addServiceButtonContainer}>
            <InvertedButton 
              onClick={handleAddService}
              disabled={servicesContent.services.length >= 6}
              style={{ padding: '12px 30px', fontSize: '16px' }}
            >
              {servicesContent.services.length >= 6 ? 
                "Maximum of 6 services reached" : 
                "Add New Service"}
            </InvertedButton>
          </View>
        </View>
      </View>
      
      {/* Single Save Button for Services Page */}
      <View style={styles.saveButtonContainer}>
        <InvertedButton 
          onClick={handleSaveServicesContent}
          disabled={loading}
          style={{ padding: '15px 40px', fontSize: '18px', fontWeight: 'bold' }}
        >
          {loading ? "Saving..." : "Save All Changes"}
        </InvertedButton>
      </View>
      
      {error && <Text style={styles.errorText}>{error}</Text>}
    </>
  );

  const renderDisclaimersTab = () => (
    <>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>POE Product Disclaimer</Text>
        <Text style={styles.sectionDescription}>
          This disclaimer will appear on POE product cards in the system builder.
        </Text>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={disclaimers.poe?.enabled ?? false}
                onChange={(e) => handleDisclaimerChange('poe', 'enabled', e.target.checked)}
              />
              Enable POE Disclaimer
            </label>
          </View>
        </View>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Disclaimer Message</Text>
            <RichTextEditor
              value={disclaimers.poe?.message ?? ''}
              onChange={(value) => handleDisclaimerChange('poe', 'message', value)}
              disabled={!disclaimers.poe?.enabled}
            />
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Cart Disclaimer</Text>
        <Text style={styles.sectionDescription}>
          This disclaimer will appear on the POE product cards AND in the shopping cart before the checkout button.
        </Text>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={disclaimers.cart.enabled}
                onChange={(e) => handleDisclaimerChange('cart', 'enabled', e.target.checked)}
              />
              Enable Cart Disclaimer
            </label>
          </View>
        </View>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Disclaimer Message</Text>
            <RichTextEditor
              value={disclaimers.cart.message}
              onChange={(value) => handleDisclaimerChange('cart', 'message', value)}
              disabled={!disclaimers.cart.enabled}
            />
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Checkout Page Disclaimer</Text>
        <Text style={styles.sectionDescription}>
          This disclaimer will appear on the checkout page before the complete purchase button.
        </Text>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={disclaimers.checkout.enabled}
                onChange={(e) => handleDisclaimerChange('checkout', 'enabled', e.target.checked)}
              />
              Enable Checkout Disclaimer
            </label>
          </View>
        </View>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Disclaimer Message</Text>
            <RichTextEditor
              value={disclaimers.checkout.message}
              onChange={(value) => handleDisclaimerChange('checkout', 'message', value)}
              disabled={!disclaimers.checkout.enabled}
            />
          </View>
        </View>
      </View>

      <View style={styles.buttonContainer}>
        <GradientButton
          onPress={handleSaveDisclaimers}
          text="Save Disclaimers"
          disabled={loading}
          style={styles.button}
        />
      </View>
    </>
  );

  const renderRecommendationsTab = () => {
    const handleSaveRecommendation = async () => {
      try {
        setLoading(true);
        setError(null);

        // Validate the recommendation
        if (!recommendation.conditions?.minTotalComponents) {
          setError('Please set a minimum number of total components');
          return;
        }

        if (!recommendation.products?.some(p => p)) {
          setError('Please select at least one recommended product');
          return;
        }

        // Prepare the data
        const recommendationData = {
          recommendations: [{
            conditions: {
              minTotalComponents: parseInt(recommendation.conditions.minTotalComponents) || 0
            },
            products: recommendation.products || ['', '', ''],
            message: recommendation.message || '',
            showInCart: recommendation.showInCart ?? true,
            showInCheckout: recommendation.showInCheckout ?? true,
            excludedProducts: recommendation.excludedProducts || []
          }],
          updatedAt: new Date()
        };

        console.log('Saving recommendation data:', recommendationData);

        // Save to Firestore
        await setDoc(doc(db, 'siteSettings', 'recommendations'), recommendationData);
        
        alert('Recommendation settings saved successfully!');
      } catch (error) {
        console.error('Error saving recommendation:', error);
        setError('Error saving recommendation settings: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    return (
      <TabContent>
        <TabHeader>
          <h2>Product Recommendations</h2>
          <SaveButton onClick={handleSaveRecommendation}>Save Changes</SaveButton>
        </TabHeader>

        <RecommendationCard>
          <SettingsGroup>
            <SettingsTitle>Display Settings</SettingsTitle>
            <DisplayOptions>
              <DisplayToggle>
                <input
                  type="checkbox"
                  checked={recommendation?.showInCart ?? true}
                  onChange={(e) => setRecommendation(prev => ({
                    ...prev,
                    showInCart: e.target.checked
                  }))}
                  id="show-in-cart"
                />
                <label htmlFor="show-in-cart">Show in Cart</label>
              </DisplayToggle>
              <DisplayToggle>
                <input
                  type="checkbox"
                  checked={recommendation?.showInCheckout ?? true}
                  onChange={(e) => setRecommendation(prev => ({
                    ...prev,
                    showInCheckout: e.target.checked
                  }))}
                  id="show-in-checkout"
                />
                <label htmlFor="show-in-checkout">Show in Checkout</label>
              </DisplayToggle>
            </DisplayOptions>
          </SettingsGroup>

          <SettingsGroup>
            <SettingsTitle>Trigger Condition</SettingsTitle>
            <ConditionInput>
              <label>Minimum Total Components:</label>
              <input
                type="number"
                min="0"
                value={recommendation?.conditions?.minTotalComponents ?? 0}
                onChange={(e) => setRecommendation(prev => ({
                  ...prev,
                  conditions: {
                    ...prev.conditions,
                    minTotalComponents: parseInt(e.target.value) || 0
                  }
                }))}
              />
            </ConditionInput>
          </SettingsGroup>

          <SettingsGroup>
            <SettingsTitle>Recommended Products</SettingsTitle>
            <ProductsGrid>
              {(recommendation?.products || ['', '', '']).map((productId, index) => (
                <ProductSelect
                  key={index}
                  value={productId}
                  onChange={(e) => {
                    const newProducts = [...(recommendation?.products || ['', '', ''])];
                    newProducts[index] = e.target.value;
                    setRecommendation(prev => ({
                      ...prev,
                      products: newProducts
                    }));
                  }}
                >
                  <option value="">Select Product</option>
                  {availableProducts.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </ProductSelect>
              ))}
            </ProductsGrid>
          </SettingsGroup>

          <SettingsGroup>
            <SettingsTitle>Excluded Products</SettingsTitle>
            <p>If any of these products are in the cart, recommendations will not be shown</p>
            <ExcludedProductsGrid>
              <ProductSelect
                value=""
                onChange={(e) => {
                  if (e.target.value) {
                    setRecommendation(prev => ({
                      ...prev,
                      excludedProducts: [...(prev.excludedProducts || []), e.target.value]
                    }));
                  }
                }}
              >
                <option value="">Add Excluded Product</option>
                {availableProducts
                  .filter(p => !recommendation?.excludedProducts?.includes(p.id))
                  .map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
              </ProductSelect>
              {recommendation?.excludedProducts?.map((productId, index) => {
                const product = availableProducts.find(p => p.id === productId);
                return (
                  <ExcludedProduct key={productId}>
                    {product?.name || productId}
                    <RemoveButton
                      onClick={() => setRecommendation(prev => ({
                        ...prev,
                        excludedProducts: (prev.excludedProducts || []).filter(id => id !== productId)
                      }))}
                    >
                      ×
                    </RemoveButton>
                  </ExcludedProduct>
                );
              })}
            </ExcludedProductsGrid>
          </SettingsGroup>

          <SettingsGroup>
            <SettingsTitle>Recommendation Message</SettingsTitle>
            <RichTextEditor
              value={recommendation?.message || ''}
              onChange={(value) => setRecommendation(prev => ({
                ...prev,
                message: value
              }))}
              disabled={false}
            />
          </SettingsGroup>
        </RecommendationCard>
      </TabContent>
    );
  };

  // Add new styled components
  const DisplayOptions = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 10px;
  `;

  const DisplayToggle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    
    input[type="checkbox"] {
      width: 18px;
      height: 18px;
    }
    
    label {
      font-size: 14px;
      color: #666;
    }
  `;

  const ExcludedProductsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  `;

  const ExcludedProduct = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    background: #f5f5f5;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
  `;

  const RemoveButton = styled.button`
    background: none;
    border: none;
    color: ${colors.primary.solid};
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      opacity: 0.8;
    }
  `;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Site Content Management</Text>

      {error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
      
      <TabContainer>
        <TabButton 
          active={activeTab === 'landing'} 
          onClick={() => setActiveTab('landing')}
        >
          Landing Page
        </TabButton>
        <TabButton 
          active={activeTab === 'services'} 
          onClick={() => setActiveTab('services')}
        >
          Services Page
        </TabButton>
        <TabButton 
          active={activeTab === 'disclaimers'} 
          onClick={() => setActiveTab('disclaimers')}
        >
          Disclaimers
        </TabButton>
        <TabButton 
          active={activeTab === 'recommendations'} 
          onClick={() => setActiveTab('recommendations')}
        >
          Product Recommendations
        </TabButton>
        <TabButton 
          active={activeTab === 'support'} 
          onClick={() => setActiveTab('support')}
        >
          Support
        </TabButton>
      </TabContainer>

      {activeTab === 'landing' && renderLandingPageTab()}
      {activeTab === 'services' && renderServicesPageTab()}
      {activeTab === 'disclaimers' && renderDisclaimersTab()}
      {activeTab === 'recommendations' && renderRecommendationsTab()}
      {activeTab === 'support' && <SupportManager />}

      {/* Only show the button container for the landing page tab */}
      {activeTab === 'landing' && (
      <View style={styles.buttonContainer}>
        <GradientButton
          onPress={handleSave}
          text="Save All Changes"
          disabled={loading || uploadingBackground || uploadingHowItWorks || uploadingImage}
          style={styles.button}
        />
      </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  section: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
  },
  sectionDescription: {
    fontSize: 14,
    color: colors.gray,
    marginBottom: 20,
  },
  imageSection: {
    marginBottom: 20,
  },
  videoSection: {
    marginBottom: 20,
  },
  videoControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  preview: {
    width: '100%',
    height: 300,
    marginBottom: 10,
    borderRadius: 5,
    backgroundColor: '#f5f5f5',
  },
  videoPreview: {
    width: '100%',
    maxHeight: 300,
    marginBottom: 10,
    borderRadius: 5,
    backgroundColor: '#f5f5f5',
  },
  fileInput: {
    marginBottom: 20,
    flex: 1,
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  button: {
    minWidth: 150,
  },
  deleteButton: {
    minWidth: 140,
  },
  uploadButton: {
    minWidth: 120,
  },
  errorContainer: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  errorText: {
    color: colors.error,
    fontSize: 14,
  },
  contentSection: {
    marginBottom: 30,
    backgroundColor: '#f9f9f9',
    padding: 20,
    borderRadius: 8,
  },
  subsectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 15,
    color: colors.primary.solid,
  },
  serviceCard: {
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 8,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  serviceCardTitle: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 15,
    color: '#333',
    borderBottom: '1px solid #eee',
    paddingBottom: 8,
  },
  bulletPointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  bulletPointInput: {
    marginBottom: 5,
  },
  sectionHeaderWithButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    width: '100%',
  },
  serviceCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: 15,
    borderBottom: '1px solid #eee',
    paddingBottom: 8,
  },
  heroContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    flexWrap: 'wrap',
  },
  heroImageSection: {
    flex: 1,
    minWidth: 300,
  },
  heroTextSection: {
    flex: 2,
    minWidth: 300,
  },
  serviceContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  serviceImageSection: {
    flex: 1,
    minWidth: 250,
  },
  serviceTextSection: {
    flex: 2,
    minWidth: 300,
  },
  serviceImagePreview: {
    height: 200,
    marginBottom: 10,
  },
  bulletPointsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 15,
    marginTop: 10,
  },
  bulletPointInput: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: colors.gray.light,
    borderRadius: 5,
    fontSize: 14,
    marginBottom: 0,
  },
  addServiceButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 20,
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  announcementContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    flexWrap: 'wrap',
  },
  announcementTextSection: {
    flex: 2,
    minWidth: 300,
  },
  announcementImageSection: {
    flex: 1,
    minWidth: 250,
  },
  imagePreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    minHeight: 60,
  },
  featureCard: {
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 8,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  featureIndex: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 15,
    color: '#333',
    borderBottom: '1px solid #eee',
    paddingBottom: 8,
  },
  featureContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    flexWrap: 'wrap',
  },
  featureIconSection: {
    flex: 1,
    minWidth: 250,
  },
  featureTextSection: {
    flex: 2,
    minWidth: 300,
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: 10,
    cursor: 'pointer',
    fontSize: 14,
  },
  label: {
    fontWeight: '600',
    marginBottom: 5,
  },
  helperText: {
    fontSize: 12,
    color: colors.gray,
  },
  formRow: {
    marginBottom: 15,
  },
  formColumn: {
    flex: 1,
  },
  recommendationCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  recommendationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    borderBottom: '1px solid #eee',
    paddingBottom: 10,
  },
  recommendationTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: colors.primary.solid,
  },
  conditionsSection: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f9f9f9',
    borderRadius: 6,
  },
  productsSection: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f9f9f9',
    borderRadius: 6,
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  select: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '14px',
    backgroundColor: 'white'
  }
}); 