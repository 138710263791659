import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { HeaderBar } from '../components/HeaderBar';
import { FadeInView } from '../components/FadeInView';
import { useCart } from '../context/CartContext';
import { colors, typography } from '../styles/SharedStyles';
import { Footer } from '../components/Footer';
// Import Firebase
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
// Keep these as fallback images
import burglaryIntrusion from '../assets/images/thief.jpeg';
import visualVerification from '../assets/images/visual_monitoring.jpg';
import accessControl from '../assets/images/access_control.jpg';
import vgmImage from '../assets/images/VGM.png';
import securityImage from '../assets/images/visual_monitoring.jpg'; // Using this for hero section, replace with actual image if needed
import { usePageTitle } from '../hooks/usePageTitle';
import styled from 'styled-components';

// Hero Section Styled Components
const SectionContainer = styled.section`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  height: 550px;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 60px auto 80px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  font-family: 'Tahoma', sans-serif;
  animation: cardAppear 1.2s ease-out forwards;
  
  @keyframes cardAppear {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 991px) {
    max-width: 991px;
    flex-direction: column;
    height: auto;
    margin: 40px 20px 60px;
    width: auto;
  }

  @media (max-width: 640px) {
    max-width: 640px;
    margin: 30px 15px 40px;
    border-radius: 12px;
  }

  @media (max-width: 480px) {
    margin: 20px 10px 30px;
    border-radius: 10px;
  }
`;

const ImageSection = styled.section`
  width: 50%;
  height: 100%;
  font-family: 'Tahoma', sans-serif;
  animation: imageReveal 1.5s ease-out forwards;
  
  @keyframes imageReveal {
    from {
      opacity: 0.6;
      transform: scale(1.05);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 640px) {
    height: 200px;
  }
`;

const SecurityImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentWrapper = styled.section`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: 'Tahoma', sans-serif;

  @media (max-width: 991px) {
    width: 100%;
    padding: 30px 0px;
  }
`;

const ContentContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 550px;
  padding: 0 30px;
  font-family: 'Tahoma', sans-serif;
  animation: contentFadeIn 1.8s ease-out forwards;
  
  @keyframes contentFadeIn {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 991px) {
    padding: 0 0px;
  }
`;

const Title = styled.h2`
  font-family: "Tahoma", sans-serif;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  color: #e71c24;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 48px;
  }

  @media (max-width: 640px) {
    font-size: 36px;
  }
`;

const Description = styled.p`
  font-family: "Tahoma", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  color: #000;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 16px;
  }

  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  font-family: 'Tahoma', sans-serif;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButton = styled.button`
  padding: 14px 50px;
  min-width: 200px;
  border-radius: 6px;
  background: ${props => props.primary ? 'linear-gradient(0deg, #e71c24 0%, #912528 100%)' : 'white'};
  border: 2px solid #e71c24;
  color: ${props => props.primary ? 'white' : '#e71c24'};
  font-family: "Tahoma", sans-serif;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
  box-shadow: ${props => props.primary ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none'};

  &:hover {
    background: ${props => props.primary ? 'linear-gradient(0deg, #c41017 0%, #7e1f22 100%)' : '#f8f8f8'};
    transform: translateY(-2px);
  }

  @media (max-width: 991px) {
  padding: 14px 40px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
  }

  @media (max-width: 640px) {
    font-size: 15px;
    padding: 12px;
  }
`;

// Styled components for the accordion
const AccordionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(580px, 1fr));
  gap: 40px;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0px;
  }
`;

const AccordionCard = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  height: ${props => props.isOpen ? '900px' : '550px'}; /* Increased height for expanded state */
  position: relative;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-family: 'Tahoma', sans-serif;
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  }
  
  @media (max-width: 768px) {
    height: ${props => props.isOpen ? '900px' : '500px'};
    margin-bottom: 30px;
    
    &:hover {
      transform: translateY(-5px);
    }
  }
  
  @media (max-width: 480px) {
    height: ${props => props.isOpen ? '900px' : '450px'};
    margin-bottom: 25px;
    border-radius: 10px;
  }
`;

const AccordionImageContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    height: 250px;
  }
  
  @media (max-width: 480px) {
    height: 200px;
  }
`;

const AccordionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
  
  ${AccordionCard}:hover & {
    transform: scale(1.08);
  }
`;

const AccordionShortDescription = styled.p`
  color: #333;
  font-size: 18px;
  line-height: 1.7;
  margin: 0 0 15px 0;
  padding: 20px 24px 10px;
  font-family: 'Tahoma', sans-serif;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.6;
    padding: 15px 20px 8px;
  }
  
  @media (max-width: 480px) {
    font-size: 15px;
    padding: 12px 15px 6px;
    line-height: 1.5;
  }
`;

const AccordionBulletPoints = styled.ul`
  list-style-type: none;
  padding: 0 24px;
  margin: 0 0 70px 0; /* Add space for the header at bottom */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    padding: 0 20px;
    margin: 0 0 60px 0;
  }
  
  @media (max-width: 480px) {
    padding: 0 15px;
    margin: 0 0 50px 0;
  }
`;

const AccordionBulletItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 15px 10px 0;
  font-size: 17px;
  font-family: 'Tahoma', sans-serif;
  color: #555;
  
  &:before {
    content: "•";
    color: ${colors.primary.solid};
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    margin-right: 8px;
  }
  
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0 12px 8px 0;
    
    &:before {
      font-size: 16px;
      margin-right: 6px;
    }
  }
  
  @media (max-width: 480px) {
    font-size: 15px;
    margin: 0 10px 6px 0;
    
    &:before {
      font-size: 15px;
      margin-right: 5px;
    }
  }
`;

const AccordionHeader = styled.div`
  padding: 18px 24px 18px 24px;
  cursor: pointer;
  background: linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: ${props => props.isOpen ? 'relative' : 'absolute'};
  bottom: ${props => props.isOpen ? 'auto' : '0'};
  left: 0;
  right: 0;
  width: 100%;
  z-index: 20; /* Ensure it's above other content */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    padding: 15px 20px;
  }
  
  @media (max-width: 480px) {
    padding: 12px 15px;
  }
`;

const AccordionTitle = styled.h3`
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  font-family: 'Tahoma', sans-serif;
  flex: 1; /* Allow title to take available space but not push icon off screen */
  padding-right: 15px; /* Add some space between title and icon */
  
  @media (max-width: 768px) {
    font-size: 20px;
    padding-right: 10px;
  }
  
  @media (max-width: 480px) {
    font-size: 18px;
    padding-right: 8px;
  }
`;

const AccordionIcon = styled.span`
  color: white;
  font-size: 28px;
  font-weight: bold;
  transition: transform 0.3s ease;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  flex-shrink: 0; /* Prevent the icon from shrinking */
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    font-size: 24px;
    width: 32px;
    height: 32px;
  }
  
  @media (max-width: 480px) {
    font-size: 20px;
    width: 28px;
    height: 28px;
  }
`;

const AccordionContent = styled.div`
  padding: 0;
  flex: 1;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0.3s;
  position: relative;
  width: 100%;
  background: white;
  z-index: 10;
  box-shadow: ${props => props.isOpen ? '0 6px 12px rgba(0, 0, 0, 0.1)' : 'none'};
  border-radius: 0 0 12px 12px;
  opacity: ${props => props.isOpen ? '1' : '0'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  display: ${props => props.isOpen ? 'block' : 'none'};
  font-family: 'Tahoma', sans-serif;
`;

const AccordionBody = styled.div`
  padding: 24px;
  height: 100%;
  overflow-y: visible; /* Changed from auto to visible */
  max-height: none; /* Removed fixed height constraint */
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    padding: 20px;
  }
  
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const AccordionDescription = styled.p`
  color: #333;
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 25px;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
`;

const AccordionFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: ${props => props.horizontal ? 'flex' : 'block'};
  flex-wrap: ${props => props.horizontal ? 'wrap' : 'nowrap'};
  gap: ${props => props.horizontal ? '10px' : '0'};
  font-family: 'Tahoma', sans-serif;
`;

const AccordionFeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  font-size: 17px;
  margin-right: ${props => props.horizontal ? '20px' : '0'};
  flex: ${props => props.horizontal ? '0 1 auto' : '1 0 100%'};
  font-family: 'Tahoma', sans-serif;
  
  &:before {
    content: "•";
    color: ${colors.primary.solid};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-right: 12px;
    flex-shrink: 0;
  }
`;

// Fix the React Native Text component in the accordion
const KeyFeaturesHeading = styled.h4`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    font-size: 17px;
    margin-bottom: 8px;
  }
  
  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 6px;
  }
`;

// Accordion component
const Accordion = ({ title, description, shortDescription, image, bulletPoints }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  // Split the description into paragraphs
  const paragraphs = description.split('\n\n').filter(p => p.trim() !== '');
  
  // The first paragraph is the main description
  const mainDescription = paragraphs[0] || description;
  
  // Use provided shortDescription or create one from the description
  const displayShortDescription = shortDescription || (mainDescription.split('. ')[0] + '.');
  
  // Extract features from the remaining paragraphs if they exist
  const features = paragraphs.length > 1 ? 
    paragraphs.slice(1).join(' ').split('. ').filter(f => f.trim() !== '') : 
    [];
    
  const toggleAccordion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
    
  return (
    <AccordionCard isOpen={isOpen} onClick={toggleAccordion}>
      <AccordionImageContainer>
        <AccordionImage src={image} alt={title} />
      </AccordionImageContainer>
      {!isOpen && (
        <>
          <AccordionShortDescription>{displayShortDescription}</AccordionShortDescription>
          <AccordionBulletPoints>
            {bulletPoints.slice(0, 6).map((point, index) => (
              <AccordionBulletItem key={index}>{point}</AccordionBulletItem>
            ))}
          </AccordionBulletPoints>
        </>
      )}
      <AccordionHeader isOpen={isOpen}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon isOpen={isOpen}>{isOpen ? '▲' : '▼'}</AccordionIcon>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        <AccordionBody>
          <AccordionDescription>{mainDescription}</AccordionDescription>
          
          {/* Always show bullet points in expanded view - now horizontal */}
          <KeyFeaturesHeading>Key Features:</KeyFeaturesHeading>
          <AccordionFeatures horizontal={true}>
            {bulletPoints.slice(0, 6).map((point, index) => (
              <AccordionFeatureItem key={index} horizontal={true}>{point}</AccordionFeatureItem>
            ))}
          </AccordionFeatures>
          
          {/* Show additional features if they exist */}
          {features.length > 0 && (
            <>
              <KeyFeaturesHeading style={{ marginTop: '20px' }}>Additional Details:</KeyFeaturesHeading>
              <AccordionFeatures>
        {features.map((feature, index) => (
                  <AccordionFeatureItem key={index}>{feature}</AccordionFeatureItem>
                ))}
              </AccordionFeatures>
            </>
          )}
        </AccordionBody>
      </AccordionContent>
    </AccordionCard>
  );
};

const PageContainer = styled.div`
  background-color: #ECECEC;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 60px;
  font-family: 'Tahoma', sans-serif;
  overflow-x: hidden;
`;

const ServicesContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  font-family: 'Tahoma', sans-serif;
`;

const SectionTitle = styled.h2`
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
  color: #000000;
  font-family: 'Tahoma', sans-serif;
`;

const CTASection = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    margin-top: 60px;
    padding: 0 15px;
  }
  
  @media (max-width: 480px) {
    margin-top: 40px;
    padding: 0 10px;
  }
`;

const CTAText = styled.h3`
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  max-width: 900px;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 30px;
    letter-spacing: 1px;
  }
  
  @media (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 25px;
    letter-spacing: 0.8px;
  }
`;

const CTAButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const CTAButton = styled.button`
  padding: 18px 40px;
  min-width: 250px;
  border-radius: 8px;
  background: linear-gradient(0deg, #e71c24 0%, #912528 100%);
  border: none;
  color: white;
  font-family: 'Tahoma', sans-serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 2.4px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(0deg, #c41017 0%, #7e1f22 100%);
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    min-width: 280px;
    font-size: 18px;
    padding: 16px 30px;
    letter-spacing: 2px;
  }
  
  @media (max-width: 480px) {
    min-width: 240px;
    font-size: 16px;
    padding: 14px 25px;
    letter-spacing: 1.5px;
  }
`;

export default function ServicesPage() {
  const navigate = useNavigate();
  const { addToCart } = useCart();
  usePageTitle('Our Services | Security Central Protection');

  // State for storing images from Firebase
  const [pageImages, setPageImages] = useState({
    heroImage: securityImage,
    burglaryIntrusionImage: burglaryIntrusion,
    visualVerificationImage: visualVerification,
    accessControlImage: accessControl,
    vgmImage: vgmImage,
    service5Image: securityImage,
    service6Image: securityImage
  });

  // Add state for content
  const [pageContent, setPageContent] = useState({
    heroTitle: 'Our Services',
    heroDescription: 'Since 1984, Security Central Protection has delivered comprehensive security solutions tailored for residential, commercial, and industrial needs. From state-of-the-art home automation to robust burglary protection and life-saving fire safety systems, we provide end-to-end security integration. Our AI-powered solutions ensure your property is protected 24/7',
    services: []
  });

  // Fetch images from Firebase on component mount
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'siteSettings', 'servicesPage'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          
          // Set images
          setPageImages({
            heroImage: data.heroImage || securityImage,
            burglaryIntrusionImage: data.burglaryIntrusionImage || burglaryIntrusion,
            visualVerificationImage: data.visualVerificationImage || visualVerification,
            accessControlImage: data.accessControlImage || accessControl,
            vgmImage: data.vgmImage || vgmImage,
            service5Image: data.service5Image || securityImage,
            service6Image: data.service6Image || securityImage
          });
          
          // Set content if available
          if (data.content) {
            setPageContent({
              heroTitle: data.content.heroTitle || 'Our Services',
              heroDescription: data.content.heroDescription || 'Since 1984, Security Central Protection has delivered comprehensive security solutions tailored for residential, commercial, and industrial needs.',
              services: data.content.services || []
            });
          } else {
            // Use default services if no custom content
            setPageContent(prev => ({
              ...prev,
              services: defaultServices
            }));
          }
        }
      } catch (err) {
        console.error('Error fetching service page content:', err);
        // Use default services if there's an error
        setPageContent(prev => ({
          ...prev,
          services: defaultServices
        }));
      }
    };

    fetchContent();
  }, []);

  // Default services data as fallback
  const defaultServices = [
    {
      id: 1,
      title: 'Burglary Intrusion Protection',
      shortDescription: 'The Burglary Intrusion Protection service offers comprehensive security for homes and businesses.',
      fullDescription: 'The Burglary Intrusion Protection service offers comprehensive security for homes and businesses. Featuring state-of-the-art alarm systems with motion, door/window, and glass break detectors. 24/7 professional monitoring with rapid response to breaches, instant mobile alerts for unusual activity, remote system control via a mobile app or online portal, quick emergency response to notify authorities or send assistance, and scalable and customizable packages to fit various property sizes and security needs.',
      image: pageImages.burglaryIntrusionImage,
      bulletPoints: ['Motion detection', 'Door/window sensors', 'Glass break detection', '24/7 monitoring', 'Mobile alerts', 'Remote control']
    },
    {
      id: 2,
      title: 'Commercial Surveillance System',
      shortDescription: 'The Commercial Surveillance System is a comprehensive, all-in-one solution designed to ensure business safety and security.',
      fullDescription: 'The Commercial Surveillance System is a comprehensive, all-in-one solution designed to ensure business safety and security. Offering features such as a powerful Network Video Recorder (NVR) for high-definition footage storage, high-resolution, weatherproof cameras with motion detection, professional installation, and optional 24/7 interactive live camera monitoring. Key benefits include high-quality video with HD and 4K options, remote access via mobile or desktop applications, motion detection alerts, and scalability for business growth, making it a reliable and customizable solution for protecting any commercial space.',
      image: pageImages.visualVerificationImage,
      bulletPoints: ['HD video quality', 'NVR storage', 'Motion detection', 'Remote access', 'Weatherproof cameras', 'Professional installation']
    },
    {
      id: 3,
      title: 'Access Control Installation and Services',
      shortDescription: 'Access Control Installation and Services offer advanced security solutions for various property types.',
      fullDescription: 'Access Control Installation and Services offer advanced security solutions for various property types. Key features include comprehensive security systems like keycard, biometric, mobile-based, and PIN code entry. Seamless integration with existing infrastructure and other security systems, user-friendly management software, remote access and monitoring capabilities, ongoing support and maintenance, custom solutions tailored to specific needs, and secure, reliable systems with fail-safe measures. The services emphasize expertise in design and installation, top-quality hardware and software, professional service teams, and a commitment to enhancing security and convenience.',
      image: pageImages.accessControlImage,
      bulletPoints: ['Keycard & biometric', 'User management', 'Remote access', 'Custom solutions', 'Ongoing support', 'Seamless integration']
    },
    {
      id: 4,
      title: 'Virtual Guard Service',
      shortDescription: 'The Virtual Guard Service provides 24/7 remote security solutions with real-time monitoring and intelligent surveillance.',
      fullDescription: 'The Virtual Guard Service provides 24/7 remote security solutions with real-time monitoring and intelligent surveillance. Trained operators actively engage with potential intruders via audio talk-down, preventing break-ins and responding to suspicious activity. This service offers interactive monitoring, immediate action in emergencies, and is suitable for both residential and commercial properties, blending technology and human expertise for comprehensive security.',
      image: pageImages.vgmImage,
      bulletPoints: ['24/7 monitoring', 'Audio talk-down', 'Real-time response', 'Intelligent surveillance', 'Trained operators', 'Emergency action']
    },
    {
      id: 5,
      title: 'Fire Safety Systems',
      shortDescription: 'Our Fire Safety Systems provide comprehensive protection against fire hazards for residential and commercial properties.',
      fullDescription: 'Our Fire Safety Systems provide comprehensive protection against fire hazards for residential and commercial properties. These systems include advanced smoke and heat detectors, sprinkler systems, fire alarms, and emergency lighting. We offer professional installation, regular maintenance, and 24/7 monitoring to ensure your property is protected at all times. Our systems are designed to meet all local fire codes and regulations, providing peace of mind for property owners.',
      image: pageImages.service5Image || securityImage,
      bulletPoints: ['Smoke detection', 'Heat sensors', 'Sprinkler systems', 'Emergency lighting', '24/7 monitoring', 'Code compliance']
    },
    {
      id: 6,
      title: 'Smart Home Integration',
      shortDescription: 'Our Smart Home Integration services connect your security system with other smart devices for a seamless experience.',
      fullDescription: 'Our Smart Home Integration services connect your security system with other smart devices for a seamless experience. Control your entire home security system from your smartphone, tablet, or voice assistant. Integrate your security cameras, door locks, lighting, thermostats, and more into one easy-to-use platform. Receive real-time alerts and notifications, and access live video feeds from anywhere in the world. Our professional installation ensures all components work together flawlessly.',
      image: pageImages.service6Image || securityImage,
      bulletPoints: ['Mobile control', 'Voice assistant', 'Camera integration', 'Smart locks', 'Lighting control', 'Real-time alerts']
    }
  ];

  // Prepare services data with images
  const services = pageContent.services.length > 0 ? 
    pageContent.services.map((service, index) => {
      // Map service content to images based on index
      let image;
      switch(index) {
        case 0:
          image = pageImages.burglaryIntrusionImage;
          break;
        case 1:
          image = pageImages.visualVerificationImage;
          break;
        case 2:
          image = pageImages.accessControlImage;
          break;
        case 3:
          image = pageImages.vgmImage;
          break;
        case 4:
          image = pageImages.service5Image || securityImage;
          break;
        case 5:
          image = pageImages.service6Image || securityImage;
          break;
        default:
          image = securityImage;
      }
      
      // Use default bullet points if not provided
      const bulletPoints = service.bulletPoints || defaultServices[index]?.bulletPoints || [];
      
      return {
        ...service,
        image,
        bulletPoints
      };
    }) : 
    defaultServices;

  return (
    <>
      <HeaderBar />
      <PageContainer>
        <link
          href="https://fonts.googleapis.com/css2?family=Tahoma:wght@400;700&display=swap"
          rel="stylesheet"
        />

          {/* Hero Section */}
        <SectionContainer>
          <ImageSection>
            <SecurityImage
              src={pageImages.heroImage}
              alt="Security devices and tablet showing security camera feeds"
            />
          </ImageSection>
          <ContentWrapper>
            <ContentContainer>
              <Title>{pageContent.heroTitle}</Title>
              <Description>
                {pageContent.heroDescription}
              </Description>
              <ButtonGroup>
                <StyledButton primary onClick={() => navigate('/packages')}>Shop Packages</StyledButton>
                <StyledButton primary onClick={() => navigate('/build')}>Build a System</StyledButton>
              </ButtonGroup>
            </ContentContainer>
          </ContentWrapper>
        </SectionContainer>

        {/* Services Section */}
        <ServicesContainer>
          <SectionTitle>Explore Our Security Solutions</SectionTitle>
          <AccordionGrid>
            {services.map((service, index) => (
              <FadeInView key={service.id} duration={800} direction="up" delay={200 + (index * 200)} style={{ position: 'relative' }}>
                <Accordion 
                  title={service.title} 
                  description={service.fullDescription}
                  shortDescription={service.shortDescription}
                  image={service.image}
                  bulletPoints={service.bulletPoints}
                />
                        </FadeInView>
                      ))}
          </AccordionGrid>

          {/* CTA Section */}
          <FadeInView duration={800} direction="up" delay={1000}>
            <CTASection>
              <CTAText>
                Ready to secure your property with our professional services?
              </CTAText>
              <CTAButtonGroup>
                <CTAButton onClick={() => navigate('/packages')}>
                  Shop Packages
                </CTAButton>
                <CTAButton onClick={() => navigate('/build')}>
                  Build Your System
                </CTAButton>
              </CTAButtonGroup>
            </CTASection>
            </FadeInView>
        </ServicesContainer>
      </PageContainer>
        <Footer />
    </>
  );
} 