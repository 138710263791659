import { getRemoteConfig, getValue } from 'firebase/remote-config';
import { app } from './firebase';

// Initialize Remote Config
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 hour

// Function to determine if we're in a test environment
const isTestEnvironment = async () => {
  try {
    // Check for development environment
    if (process.env.NODE_ENV === 'development') return true;
    
    // Check for staging/localhost
    const hostname = window.location.hostname;
    if (hostname.includes('staging') || hostname.includes('localhost')) return true;

    // Get mode from Remote Config
    console.log('Checking environment...');
    const stripeMode = await getValue(remoteConfig, 'stripe_mode');
    console.log('Stripe mode:', stripeMode.asString());
    return stripeMode.asString() === 'test';
  } catch (error) {
    console.error('Error checking environment:', error);
    // Default to test mode if there's an error
    return true;
  }
};

// Export the Stripe configuration
export const STRIPE_CONFIG = {
  publishableKey: isTestEnvironment()
    ? 'pk_test_51QorOJJWFhncz4I5mRdIoeInJKzuQWtoTCo1PvT4bqgHY4x1izU0KnOr0sqOia9zxeR41FQTbcmaXw4M4XU6PdEO00WPxAMgw3'
    : 'pk_live_51QorOJJWFhncz4I5jK6LZDnSCBxo9IuemCaFP2biFqOeCmT3vyz0sCUJh8g8mOXvhlamFMrEnzvkxVgegWoR743x003ufpCAMQ'
};

// Function to update Stripe mode
export const updateStripeMode = async (mode) => {
  try {
    const configRef = doc(db, 'config', 'stripe');
    await setDoc(configRef, { mode }, { merge: true });
    return true;
  } catch (error) {
    console.error('Error updating Stripe mode:', error);
    return false;
  }
};

export const getStripeMode = () => isTestEnvironment() ? 'test' : 'live';

// Export the API base URL based on environment
export const getApiBaseUrl = () => {
  const hostname = window.location.hostname;
  if (process.env.NODE_ENV === 'development') {
    return 'http://127.0.0.1:5002/security-central-protection/us-central1';
  } else if (hostname.includes('staging')) {
    return 'https://us-central1-security-central-protection.cloudfunctions.net';
  } else {
    return 'https://us-central1-security-central-protection.cloudfunctions.net';
  }
}; 