import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { colors, typography } from '../styles/SharedStyles';
import { GradientButton } from '../components/GradientButton';
import styled from 'styled-components';
import { isDevelopment } from '../utils/environment';
import { emailService } from '../services/emailService';
import { functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import { app } from '../config/firebase';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

// Admin Components
import { ProductManager } from '../components/admin/ProductManager';
import { PackageBuilder } from '../components/admin/PackageBuilder';
import { AnnouncementManager } from '../components/admin/AnnouncementManager';
import { MessageManager } from '../components/admin/MessageManager';
import { SalesAnalytics } from '../components/admin/SalesAnalytics';
import { SiteManagement } from '../components/admin/SiteManagement';
import { FulfillmentManager } from '../components/admin/FulfillmentManager';
import { SystemBuilder } from '../components/admin/SystemBuilder';
import { SupportManager } from '../components/admin/SupportManager';
import { BlogManager } from '../components/admin/BlogManager';
import { UsersManager } from '../components/admin/UsersManager';
import AdminLoginForm from '../components/admin/AdminLoginForm';

const NavGroup = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const NavDivider = styled.div`
  width: 1px;
  height: 30px;
  background: ${colors.gray};
  opacity: 0.3;
  margin: 0 10px;
`;

const AdminNav = ({ navigate }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Update page title based on current path
  useEffect(() => {
    let title = 'Admin Dashboard | Security Central Protection';
    if (currentPath.includes('/sales')) {
      title = 'Admin - Sales Analytics';
    } else if (currentPath.includes('/fulfillment')) {
      title = 'Admin - Order Fulfillment';
    } else if (currentPath.includes('/products')) {
      title = 'Admin - Products';
    } else if (currentPath.includes('/packages')) {
      title = 'Admin - Package Builder';
    } else if (currentPath.includes('/system-builder')) {
      title = 'Admin - System Builder';
    } else if (currentPath.includes('/announcements')) {
      title = 'Admin - Announcements';
    } else if (currentPath.includes('/messages')) {
      title = 'Admin - Messages';
    } else if (currentPath.includes('/site-management')) {
      title = 'Admin - Site Content';
    } else if (currentPath.includes('/blog')) {
      title = 'Admin - Blog';
    }
    document.title = title;
  }, [currentPath]);

  return (
    <View style={styles.adminNav}>
      <Text style={styles.adminTitle}>Admin Dashboard</Text>
      <View style={styles.navLinks}>
        {/* Sales & Orders Group */}
        <NavGroup>
          <GradientButton
            onPress={() => navigate('/admin/sales')}
            text="Sales Analytics"
            variant={currentPath.includes('/sales') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/sales') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/fulfillment')}
            text="Order Fulfillment"
            variant={currentPath.includes('/fulfillment') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/fulfillment') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
        </NavGroup>

        <NavDivider />

        {/* Products & Systems Group */}
        <NavGroup>
          <GradientButton
            onPress={() => navigate('/admin/products')}
            text="Products"
            variant={currentPath.includes('/products') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/products') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/packages')}
            text="Package Builder"
            variant={currentPath.includes('/packages') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/packages') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/system-builder')}
            text="System Builder"
            variant={currentPath.includes('/system-builder') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/system-builder') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
        </NavGroup>

        <NavDivider />

        {/* Content Management Group */}
        <NavGroup>
          <GradientButton
            onPress={() => navigate('/admin/announcements')}
            text="Announcements"
            variant={currentPath.includes('/announcements') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/announcements') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/messages')}
            text="Messages"
            variant={currentPath.includes('/messages') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/messages') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/site-content')}
            text="Site Content"
            variant={currentPath.includes('/site-content') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/site-content') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/site-management')}
            text="Site Management"
            variant={currentPath.includes('/site-management') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/site-management') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/support')}
            text="Support"
            variant={currentPath.includes('/support') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/support') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/blog')}
            text="Blog"
            variant={currentPath.includes('/blog') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/blog') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/users')}
            text="Users"
            variant={currentPath.includes('/users') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/users') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
        </NavGroup>
      </View>
    </View>
  );
};

const Welcome = () => (
  <View style={styles.welcome}>
    <Text style={styles.welcomeTitle}>Welcome to Admin Dashboard</Text>
    <Text style={styles.welcomeText}>
      Select a section from the navigation menu to get started.
    </Text>
  </View>
);

const SITE_MANAGEMENT_PASSWORD = 'Newtjhawk27';

const EmailTestingSection = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('ORDER_CONFIRMATION');
  const [testEmail, setTestEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [includeInstallation, setIncludeInstallation] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  });
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  // Fetch products when component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, 'products');
        const q = query(productsRef, orderBy('name'));
        const querySnapshot = await getDocs(q);
        const productsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(productsList);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const templates = {
    ORDER_CONFIRMATION: {
      name: 'Order Confirmation',
      sampleData: {
        id: 'TEST-' + Math.random().toString(36).substr(2, 9),
        total: 0,
        items: [],
        shipping: {
          name: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          phone: ''
        },
        delivery: {
          type: 'shipping'
        }
      }
    },
    CONTACT_FORM_USER: {
      name: 'Contact Form - User Confirmation',
      sampleData: {
        name: '',
        message: ''
      }
    },
    CONTACT_FORM_ADMIN: {
      name: 'Contact Form - Admin Notification',
      sampleData: {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProducts(prev => [...prev, {
      ...product,
      quantity: 1,
      price: product.price || 0
    }]);
  };

  const handleProductRemove = (index) => {
    setSelectedProducts(prev => prev.filter((_, i) => i !== index));
  };

  const handleQuantityChange = (index, value) => {
    setSelectedProducts(prev => prev.map((product, i) => 
      i === index ? { ...product, quantity: parseInt(value) || 1 } : product
    ));
  };

  const handleCustomerInfoChange = (field, value) => {
    setCustomerInfo(prev => ({ ...prev, [field]: value }));
  };

  const calculateTotal = () => {
    return selectedProducts.reduce((sum, product) => 
      sum + (product.price * product.quantity), 0
    );
  };

  const handleSendTest = async () => {
    if (!testEmail) {
      setError('Please enter a test email address');
      return;
    }

    setIsSending(true);
    setError(null);
    setResult(null);

    try {
      const functionsInstance = isDevelopment() 
        ? getFunctions(app, 'http://127.0.0.1:5002/security-central-protection/us-central1')
        : functions;

      const sendTestEmail = httpsCallable(functionsInstance, 'sendTestEmail', {
        timeout: 60000,
        httpHeaders: {
          'Content-Type': 'application/json',
        }
      });
      
      // Create test data based on template
      let testData = { ...templates[selectedTemplate].sampleData };
      
      if (selectedTemplate === 'ORDER_CONFIRMATION') {
        testData = {
          ...testData,
          total: calculateTotal(),
          items: selectedProducts,
          shipping: customerInfo,
          delivery: {
            type: includeInstallation ? 'installation' : 'shipping'
          }
        };
      } else if (selectedTemplate === 'CONTACT_FORM_USER') {
        testData = {
          name: customerInfo.name,
          message: 'Test message from contact form'
        };
      } else if (selectedTemplate === 'CONTACT_FORM_ADMIN') {
        testData = {
          name: customerInfo.name,
          email: testEmail,
          phone: customerInfo.phone,
          message: 'Test message from contact form'
        };
      }
      
      const result = await sendTestEmail({
        template: selectedTemplate,
        email: testEmail,
        data: testData
      });
      
      setResult(result.data);
    } catch (error) {
      console.error('Error sending test email:', error);
      setError(error.message || 'Failed to send test email. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <h3 style={{ marginBottom: '20px' }}>Email Template Testing</h3>
      
      <div style={{ 
        background: 'white', 
        padding: '20px', 
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>Select Template:</label>
          <select
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ddd'
            }}
          >
            {Object.entries(templates).map(([key, template]) => (
              <option key={key} value={key}>{template.name}</option>
            ))}
          </select>
        </div>

        {selectedTemplate === 'ORDER_CONFIRMATION' && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <input
                  type="checkbox"
                  checked={includeInstallation}
                  onChange={(e) => setIncludeInstallation(e.target.checked)}
                  style={{ width: '18px', height: '18px' }}
                />
                Include Installation Service
              </label>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <h4 style={{ marginBottom: '10px' }}>Customer Information</h4>
              <div style={{ display: 'grid', gap: '10px' }}>
                <input
                  type="text"
                  placeholder="Name"
                  value={customerInfo.name}
                  onChange={(e) => handleCustomerInfoChange('name', e.target.value)}
                  style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={customerInfo.address}
                  onChange={(e) => handleCustomerInfoChange('address', e.target.value)}
                  style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
                />
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
                  <input
                    type="text"
                    placeholder="City"
                    value={customerInfo.city}
                    onChange={(e) => handleCustomerInfoChange('city', e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    value={customerInfo.state}
                    onChange={(e) => handleCustomerInfoChange('state', e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
                  />
                  <input
                    type="text"
                    placeholder="ZIP"
                    value={customerInfo.zip}
                    onChange={(e) => handleCustomerInfoChange('zip', e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
                  />
                </div>
                <input
                  type="tel"
                  placeholder="Phone"
                  value={customerInfo.phone}
                  onChange={(e) => handleCustomerInfoChange('phone', e.target.value)}
                  style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
                />
              </div>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <h4 style={{ marginBottom: '10px' }}>Add Products</h4>
              <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <select
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                  style={{
                    flex: 1,
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #ddd'
                  }}
                >
                  <option value="">Select a product...</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name} - ${product.price}
                    </option>
                  ))}
                </select>
                <button
                  onClick={() => {
                    if (selectedProduct) {
                      const product = products.find(p => p.id === selectedProduct);
                      if (product) {
                        handleProductSelect(product);
                        setSelectedProduct('');
                      }
                    }
                  }}
                  style={{
                    background: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer'
                  }}
                >
                  Add Product
                </button>
              </div>

              <h4 style={{ marginBottom: '10px' }}>Selected Products</h4>
              {selectedProducts.map((product, index) => (
                <div key={index} style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '10px',
                  marginBottom: '10px',
                  padding: '10px',
                  background: '#f5f5f5',
                  borderRadius: '4px'
                }}>
                  <div style={{ flex: 1 }}>{product.name}</div>
                  <input
                    type="number"
                    value={product.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    style={{ width: '60px', padding: '4px', borderRadius: '4px', border: '1px solid #ddd' }}
                  />
                  <div style={{ width: '80px' }}>${(product.price * product.quantity).toFixed(2)}</div>
                  <button
                    onClick={() => handleProductRemove(index)}
                    style={{
                      background: '#ff4444',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      padding: '4px 8px',
                      cursor: 'pointer'
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                Total: ${calculateTotal().toFixed(2)}
              </div>
            </div>
          </>
        )}

        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>Test Email Address:</label>
          <input
            type="email"
            value={testEmail}
            onChange={(e) => setTestEmail(e.target.value)}
            placeholder="Enter email address for testing"
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ddd'
            }}
          />
        </div>

        <button
          onClick={handleSendTest}
          disabled={isSending}
          style={{
            background: '#e71c24',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: isSending ? 'not-allowed' : 'pointer',
            opacity: isSending ? 0.7 : 1
          }}
        >
          {isSending ? 'Sending...' : 'Send Test Email'}
        </button>

        {error && (
          <div style={{ 
            color: 'red', 
            marginTop: '15px',
            padding: '10px',
            background: '#fff5f5',
            borderRadius: '4px'
          }}>
            {error}
          </div>
        )}

        {result && (
          <div style={{ 
            color: 'green', 
            marginTop: '15px',
            padding: '10px',
            background: '#f0fff4',
            borderRadius: '4px'
          }}>
            Test email sent successfully!
          </div>
        )}
      </div>
    </div>
  );
};

const SiteManagementTab = () => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState('');
  const [stripeMode, setStripeMode] = useState('test');
  const [isUpdating, setIsUpdating] = useState(false);
  const [skipAuth, setSkipAuth] = useState(false);
  const [requireDevLogin, setRequireDevLogin] = useState(false);

  useEffect(() => {
    const fetchStripeMode = async () => {
      const mode = await getStripeMode();
      setStripeMode(mode);
    };
    fetchStripeMode();
  }, []);

  const handleStripeToggle = async () => {
    setIsUpdating(true);
    try {
      const newMode = stripeMode === 'test' ? 'live' : 'test';
      const success = await updateStripeMode(newMode);
      if (success) {
        setStripeMode(newMode);
        alert(`Successfully switched to ${newMode.toUpperCase()} mode`);
      } else {
        throw new Error('Failed to update Stripe mode');
      }
    } catch (error) {
      alert('Error updating Stripe mode: ' + error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === SITE_MANAGEMENT_PASSWORD) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect password');
    }
  };

  // Skip authentication in development if toggle is on
  if (!isDevelopment() || !skipAuth) {
    if (!isAuthenticated) {
      return (
        <div style={{ maxWidth: '400px', margin: '40px auto', padding: '20px' }}>
          <h2 style={{ marginBottom: '20px' }}>Site Management Access</h2>
          <form onSubmit={handlePasswordSubmit}>
            <div style={{ marginBottom: '20px' }}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
            </div>
            {error && (
              <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>
            )}
            <button
              type="submit"
              style={{
                background: '#e71c24',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Access Site Management
            </button>
          </form>
        </div>
      );
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ marginBottom: '30px' }}>Site Management</h2>
      
      {isDevelopment() && (
        <div style={{ 
          background: 'white', 
          padding: '20px', 
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          marginBottom: '20px'
        }}>
          <h3 style={{ marginBottom: '20px' }}>Development Settings</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <input
                type="checkbox"
                id="skipAuth"
                checked={skipAuth}
                onChange={(e) => setSkipAuth(e.target.checked)}
                style={{ width: '18px', height: '18px' }}
              />
              <label htmlFor="skipAuth" style={{ fontSize: '14px' }}>
                Skip Site Management Authentication (Development Only)
              </label>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <input
                type="checkbox"
                id="requireDevLogin"
                checked={requireDevLogin}
                onChange={(e) => {
                  setRequireDevLogin(e.target.checked);
                  // Save to localStorage to persist through page refresh
                  localStorage.setItem('requireDevLogin', e.target.checked ? 'true' : 'false');
                }}
                style={{ width: '18px', height: '18px' }}
              />
              <label htmlFor="requireDevLogin" style={{ fontSize: '14px' }}>
                Show Admin Login Page in Development Mode
              </label>
            </div>
          </div>
        </div>
      )}
      
      <div style={{ 
        background: 'white', 
        padding: '20px', 
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        marginBottom: '20px'
      }}>
        <h3 style={{ marginBottom: '20px' }}>Stripe Payment Mode</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <div>Current Mode: <strong>{stripeMode.toUpperCase()}</strong></div>
          <button
            onClick={handleStripeToggle}
            disabled={isUpdating}
            style={{
              background: stripeMode === 'live' ? '#28a745' : '#e71c24',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: isUpdating ? 'not-allowed' : 'pointer',
              opacity: isUpdating ? 0.7 : 1
            }}
          >
            {isUpdating ? 'Updating...' : `Switch to ${stripeMode === 'test' ? 'LIVE' : 'TEST'} Mode`}
          </button>
        </div>
      </div>

      <EmailTestingSection />
    </div>
  );
};

export const AdminPage = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [forceLoginScreen, setForceLoginScreen] = useState(false);
  
  useEffect(() => {
    // Check if we should force the login screen in development
    if (isDevelopment()) {
      const requireDevLogin = localStorage.getItem('requireDevLogin') === 'true';
      setForceLoginScreen(requireDevLogin);
      
      // In development, check session storage first
      const authenticated = sessionStorage.getItem('adminAuthenticated') === 'true';
      if (authenticated) {
        setIsAuthenticated(true);
        // If authenticated, turn off the force login screen
        setForceLoginScreen(false);
        localStorage.setItem('requireDevLogin', 'false');
      } else if (!requireDevLogin) {
        // Only auto-authenticate if not forcing login
        setIsAuthenticated(true);
      }
    } else {
      // In production, check session storage
      const authenticated = sessionStorage.getItem('adminAuthenticated') === 'true';
      setIsAuthenticated(authenticated);
    }
  }, []);
  
  // Logout function
  const handleLogout = () => {
    sessionStorage.removeItem('adminAuthenticated');
    setIsAuthenticated(false);
    // Reset the force login screen setting on logout
    if (isDevelopment()) {
      localStorage.setItem('requireDevLogin', 'false');
      setForceLoginScreen(false);
    }
  };

  // Show login page if not authenticated OR if forcing login in dev mode
  if (!isAuthenticated || (forceLoginScreen && !sessionStorage.getItem('adminAuthenticated'))) {
    return (
      <View style={styles.pageContainer}>
        <HeaderBar />
        <ScrollView style={styles.content}>
          <View style={styles.mainContent}>
            <AdminLoginForm onAuthenticated={() => {
              setIsAuthenticated(true);
              setForceLoginScreen(false);
              if (isDevelopment()) {
                localStorage.setItem('requireDevLogin', 'false');
              }
            }} />
          </View>
        </ScrollView>
        <Footer />
      </View>
    );
  }

  return (
    <View style={styles.pageContainer}>
      <HeaderBar />
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <View style={styles.logoutContainer}>
            {/* Show logout button in both production and dev when showing login */}
            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          </View>
          <AdminNav navigate={navigate} />
          <View style={styles.adminContent}>
            <Routes>
              <Route index element={<Welcome />} />
              <Route path="sales" element={<SalesAnalytics />} />
              <Route path="fulfillment" element={<FulfillmentManager />} />
              <Route path="products" element={<ProductManager />} />
              <Route path="packages" element={<PackageBuilder />} />
              <Route path="system-builder" element={<SystemBuilder />} />
              <Route path="announcements" element={<AnnouncementManager />} />
              <Route path="messages" element={<MessageManager />} />
              <Route path="site-content" element={<SiteManagement />} />
              <Route path="site-management" element={<SiteManagementTab />} />
              <Route path="support" element={<SupportManager />} />
              <Route path="blog" element={<BlogManager />} />
              <Route path="users" element={<UsersManager />} />
            </Routes>
          </View>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
};

const LogoutButton = styled.button`
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #eee;
    color: ${colors.primary.solid};
  }
`;

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    minHeight: '100vh',
    width: '100%',
    backgroundColor: 'transparent',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 100,
    paddingBottom: 40,
    minWidth: 1200,
    width: '100%',
    alignSelf: 'center',
  },
  logoutContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  adminNav: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  adminTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  navLinks: {
    flexDirection: 'row',
    gap: 15,
    flexWrap: 'wrap',
  },
  navButton: {
    minWidth: 150,
    height: 40,
    paddingVertical: 8,
  },
  navButtonText: {
    fontSize: 14,
    fontWeight: '600',
  },
  adminContent: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: 30,
    borderRadius: 10,
    minHeight: 500,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  welcome: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  welcomeTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  welcomeText: {
    fontSize: 18,
    color: colors.gray,
    textAlign: 'center',
  },
  authContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  authCard: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 30,
    width: '100%',
    maxWidth: 400,
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  authTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.primary,
    textAlign: 'center',
  },
  authDescription: {
    fontSize: 16,
    color: '#666',
    marginBottom: 20,
    textAlign: 'center',
  },
  authForm: {
    width: '100%',
  },
  errorText: {
    color: 'red',
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'center',
  },
  comingSoonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
  comingSoonTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  comingSoonText: {
    fontSize: 18,
    color: colors.gray,
  },
}); 