import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { colors, breakpoints, createContainerStyle, getResponsiveSpacing } from '../styles/theme';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { PageNavigation, PageHeader } from '../components/PageNavigation';
import StepComponent from '../components/StepComponent';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import BuildProgress from '../components/BuildProgress';
import { usePageTitle } from '../hooks/usePageTitle';
import { DisclaimerMessage } from '../components/DisclaimerMessage';

// Import images (you'll need to add these to your assets)
import haloImage from '../assets/images/HALO.jpg';
import poeImage from '../assets/images/HALO.jpg';
import monitoringImage from '../assets/images/HALO.jpg';
import shippingImage from '../assets/images/HALO.jpg';
import installationImage from '../assets/images/HALO.jpg';
import spaceImage from '../assets/images/residential.jpg';
import residentialImage from '../assets/images/residential.jpg';
import commercialImage from '../assets/images/commercial.jpg';
import industrialImage from '../assets/images/industrial.jpg';
import noCamerasImage from '../assets/images/HALO.jpg';
import selectCamerasImage from '../assets/images/HALO.jpg';
import haloPlusImage from '../assets/images/HALO.jpg';
import haloProImage from '../assets/images/HALO.jpg';
import haloEnterpriseImage from '../assets/images/HALO.jpg';
import poe4Image from '../assets/images/HALO.jpg';
import poe8Image from '../assets/images/HALO.jpg';
import poe16Image from '../assets/images/HALO.jpg';
// Use HALO image for NVR temporarily until a proper image is provided
import nvrImage from '../assets/images/HALO.jpg';

// Import new system type images
import alarmSystemImage from '../assets/images/access_control.jpg';
import cameraSystemImage from '../assets/images/camera_system.jpg';
import alarmAndCamerasImage from '../assets/images/alarms_cameras.webp';

const steps = [
  {
    id: 'space',
    title: 'Premises',
    question: 'What type of premises are you securing?',
    image: spaceImage,
    options: [
      {
        id: 'residential',
        title: 'Residential',
        description: 'Home or Personal Property',
        subtext: 'Perfect for houses, apartments, and small properties',
        price: 0,
        image: residentialImage
      },
      {
        id: 'commercial',
        title: 'Commercial',
        description: 'Business or Office Premises',
        subtext: 'Ideal for retail, offices, and medium-sized properties',
        price: 0,
        image: commercialImage
      },
      {
        id: 'industrial',
        title: 'Industrial',
        description: 'Large Scale Property',
        subtext: 'Best for warehouses, factories, and large facilities',
        price: 0,
        image: industrialImage
      }
    ]
  },
  {
    id: 'system-type',
    title: 'System Type',
    question: 'What type of system are you looking for?',
    image: alarmAndCamerasImage,
    options: [
      {
        id: 'alarm-system',
        title: 'Alarm System',
        description: 'Security Alarm System',
        subtext: 'Motion sensors, door/window contacts, and control panel',
        price: 0,
        image: alarmSystemImage,
        showTabs: ['alarms']
      },
      {
        id: 'camera-system',
        title: 'Camera System',
        description: 'Security Camera System',
        subtext: 'HD cameras with remote viewing and recording',
        price: 0,
        image: cameraSystemImage,
        showTabs: ['deterrence', 'cameras', 'model', 'nvr', 'power']
      },
      {
        id: 'alarm-and-cameras',
        title: 'Alarm and Cameras',
        description: 'Complete Security System',
        subtext: 'Full security solution with alarms and cameras',
        price: 0,
        image: alarmAndCamerasImage,
        showTabs: ['alarms', 'deterrence', 'cameras', 'model', 'nvr', 'power']
      }
    ]
  },
  {
    id: 'alarms',
    title: 'Alarms',
    question: 'Select your alarm system components',
    image: alarmSystemImage,
    options: []
  },
  {
    id: 'deterrence',
    title: 'Deterrence Devices',
    question: 'Select your deterrence devices',
    image: cameraSystemImage,
    options: []
  },
  {
    id: 'cameras',
    title: 'Cameras',
    question: 'Select your camera components',
    image: cameraSystemImage,
    options: []
  },
  {
    id: 'model',
    title: 'Virtual Guard Model',
    question: 'Select your Virtual Guard model',
    image: haloImage,
    options: []
  },
  {
    id: 'nvr',
    title: 'NVR',
    question: 'Select your NVR model',
    image: nvrImage,
    options: []
  },
  {
    id: 'power',
    title: 'Power Over Ethernet (POE)',
    question: 'Select your POE model',
    image: poeImage,
    options: []
  }
];

// Add styled component for the checkout button
const CheckoutButton = styled.button`
  background: ${props => props.$disabled ? '#cccccc' : `linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end})`};
  color: ${props => props.$disabled ? '#666666' : 'white'};
  border: none;
  border-radius: 8px;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 700;
  cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.3s ease;
  margin: 40px auto;
  display: block;
  width: 300px;
  opacity: ${props => props.$disabled ? 0.7 : 1};
  box-shadow: ${props => props.$disabled ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.2)'};
  
  &:hover {
    transform: ${props => props.$disabled ? 'none' : 'translateY(-2px)'};
    box-shadow: ${props => props.$disabled ? 'none' : '0 6px 12px rgba(0, 0, 0, 0.2)'};
  }
`;

const PageTitle = styled.h1`
  font-family: Tahoma, sans-serif;
  font-size: 32px;
  color: ${colors.primary.solid};
  margin-bottom: 20px;
  text-align: center;
`;

const StepTitle = styled.h2`
  font-family: Tahoma, sans-serif;
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
`;

const Question = styled.h3`
  font-family: Tahoma, sans-serif;
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
  text-align: center;
`;

const OptionTitle = styled.h4`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${colors.primary.solid};
`;

const OptionDescription = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
`;

const OptionSubtext = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #999;
`;

const OptionPrice = styled.span`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: ${colors.primary.solid};
`;

const ErrorMessage = styled.div`
  font-family: Tahoma, sans-serif;
  color: #c00;
  padding: 10px;
  margin: 10px 0;
  background: #fee;
  border-radius: 4px;
  text-align: center;
`;

const LoadingText = styled.div`
  font-family: Tahoma, sans-serif;
  text-align: center;
  color: #666;
  padding: 20px;
`;

const TotalPrice = styled.div`
  font-family: Tahoma, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: ${colors.primary.solid};
  text-align: right;
  margin-top: 20px;
`;

const ComponentQuantity = styled.div`
  font-family: Tahoma, sans-serif;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const QuantityButton = styled.button`
  font-family: Tahoma, sans-serif;
  padding: 5px 10px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  cursor: pointer;
  border-radius: 4px;
  
  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }
`;

const QuantityDisplay = styled.span`
  font-family: Tahoma, sans-serif;
  font-size: 16px;
  min-width: 30px;
  text-align: center;
`;

export function BuildPage() {
  usePageTitle('Build a System');
  const navigate = useNavigate();
  const location = useLocation();
  const { addToCart } = useCart();
  const [currentStep, setCurrentStep] = useState(0);
  const [selections, setSelections] = useState({
    space: null,
    'system-type': null,
    alarms: null,
    deterrence: null,
    cameras: null,
    model: null,
    nvr: null,
    power: null,
    services: [],
    shipping: null,
    installation: null
  });
  const [availableSteps, setAvailableSteps] = useState(steps);
  const [alarmComponents, setAlarmComponents] = useState([]);
  const [deterrenceComponents, setDeterrenceComponents] = useState([]);
  const [cameraComponents, setCameraComponents] = useState([]);
  const [virtualGuardComponents, setVirtualGuardComponents] = useState([]);
  const [nvrComponents, setNvrComponents] = useState([]);
  const [poeComponents, setPoeComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Create refs for each step section for scrolling
  const stepRefs = useRef([]);

  // Handle URL parameters for step selection
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const step = params.get('step');
    if (step) {
      const stepIndex = steps.findIndex(s => s.id === step);
      if (stepIndex !== -1) {
        setCurrentStep(stepIndex);
      }
    }
  }, [location.search]);

  // Add effect to fetch components
  useEffect(() => {
    const fetchComponents = async () => {
      try {
        const fetchSubcollection = async (type) => {
          const snapshot = await getDocs(collection(db, 'systemBuilder', type, 'components'));
          return snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              productId: data.productId,
              name: data.name,
              description: data.description,
              price: data.price,
              imageUrl: data.imageUrl,
              maxCameras: data.maxCameras || 0,
              installationFee: data.installationFee || 0,
              type: data.type,
              category: data.category
            };
          });
        };

        const [alarmData, deterrenceData, cameraData, virtualGuardData, nvrData, poeData] = await Promise.all([
          fetchSubcollection('alarms'),
          fetchSubcollection('deterrence'),
          fetchSubcollection('cameras'),
          fetchSubcollection('halo'),
          fetchSubcollection('nvr'),
          fetchSubcollection('poe')
        ]);

        setAlarmComponents(alarmData);
        setDeterrenceComponents(deterrenceData);
        setCameraComponents(cameraData);
        setVirtualGuardComponents(virtualGuardData);
        setNvrComponents(nvrData);
        setPoeComponents(poeData);
      } catch (error) {
        console.error('Error fetching components:', error);
        setError('Failed to fetch components');
      }
    };

    fetchComponents();
  }, []);

  // Effect to filter steps based on system type selection
  useEffect(() => {
    if (selections['system-type']) {
      const systemType = steps.find(s => s.id === 'system-type')?.options.find(opt => opt.id === selections['system-type'].id);
      if (systemType) {
        const filteredSteps = steps.filter(step => 
          step.id === 'space' || 
          step.id === 'system-type' || 
          systemType.showTabs.includes(step.id)
        );
        console.log('Selected system type:', systemType);
        console.log('Show tabs:', systemType.showTabs);
        console.log('Filtered steps:', filteredSteps);
        setAvailableSteps(filteredSteps);
        
        // If alarms tab is included and alarmComponents are loaded, ensure PROA7PLUS is in selections
        if (systemType.showTabs.includes('alarms') && alarmComponents.length > 0) {
          setSelections(prev => {
            // If alarms selection doesn't exist or is empty, initialize it
            if (!prev.alarms || !Array.isArray(prev.alarms) || prev.alarms.length === 0) {
              // Find PROA7PLUS in alarmComponents
              const proa7plus = alarmComponents.find(comp => 
                comp.id === 'PROA7PLUS' || 
                comp.name.includes('PROA7PLUS') || 
                comp.name.includes('ProSeries Alarm')
              );
              
              if (proa7plus) {
                return {
                  ...prev,
                  alarms: [{
                    id: proa7plus.id,
                    title: proa7plus.name,
                    description: proa7plus.description,
                    price: parseFloat(proa7plus.price),
                    imageUrl: proa7plus.imageUrl,
                    image: proa7plus.imageUrl,
                    quantity: 1,
                    minQuantity: 1,
                    type: 'alarm',
                    installationFee: proa7plus.installationFee || 0
                  }]
                };
              }
            } else {
              // Check if PROA7PLUS is already in the selections
              const proa7plusIndex = prev.alarms.findIndex(item => 
                item.id === 'PROA7PLUS' || 
                item.title?.includes('PROA7PLUS') || 
                item.title?.includes('ProSeries Alarm')
              );
              
              // If PROA7PLUS is not in the selections, add it
              if (proa7plusIndex === -1) {
                const proa7plus = alarmComponents.find(comp => 
                  comp.id === 'PROA7PLUS' || 
                  comp.name.includes('PROA7PLUS') || 
                  comp.name.includes('ProSeries Alarm')
                );
                
                if (proa7plus) {
                  return {
                    ...prev,
                    alarms: [
                      ...prev.alarms,
                      {
                        id: proa7plus.id,
                        title: proa7plus.name,
                        description: proa7plus.description,
                        price: parseFloat(proa7plus.price),
                        imageUrl: proa7plus.imageUrl,
                        image: proa7plus.imageUrl,
                        quantity: 1,
                        minQuantity: 1,
                        type: 'alarm',
                        installationFee: proa7plus.installationFee || 0
                      }
                    ]
                  };
                }
              }
            }
            
            return prev;
          });
        }
      }
    } else {
      // When no system type is selected, only show space and system-type steps
      setAvailableSteps(steps.filter(step => step.id === 'space' || step.id === 'system-type'));
    }
  }, [selections['system-type'], alarmComponents]);

  const handleStepChange = (index) => {
    if (index <= currentStep) {
      setCurrentStep(index);
      const stepId = availableSteps[index].id;
      navigate(`/build?step=${stepId}`, { replace: true });
      
      // Remove auto-scrolling behavior
      // The user will need to click the Next button to scroll
    }
  };

  // Function to handle "Next" button click
  const handleNextStep = (currentIndex) => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < availableSteps.length) {
      setCurrentStep(nextIndex);
      const stepId = availableSteps[nextIndex].id;
      navigate(`/build?step=${stepId}`, { replace: true });
      
      // Calculate scroll position manually
      if (stepRefs.current[nextIndex]) {
        const headerOffset = 200; // Account for fixed headers
        const elementPosition = stepRefs.current[nextIndex].getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  const handleCameraQuantityChange = (quantity) => {
    setSelections(prev => ({
      ...prev,
      cameras: quantity
    }));
  };

  const handleSelection = (stepId, selection) => {
    // If the component has disableClick and it's not a quantity update, ignore the selection
    if (selection.disableClick && selection.quantity === undefined) {
      return;
    }

    setSelections(prev => {
      const newSelections = { ...prev };
      if (stepId === 'services') {
        const index = prev[stepId]?.indexOf(selection.id) ?? -1;
        if (index === -1) {
          newSelections[stepId] = [...(prev[stepId] || []), selection.id];
        } else {
          newSelections[stepId] = (prev[stepId] || []).filter(id => id !== selection.id);
        }
      } else if (stepId === 'cameras' || stepId === 'alarms' || stepId === 'deterrence') {
        // Handle multiple selections for alarms, cameras, and deterrence
        const currentSelections = Array.isArray(prev[stepId]) ? prev[stepId] : [];
        const index = currentSelections.findIndex(item => item.id === selection.id);
        
        // For alarms, ensure PROA7PLUS is always included with at least quantity 1
        if (stepId === 'alarms') {
          // If quantity is 0 and it's not PROA7PLUS, remove the item
          if (selection.quantity === 0 && 
              !(selection.id === 'PROA7PLUS' || 
                selection.title.includes('PROA7PLUS') || 
                selection.title.includes('ProSeries Alarm'))) {
            newSelections[stepId] = currentSelections.filter(item => item.id !== selection.id);
          } else {
            // If item exists, update it, otherwise add it
            if (index === -1) {
              newSelections[stepId] = [...currentSelections, selection];
            } else {
              newSelections[stepId] = currentSelections.map(item => 
                item.id === selection.id ? selection : item
              );
            }
          }
          
          // Check if PROA7PLUS is in the selections
          const proa7plusIndex = newSelections[stepId]?.findIndex(item => 
            item.id === 'PROA7PLUS' || 
            item.title?.includes('PROA7PLUS') || 
            item.title?.includes('ProSeries Alarm')
          );
          
          // If PROA7PLUS is not in the selections, find it in alarmComponents and add it
          if (proa7plusIndex === -1) {
            const proa7plus = alarmComponents.find(comp => 
              comp.id === 'PROA7PLUS' || 
              comp.name.includes('PROA7PLUS') || 
              comp.name.includes('ProSeries Alarm')
            );
            
            if (proa7plus) {
              newSelections[stepId] = [...(newSelections[stepId] || []), {
                id: proa7plus.id,
                title: proa7plus.name,
                description: proa7plus.description,
                price: parseFloat(proa7plus.price),
                imageUrl: proa7plus.imageUrl,
                image: proa7plus.imageUrl,
                quantity: 1,
                minQuantity: 1,
                type: 'alarm',
                installationFee: proa7plus.installationFee || 0
              }];
            }
          }
        } else {
          // For cameras and deterrence, use the same logic
          // If quantity is 0, remove the item
          if (selection.quantity === 0) {
            newSelections[stepId] = currentSelections.filter(item => item.id !== selection.id);
          } else {
            // If item exists, update it, otherwise add it
            if (index === -1) {
              newSelections[stepId] = [...currentSelections, selection];
            } else {
              newSelections[stepId] = currentSelections.map(item => 
                item.id === selection.id ? selection : item
              );
            }
          }
        }
      } else if (stepId === 'model' || stepId === 'nvr') {
        // For HALO and NVR, allow unselecting by clicking again
        if (prev[stepId] && prev[stepId].id === selection.id) {
          // If the same item is clicked again, unselect it
          newSelections[stepId] = null;
        } else {
          // Otherwise, select the new item
          newSelections[stepId] = selection;
        }
      } else {
        // For single-select steps like system-type
        newSelections[stepId] = selection;
      }

      // Change image based on selection
      if (selection.image) {
        newSelections.image = selection.image;
      }

      console.log('New selections:', newSelections);
      return newSelections;
    });

    // Ensure the page remains scrollable
    document.body.style.overflow = 'auto';
  };

  const calculateTotal = () => {
    let total = 0;

    // Add model price (Virtual Guard)
    if (selections.model) {
      total += selections.model.price;
    }

    // Add NVR price
    if (selections.nvr) {
      total += selections.nvr.price;
    }

    // Add POE price
    if (selections.power) {
      total += selections.power.price;
    }

    // Add alarm components prices
    if (selections.alarms) {
      selections.alarms.forEach(alarm => {
        total += alarm.price * (alarm.quantity || 1);
      });
    }

    // Add deterrence device prices
    if (selections.deterrence) {
      selections.deterrence.forEach(device => {
        total += device.price * (device.quantity || 1);
      });
    }

    // Add camera components prices
    if (selections.cameras) {
      selections.cameras.forEach(camera => {
        total += camera.price * (camera.quantity || 1);
      });
    }

    return total;
  };

  const handleAddToCart = () => {
    const total = calculateTotal();

    // Get the selected HALO, NVR, and POE components directly from selections
    const modelDetails = selections.model;
    const nvrDetails = selections.nvr;
    const powerDetails = selections.power;

    // Format components for display
    const components = {
      model: modelDetails ? {
        id: modelDetails.id,
        name: modelDetails.title,
        price: modelDetails.price,
        quantity: 1,
        installationFee: modelDetails.installationFee || 0,
        maxCameras: modelDetails.maxCameras || 0
      } : null,
      nvr: nvrDetails ? {
        id: nvrDetails.id,
        name: nvrDetails.title,
        price: nvrDetails.price,
        quantity: 1,
        installationFee: nvrDetails.installationFee || 0,
        maxCameras: nvrDetails.maxCameras || 0
      } : null,
      power: powerDetails ? {
        id: powerDetails.id,
        name: powerDetails.title,
        price: powerDetails.price,
        quantity: 1,
        installationFee: powerDetails.installationFee || 0,
        maxCameras: powerDetails.maxCameras || 0
      } : null,
      alarms: selections.alarms?.map(alarm => ({
        id: alarm.id,
        name: alarm.title,
        price: alarm.price,
        quantity: alarm.quantity || 1,
        installationFee: alarm.installationFee || 0
      })) || [],
      cameras: selections.cameras?.map(camera => ({
        id: camera.id,
        name: camera.title,
        price: camera.price,
        quantity: camera.quantity || 1,
        installationFee: camera.installationFee || 0
      })) || [],
      deterrence: selections.deterrence?.map(device => ({
        id: device.id,
        name: device.title,
        price: device.price,
        quantity: device.quantity || 1,
        installationFee: device.installationFee || 0
      })) || []
    };

    // Create the cart item
    const cartItem = {
      id: 'custom-' + Date.now(),
      type: 'system',
      name: `Custom Security System - ${selections['system-type']?.title || 'Complete Package'}`,
      price: total,
      quantity: 1,
      imageUrl: selections['system-type']?.image || alarmAndCamerasImage,
      components: components,
      details: {
        model: modelDetails?.title || 'N/A',
        nvr: nvrDetails?.title || 'N/A',
        power: powerDetails?.title || 'N/A',
        alarms: selections.alarms?.length || 0,
        cameras: selections.cameras?.length || 0,
        deterrence: selections.deterrence?.length || 0,
        premises: selections.space?.title || 'N/A',
        systemType: selections['system-type']?.title || 'N/A'
      }
    };
    
    addToCart(cartItem);
    navigate('/checkout');
  };

  // Filter available options based on space type selection
  const getAvailableOptions = (stepId) => {
    const step = steps.find(s => s.id === stepId);
    if (!step) return [];

    if (stepId === 'space' || !selections.space) return step.options;

    const spaceType = steps[0].options.find(o => o.id === selections.space);
    if (!spaceType?.recommendations?.[stepId]) return step.options;

    if (stepId === 'model' && selections.cameras) {
      return step.options.filter(option => option.maxCameras >= selections.cameras);
    }

    return step.options.filter(option => 
      spaceType.recommendations[stepId].includes(option.id)
    );
  };

  // Update steps to use fetched components
  const getStepOptions = (stepId) => {
    switch (stepId) {
      case 'alarms':
        return alarmComponents.map(component => {
          // Make PROA7PLUS ProSeries Alarm mandatory with a minimum quantity of 1
          const isPROA7PLUS = component.id === 'PROA7PLUS' || 
                             component.name.includes('PROA7PLUS') || 
                             component.name.includes('ProSeries Alarm');
          
          return {
            id: component.id,
            title: component.name,
            description: component.description,
            shortDescription: component.shortDescription,
            price: parseFloat(component.price),
            imageUrl: component.imageUrl,
            image: component.imageUrl,
            quantity: isPROA7PLUS ? 1 : 0,
            minQuantity: isPROA7PLUS ? 1 : 0,
            maxQuantity: 99,
            type: 'alarm',
            installationFee: component.installationFee || 0,
            disableClick: true
          };
        });

      case 'deterrence':
        return deterrenceComponents.map(component => ({
          id: component.id,
          title: component.name,
          description: component.description,
          shortDescription: component.shortDescription,
          price: parseFloat(component.price),
          imageUrl: component.imageUrl,
          image: component.imageUrl,
          quantity: 0,
          minQuantity: 0,
          maxQuantity: 99,
          type: 'deterrence',
          installationFee: component.installationFee || 0,
          disableClick: true
        }));

      case 'cameras':
        return cameraComponents.map(component => ({
          id: component.id,
          title: component.name,
          description: component.description,
          shortDescription: component.shortDescription,
          price: parseFloat(component.price),
          imageUrl: component.imageUrl,
          image: component.imageUrl,
          quantity: 0,
          maxQuantity: 99,
          type: 'camera',
          installationFee: component.installationFee || 0,
          disableClick: true
        }));

      case 'model': {
        const totalCameras = selections.cameras ? 
          selections.cameras.reduce((sum, camera) => sum + (camera.quantity || 1), 0) : 0;

        // Check if either Virtual Guard or NVR is selected
        const hasVirtualGuardOrNvr = selections.model !== null || selections.nvr !== null;
        
        return virtualGuardComponents
          .sort((a, b) => (a.maxCameras || 0) - (b.maxCameras || 0))
          .map(component => {
            const maxCameras = component.maxCameras || 0;
            const isTooSmall = maxCameras < totalCameras;
            
            return {
              id: component.id,
              title: component.name,
              description: component.description,
              shortDescription: component.shortDescription,
              price: parseFloat(component.price),
              imageUrl: component.imageUrl,
              image: component.imageUrl,
              maxCameras: maxCameras,
              disabled: isTooSmall,
              disabledReason: isTooSmall ? 
                `This model only supports up to ${maxCameras} cameras. You have selected ${totalCameras} cameras.` : null,
              installationFee: component.installationFee || 0,
              note: !hasVirtualGuardOrNvr ? 'This system type requires either a Virtual Guard or an NVR (or both)' : null
            };
          });
      }

      case 'nvr': {
        const totalCameras = selections.cameras ? 
          selections.cameras.reduce((sum, camera) => sum + (camera.quantity || 1), 0) : 0;

        // Check if either Virtual Guard or NVR is selected
        const hasVirtualGuardOrNvr = selections.model !== null || selections.nvr !== null;
        
        return nvrComponents
          .sort((a, b) => (a.maxCameras || 0) - (b.maxCameras || 0))
          .map(component => {
            const maxCameras = component.maxCameras || 0;
            const isTooSmall = maxCameras < totalCameras;
            
            return {
              id: component.id,
              title: component.name,
              description: component.description,
              shortDescription: component.shortDescription,
              price: parseFloat(component.price),
              imageUrl: component.imageUrl,
              image: component.imageUrl,
              maxCameras: maxCameras,
              disabled: isTooSmall,
              disabledReason: isTooSmall ? 
                `This NVR only supports up to ${maxCameras} cameras. You have selected ${totalCameras} cameras.` : null,
              installationFee: component.installationFee || 0,
              note: !hasVirtualGuardOrNvr ? 'This system type requires either a Virtual Guard or an NVR (or both)' : null
            };
          });
      }

      case 'power': {
        const totalCameras = selections.cameras ? 
          selections.cameras.reduce((sum, camera) => sum + (camera.quantity || 1), 0) : 0;

        return poeComponents
          .sort((a, b) => (a.maxCameras || 0) - (b.maxCameras || 0))
          .map(component => {
            const maxCameras = component.maxCameras || 0;
            
            return {
              id: component.id,
              title: component.name,
              description: component.description,
              shortDescription: component.shortDescription,
              price: parseFloat(component.price),
              imageUrl: component.imageUrl,
              image: component.imageUrl,
              maxCameras: maxCameras,
              disabled: false, // Never disable POE options
              recommendedCameras: maxCameras,
              note: maxCameras < totalCameras ? 
                `Note: This POE model is rated for ${maxCameras} cameras. You have selected ${totalCameras} cameras.` : null,
              installationFee: component.installationFee || 0,
              // Only show disclaimer if no POE is selected yet
              disclaimer: !selections.power ? <DisclaimerMessage location="poe" /> : null
            };
          });
      }
      default: {
        const step = steps.find(s => s.id === stepId);
        return step ? step.options : [];
      }
    }
  };

  // Add Add to Cart button logic
  const allStepsCompleted = () => {
    const requiredSteps = availableSteps.map(step => step.id);
    
    // Check if either Virtual Guard or NVR is selected when both tabs are available
    const virtualGuardTabAvailable = requiredSteps.includes('model');
    const nvrTabAvailable = requiredSteps.includes('nvr');
    const virtualGuardOrNvrRequired = virtualGuardTabAvailable && nvrTabAvailable;
    
    // Check if cameras are required based on system type
    const systemType = selections['system-type']?.id;
    const camerasRequired = systemType === 'camera-system' || systemType === 'alarm-and-cameras';
    
    // Check if at least one camera is selected when required
    const hasCameras = selections.cameras && 
      Array.isArray(selections.cameras) && 
      selections.cameras.some(camera => camera.quantity > 0);
    
    // If cameras are required but none are selected, return false
    if (camerasRequired && !hasCameras) {
      return false;
    }
    
    return requiredSteps.every(stepId => {
      // Skip the check for model and nvr if they're both available - we'll handle that separately
      if (virtualGuardOrNvrRequired && (stepId === 'model' || stepId === 'nvr')) {
        return true;
      }
      
      // Make deterrence devices optional
      if (stepId === 'deterrence') return true;
      
      // Handle other optional steps
      if (stepId === 'services') return true;
      
      // Handle multi-select steps
      if (stepId === 'alarms' || stepId === 'cameras') {
        return !selections[stepId] || selections[stepId].length > 0;
      }
      
      return selections[stepId] !== null;
    }) && (!virtualGuardOrNvrRequired || selections.model !== null || selections.nvr !== null);
  };

  return (
    <View style={styles.container}>
      <HeaderBar />
      <BuildProgressWrapper>
        <BuildProgress 
          steps={availableSteps}
          currentStep={currentStep}
          selections={selections}
        />
      </BuildProgressWrapper>
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <CatalogWrapper>
            <PageNavigation />
            <CatalogContainer>
              <PageHeader title="Build a System" />
              <BuilderContent>
                {availableSteps.map((step, index) => (
                  <StepSection 
                    key={step.id}
                    ref={el => stepRefs.current[index] = el}
                  >
                    <StepComponent
                      key={step.id}
                      step={step}
                      index={index}
                      currentStep={currentStep}
                      onStepChange={handleStepChange}
                      onSelection={handleSelection}
                      selected={selections[step.id]}
                      options={getStepOptions(step.id)}
                      onQuantityChange={handleCameraQuantityChange}
                      isOpen={true}
                      allowMultiSelect={step.id === 'alarms' || step.id === 'cameras' || step.id === 'deterrence' || step.id === 'services'}
                    />
                    
                    {/* Add Next button if this isn't the last step and a selection has been made */}
                    {index < availableSteps.length - 1 && selections[step.id] && (
                      <NextButtonContainer>
                        <NextButton 
                          onClick={() => handleNextStep(index)}
                          selectionMade={true}
                        >
                          Next: {availableSteps[index + 1].title}
                        </NextButton>
                      </NextButtonContainer>
                    )}
                  </StepSection>
                ))}
                
                {/* Always show the Finish & Add to Cart button, but grey it out if not all steps are completed */}
                <CheckoutButton 
                  onClick={allStepsCompleted() ? handleAddToCart : undefined}
                  $disabled={!allStepsCompleted()}
                >
                  Finish & Add to Cart
                </CheckoutButton>
              </BuilderContent>
              <PageNavigation isFooter />
            </CatalogContainer>
          </CatalogWrapper>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
}

const CatalogWrapper = styled.div`
  ${createContainerStyle()}
  display: flex;
  flex-direction: column;
  font-family: Tahoma, sans-serif;
  align-items: center;
  margin: 0 auto;
  padding-top: var(--announcement-offset, 0px);

  @media (max-width: ${breakpoints.md}px) {
    padding-top: var(--announcement-offset-mobile, 0px);
  }
`;

const CatalogContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${getResponsiveSpacing('md').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('md').md}px;
  }
`;

const BuilderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${getResponsiveSpacing('lg').xs}px;
  
  @media (min-width: ${breakpoints.md}px) {
    gap: ${getResponsiveSpacing('lg').md}px;
  }
`;

// Add new styled components
const StepSection = styled.div`
  margin-bottom: 30px;
  position: relative;
  scroll-behavior: unset;
  overflow: visible;
`;

const NextButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const NextButton = styled.button`
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
  ${props => props.selectionMade && `
    background: white;
    color: ${colors.primary.solid};
    border: 3px solid ${colors.primary.solid};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `}
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
`;

// Hide BuildProgress on mobile
const BuildProgressWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 998;
  max-width: 220px;
  
  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingTop: getResponsiveSpacing('xl', 3).xs,
    paddingBottom: getResponsiveSpacing('xl').xs,
    
    '@media (min-width: ${breakpoints.md}px)': {
      paddingTop: getResponsiveSpacing('xl', 3).md,
      paddingBottom: getResponsiveSpacing('xl').md,
    }
  },
});

