import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { GradientButton } from '../components/GradientButton';
import { GradientBackground } from '../components/GradientBackground';
import { FadeInView } from '../components/FadeInView';
import { colors, typography, layout, spacing } from '../styles/SharedStyles';
import { db } from '../config/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { RotatingWords } from '../components/RotatingWords';
import styled from 'styled-components';
import { usePageTitle } from '../hooks/usePageTitle';
import AI from '../assets/images/AI.png';
import { AnimatedButtonWrapper } from '../components/AnimatedButtonWrapper';
import SecurityFeatures from '../components/SecurityFeatures';
import { FaPlay } from 'react-icons/fa';
import { useSplash } from '../context/SplashContext';

// Import icons
import customSecurityIcon from '../assets/images/custom-security-icon.png';
import burglaryPreventionIcon from '../assets/images/burglary-prevention.png';
import liveOperatorIcon from '../assets/images/live-operator-icon.png';
import realTimeAlertsIcon from '../assets/images/real-time-alerts-icon.png';

// Import additional assets
import landerVideo from '../assets/videos/lander-video.mp4';

const features = [
  {
    icon: customSecurityIcon,
    title: "Custom security solutions to meet your needs",
    description: "Tailored security systems designed specifically for your property and requirements."
  },
  {
    icon: burglaryPreventionIcon,
    title: "We specialize in burglary prevention, not just notification",
    description: "Proactive security measures to stop intruders before they can cause harm."
  },
  {
    icon: liveOperatorIcon,
    title: "Live operator monitoring 24/7, no pre-recordings",
    description: "Real human operators ready to respond to any security situation at any time."
  },
  {
    icon: realTimeAlertsIcon,
    title: "Live real-time alerts to your phone with absolutely no false alerts",
    description: "Instant, accurate notifications ensure you're always informed of genuine security events."
  }
];

const howItWorks = [
  {
    title: "Instant Detection",
    description: "As soon as an intruder approaches, our system triggers a virtual alarm, alerting live agents to monitor your cameras in real time."
  },
  {
    title: "Immediate Action",
    description: "Agents issue verbal warnings, activate sirens and strobes, and use specific details to ensure intruders know they're being watched."
  },
  {
    title: "Rapid Responses",
    description: "If warnings are ignored, authorities are notified immediately, ensuring intruders are apprehended or leave your property."
  }
];

const webStyles = {
  outerContainer: {
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
    position: 'relative',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  heroSection: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    padding: '0 42px',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  contentWrapper: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
  },
  mainWrapper: {
    width: '100%',
    position: 'relative',
    paddingTop: '100px',
    marginTop: '-100px',
  },
  mainContent: {
    position: 'relative',
    width: '100%',
    backgroundColor: colors.white,
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 2,
  },
  heroTextContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3,
    padding: '0 20px',
  },
  heroVideoContainer: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  heroVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  heroContent: {
    width: '100%',
    maxWidth: '1200px',
    marginHorizontal: 'auto',
    paddingVertical: 20,
    paddingRight: 40,
    position: 'relative',
    zIndex: 5,
  },
  heroTitle: {
    ...typography.h1,
    fontSize: 94,
    color: colors.white,
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  },
  heroDescription: {
    fontSize: 32,
    letterSpacing: 1.68,
    marginTop: 30,
    width: '50%',
    color: colors.white,
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
  },
  buttonContainer: {
    marginTop: 40,
    width: '75%',
    display: 'flex',
    justifyContent: 'flex-start', 
    flexDirection: 'row',
    gap: 20,
    '@media (maxWidth: 768px)': {
      width: '100%',
      justifyContent: 'center',
    },
    '@media (maxWidth: 480px)': {
      marginTop: 25,
    }
  },
  button: {
    width: '100%',
    '@media (maxWidth: 768px)': {
      maxWidth: '10%',
      fontSize: '16px',
      padding: '12px 15px',
    },
    '@media (maxWidth: 480px)': {
      maxWidth: '100%',
      fontSize: '16px',
      padding: '12px 15px',
    }
  },
  mainContent: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    zIndex: 3,
  },
  securityBanner: {
    width: '100%',
    padding: 76,
    justifyContent: 'center',
    scrollSnapAlign: 'start',
    display: 'flex',
    alignItems: 'center',
  },
  bannerText: {
    ...typography.h3,
    color: colors.white,
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
    fontSize: 38,
  },
  featuresSection: {
    width: '100%',
    padding: 50,
    alignItems: 'center',
  },
  productSectionTitle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
  },
  productSectionImage: {
    width: '64px',
    height: '64px',
    objectFit: 'cover',
    marginRight: 15,
    '@media (maxWidth: 768px)': {
      width: '50px',
      height: '50px',
      marginRight: 10,
    },
    '@media (maxWidth: 480px)': {
      width: '40px',
      height: '40px',
      marginRight: 8,
    }
  },
  productSectionImage2: {
    width: '64px',
    height: '64px',
    objectFit: 'cover',
    marginLeft: 15,
    transform: 'translateZ(180px) scaleX(-1)',
    '@media (maxWidth: 768px)': {
      width: '50px',
      height: '50px',
      marginLeft: 10,
    },
    '@media (maxWidth: 480px)': {
      width: '40px',
      height: '40px',
      marginLeft: 8,
    }
  },
  sectionTitle: {
    ...typography.h2,
    fontFamily: "Tahoma",
    color: colors.white,
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 40,
    fontSize: 52,
    padding: 50,
    background: 'linear-gradient(0deg, #0154b2 0%, #003d82 105.71%)',
    '@media (maxWidth: 768px)': {
      fontSize: 32,
      padding: '30px 20px',
    },
    '@media (maxWidth: 480px)': {
      fontSize: '16px',
      padding: '15px 10px',
      marginTop: 15,
    }
  },
  featuresGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 30,
    maxWidth: 1200,
    marginHorizontal: 'auto',
  },
  featureCard: {
    flex: 1,
    minWidth: 300,
    maxWidth: 350,
    padding: 30,
    backgroundColor: colors.white,
    borderRadius: 20,
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  },
  featureTitle: {
    ...typography.body,
    color: colors.white,
    textAlign: 'center',
    marginBottom: 15,
  },
  featureDescription: {
    ...typography.h4,
    color: colors.white,
  },
  productSection: {
    width: '100%',
    backgroundColor: '#EEEEEE',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '60px 20px',
    '@media (maxWidth: 768px)': {
      padding: '40px 15px',
      minHeight: 'auto',
    },
    '@media (maxWidth: 480px)': {
      padding: '30px 10px',
    }
  },
  productContent: {
    maxWidth: 1200,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 50,
    '@media (maxWidth: 991px)': {
      flexDirection: 'column',
      gap: 30,
    }
  },
  productInfo: {
    flex: 1,
    padding: 30,
    '@media (maxWidth: 768px)': {
      padding: '20px 15px',
    },
    '@media (maxWidth: 480px)': {
      padding: '15px 10px',
    }
  },
  productTitle: {
    ...typography.h3,
    fontSize: 40,
    color: colors.black,
    marginBottom: 20,
    '@media (maxWidth: 768px)': {
      fontSize: 32,
      marginBottom: 15,
    },
    '@media (maxWidth: 480px)': {
      fontSize: 28,
      marginBottom: 10,
    }
  },
  productDescription: {
    ...typography.h4,
    color: colors.gray,
    marginBottom: 10,
    fontSize: 24,
    lineHeight: 36,
    color: colors.gray.dark,
    '@media (maxWidth: 768px)': {
      fontSize: 20,
      lineHeight: 30,
    },
    '@media (maxWidth: 480px)': {
      fontSize: 16,
      lineHeight: 24,
    }
  },
  contactBanner: {
    width: '100%',
    minHeight: 187,
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highlight: {
    color: colors.white,
    fontWeight: '700',
  },
  featureIconContainer: {
    width: 120,
    height: 120,
    // backgroundColor: '#E71C24',
    borderRadius: 60,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    overflow: 'hidden',
  },
  featureIcon: {
    width: '80%',
    height: '80%',
    marginTop: 20,
    // tintColor: colors.white,
  },
  securityFeatures: {
    width: '100%',
    backgroundColor: '#1E1E1E',
    padding: '60px 20px',
    marginTop: -1, // Prevent any gap
  },
  featuresContainer: {
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
  },
  featuresRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    gap: 15,
    '@media (maxWidth: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  featureColumn: {
    flex: 1,
    alignItems: 'center',
    maxWidth: '25%',
    '@media (maxWidth: 768px)': {
      maxWidth: '100%',
      marginBottom: 40,
    },
  },
  howItWorksSection: {
    width: '100%',
    padding: '60px 20px',
    backgroundColor: colors.white,
    scrollSnapAlign: 'start',
    scrollSnapStop: 'always',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (maxWidth: 768px)': {
      padding: '40px 15px',
      minHeight: 'auto',
    },
    '@media (maxWidth: 480px)': {
      padding: '30px 10px',
    }
  },
  howItWorksTitle: {
    fontSize: '3.25rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 40,
    color: colors.black,
    paddingTop: 50,
    '@media (max-width: 768px)': {
      fontSize: 40,
      marginBottom: 30,
      paddingTop: 30,
    },
    '@media (max-width: 480px)': {
      fontSize: 24,
      marginBottom: 20,
      paddingTop: 20,
    }
  },
  howItWorksCard: {
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    '@media (maxWidth: 991px)': {
      marginBottom: 20,
    }
  },
  videoSection: {
    width: '100%',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    marginTop: 40,
    aspectRatio: 16/9,
    overflow: 'hidden',
    borderRadius: 10,
    '@media (maxWidth: 768px)': {
      marginTop: 30,
      borderRadius: 8,
    },
    '@media (maxWidth: 480px)': {
      marginTop: 20,
      borderRadius: 6,
    }
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: '#000'
  },
  productImageContainer: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
    overflow: 'hidden',
    '@media (maxWidth: 991px)': {
      width: '100%',
      maxWidth: 500,
      marginTop: 20,
    },
    '@media (maxWidth: 480px)': {
      maxWidth: 300,
      marginTop: 15,
      display: 'none',
    }
  },
  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: colors.white,
  },
  loadingText: {
    fontSize: 20,
    color: colors.primary,
    fontWeight: 'bold',
  },
};

const GradientText = styled.span`
  background: linear-gradient(to bottom, rgb(255, 47, 47), rgb(255, 0, 13));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 80px;
  background-color: #ECECEC;
  animation: heroFadeIn 1.2s ease-out forwards;
  
  @keyframes heroFadeIn {
    from {
      opacity: 0.4;
      transform: scale(0.98);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @media (maxWidth: 768px) {
    min-height: auto;
    height: auto;
    padding: 20px 0 20px;
  }
  
  @media (maxWidth: 480px) {
    padding: 20px 0 15px;
  }
`;

const HeroCardContainer = styled.section`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  height: 65vh;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
  position: relative;
 
  @media (max-width: 991px) {
    max-width: 991px;
    flex-direction: column;
    height: auto;
    margin: 0 15px;
    width: auto;
  }

  @media (max-width: 640px) {
    max-width: 640px;
    margin: 0 12px;
    border-radius: 12px;
  }
  
  @media (max-width: 480px) {
    margin: 0 10px;
    border-radius: 8px;
  }
`;

const VideoSection = styled.section`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  animation: videoFadeIn 1.5s ease-out forwards;
  overflow: hidden;
  
  @keyframes videoFadeIn {
    from {
      opacity: 0.6;
      transform: scale(1.05);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 400px;
  }

  @media (max-width: 640px) {
    height: 350px;
  }
  
  @media (max-width: 480px) {
    height: 300px;
  }
`;

const ContentWrapper = styled.section`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 25px;
  animation: contentSlideIn 1.5s ease-out forwards;
  
  @keyframes contentSlideIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 25px 20px;
  }
  
  @media (max-width: 640px) {
    padding: 20px 15px;
  }
  
  @media (max-width: 480px) {
    padding: 15px 12px;
  }
`;

const ContentContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
  max-width: 550px;
  
  @media (maxWidth: 640px) {
    gap: 15px;
    padding: 0;
  }
  
  @media (maxWidth: 480px) {
    gap: 10px;
    padding: 0;
    width: 100%;
  }
`;

const HeroTitle = styled.h1`
  font-family: "Tahoma", sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: #000;
  margin: 0;
  line-height: 1.2;
  
  @media (max-width: 991px) {
    font-size: 36px;
  }
  
  @media (max-width: 640px) {
    font-size: 30px;
  }
  
  @media (max-width: 480px) {
    padding-top: 1.2rem;
    font-size: 24px;
    br {
      display: block;
      content: "";
      margin-top: 8px;
    }
  }
`;

const HeroSubtitle = styled.p`
  font-size: 24px;
  line-height: 1.5;
  color: #333;
  margin: 0;
  margin-top: 5px;
  padding-left: 10px;
  
  @media (max-width: 991px) {
    font-size: 22px;
  }
  
  @media (max-width: 640px) {
    font-size: 22px;
  }
  
  @media (max-width: 480px) {
    font-size: 20px;
    margin-top: -10px;
    line-height: 1.4;
    padding-right: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
  
  @media (max-width: 768px) {
    margin-top: 12px;
    gap: 12px;
    padding: 0;
    max-width: 100%;
  }
  
  @media (max-width: 480px) {
    margin-top: 15px;
    gap: 10px;
    padding: 0 15px;
    max-width: calc(100% - 30px);
  }
`;

const AnnouncementBanner = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #0066cc, #003399);
  color: white;
  padding: 15px 0px 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px 10px;
    gap: 8px;
    min-height: auto;
  }
  
  @media (max-width: 480px) {
    padding: 10px 8px;
    gap: 6px;
    position: relative;
  }
`;

const AnnouncementTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  flex: 1;
`;

const AnnouncementTopText = styled.div`
  font-size: 28px;
  font-weight: 600;
  
  @media (max-width: 768px) {
    font-size: 22px;
  }
  
  @media (max-width: 640px) {
    font-size: 18px;
  }
  
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const AnnouncementBottomText = styled.div`
  font-size: 20px;
  font-weight: 400;
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
  
  @media (max-width: 640px) {
    font-size: 14px;
  }
  
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const AnnouncementImageContainer = styled.div`
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AnnouncementRightText = styled.div`
  font-size: 52px;
  font-weight: 700;
  color: #ff0000;
  margin-left: 25px;
  
  @media (max-width: 768px) {
    font-size: 36px;
    margin-left: 0;
    margin-bottom: 4px;
  }
  
  @media (max-width: 640px) {
    font-size: 28px;
  }
  
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const HeroVideo = styled.video`
  width: 100%;
  height: calc(100% - 100px); /* Subtract banner height */
  object-fit: fill;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (maxWidth: 480px) {
  height: calc(100%);
    object-position: center;
     object-fit: cover;
  }
`;

const ProductSectionContainer = styled.div`
  width: 100%;
  background-color: #EEEEEE;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 20px;
  
  @media (maxWidth: 768px) {
    padding: 40px 15px;
    min-height: auto;
  }
  
  @media (maxWidth: 480px) {
    padding: 30px 10px;
  }
`;

const ProductContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  
  @media (maxWidth: 991px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const ProductInfoContainer = styled.div`
  flex: 1;
  padding: 30px;
  
  @media (maxWidth: 768px) {
    padding: 20px 15px;
  }
  
  @media (maxWidth: 480px) {
    padding: 15px 10px;
  }
`;

const ProductTitle = styled.h2`
  font-family: "Tahoma", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
  
  @media (maxWidth: 768px) {
    font-size: 32px;
    margin-bottom: 15px;
  }
  
  @media (maxWidth: 480px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
`;

const ProductDescription = styled.p`
font-family: "Tahoma", sans-serif;
  font-size: 24px;
  line-height: 36px;
  color: #333;
  margin-bottom: 10px;
  
  @media (maxWidth: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  
  @media (maxWidth: 480px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ProductButtonContainer = styled.div`
  margin-top: 40px;
  width: 75%;
  display: flex;
  justify-content: flex-start;
  
  @media (maxWidth: 768px) {
    width: 100%;
    justify-content: center;
  }
  
  @media (maxWidth: 480px) {
    margin-top: 25px;
  }
`;

const ProductImageContainer = styled.div`
  flex: 1;
  aspect-ratio: 1;
  border-radius: 10px;
  overflow: hidden;
  
  @media (maxWidth: 991px) {
    width: 100%;
    maxWidth: 500px;
    margin-top: 20px;
  }
  
  @media (maxWidth: 480px) {
    maxWidth: 300px;
    margin-top: 15px;
    display: block;
  }
`;

// Add these new styled components for the How It Works section
const HowItWorksContainer = styled.div`
  width: 100%;
  padding: 60px 20px;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (maxWidth: 768px) {
    padding: 40px 15px;
    min-height: auto;
  }
  
  @media (maxWidth: 480px) {
    padding: 30px 10px;
  }
`;

const HowItWorksGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
  margin-bottom: 50px;
  
  @media (maxWidth: 991px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
  }
`;

const HowItWorksCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  
  @media (maxWidth: 991px) {
    margin-bottom: 20px;
  }
  
  @media (maxWidth: 480px) {
    margin-bottom: 15px;
  }
`;

const HowItWorksCardTitle = styled.h3`
  font-family: "Tahoma", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
  text-align: center;
  text-decoration: underline;
  
  @media (maxWidth: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  @media (maxWidth: 480px) {
    font-size: 16px;
    margin-bottom: 6px;
  }
`;

const HowItWorksCardDescription = styled.p`
  font-family: "Tahoma", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  text-align: center;
  
  @media (maxWidth: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
  
  @media (maxWidth: 480px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const VideoSectionContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto 0;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 10px;
  
  @media (maxWidth: 768px) {
    margin-top: 30px;
    border-radius: 8px;
  }
  
  @media (maxWidth: 480px) {
    margin-top: 20px;
    border-radius: 6px;
  }
`;

// Add this new styled component for the Contact Banner
const ContactBannerContainer = styled.div`
  width: 100%;
  min-height: 187px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #0154b2 0%, #003d82 105.71%);
  
  @media (maxWidth: 768px) {
    min-height: 150px;
    padding: 30px 15px;
  }
  
  @media (maxWidth: 480px) {
    min-height: 120px;
    padding: 25px 10px;
  }
`;

const ContactBannerText = styled.h3`
  font-size: 38px;
  color: white;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    font-size: 28px;
  }
  
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const HighlightText = styled.span`
  color: white;
  font-weight: 700;
`;

// Add new styled components for video player
const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const PlayButtonLarge = styled.div`
  width: 80px;
  height: 80px;
  background: rgba(231, 28, 36, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 32px;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.1);
    background: ${colors.primary.solid};
  }
`;

const HowItWorksTitle = styled.h2`
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  color: colors.black;
  paddingTop: 50px;
  
  @media (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 25px;
    paddingTop: 25px;
  }
  
  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 15px;
    paddingTop: 15px;
  }
`;

export const LandingPage = () => {
  usePageTitle('Home');
  const navigate = useNavigate();
  const mainContentRef = useRef(null);
  const [productImage, setProductImage] = useState(null);
  const [backgroundVideo, setBackgroundVideo] = useState(null);
  const [howItWorksVideo, setHowItWorksVideo] = useState(null);
  const [announcement, setAnnouncement] = useState({
    topText: '',
    bottomText: '',
    image: null,
    rightText: ''
  });
  const [isHowItWorksVideoPlaying, setIsHowItWorksVideoPlaying] = useState(false);
  const howItWorksVideoRef = useRef(null);

  // Get isEnabled instead of isLoading from useSplash
  const { isEnabled } = useSplash();

  useEffect(() => {
    const handleScroll = () => {
      if (!mainContentRef.current) return;
      
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      
      // Only apply transform effect until we reach the security banner
      if (scrollPosition <= viewportHeight) {
        const translateY = Math.max(0, viewportHeight - scrollPosition);
        const scale = 0.9 + (Math.min(scrollPosition, viewportHeight) / viewportHeight) * 0.1;
        
        mainContentRef.current.style.transform = `translateY(${translateY}px) scale(${scale})`;
      } else {
        mainContentRef.current.style.transform = 'translateY(0) scale(1)';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    fetchSiteSettings();
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'siteSettings', 'landingPage'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setBackgroundVideo(data.backgroundVideo || landerVideo);
          setHowItWorksVideo(data.howItWorksVideo || landerVideo);
        }
      } catch (err) {
        console.error('Error fetching videos:', err);
        // Fallback to default videos
        setBackgroundVideo(landerVideo);
        setHowItWorksVideo(landerVideo);
      }
    };

    fetchVideos();
  }, []);

  const fetchSiteSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'siteSettings', 'landingPage'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setProductImage(data.productImage);
        
        // Set announcement data with fallbacks
        setAnnouncement({
          topText: data.announcementTopText || 'Your Trusted Security Solutions Company',
          bottomText: data.announcementBottomText || 'Residential and Commercial Protection',
          image: data.announcementImage || null,
          rightText: data.announcementRightText || ''
        });
        
        // Store security features in Firestore for the SecurityFeatures component to access
        if (data.securityFeatures && data.securityFeatures.length > 0) {
          await setDoc(doc(db, 'siteContent', 'landingPage'), {
            securityFeatures: data.securityFeatures
          }, { merge: true });
        }
      }
    } catch (err) {
      console.error('Error fetching site settings:', err);
    }
  };

  // Add video play handler
  const handlePlayHowItWorksVideo = () => {
    if (howItWorksVideoRef.current) {
      howItWorksVideoRef.current.play();
      setIsHowItWorksVideoPlaying(true);
    }
  };

  return (
    <div style={webStyles.outerContainer}>
      <div style={webStyles.header}>
        <HeaderBar />
      </div>

      <HeroSection>
        <HeroCardContainer>
          {/* Left side - Text content */}
          <ContentWrapper>
            <FadeInView duration={1000} direction="up" delay={200}>
              <ContentContainer>
                
                <HeroTitle>
                  Protect Your{' '}
                  <RotatingWords textColor="#000" />
                  <br />
                  <span style={{ display: 'block' }}>with <GradientText>Virtual Guard</GradientText><br /><GradientText>Monitoring</GradientText></span>
                </HeroTitle>
                <HeroSubtitle>
                  Stop intruders before they get inside with AI-backed virtual security monitoring.
                </HeroSubtitle>
                <ButtonsContainer>
                  <AnimatedButtonWrapper>
                    <GradientButton
                      text="Build a System"
                      onPress={() => navigate('/build')}
                      style={{...webStyles.button, borderRadius: 8}}
                      textStyle={{ fontSize: 20 }}
                    />
                  </AnimatedButtonWrapper>
                  <AnimatedButtonWrapper>
                    <GradientButton   
                      text="Shop Our Packages"
                      onPress={() => navigate('/packages')}
                      style={{...webStyles.button, borderRadius: 8}}
                      textStyle={{ fontSize: 20 }}
                    />
                  </AnimatedButtonWrapper>
                </ButtonsContainer>
              </ContentContainer>
            </FadeInView>
          </ContentWrapper>
          
          {/* Right side - Video */}
          <VideoSection>
            <HeroVideo
              autoPlay
              muted
              loop
              playsInline
              src={backgroundVideo}
            />
            {/* Bottom Banner */}
            <AnnouncementBanner>
              {announcement.rightText && (
                <AnnouncementRightText>{announcement.rightText}</AnnouncementRightText>
              )}
              <AnnouncementTextContainer>
                <AnnouncementTopText>{announcement.topText}</AnnouncementTopText>
                <AnnouncementBottomText>{announcement.bottomText}</AnnouncementBottomText>
              </AnnouncementTextContainer>
              {announcement.image && (
                <AnnouncementImageContainer>
                  <img src={announcement.image} alt="Announcement" />
                </AnnouncementImageContainer>
              )}
            </AnnouncementBanner>
          </VideoSection>
        </HeroCardContainer>
      </HeroSection>

      <div style={webStyles.mainWrapper}>
        <div style={webStyles.mainContent} ref={mainContentRef}>
          <SecurityFeatures />

          <View style={webStyles.howItWorksSection}>
            <Text style={webStyles.howItWorksTitle}>How Does It Work?</Text>
            <HowItWorksGrid>
              {howItWorks.map((item, index) => (
                <FadeInView 
                  key={index} 
                  duration={800} 
                  direction="up" 
                  delay={200 + (index * 200)}
                >
                  <HowItWorksCard>
                    <HowItWorksCardTitle>{item.title}</HowItWorksCardTitle>
                    <HowItWorksCardDescription>{item.description}</HowItWorksCardDescription>
                  </HowItWorksCard>
                </FadeInView>
              ))}
            </HowItWorksGrid>
            <View style={webStyles.videoSection}>
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <video
                  ref={howItWorksVideoRef}
                  style={webStyles.video}
                  src={howItWorksVideo}
                  muted
                  playsInline
                />
                {!isHowItWorksVideoPlaying && (
                  <VideoOverlay onClick={handlePlayHowItWorksVideo}>
                    <PlayButtonLarge>
                      <FaPlay />
                    </PlayButtonLarge>
                  </VideoOverlay>
                )}
              </div>
            </View>
          </View>

          <View style={webStyles.productSection}>
            <FadeInView duration={800} direction="up" delay={1400}>
              <Text style={{
                ...webStyles.sectionTitle,
                '@media (maxWidth: 480px)': {
                  fontSize: 24,
                  padding: '20px 15px',
                  marginTop: 20,
                  fontFamily: "Tahoma",
                }
              }}>
                <Image source={AI} style={webStyles.productSectionImage}/>
                AI-Powered Protection
                <Image source={AI} style={webStyles.productSectionImage2}/> {'\n'}
                Anytime, Anywhere
              </Text>
            
              <ProductContentWrapper>
                <ProductInfoContainer>
                  <ProductTitle>Get Better Protected with Virtual Guard Server</ProductTitle>
                  <ProductDescription>
                    Get Virtual Guard Server powered by AI for accurate identification of persons and vehicles. 
                    Connects to 24 Hour Emergency Central Station. Plug and play directly to your network.
                  </ProductDescription>
                  <ProductButtonContainer>
                    <AnimatedButtonWrapper>
                      <GradientButton
                        text="Buy Now"
                        onPress={() => navigate('/products')}
                        style={{
                          ...webStyles.button, 
                          width: '100%', 
                          borderRadius: 8,
                        }}
                        textStyle={{ 
                          fontSize: 28,
                          '@media (maxWidth: 480px)': {
                            fontSize: 20,
                          }
                        }}
                      />
                    </AnimatedButtonWrapper>
                  </ProductButtonContainer>
                </ProductInfoContainer>
                {productImage && (
                  <ProductImageContainer>
                    <Image 
                      source={{ uri: productImage }}
                      style={webStyles.productImage}
                      resizeMode="cover"
                    />
                  </ProductImageContainer>
                )}
              </ProductContentWrapper>
            </FadeInView>
          </View>

          <FadeInView duration={800} direction="up" delay={1600}>
            <GradientBackground style={webStyles.contactBanner}>
              <Text style={webStyles.bannerText}>
                <Text style={webStyles.highlight}>Contact Us</Text> About Live Video Monitoring
              </Text>
            </GradientBackground>
          </FadeInView>

          <Footer />
        </div>
      </div>
    </div>
  );
}; 