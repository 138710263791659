import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';

const AnnouncementDisplay = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [hiddenAnnouncements, setHiddenAnnouncements] = useState(new Set());

  const handleClose = (id) => {
    setHiddenAnnouncements(prev => new Set([...prev, id]));
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'announcements'));
        const announcementsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Filter for active announcements only
        const activeAnnouncements = announcementsData.filter(announcement => announcement.active === true);
        setAnnouncements(activeAnnouncements);
      } catch (err) {
        console.error('Error fetching announcements:', err);
      }
    };

    fetchAnnouncements();
  }, []);

  // Set CSS variables for announcement offset
  useEffect(() => {
    const visibleAnnouncements = announcements.filter(announcement => !hiddenAnnouncements.has(announcement.id));
    const hasBanner = visibleAnnouncements.some(announcement => announcement.displayType === 'banner');
    
    if (hasBanner) {
      // Banner height is roughly 50px (10px padding top/bottom + ~30px content)
      // Add a little extra padding (15px) for spacing
      document.documentElement.style.setProperty('--announcement-offset', '65px');
      document.documentElement.style.setProperty('--announcement-offset-mobile', '50px');
    } else {
      document.documentElement.style.setProperty('--announcement-offset', '0px');
      document.documentElement.style.setProperty('--announcement-offset-mobile', '0px');
    }

    return () => {
      document.documentElement.style.setProperty('--announcement-offset', '0px');
      document.documentElement.style.setProperty('--announcement-offset-mobile', '0px');
    };
  }, [announcements, hiddenAnnouncements]);

  if (announcements.length === 0) return null;

  const visibleAnnouncements = announcements.filter(announcement => !hiddenAnnouncements.has(announcement.id));
  if (visibleAnnouncements.length === 0) return null;

  return (
    <View style={styles.container}>
      {visibleAnnouncements.map(announcement => (
        <View key={announcement.id} style={announcement.displayType === 'banner' ? styles.banner : styles.sidebar}>
          <Text style={announcement.displayType === 'banner' ? styles.title : styles.titleSidercar}>{announcement.title}</Text>
          <Text style={styles.subTitle}>{announcement.content}</Text>
          <Text onPress={() => handleClose(announcement.id)} style={styles.closeButton}>Close</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 900,
    pointerEvents: 'none',
  },
  banner: {
    backgroundColor: '#1E1E1E',
    padding: 10,
    alignItems: 'center',
    marginTop: 85,
    pointerEvents: 'auto',
    transform: 'translateZ(0)',
    '@media (max-width: 768px)': {
      marginTop: 50,
      padding: 8,
    },
    '@media (max-width: 480px)': {
      marginTop: 50,
      padding: 6,
    },
  },
  sidebar: {
    position: 'fixed',
    right: -5,
    top: 175,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 15,
    width: 175,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    background: 'linear-gradient(to right, rgb(213, 0, 0), rgb(255, 22, 22))',
    borderWidth: 3,
    borderColor: 'white',
    pointerEvents: 'auto',
    '@media (max-width: 768px)': {
      top: 140,
      width: 150,
      padding: 12,
    },
    '@media (max-width: 480px)': {
      top: 120,
      width: 130,
      padding: 10,
      borderWidth: 2,
    },
  },
  title: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 16,
    background: 'linear-gradient(to right, rgb(213, 0, 0), rgb(255, 22, 22))',
    '@media (max-width: 480px)': {
      fontSize: 14,
    },
  },
  titleSidercar: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 16,
    color: 'white',
    '@media (max-width: 480px)': {
      fontSize: 14,
    },
  },
  subTitle: {
    color: 'white',
    fontSize: 14,
    '@media (max-width: 480px)': {
      fontSize: 12,
    },
  },
  closeButton: {
    color: 'red',
    marginTop: 5,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
});

export default AnnouncementDisplay; 