import { StyleSheet } from 'react-native';
import { colors, typography as baseTypography, spacing, shadows, createGradientBackground, gradients } from './theme';

export const typography = StyleSheet.create(baseTypography);

export const layout = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  section: {
    padding: spacing.xxl,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  stickyFooter: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1000,
  }
});

export const buttons = StyleSheet.create({
  primary: {
    ...createGradientBackground(gradients.primary),
    borderRadius: 50,
    padding: spacing.md,
    minWidth: 320,
    alignItems: 'center',
    ...shadows.medium,
  },
  primaryText: {
    ...typography.button,
    color: colors.white,
  },
  secondary: {
    backgroundColor: colors.white,
    borderRadius: 50,
    padding: spacing.md,
    minWidth: 320,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: colors.primary.solid,
    ...shadows.small,
  },
  secondaryText: {
    ...typography.button,
    color: colors.primary.solid,
  }
});

export { colors, spacing, shadows, createGradientBackground, gradients }; 