// Company's address coordinates
const COMPANY_LOCATION = {
  address: '12821 Capital Street Oak Park, MI 48237',
  zipCode: '48237',
  coordinates: {
    lat: 42.4720,
    lng: -83.1826
  }
};

// Zip code distance estimation
const getZipCodeCoordinates = async (zipCode) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&postalcode=${zipCode}&country=USA&limit=1`,
      {
        headers: {
          'User-Agent': 'SCP911-Website/1.0'
        }
      }
    );
    const data = await response.json();
    
    if (data.length === 0) {
      // Return approximate coordinates for Michigan if zip code not found
      return {
        lat: 42.4720,
        lng: -83.1826
      };
    }

    return {
      lat: parseFloat(data[0].lat),
      lng: parseFloat(data[0].lon)
    };
  } catch (error) {
    console.error('Error getting zip code coordinates:', error);
    // Return approximate coordinates for Michigan if error
    return {
      lat: 42.4720,
      lng: -83.1826
    };
  }
};

// Calculate distance between two points using Haversine formula
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Earth's radius in miles
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const distance = R * c;
  return Math.round(distance); // Round to nearest mile
};

const toRad = (value) => {
  return value * Math.PI / 180;
};

// Calculate shipping fee based on distance
export const calculateShippingFee = (distance) => {
  if (distance <= 50) {
    return 0; // Free shipping within 50 miles
  }
  return distance - 50; // $1 per mile after 50 miles
};

// Calculate installation fee based on distance and product base fee
export const calculateInstallationFee = (distance, products) => {
  // Calculate base installation fee (sum of all products' installation fees)
  const baseInstallationFee = products.reduce((total, product) => {
    return total + (product.installationFee || 0) * (product.quantity || 1);
  }, 0);

  // Add distance fee ($1 per mile)
  const distanceFee = distance;

  return baseInstallationFee + distanceFee;
};

// Get distance from company using zip codes
export const getDistanceFromCompany = async (customerAddress) => {
  try {
    // Extract zip code from customer address
    const zipCodeMatch = customerAddress.match(/\b\d{5}\b/);
    if (!zipCodeMatch) {
      throw new Error('Invalid zip code');
    }
    const customerZip = zipCodeMatch[0];
    
    // Get coordinates for both zip codes
    const customerCoords = await getZipCodeCoordinates(customerZip);
    
    const distance = calculateDistance(
      COMPANY_LOCATION.coordinates.lat,
      COMPANY_LOCATION.coordinates.lng,
      customerCoords.lat,
      customerCoords.lng
    );
    return distance;
  } catch (error) {
    console.error('Error calculating distance:', error);
    // Return a default distance if calculation fails
    return 50; // Default to minimum distance for free shipping
  }
};

export const COMPANY_ADDRESS = COMPANY_LOCATION.address; 