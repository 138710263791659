import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, TextInput, ScrollView } from 'react-native';
import { colors } from '../../styles/SharedStyles';
import { db, storage } from '../../config/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { GradientButton } from '../GradientButton';
import { SearchBar } from '../SearchBar';
import { TabButton } from '../TabButton';
import styled from 'styled-components';

// Add styled components for image upload
const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;

  &:hover {
    background: ${colors.primary.dark};
  }
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

// Add this near the top with other styled components
const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  max-height: 400px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid ${colors.gray};
  border-radius: 5px;
  background: #f9f9f9;
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: white;
  border-radius: 8px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
  gap: 10px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const PackageBuilder = () => {
  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [packageDetails, setPackageDetails] = useState({
    title: '',
    description: '',
    price: '',
    features: [''],
    id: null,
    imageUrl: ''
  });
  const [selectedTab, setSelectedTab] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = React.useRef(null);
  const [orderChanged, setOrderChanged] = useState(false);

  useEffect(() => {
    fetchProducts();
    fetchPackages();
    setSelectedTab(null);
  }, []);

  const fetchPackages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'packages'));
      const packagesData = querySnapshot.docs.map(doc => ({
        docId: doc.id,
        ...doc.data(),
        displayOrder: doc.data().displayOrder || 0
      }));
      // Sort packages by displayOrder
      const sortedPackages = packagesData.sort((a, b) => a.displayOrder - b.displayOrder);
      setPackages(sortedPackages);
    } catch (err) {
      console.error('Error fetching packages:', err);
      setError('Failed to fetch packages: ' + err.message);
    }
  };

  const handleQuantityChange = (productId, newValue) => {
    const value = parseInt(newValue);
    if (value >= 0) {
      if (value === 0) {
        setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
      } else {
        setSelectedProducts(selectedProducts.map(p =>
          p.id === productId ? { ...p, quantity: value } : p
        ));
      }
    }
  };

  const handleQuantityAdjust = (productId, increment) => {
    const product = selectedProducts.find(p => p.id === productId);
    const newQuantity = (product?.quantity || 0) + increment;
    if (newQuantity > 0) {
      handleQuantityChange(productId, newQuantity);
    } else {
      setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
    }
  };

  const handleEdit = (pkg) => {
    setPackageDetails({
      title: pkg.title,
      description: pkg.description,
      price: pkg.price.toString(),
      features: pkg.features || [''],
      id: pkg.docId,
      imageUrl: pkg.imageUrl
    });
    setSelectedProducts(pkg.products.map(p => ({
      ...products.find(prod => prod.id === p.id),
      quantity: p.quantity
    })));
  };

  const handleDelete = async (docId) => {
    try {
      await deleteDoc(doc(db, 'packages', docId));
      await fetchPackages();
    } catch (err) {
      console.error('Error deleting package:', err);
      setError('Failed to delete package: ' + err.message);
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.sku.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products: ' + err.message);
    }
    setLoading(false);
  };

  const handleProductSelect = (product) => {
    const existingProduct = selectedProducts.find(p => p.id === product.id);
    if (existingProduct) {
      setSelectedProducts(selectedProducts.map(p => 
        p.id === product.id 
          ? { ...p, quantity: p.quantity + 1 }
          : p
      ));
    } else {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    }
  };

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...packageDetails.features];
    newFeatures[index] = value;
    setPackageDetails({ ...packageDetails, features: newFeatures });
  };

  const addFeature = () => {
    setPackageDetails({
      ...packageDetails,
      features: [...packageDetails.features, '']
    });
  };

  const removeFeature = (index) => {
    const newFeatures = packageDetails.features.filter((_, i) => i !== index);
    setPackageDetails({ ...packageDetails, features: newFeatures });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    try {
      const storageRef = ref(storage, `packages/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!packageDetails.title || !packageDetails.price) {
      setError('Title and price are required');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      let imageUrl = packageDetails.imageUrl;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      }

      const packageData = {
        title: packageDetails.title,
        description: packageDetails.description || '',
        price: parseFloat(packageDetails.price),
        products: selectedProducts.map(p => ({
          id: p.id,
          name: p.name,
          imageUrl: p.imageUrl || '',
          quantity: p.quantity || 1
        })),
        features: packageDetails.features.filter(f => f.trim() !== ''),
        imageUrl: imageUrl || ''
      };

      if (packageDetails.id) {
        await updateDoc(doc(db, 'packages', packageDetails.id), packageData);
        setError('Package updated successfully!');
      } else {
        await addDoc(collection(db, 'packages'), packageData);
        setError('Package created successfully!');
      }

      await fetchPackages();
      setPackageDetails({
        title: '',
        description: '',
        price: '',
        features: [''],
        id: null,
        imageUrl: ''
      });
      setSelectedProducts([]);
      setImageFile(null);
      setImagePreview(null);
    } catch (err) {
      console.error('Error saving package:', err);
      setError('Failed to save package: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const movePackage = async (currentIndex, direction) => {
    const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= packages.length) return;

    const newPackages = [...packages];
    const [movedPackage] = newPackages.splice(currentIndex, 1);
    newPackages.splice(newIndex, 0, movedPackage);

    // Update displayOrder for all packages
    const updatedPackages = newPackages.map((pkg, index) => ({
      ...pkg,
      displayOrder: index
    }));

    setPackages(updatedPackages);
    setOrderChanged(true);
  };

  const moveProduct = (packageIndex, currentIndex, direction) => {
    const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= packages[packageIndex].products.length) return;

    const updatedPackages = [...packages];
    const packageProducts = [...updatedPackages[packageIndex].products];
    const [movedProduct] = packageProducts.splice(currentIndex, 1);
    packageProducts.splice(newIndex, 0, movedProduct);
    updatedPackages[packageIndex].products = packageProducts;

    setPackages(updatedPackages);
    setOrderChanged(true);
  };

  const saveDisplayOrder = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const batch = writeBatch(db);
      
      packages.forEach((pkg) => {
        const packageRef = doc(db, 'packages', pkg.docId);
        batch.update(packageRef, { 
          displayOrder: pkg.displayOrder,
          products: pkg.products // Save the reordered products
        });
      });
      
      await batch.commit();
      setOrderChanged(false);
      setError('Display order saved successfully!');
    } catch (error) {
      console.error('Error saving display order:', error);
      setError('Failed to save display order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollView style={styles.container}>
      <View>
        <Text style={styles.title}>Package Builder</Text>
      </View>
      
      {error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          <GradientButton
            onPress={() => setError(null)}
            text="Dismiss"
            variant="secondary"
            style={styles.dismissButton}
          />
        </View>
      )}

      <View style={styles.form}>
        <View>
          <TextInput
            style={styles.input}
            placeholder="Package Title"
            value={packageDetails.title}
            onChangeText={(text) => setPackageDetails({...packageDetails, title: text})}
          />
        </View>

        <View style={styles.imageUploadContainer}>
          <View>
            <Text style={styles.sectionTitle}>Package Image</Text>
          </View>
          {imagePreview || packageDetails.imageUrl ? (
            <View style={styles.imagePreviewContainer}>
              <img
                src={imagePreview || packageDetails.imageUrl}
                alt="Package preview"
                style={styles.imagePreview}
              />
              <GradientButton
                onPress={() => {
                  setImageFile(null);
                  setImagePreview(null);
                  setPackageDetails({...packageDetails, imageUrl: ''});
                }}
                text="Remove Image"
                variant="secondary"
                style={styles.removeImageButton}
              />
            </View>
          ) : (
            <View style={styles.imageUploadBox}>
              <HiddenFileInput
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
              />
              <FileInputLabel onClick={() => fileInputRef.current?.click()}>
                Choose Image
              </FileInputLabel>
            </View>
          )}
        </View>

        <TextInput
          style={[styles.input, styles.textArea]}
          placeholder="Package Description"
          value={packageDetails.description}
          onChangeText={(text) => setPackageDetails({...packageDetails, description: text})}
          multiline
        />

        <TextInput
          style={styles.input}
          placeholder="Package Price"
          value={packageDetails.price}
          onChangeText={(text) => setPackageDetails({...packageDetails, price: text})}
          keyboardType="numeric"
        />

        <View style={styles.featuresContainer}>
          <Text style={styles.sectionTitle}>Package Features</Text>
          {packageDetails.features.map((feature, index) => (
            <View key={index} style={styles.featureRow}>
              <TextInput
                style={[styles.input, styles.featureInput]}
                placeholder="Feature description"
                value={feature}
                onChangeText={(text) => handleFeatureChange(index, text)}
              />
              <GradientButton
                onPress={() => removeFeature(index)}
                text="Remove"
                variant="secondary"
                style={styles.removeButton}
              />
            </View>
          ))}
          <GradientButton
            onPress={addFeature}
            text="Add Feature"
            variant="secondary"
            style={styles.addButton}
          />
        </View>

        <View style={styles.productsSection}>
          <Text style={styles.sectionTitle}>Add Products to Package</Text>
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search products by name or SKU..."
          />
          <ProductsGrid>
            {filteredProducts.map((product) => (
              <ProductCard key={product.id}>
                {product.imageUrl && (
                  <img 
                    src={product.imageUrl} 
                    alt={product.name}
                    style={styles.productImage}
                  />
                )}
                <View style={styles.productInfo}>
                  <Text style={styles.productName}>{product.name}</Text>
                  <Text style={styles.productPrice}>${product.price}</Text>
                  <Text style={styles.productSku}>SKU: {product.sku}</Text>
                </View>
                <GradientButton
                  onPress={() => handleProductSelect(product)}
                  text="Add"
                  variant="secondary"
                  style={styles.addButton}
                />
              </ProductCard>
            ))}
          </ProductsGrid>
        </View>

        <View style={styles.selectedProducts}>
          <Text style={styles.sectionTitle}>Selected Products</Text>
          {selectedProducts.map((product) => (
            <View key={product.id} style={styles.selectedProductItem}>
              {product.imageUrl && (
                <img 
                  src={product.imageUrl} 
                  alt={product.name}
                  style={styles.productImage}
                />
              )}
              <Text style={styles.productName}>{product.name}</Text>
              <View style={styles.quantityContainer}>
                <GradientButton
                  onPress={() => handleQuantityAdjust(product.id, -1)}
                  text="-"
                  variant="secondary"
                  style={styles.quantityButton}
                />
                <TextInput
                  style={styles.quantityInput}
                  value={String(product.quantity)}
                  onChangeText={(text) => handleQuantityChange(product.id, text)}
                  keyboardType="numeric"
                />
                <GradientButton
                  onPress={() => handleQuantityAdjust(product.id, 1)}
                  text="+"
                  variant="secondary"
                  style={styles.quantityButton}
                />
              </View>
            </View>
          ))}
        </View>

        <View style={styles.buttonContainer}>
          <GradientButton
            onPress={handleSubmit}
            text={packageDetails.id ? "Update Package" : "Create Package"}
            disabled={loading}
            style={styles.submitButton}
          />
        </View>
      </View>

      <View style={styles.packagesList}>
        <View style={styles.packageListHeader}>
          <Text style={styles.sectionTitle}>Existing Packages</Text>
          {orderChanged && (
            <GradientButton
              onPress={saveDisplayOrder}
              text="Save Order"
              variant="primary"
              style={styles.saveOrderButton}
              disabled={loading}
            />
          )}
        </View>
        {packages.map((pkg, packageIndex) => (
          <View key={pkg.docId} style={styles.packageItem}>
            <View style={styles.reorderControls}>
              <GradientButton
                onPress={() => movePackage(packageIndex, 'left')}
                text="←"
                variant="secondary"
                style={styles.reorderButton}
                disabled={packageIndex === 0}
              />
              <Text style={styles.orderText}>Order: {packageIndex + 1}</Text>
              <GradientButton
                onPress={() => movePackage(packageIndex, 'right')}
                text="→"
                variant="secondary"
                style={styles.reorderButton}
                disabled={packageIndex === packages.length - 1}
              />
            </View>
            <View style={styles.packageInfo}>
              <Text style={styles.packageTitle}>{pkg.title || 'Untitled Package'}</Text>
              <Text style={styles.packagePrice}>${pkg.price || '0'}</Text>
              <Text style={styles.packageDescription}>{pkg.description || 'No description available'}</Text>
              <View style={styles.packageProducts}>
                {pkg.products?.map((product, productIndex) => (
                  <View key={product.id} style={styles.packageProductContainer}>
                    <View style={styles.productReorderControls}>
                      <GradientButton
                        onPress={() => moveProduct(packageIndex, productIndex, 'left')}
                        text="←"
                        variant="secondary"
                        style={styles.productReorderButton}
                        disabled={productIndex === 0}
                      />
                      <GradientButton
                        onPress={() => moveProduct(packageIndex, productIndex, 'right')}
                        text="→"
                        variant="secondary"
                        style={styles.productReorderButton}
                        disabled={productIndex === pkg.products.length - 1}
                      />
                    </View>
                    <View style={styles.packageProductThumb}>
                      <img 
                        src={product.imageUrl || 'https://via.placeholder.com/50'} 
                        alt={product.name}
                        style={styles.thumbImage}
                      />
                      <Text style={styles.thumbQuantity}>x{product.quantity || 1}</Text>
                    </View>
                  </View>
                ))}
              </View>
              {pkg.features && pkg.features.length > 0 && (
                <View style={styles.packageFeatures}>
                  {pkg.features.map((feature, index) => (
                    <Text key={index} style={styles.featureText}>• {feature}</Text>
                  ))}
                </View>
              )}
            </View>
            <View style={styles.packageActions}>
              <GradientButton
                onPress={() => handleEdit(pkg)}
                text="Edit"
                variant="secondary"
                style={styles.actionButton}
              />
              <GradientButton
                onPress={() => handleDelete(pkg.docId)}
                text="Delete"
                variant="secondary"
                style={[styles.actionButton, styles.deleteButton]}
              />
            </View>
          </View>
        ))}
      </View>

      {loading && (
        <View style={styles.loadingOverlay}>
          <Text style={styles.loadingText}>Processing...</Text>
        </View>
      )}


      {selectedTab === 'products' && <ProductsTabContent />}
      {selectedTab === 'features' && <FeaturesTabContent />}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary.solid,
  },
  form: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  input: {
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    fontSize: 14,
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  featuresContainer: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: colors.primary.solid,
  },
  featureRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  featureInput: {
    flex: 1,
    marginRight: 10,
    marginBottom: 0,
  },
  productsSection: {
    marginBottom: 20,
  },
  productsList: {
    // Remove this style
  },
  productItem: {
    // Remove this style
  },
  selectedProducts: {
    marginBottom: 20,
  },
  selectedProductItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: colors.lightGray,
    borderRadius: 5,
    marginBottom: 10,
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  quantityLabel: {
    marginRight: 10,
    fontSize: 14,
  },
  quantityInput: {
    width: 60,
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 5,
    textAlign: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: 20,
  },
  submitButton: {
    minWidth: 180,
  },
  addButton: {
    minWidth: 100,
    height: 36,
    fontSize: 14,
  },
  removeButton: {
    minWidth: 100,
    height: 36,
    fontSize: 14,
  },
  errorContainer: {
    backgroundColor: props => props.error?.includes('successfully') 
      ? 'rgba(0, 255, 0, 0.1)' 
      : 'rgba(255, 0, 0, 0.1)',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorText: {
    color: props => props.error?.includes('successfully') 
      ? colors.success 
      : colors.error,
    flex: 1,
  },
  dismissButton: {
    minWidth: 80,
    marginLeft: 10,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: colors.primary.solid,
    fontSize: 16,
    fontWeight: '600',
  },
  quantityButton: {
    width: 30,
    height: 30,
    padding: 0,
    minWidth: 'auto',
    fontSize: 16,
  },
  packagesList: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginTop: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  packageItem: {
    flexDirection: 'column',
    padding: 25,
    borderRadius: 15,
    marginBottom: 25,
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  packageInfo: {
    flex: 1,
    marginBottom: 20,
  },
  packageTitle: {
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 10,
    color: colors.primary.solid,
  },
  packagePrice: {
    fontSize: 20,
    color: colors.primary.solid,
    marginBottom: 20,
  },
  packageDescription: {
    fontSize: 16,
    color: '#666',
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
  packageProducts: {
    flexDirection: 'row',
    gap: 20,
    flexWrap: 'wrap',
    marginBottom: 20,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  packageProductContainer: {
    position: 'relative',
    padding: 10,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    minWidth: 80,
  },
  packageProductThumb: {
    position: 'relative',
    width: 70,
    height: 70,
    marginBottom: 10,
  },
  thumbImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: 5,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  thumbQuantity: {
    position: 'absolute',
    right: -5,
    bottom: -5,
    backgroundColor: colors.primary.solid,
    color: 'white',
    borderRadius: 10,
    padding: '2px 5px',
    fontSize: 12,
  },
  packageFeatures: {
    marginTop: 20,
    paddingTop: 20,
    borderTopWidth: 1,
    borderTopColor: '#eee',
  },
  featureText: {
    fontSize: 16,
    color: '#666',
    marginBottom: 10,
    paddingLeft: 20,
  },
  reorderControls: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    marginBottom: 15,
    justifyContent: 'flex-end',
  },
  packageActions: {
    flexDirection: 'row',
    gap: 15,
    marginTop: 20,
    justifyContent: 'flex-end',
    borderTop: '1px solid #eee',
    paddingTop: 20,
  },
  actionButton: {
    minWidth: 100,
    height: 40,
  },
  deleteButton: {
    backgroundColor: colors.error,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '600',
  },
  orderText: {
    fontSize: 14,
    color: colors.gray,
  },
  imageUploadContainer: {
    marginBottom: 20,
    width: '100%',
  },
  imagePreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 10,
  },
  imagePreview: {
    width: 200,
    height: 200,
    objectFit: 'contain',
    borderRadius: 8,
    backgroundColor: '#f5f5f5',
    padding: 10,
    border: '1px solid #ddd',
  },
  imageUploadBox: {
    width: '100%',
    minHeight: 100,
    borderWidth: 2,
    borderColor: colors.gray,
    borderStyle: 'dashed',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: 10,
    backgroundColor: '#f9f9f9',
  },
  removeImageButton: {
    minWidth: 120,
    marginTop: 10,
  },
  packageListHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  saveOrderButton: {
    minWidth: 120,
    height: 36,
  },
  reorderButton: {
    width: 36,
    height: 36,
    padding: 0,
    minWidth: 'auto',
  },
  productReorderControls: {
    position: 'absolute',
    top: -20,
    left: '50%',
    transform: 'translateX(-50%)',
    flexDirection: 'row',
    gap: 8,
    zIndex: 1,
  },
  productReorderButton: {
    width: 28,
    height: 28,
    padding: 0,
    minWidth: 'auto',
    fontSize: 14,
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  productInfo: {
    width: '100%',
    textAlign: 'center',
  },
  productImage: {
    width: 100,
    height: 100,
    objectFit: 'contain',
    borderRadius: 5,
    backgroundColor: 'white',
    padding: 5,
  },
  productName: {
    fontSize: 14,
    fontWeight: '500',
    marginBottom: 5,
  },
  productPrice: {
    color: colors.primary.solid,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  productSku: {
    fontSize: 12,
    color: colors.gray,
    marginBottom: 10,
  },
}); 