import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db } from '../config/firebase';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { colors } from '../styles/theme';
import { useCart } from '../context/CartContext';

export const ProductRecommendation = ({ location = 'cart', onClose }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [matchingRecommendation, setMatchingRecommendation] = useState(null);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const { cartItems, addToCart } = useCart();

  // Add function to fetch all products
  const fetchProducts = async () => {
    try {
      const productsSnapshot = await getDocs(collection(db, 'products'));
      const productsData = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        displayOrder: doc.data().displayOrder || 0
      }));
      
      // Sort products by displayOrder
      const sortedProducts = productsData.sort((a, b) => 
        a.displayOrder - b.displayOrder
      );
      
      setProducts(sortedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Add useEffect to fetch products on mount
  useEffect(() => {
    fetchProducts();
  }, []);

  // Function to count total components
  const getTotalComponentsByCategory = () => {
    let counts = {
      cameras: 0,
      nvr: 0,
      halo: 0,
      deterrence: 0
    };

    cartItems.forEach(item => {
      if (item.type === 'system') {
        // Count cameras in system
        if (item.components?.cameras) {
          counts.cameras += item.components.cameras.reduce((sum, camera) => sum + (camera.quantity || 1), 0);
        }
        // Count Virtual Guard
        if (item.components?.model) {
          counts.halo += 1;
        }
        // Count NVR
        if (item.components?.nvr) {
          counts.nvr += 1;
        }
        // Count deterrence devices
        if (item.components?.deterrence) {
          counts.deterrence += item.components.deterrence.reduce((sum, device) => sum + (device.quantity || 1), 0);
        }
      } else if (item.type === 'package' && item.products) {
        // Count components in packages
        item.products.forEach(product => {
          if (product.name?.toLowerCase().includes('camera') || product.name?.toLowerCase().includes('hikvision')) {
            counts.cameras += (product.quantity || product.packageQuantity || 1);
          }
          if (product.name?.toLowerCase().includes('nvr')) {
            counts.nvr += (product.quantity || product.packageQuantity || 1);
          }
          if (product.name?.toLowerCase().includes('halo')) {
            counts.halo += (product.quantity || product.packageQuantity || 1);
          }
          if (product.name?.toLowerCase().includes('deterrence')) {
            counts.deterrence += (product.quantity || product.packageQuantity || 1);
          }
        });
      } else {
        // Count standalone products
        if (item.name?.toLowerCase().includes('camera') || item.name?.toLowerCase().includes('hikvision')) {
          counts.cameras += (item.quantity || 1);
        }
        if (item.name?.toLowerCase().includes('nvr')) {
          counts.nvr += (item.quantity || 1);
        }
        if (item.name?.toLowerCase().includes('halo')) {
          counts.halo += (item.quantity || 1);
        }
        if (item.name?.toLowerCase().includes('deterrence')) {
          counts.deterrence += (item.quantity || 1);
        }
      }
    });

    console.log('Total components counted:', counts); // Add logging
    return counts;
  };

  // Function to check if any excluded products are in cart
  const hasExcludedProducts = (excludedProducts) => {
    if (!excludedProducts || excludedProducts.length === 0) return false;

    // Helper function to check if a product matches any excluded product
    const isExcluded = (product) => {
      // Check by ID
      if (excludedProducts.includes(product.id) || excludedProducts.includes(product.productId)) {
        console.log('Found excluded product by ID:', product.id || product.productId);
        return true;
      }

      // Check by name
      const productName = product.name?.toLowerCase() || '';
      const matchingExcluded = excludedProducts.find(excludedId => {
        const excludedProduct = products.find(p => p.id === excludedId);
        if (!excludedProduct) return false;
        return productName.includes(excludedProduct.name.toLowerCase());
      });

      if (matchingExcluded) {
        console.log('Found excluded product by name:', product.name);
        return true;
      }

      return false;
    };

    return cartItems.some(item => {
      // Check the main item itself
      if (isExcluded(item)) return true;

      // Check system components
      if (item.type === 'system') {
        const systemComponents = [
          item.components?.model,         // HALO
          item.components?.nvr,          // NVR
          item.components?.power,        // POE
          ...(item.components?.alarms || []),
          ...(item.components?.cameras || [])
        ].filter(Boolean);

        return systemComponents.some(component => {
          if (isExcluded(component)) {
            console.log('Found excluded component in system:', component.name);
            return true;
          }
          return false;
        });
      }

      // Check package products
      if (item.type === 'package' && item.products) {
        return item.products.some(product => {
          if (isExcluded(product)) {
            console.log('Found excluded product in package:', product.name);
            return true;
          }
          return false;
        });
      }

      return false;
    });
  };

  const handleAddToCart = (product) => {
    addToCart({
      ...product,
      quantity: 1
    });
  };

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const recommendationsDoc = await getDoc(doc(db, 'siteSettings', 'recommendations'));
        if (recommendationsDoc.exists()) {
          const data = recommendationsDoc.data();
          console.log('Fetched recommendations:', data);
          
          // Get total components
          const componentCounts = getTotalComponentsByCategory();
          const totalComponents = componentCounts.cameras + componentCounts.nvr + componentCounts.halo + componentCounts.deterrence;
          console.log('Total components:', totalComponents);

          // Find matching recommendation
          const matching = data.recommendations?.[0]; // Only one recommendation now
          
          if (matching) {
            console.log('Found recommendation:', matching);
            
            // Check for excluded products first
            const hasExcluded = hasExcludedProducts(matching.excludedProducts || []);
            console.log('Has excluded products:', hasExcluded);

            if (hasExcluded) {
              console.log('Excluded products found in cart, hiding recommendations');
              setMatchingRecommendation(null);
              setRecommendedProducts([]);
              return;
            }

            console.log('Conditions check:', {
              totalComponents,
              minRequired: matching.conditions.minTotalComponents,
              showInLocation: location === 'cart' ? matching.showInCart : matching.showInCheckout
            });

            if (totalComponents >= matching.conditions.minTotalComponents &&
                ((location === 'cart' && matching.showInCart) || 
                 (location === 'checkout' && matching.showInCheckout))) {
              
              setMatchingRecommendation(matching);
              
              // Fetch recommended products
              const products = await Promise.all(
                matching.products
                  .filter(productId => productId)
                  .map(async productId => {
                    const productDoc = await getDoc(doc(db, 'products', productId));
                    if (productDoc.exists()) {
                      return { id: productId, ...productDoc.data() };
                    }
                    return null;
                  })
              );
              
              const validProducts = products.filter(p => p !== null);
              console.log('Setting recommended products:', validProducts);
              setRecommendedProducts(validProducts);
            } else {
              console.log('Recommendation conditions not met');
              setMatchingRecommendation(null);
              setRecommendedProducts([]);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    };

    if (cartItems.length > 0) {
      fetchRecommendations();
    }
  }, [cartItems, location]);

  if (!matchingRecommendation || recommendedProducts.length === 0) return null;

  return (
    <RecommendationContainer $location={location}>
      {location === 'checkout' && (
        <CloseButton onClick={onClose}>×</CloseButton>
      )}
      <RecommendationTitle>Recommended Products</RecommendationTitle>
      <RecommendationMessage dangerouslySetInnerHTML={{ __html: matchingRecommendation.message }} />
      <ProductsGrid>
        {recommendedProducts.map(product => (
          <ProductCard key={product.id}>
            {product.imageUrl && (
              <ProductImage src={product.imageUrl} alt={product.name} />
            )}
            <ProductInfo>
              <ProductName>{product.name}</ProductName>
              <ProductPrice>${product.price.toFixed(2)}</ProductPrice>
            </ProductInfo>
            <AddToCartButton onClick={() => handleAddToCart(product)}>
              Add to Cart
            </AddToCartButton>
          </ProductCard>
        ))}
      </ProductsGrid>
    </RecommendationContainer>
  );
};

const RecommendationContainer = styled.div`
  background: #fee;
  border-radius: 10px;
  padding: 20px;
  margin: ${props => props.$location === 'cart' ? '20px 0' : '0 0 20px 0'};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 2px solid ${colors.primary.solid};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: ${colors.primary.solid};
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    opacity: 0.8;
  }
`;

const RecommendationTitle = styled.h3`
  font-family: 'Tahoma', sans-serif;
  font-size: 18px;
  color: ${colors.primary.solid};
  margin: 0 0 10px;
`;

const RecommendationMessage = styled.p`
  font-family: 'Tahoma', sans-serif;
  font-size: 13px;
  color: #666;
  margin: 0 0 12px;
  line-height: 1.4;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
`;

const ProductCard = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h4`
  font-family: 'Tahoma', sans-serif;
  font-size: 14px;
  margin: 0 0 5px;
  color: #333;
`;

const ProductPrice = styled.p`
  font-family: 'Tahoma', sans-serif;
  font-size: 14px;
  color: ${colors.primary.solid};
  font-weight: 600;
  margin: 0;
`;

const AddToCartButton = styled.button`
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: auto;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`; 