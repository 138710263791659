import React, { useEffect, useRef } from 'react';
import { Animated, View, StyleSheet, useWindowDimensions, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { colors } from '../styles/theme';

export const AnimatedGradientBackground = ({ children, style }) => {
  const { width, height } = useWindowDimensions();
  const translateX1 = useRef(new Animated.Value(0)).current;
  const translateY1 = useRef(new Animated.Value(0)).current;
  const translateX2 = useRef(new Animated.Value(0)).current;
  const translateY2 = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const createAnimation = (value, toValue, duration) => {
      return Animated.sequence([
        Animated.timing(value, {
          toValue,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(value, {
          toValue: 0,
          duration,
          useNativeDriver: true,
        })
      ]);
    }; 
    

    Animated.parallel([
      Animated.loop(createAnimation(translateX1, 100, 20000)),
      Animated.loop(createAnimation(translateY1, 80, 25000)),
      Animated.loop(createAnimation(translateX2, -100, 22000)),
      Animated.loop(createAnimation(translateY2, -80, 23000)),
    ]).start();
  }, []);

  if (Platform.OS === 'web') {
    const webStyle = {
      background: colors.white,
    };

    return (
      <View style={[styles.container, { width, minHeight: height }, webStyle, style]}>
        <Animated.View
          style={[
            styles.gradientBlob1,
            {
              transform: [
                { translateX: translateX1 },
                { translateY: translateY1 }
              ]
            }
          ]}
        />
        <Animated.View
          style={[
            styles.gradientBlob2,
            {
              transform: [
                { translateX: translateX2 },
                { translateY: translateY2 }
              ]
            }
          ]}
        />
        <View style={styles.content}>
          {children}
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.container, { width, height }, style]}>
      <LinearGradient
        colors={['rgba(231, 28, 36, 0.05)', 'rgba(145, 37, 40, 0.05)']}
        style={StyleSheet.absoluteFillObject}
      />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundImage: 'linear-gradient(0deg, #e71c24 -14.96%, #912528 100%)',
    overflow: 'hidden',
    position: 'relative',
  },
  gradientBlob1: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '120%',
    height: '120%',
    borderRadius: '50%',
    background: 'radial-gradient(circle at center, rgba(231, 28, 36, 0.08) 0%, rgba(231, 28, 36, 0) 70%)',
    filter: 'blur(80px)',
    opacity: 0.8,
  },
  gradientBlob2: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '120%',
    height: '120%',
    borderRadius: '50%',
    background: 'radial-gradient(circle at center, rgba(145, 37, 40, 0.08) 0%, rgba(145, 37, 40, 0) 70%)',
    filter: 'blur(80px)',
    opacity: 0.8,
  },
  content: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
}); 